import React, { useState } from 'react';
import {
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import { useMutation, useSubscription, gql } from '@apollo/client';
import { boxShadows } from '../../../constants';
import { useColors } from '../../../hooks';

const { themeColors } = useColors();

const TAGS = gql`
  subscription TAGS {
    tag(order_by: { tag_value: asc_nulls_last }) {
      tag_id
      tag_value
    }
  }
`;
const CATEGORIES = gql`
  subscription CATEGORIES {
    category(order_by: { category_name: asc_nulls_last }) {
      category_id
      category_name
    }
  }
`;

const ADD_TAG = gql`
  mutation ADD_TAG($tag_value: String) {
    insert_tag(objects: { tag_value: $tag_value }) {
      affected_rows
    }
  }
`;
const DELETE_TAG = gql`
  mutation DELETE_TAG($tag_id: Int) {
    delete_tag(where: { tag_id: { _eq: $tag_id } }) {
      affected_rows
    }
  }
`;

const ADD_CATEGORY = gql`
  mutation ADD_CATEGORY($category_name: String) {
    insert_category(objects: { category_name: $category_name }) {
      affected_rows
    }
  }
`;
const DELETE_CATEGORY = gql`
  mutation DELETE_CATEGORY($category_id: Int) {
    delete_category(where: { category_id: { _eq: $category_id } }) {
      affected_rows
    }
  }
`;

const KeywordsCategories = () => {
  const [addTag] = useMutation(ADD_TAG);
  const [deleteTag] = useMutation(DELETE_TAG);
  const [addCategory] = useMutation(ADD_CATEGORY);
  const [deleteCategory] = useMutation(DELETE_CATEGORY);
  const {
    loading: loadingKeywords,
    error: errorKeywords,
    data: Keywords,
  } = useSubscription(TAGS);
  const {
    loading: loadingCategories,
    error: errorCategories,
    data: Categories,
  } = useSubscription(CATEGORIES);
  const bodytext = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 30,
  };

  // Dialog Functions
  const [dialogTitle, setDialogTitle] = useState(undefined);
  const [dialogType, setDialogType] = useState(undefined);
  const [dialogCategory, setDialogCategory] = useState(undefined);
  const [value, setValue] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(undefined);

  const handleClickOpen = (title, id) => {
    setDialogTitle(title);
    setDeleteID(id);
    setOpen(true);
  };
  const handleTagDelete = (title, id) => {
    setDialogType('delete');
    setDialogCategory('tag');
    handleClickOpen(title, id);
  };
  const handleTagAdd = title => {
    setDialogCategory('tag');
    setDialogType('add');
    handleClickOpen(title);
  };

  const handleCatDelete = (title, id) => {
    setDialogType('delete');
    setDialogCategory('category');
    handleClickOpen(title, id);
  };
  const handleCatAdd = title => {
    setDialogCategory('category');
    setDialogType('add');
    handleClickOpen(title);
  };

  const handleValue = e => {
    setValue(e.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAgreeAddDeleteTag = () => {
    setOpen(false);
    if (dialogType === 'add') {
      addTag({ variables: { tag_value: value } });
    }
    if (dialogType === 'delete') {
      deleteTag({ variables: { tag_id: deleteID } });
    }
  };
  const handleCloseAgreeAddDeleteCategory = () => {
    setOpen(false);
    if (dialogType === 'add') {
      addCategory({ variables: { category_name: value } });
    }
    if (dialogType === 'delete') {
      deleteCategory({ variables: { category_id: deleteID } });
    }
  };
  // conditionals for apollo subscriptions and queries
  if (loadingKeywords) {
    return <div>loading...</div>;
  }
  if (errorKeywords) {
    return <div>there has been an error</div>;
  }
  if (loadingCategories) {
    return <div>loading...</div>;
  }
  if (errorCategories) {
    return <div>there has been an error</div>;
  }
  return (
    <>
      <div style={bodytext}>
        <div className="poststyling">
          <div style={{ height: 30 }} />
          <div style={{ margin: 'auto' }}>
            <h2>Keywords</h2>
            <Paper
              style={{
                padding: 30,
                borderLeft: `20px double ${themeColors.mainColors.primaryColor}`,
                margin: 'auto',
                marginBottom: 45,
                boxShadow: boxShadows.three,
              }}
            >
              <div style={{ margin: 5 }}>
                {Keywords.tag.map(({ tag_id, tag_value }) => (
                  <Chip
                    key={tag_id}
                    label={tag_value}
                    style={{ margin: 5 }}
                    color="secondary"
                    onDelete={() =>
                      handleTagDelete(
                        `Delete Keyword '${tag_value}'?`,
                        tag_id,
                        tag_value,
                      )
                    }
                  />
                ))}
                <Chip
                  label="Add Keyword"
                  style={{ margin: 5 }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleTagAdd('Add Keyword')}
                />
              </div>
            </Paper>
            {/* //categories section */}
            <h2>Categories</h2>
            <Paper
              style={{
                padding: 30,
                borderLeft: `20px double ${themeColors.mainColors.primaryColor}`,
                margin: 'auto',
                marginBottom: 45,
                boxShadow: boxShadows.three,
              }}
            >
              <div style={{ margin: 5 }}>
                {Categories.category.map(({ category_id, category_name }) => (
                  <Chip
                    key={category_id}
                    label={category_name}
                    style={{ margin: 5 }}
                    color="secondary"
                    onDelete={() =>
                      handleCatDelete(
                        `Delete Category '${category_name}'?`,
                        category_id,
                        category_name,
                      )
                    }
                  />
                ))}
                <Chip
                  label="Add Category"
                  style={{ margin: 5 }}
                  color="secondary"
                  variant="outlined"
                  onClick={() => handleCatAdd('Add Category')}
                />
              </div>
            </Paper>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogType === 'add' ? (
              <TextField
                id="filled-basic"
                label={
                  dialogCategory === 'tag' ? 'Keyword Name' : 'Category Name'
                }
                variant="filled"
                onChange={handleValue}
              />
            ) : (
              <div style={{ textAlign: 'center', width: '100%' }}>
                This action is permanent and cannot be undone.
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            Cancel
          </Button>
          <Button
            onClick={
              dialogCategory === 'tag'
                ? handleCloseAgreeAddDeleteTag
                : handleCloseAgreeAddDeleteCategory
            }
            color="primary"
            autoFocus
            style={{ fontWeight: 'bold' }}
          >
            {dialogType === 'add' ? 'Submit' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default KeywordsCategories;
