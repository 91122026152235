/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Delete, Download } from '@bit/starkslawpc.core.icons';
import {
  Button,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DropContainer, DropText, LoaderContainer, UploadContainer } from '.';
import { useColors } from '../../../../../hooks';
import { Uploader } from '../../../../../utils/Uploader';

interface UploadProps {
  clientID: string;
  matterID: string;
  reload: number;
  setReload: (x: number) => void;
}

export const Upload: React.FC<UploadProps> = ({
  clientID,
  matterID,
  reload,
  setReload,
}) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone();
  const { themeColors } = useColors();
  const [files, setFiles] = useState<any>();
  const [readyFiles, setReadyFiles] = useState<any>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const dropFiles = acceptedFiles?.map(f => f);
      setFiles(dropFiles);
      Array.from(dropFiles).forEach(f =>
        // eslint-disable-next-line no-shadow
        setReadyFiles((readyFiles: []) => [...readyFiles, (f as any).name]),
      );
    }
  }, [acceptedFiles]);

  const handleUpload = (e: any): any => {
    const docs = e.target.files;
    Array.from(docs).forEach(f =>
      // eslint-disable-next-line no-shadow
      setReadyFiles((readyFiles: []) => [...readyFiles, (f as any).name]),
    );
    setFiles(docs);
  };

  const handleClearFiles = (): void => {
    setFiles(null);
    setReadyFiles([]);
  };

  return (
    <>
      <DropContainer
        isDragActive={isDragActive}
        color={themeColors.mainColors as any}
        {...getRootProps({ className: 'dropzone' })}
      >
        <Download width={72} fill={themeColors.mainColors.primaryColor} />
        <Typography variant="h2" component="div">
          <DropText files={files || []} />
        </Typography>
        <input
          {...getInputProps()}
          style={{ display: 'none' }}
          multiple
          id="file-upload"
          type="file"
          onChange={handleUpload}
        />
      </DropContainer>
      <UploadContainer>
        <>
          {(open && (
            <LoaderContainer>
              <LinearProgress
                variant="determinate"
                value={progress}
                color="primary"
              />
            </LoaderContainer>
          )) || (
            <TextField
              fullWidth
              id="api-key-input"
              label="File Name(s)"
              variant="outlined"
              value={readyFiles.join(' || ')}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {files && (
                      <div onClick={handleClearFiles}>
                        <Delete
                          width={18}
                          style={{ cursor: 'pointer', margin: '0 10px' }}
                          fill={themeColors.mainColors.danger}
                        />
                      </div>
                    )}
                    {!files && (
                      <label htmlFor="file-upload">
                        <Button
                          variant="outlined"
                          component="span"
                          color="primary"
                        >
                          Browse
                        </Button>
                      </label>
                    )}
                    <input
                      style={{ display: 'none' }}
                      multiple
                      id="file-upload"
                      type="file"
                      onChange={handleUpload}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </>
        {files && (
          <Button
            variant="outlined"
            fullWidth
            disabled={open}
            component="span"
            color="primary"
            onClick={(): void =>
              Uploader(
                files,
                clientID,
                matterID,
                reload,
                setReload,
                setOpen,
                setProgress,
                handleClearFiles,
              )
            }
            style={{ height: '100%', maxWidth: '20vw' }}
          >
            Upload
          </Button>
        )}
      </UploadContainer>
    </>
  );
};
