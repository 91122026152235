import React from 'react';
import styled from 'styled-components';
import {
  AtomSpinner,
  FingerprintSpinner,
  CirclesToRhombusesSpinner,
  LoopingRhombusesSpinner,
  SemipolarSpinner,
} from 'react-epic-spinners';
import { useColors } from '../hooks';

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

interface SpinnerTypes {
  type?:
    | 'atom'
    | 'finger print'
    | '3 circles linear'
    | '3 linear moving squares';
  size?: number;
}

const defaultProps = {
  size: 35,
};

export const Loader: React.FC<SpinnerTypes> = ({ size, type }): any => {
  const { themeColors } = useColors();

  if (type === 'atom') {
    return (
      <SpinnerContainer>
        <AtomSpinner color={themeColors.mainColors.primaryColor} size={size} />
      </SpinnerContainer>
    );
  }
  if (type === 'finger print') {
    return (
      <SpinnerContainer>
        <FingerprintSpinner
          color={themeColors.mainColors.primaryColor}
          size={size}
        />
      </SpinnerContainer>
    );
  }
  if (type === '3 circles linear') {
    return (
      <SpinnerContainer>
        <CirclesToRhombusesSpinner
          color={themeColors.mainColors.primaryColor}
          size={size}
        />
      </SpinnerContainer>
    );
  }
  if (type === '3 linear moving squares') {
    return (
      <SpinnerContainer>
        <LoopingRhombusesSpinner
          color={themeColors.mainColors.primaryColor}
          size={size}
        />
      </SpinnerContainer>
    );
  }
  return (
    <SpinnerContainer>
      <SemipolarSpinner
        color={themeColors.mainColors.primaryColor}
        size={size}
      />
    </SpinnerContainer>
  );
};
Loader.defaultProps = defaultProps;
