export const GetInitials = (name): string | undefined => {
  const pieces = name
    ?.trim()
    .replace('  ', ' ')
    .split(' ');
  let initials;
  if (pieces?.length > 2) {
    initials = `${pieces[0].split('')[0]} ${pieces[1].split('')[0]} ${
      pieces[2].split('')[0]
    }`
      .replace(' ', '')
      .replace(' ', '')
      .toUpperCase();
  } else if (pieces.length <= 2) {
    initials = `${pieces[0]?.split('')[0]} ${pieces[1]?.split('')[0]}`
      .replace(' ', '')
      .toUpperCase();
  } else {
    initials = '';
  }
  return initials;
};
