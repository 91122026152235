import React from 'react';
import styled from 'styled-components';
import { useColors } from '../../hooks';

const { themeColors } = useColors();
const StyledError = styled.span`
  color: ${themeColors.mainColors.danger};
  display: block;
  text-align: right;
  font-size: 14px;
  font-weight: bold;
  margin: 12px 0 8px;
  line-height: 1.5;
`;

type ErrorNotificationProps = {
  className?: string;
  show?: boolean;
  text: string;
};

const ErrorNotification: React.FC<ErrorNotificationProps> = ({
  show,
  text,
  ...props
}) => <>{show && <StyledError {...props}>{text}</StyledError>}</>;

export { ErrorNotification };
