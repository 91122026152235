import React, { useState, useEffect } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';

type ToggleProps = {
  label: string;
  initialState?: boolean;
  onToggle: (state: boolean) => void;
  className?: string;
};

const Toggle: React.FC<ToggleProps> = ({
  label,
  initialState,
  onToggle,
  ...props
}) => {
  const [state, setState] = useState(!!initialState);

  useEffect(() => {
    onToggle(state);
  }, [state]);

  return (
    <FormControlLabel
      style={{ marginLeft: 8 }}
      control={
        <Switch
          checked={state}
          onChange={(_, checked): void => setState(checked)}
          name={label}
        />
      }
      label={label}
      {...props}
    />
  );
};

export { Toggle };
