import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TablePagination,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';
import { FingerprintSpinner, HollowDotsSpinner } from 'react-epic-spinners';
import styled from 'styled-components';
import { boxShadows } from '../../../constants/shadows/shadows';
import { useColors } from '../../../hooks';
import { useTaskFilter } from '../../../utils';
import { Matter, MatterEvent, MatterTask } from '../../../utils/types';
import {
  AddTaskModal,
  EditTaskModal,
  SelectWorkflowModal,
  ViewTaskModal,
} from '../../Modals';
import { ATTORNEYS_ALL } from '../../queries';
import { EmptyTasksBody } from '../EmptyTasksBody';
import { TaskTableBody } from './TableBody';
import { TaskTableHead } from './TableHead';
import { TableOptions } from './TableOptions';

type TaskTableProps = {
  tasks: MatterTask[];
  matter?: Matter;
  event?: MatterEvent;
  individual?: boolean;
  loading?: boolean;
  showCompletedSwitch?: boolean;
};

const MultiTaskUpdateAttorney = gql`
  mutation MassTaskUpdateAttorney($task_id: uuid, $attorney_id: Int) {
    update_task(
      where: { task_id: { _eq: $task_id } }
      _set: { task_owner: $attorney_id }
    ) {
      affected_rows
    }
  }
`;
const MultitaskComplete = gql`
  mutation MassTaskComplete($task_id: uuid, $isComplete: Boolean) {
    update_task(
      where: { task_id: { _eq: $task_id } }
      _set: { task_completed: $isComplete }
    ) {
      affected_rows
    }
  }
`;
const MultiTaskDelete = gql`
  mutation DeleteMultiTasks($task_id: uuid) {
    delete_task(where: { task_id: { _eq: $task_id } }) {
      affected_rows
    }
  }
`;
const StyledButtonGroup = styled(ButtonGroup)`
  margin: 0px 0px 0px 0px;
`;
const StyledSectionWrapper = styled.section<{
  topBorderColor: string;
  isSelected: [];
}>`
  width: 100%;
  border-radius: 4px;
  /* padding: 0 0 30px 0; */
  padding: ${({ isSelected }): string =>
    (isSelected.length > 0 && '0 0 0 0') || '0 0 31px 0'};
  box-shadow: ${boxShadows.one};
  border-top: 4px solid ${({ topBorderColor }): string => topBorderColor};
`;

const StyledTablePagination = styled(TablePagination)`
  float: right;
  padding: 0;
  border-bottom: none;
`;

const StyledTable = styled(Table)<{ topBorderColor: string }>`
  border-top: 2px solid ${({ topBorderColor }): string => topBorderColor};
  width: 100%;
`;

const StyledDiv = styled.div<{ borderColor: string }>`
  border-top: 2px solid ${({ borderColor }): string => borderColor};
`;

const LoadContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 25px 0 25px 0;
  margin: auto;
  justify-content: center;
  align-content: center;
`;

const TaskTable: React.FC<TaskTableProps> = ({
  tasks,
  matter,
  event,
  individual,
  loading,
  showCompletedSwitch,
}) => {
  // Logic and state for multi select feature

  const { loading: loadingAttorneys, data } = useQuery(ATTORNEYS_ALL);
  const [isSelected, setIsSelected] = useState<any>([]);
  const [changeLoading, setChangeLoading] = useState<boolean>(false);
  const [openMultiReassign, setOpenMultiReassign] = useState(false);
  const handleModal = (): void => {
    setOpenMultiReassign(true);
  };
  const [attorney, setAttorney] = useState<any>();
  const handleAttorneyChange = (e: any): void => {
    setAttorney(e);
  };
  const [
    updateTaskAttorneyMulti,
    { loading: loadingTaskOwnerChanges },
  ] = useMutation(MultiTaskUpdateAttorney);
  const [
    updateTaskCompleteMulti,
    { loading: loadingTaskCompleteChanges },
  ] = useMutation(MultitaskComplete);
  const [deleteTaskMulti, { loading: loadingTaskDeleteChanges }] = useMutation(
    MultiTaskDelete,
  );
  const [isReversible, setIsReversible] = useState<boolean>(false);
  const handleMultiSelect = (id): any => {
    if (isSelected.indexOf(id as any) === -1) {
      setIsSelected(prevState => [...prevState, id] as any);
    } else {
      setIsSelected(current =>
        current.filter(x => {
          return x !== id;
        }),
      );
    }
  };

  const clearMultiSelectState = (): void => {
    setIsReversible(false);
    setIsSelected([]);
    setTimeout(() => setChangeLoading(false), 1300);
  };
  const handleSaveMulti = (): void => {
    setChangeLoading(true);
    setOpenMultiReassign(false);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < isSelected.length; i++) {
      updateTaskAttorneyMulti({
        variables: { task_id: isSelected[i], attorney_id: attorney.value },
      }).then(() => clearMultiSelectState());
    }
  };
  const handleCompleteMulti = (): void => {
    setChangeLoading(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < isSelected.length; i++) {
      updateTaskCompleteMulti({
        variables: { task_id: isSelected[i], isComplete: true },
      }).then(() => clearMultiSelectState());
    }
    setIsReversible(true);
  };
  const handleReverseCompleteMulti = (): void => {
    setChangeLoading(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < isSelected.length; i++) {
      updateTaskCompleteMulti({
        variables: { task_id: isSelected[i], isComplete: false },
      }).then(() => clearMultiSelectState());
    }
    setIsReversible(false);
  };
  const handleDeleteMulti = (): void => {
    setChangeLoading(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < isSelected.length; i++) {
      deleteTaskMulti({
        variables: { task_id: isSelected[i] },
      }).then(() => clearMultiSelectState());
    }
    setIsReversible(false);
  };

  // end Logic and state for multi select feature

  const { themeColors } = useColors();

  const [openViewTaskModal, setOpenViewTaskModal] = useState(false);
  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const [openSelectWorkflowModal, setOpenSelectWorkflowModal] = useState(false);
  const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
  const [viewedTask, setViewedTask] = useState<MatterTask | null>(null);
  // Added by Travis for pagination ability and dense rows ability
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('taskRowsPerPage') as string, 10) || 5,
  );

  useEffect(() => {
    if (localStorage.getItem('taskTableDense') === 'dense') {
      setDense(true);
    }
    if (localStorage.getItem('taskTableDense') === 'notDense') {
      setDense(false);
    }
    localStorage.setItem('taskRowsPerPage', rowsPerPage.toString());
  }, []);

  const handleChangePage = (
    _event: unknown,
    newPage: React.SetStateAction<number>,
  ): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: { target: { value: string } }): void => {
    setRowsPerPage(parseInt(e.target.value, 10));
    localStorage.setItem('taskRowsPerPage', e.target.value);
    setPage(0);
  };
  // End Added by Travis

  const findTaskById = (taskId: string): MatterTask | undefined => {
    return tasks.find(task => task.task_id === taskId);
  };

  const [filterSwitch, setFilterSwitch] = React.useState({
    completed: false,
    overdue: true,
    inProgress: true,
  });

  const handleFilterSwitch = (e): any => {
    setFilterSwitch({ ...filterSwitch, [e]: !filterSwitch[e] });
  };

  const filteredTasks = useTaskFilter(
    tasks,
    !filterSwitch.completed,
    !filterSwitch.overdue,
    !filterSwitch.inProgress,
  );

  // eslint-disable-next-line consistent-return
  const sortedTasks = useMemo(() => {
    if (individual) {
      return filteredTasks
        .filter(f => (f.task_event_id as string) === null)
        .slice()
        .sort((a, b): number => {
          let rv = a.task_due.localeCompare(b.task_due);
          if (rv === 0) {
            rv = a.task_name.localeCompare(b.task_name);
          }
          return rv;
        });
    }
    if (!individual) {
      return filteredTasks.slice().sort((a, b): number => {
        let rv = a.task_due.localeCompare(b.task_due);
        if (rv === 0) {
          rv = a.task_name.localeCompare(b.task_name);
        }
        return rv;
      });
    }
  }, [tasks, filteredTasks]);

  const attorneyMenu = data?.attorney
    ?.filter(
      x =>
        ['Attorney', 'Support Staff'].includes(x.attorney_role) &&
        x.attorney_active,
    )
    .map(({ attorney_name, attorney_id }) => {
      return { label: attorney_name, value: attorney_id };
    });
  return (
    <>
      <StyledSectionWrapper
        topBorderColor={themeColors.mainColors.primaryColor}
        isSelected={isSelected}
      >
        <TableOptions
          onAddTask={(): void => {
            setOpenAddTaskModal(true);
          }}
          onAddWorkflow={(): void => {
            setOpenSelectWorkflowModal(true);
          }}
          handleFilterSwitch={handleFilterSwitch}
          filterSwitch={filterSwitch}
          disableAddWorkflow={!matter}
          showCompletedSwitch={showCompletedSwitch}
        />

        <>
          <StyledTablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
            count={filteredTasks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>

        <StyledTable
          topBorderColor={themeColors.mainColors.primaryColor}
          size={dense ? 'small' : 'medium'}
        >
          {((loading || changeLoading || loadingAttorneys) && (
            <LoadContainer>
              <FingerprintSpinner
                color={themeColors.mainColors.primaryColor}
                size={75}
                animationDuration={2000}
              />
            </LoadContainer>
          )) || (
            <>
              <TaskTableHead />
              <TaskTableBody
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                tasks={sortedTasks!.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                )}
                caseMatter={matter}
                onViewTask={(taskId: string): void => {
                  setViewedTask(findTaskById(taskId) || null);
                  setOpenViewTaskModal(true);
                }}
                onEditTask={(taskId: string): void => {
                  setViewedTask(findTaskById(taskId) || null);
                  setOpenEditTaskModal(true);
                }}
                filterCompleted={!filterSwitch.completed}
                filterInProgress={!filterSwitch.inProgress}
                filterOverdue={!filterSwitch.overdue}
                isSelected={isSelected}
                handleMultiSelect={handleMultiSelect}
              />
              {!tasks.length && <EmptyTasksBody />}
            </>
          )}
        </StyledTable>

        <ViewTaskModal
          open={openViewTaskModal}
          matter={matter}
          task={viewedTask}
          individual={individual}
          onClose={(): void => setOpenViewTaskModal(false)}
          onEditClick={(): void => {
            setOpenViewTaskModal(false);
            setOpenEditTaskModal(true);
          }}
          showEditButton
        />
        <AddTaskModal
          open={openAddTaskModal}
          matter={matter}
          event={event}
          onClose={(): void => setOpenAddTaskModal(false)}
        />
        <EditTaskModal
          open={openEditTaskModal}
          matter={matter || viewedTask?.matter}
          event={event || (viewedTask?.event as MatterEvent)}
          onClose={(): void => setOpenEditTaskModal(false)}
          initialState={viewedTask || undefined}
        />
        {!!matter && (
          <SelectWorkflowModal
            open={openSelectWorkflowModal}
            matter={matter}
            event={event}
            onClose={(): void => {
              setOpenSelectWorkflowModal(false);
            }}
          />
        )}
        <StyledDiv borderColor={themeColors.mainColors.primaryColor} />
        {isSelected.length !== 0 && (
          <StyledButtonGroup
            color="primary"
            variant="outlined"
            aria-label="outlined primary multi-select button group"
            size="small"
            fullWidth
          >
            <Button
              onClick={(): void => handleModal()}
              disabled={loadingTaskOwnerChanges}
            >
              {(loadingTaskOwnerChanges && (
                <HollowDotsSpinner
                  size={10}
                  color={themeColors.mainColors.primaryColor}
                />
              )) ||
                'Edit Owner'}
            </Button>
            {!isReversible && (
              <Button
                onClick={(): void => handleCompleteMulti()}
                disabled={loadingTaskCompleteChanges}
              >
                {(loadingTaskCompleteChanges && (
                  <HollowDotsSpinner
                    size={10}
                    color={themeColors.mainColors.primaryColor}
                  />
                )) ||
                  'Mark Complete'}
              </Button>
            )}
            {isReversible && (
              <Button
                onClick={(): void => handleReverseCompleteMulti()}
                disabled={loadingTaskCompleteChanges}
              >
                {(loadingTaskCompleteChanges && (
                  <HollowDotsSpinner
                    size={10}
                    color={themeColors.mainColors.primaryColor}
                  />
                )) ||
                  'Undo Complete'}
              </Button>
            )}
            <Button
              onClick={(): void => handleDeleteMulti()}
              disabled={loadingTaskDeleteChanges}
            >
              Delete Selected
            </Button>
          </StyledButtonGroup>
        )}
        <Dialog open={openMultiReassign} fullWidth>
          <DialogTitle id="group-assign-dialog-title">
            Group Reassign
          </DialogTitle>
          <DialogContent>
            <Autocomplete
              id="attorney-reassign-dropdown"
              // margin="normal"
              autoHighlight
              options={attorneyMenu as []}
              getOptionLabel={(option: any): string => option.label}
              fullWidth
              onChange={(e, v): void => handleAttorneyChange(v)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Assign To"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(): void => setOpenMultiReassign(false)}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={(): void => handleSaveMulti()}
              color="primary"
              autoFocus
              disabled={!attorney}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </StyledSectionWrapper>
    </>
  );
};

export { TaskTable };
