import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { Paper, Tabs, Tab, Typography } from '@material-ui/core';
import { GENERAL } from './sections/general';
import { CLIENT } from './sections/client';
import { BILLING } from './sections/Billing';
import { COURT } from './sections/court';
import { OPPOSING_COUNSEL } from './sections/opposingCounsel';
import { Activities } from './sections/Activities';
import { Calendar } from './sections/Calendar';
import { Expenses } from './sections/Expenses';
import { DOCUMENTS } from './sections/Documents';
import { OPPOSING_PARTY } from './sections/opposingParty';
import { Notes } from './sections/Notes';
import { ViewEventModal, EditEventModal } from '../../components';
import { Matter, MatterEvent } from '../../utils/types';
import { useColors, useWidth } from '../../hooks';
import { boxShadows } from '../../constants';
import { MatterTypeIcon } from '../../utils/MatterTypeIcon';

type CaseLoaderProps = {
  matter: Matter;
};

const { themeColors } = useColors();

const StyledWrapper = styled(Paper)<{ size }>`
  position: relative;
  /* width: 100%; */
  border-radius: 4px;
  padding-bottom: 40px;
  margin: ${({ size }): string => (size >= 960 && ' auto 40px') || ' auto 5px'};
`;
const cardTitle = {
  position: 'relative',
  padding: 5,
  margin: 'auto',
  textAlign: 'center',
  backgroundColor: themeColors.mainColors.primaryColor,
  borderRadius: '0px 0px 10px 10px',
  boxShadow: boxShadows.one,
  minWidth: '8vw',
  maxWidth: 200,
  top: -1,
  marginBottom: 15,
  color: 'white',
};
const StyledTitleContainer = styled(Paper)<{ mode: string }>`
  font-weight: 200;
  font-style: italic;
  border-radius: none;
  text-align: right;
  box-shadow: none;
  margin-right: 45px;
  background-color: inherit;
  color: ${({ mode }): string =>
    (mode === 'dark' && 'white') || 'rgb(48, 54, 77)'};
`;
const titleVariant = 'h6';
const CaseLoader: React.FC<CaseLoaderProps> = ({ matter }) => {
  const { width } = useWidth();
  const [tab, setTab] = useState(0);
  const [openViewEvent, setViewEventOpen] = useState(false);
  const [openEditEvent, setEditEventOpen] = useState(false);
  const [viewedEvent, setViewedEvent] = useState<MatterEvent | null>(null);

  const {
    matter_id,
    client,
    court,
    owning_attorney,
    op_firm,
    events,
    op_party,
    matter_judge_name,
    matter_name,
  } = matter;

  const onTabChange = (_, newTab): void => {
    setTab(newTab);
  };

  const onViewEvent = (event: MatterEvent): void => {
    setViewEventOpen(true);
    setViewedEvent(event);
  };

  const onEditEvent = (): void => {
    setViewEventOpen(false);
    setEditEventOpen(true);
  };

  const sortedEvents = useMemo(() => {
    if (!matter.events) return [];
    return matter.events.sort((a, b): number => {
      const startA = a.event_date_time;
      const startB = b.event_date_time;
      if (startA > startB) return 1;
      if (startA < startB) return -1;
      return 0;
    });
  }, [matter]);

  return (
    <>
      <StyledTitleContainer mode={localStorage.getItem('theme_mode') ?? 'dark'}>
        <Typography variant="h6">
          <MatterTypeIcon
            type={(court?.court_name !== 'Default' && court?.court_type) || ''}
          />
          {matter_name ?? ''}
        </Typography>
      </StyledTitleContainer>
      <StyledWrapper size={width}>
        <Tabs
          style={{ width: '80%', paddingLeft: 5 }}
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={onTabChange}
          aria-label="Case Nav Tabs"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab label="Overview" />
          <Tab label="Activities" />
          <Tab label="Calendar" />
          <Tab label="Documents" />
          <Tab label="Notes" />
          <Tab label="Billing" />
          <Tab label="Expenses" />
        </Tabs>

        {/* Overview information */}
        {tab === 0 ? (
          <>
            {/* General Info */}
            <GENERAL
              c={matter}
              at={owning_attorney}
              readOnly
              cardTitleStyle={cardTitle}
              titleVariant={titleVariant}
            />
            {/* Client Info */}
            <CLIENT
              cl={client}
              readOnly
              cardTitleStyle={cardTitle}
              titleVariant={titleVariant}
            />
            {/* Opposing Party */}
            <OPPOSING_PARTY
              c={matter}
              pa={op_party}
              readOnly
              cardTitleStyle={cardTitle}
              titleVariant={titleVariant}
            />
            {/* Court Info */}
            <COURT
              c={matter}
              co={court}
              judge={matter_judge_name}
              readOnly
              cardTitleStyle={cardTitle}
              titleVariant={titleVariant}
            />
            {/* Opposing Firm */}
            <OPPOSING_COUNSEL
              c={matter}
              op={op_firm}
              readOnly
              cardTitleStyle={cardTitle}
              titleVariant={titleVariant}
            />
          </>
        ) : null}
        {/* tasks */}
        {tab === 1 ? (
          <Activities matter={matter} events={sortedEvents} />
        ) : null}
        {/* Calendar View */}
        {tab === 2 ? (
          <Calendar events={events || []} onViewEvent={onViewEvent} />
        ) : null}
        {/* Documents */}
        {tab === 3 ? <DOCUMENTS cl={client} c={matter} /> : null}
        {/* Billing */}
        {tab === 5 ? <BILLING client={client} matter={matter} /> : null}
        {tab === 4 ? <Notes matter={matter} /> : null}
        {tab === 6 ? <Expenses matter={matter} client={client} /> : null}
      </StyledWrapper>
      <ViewEventModal
        open={openViewEvent}
        event={viewedEvent}
        onClose={(): void => setViewEventOpen(false)}
        onEditClick={onEditEvent}
      />
      <EditEventModal
        open={openEditEvent}
        matterId={matter_id}
        onClose={(): void => setEditEventOpen(false)}
        initialState={viewedEvent || undefined}
      />
    </>
  );
};

export { CaseLoader };
