import { gql } from '@apollo/client';

export const ATTORNEYS = gql`
  query ATTORNEYS($role: String) {
    attorney(where: { attorney_role: { _eq: $role } }) {
      attorney_id
      attorney_name
      attorney_login_id
      attorney_role
      attorney_active
    }
  }
`;

export const ATTORNEYS_ALL = gql`
  query ATTORNEYS_ALL {
    attorney {
      attorney_id
      attorney_name
      attorney_login_id
      attorney_role
      attorney_active
    }
  }
`;

export const AUTHENTICATED_ATTORNEY = gql`
  query ATTORNEYS($authId: String!) {
    attorney(where: { attorney_email: { _eq: $authId } }) {
      attorney_id
      attorney_name
      attorney_bar_id
      attorney_email
      attorney_extension
      attorney_bar_state
      attorney_login_id
      attorney_home_street
      attorney_home_suite
      attorney_home_city
      attorney_home_state
      attorney_home_zip
      attorney_id_color
      attorney_active
      attorney_office_street
      attorney_office_suite
      attorney_office_city
      attorney_office_state
      attorney_office_zip
      attorney_office_zip_4
      attorney_role
      events(where: { event_complete: { _eq: false } }) {
        event_color
        event_complete
        event_county
        event_date_time
        event_end_time
        event_id
        event_length
        event_location
        event_matter_id
        event_name
        event_notes
        event_owner
        event_type
        attorney {
          attorney_id
          attorney_name
          attorney_active
          attorney_role
        }
      }
      tasks(where: { task_completed: { _eq: false } }) {
        task_completed
        task_description
        task_due
        task_due_dynamic
        task_dynamic_due_from
        task_dynamic_lock
        task_event_id
        task_id
        task_matter_id
        task_name
        task_is_priority
        task_owner
        trigger_workflow_id
        event {
          event_name
          event_id
        }
        attorney {
          attorney_id
          attorney_name
          attorney_role
          attorney_active
        }
        matter {
          matter_name
          matter_stage
          matter_id
          matter_caption
          owning_attorney {
            attorney_name
            attorney_active
            attorney_role
          }
          events {
            event_name
          }
        }
      }
    }
  }
`;
