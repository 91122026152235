import React, { useState } from 'react';
import { useSubscription } from '@apollo/client';
import styled from 'styled-components';
import { Paper, TextField, Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Autocomplete } from '@material-ui/lab';
import {
  Creditors,
  Judges,
  OpposingFirms,
  OpposingAttorneys,
  Courts,
} from './sections';
import {
  GetCreditors,
  GetJudgesSub,
  GetOpposingFirmsSub,
  GetCourtsSub,
  OpposingAttorneysSub,
} from '../../../components/queries';
import { AddProfileDialog } from './addProfile/AddProfileDialog';

const StyledFab = styled(Fab)`
  position: absolute;
  right: 50px;
`;
const StyledMenu = styled.menu`
  display: flex;
  align-items: center;
  width: 100%;
`;
const StyledPaper = styled(Paper)`
  overflow-x: auto;
  width: 97%;
  margin: auto;
  border-radius: 10px;
`;

export const Profiles: React.FC = () => {
  const { loading: loadingCreditors, data: creditorData } = useSubscription(
    GetCreditors,
  );
  const { loading: loadingOpAtty, data: opAtty } = useSubscription(
    OpposingAttorneysSub,
  );
  const { loading: loadingJudges, data: judgesData } = useSubscription(
    GetJudgesSub,
  );
  const { loading: loadingOpFirms, data: opFirmData } = useSubscription(
    GetOpposingFirmsSub,
  );
  const { loading: loadingCourts, data: courtData } = useSubscription(
    GetCourtsSub,
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const handleChange = (v): void => {
    setTab(v?.value);
  };

  const handleClick = (): void => {
    setOpen(!open);
  };
  const menuItems = [
    { label: 'Opposing Parties', value: 0 },
    { label: 'Opposing Firms', value: 1 },
    { label: 'Opposing Attorneys', value: 2 },
    { label: 'Judges', value: 3 },
    { label: 'Courts', value: 4 },
  ];
  if (
    loadingCreditors ||
    loadingJudges ||
    loadingOpFirms ||
    loadingCourts ||
    loadingOpAtty
  ) {
    return <>loading...</>;
  }
  return (
    <>
      <StyledMenu>
        <Autocomplete
          id="profiles-select"
          autoHighlight
          autoComplete
          onChange={(_e, v): void => handleChange(v)}
          defaultValue={menuItems[0]}
          options={menuItems}
          getOptionLabel={(option: any): any => option.label}
          style={{ width: 300 }}
          renderInput={params => (
            <TextField {...params} label="Profile Type" variant="outlined" />
          )}
        />
        <StyledFab
          size="medium"
          color="primary"
          aria-label="add"
          onClick={handleClick}
        >
          <AddIcon />
        </StyledFab>
      </StyledMenu>
      <StyledPaper>
        {tab === 0 && <Creditors creditors={creditorData.op_party} />}
        {tab === 1 && <OpposingFirms opFirms={opFirmData.op_firm} />}
        {tab === 2 && <OpposingAttorneys opAtty={opAtty.op_counsel} />}
        {tab === 3 && <Judges judges={judgesData.judge} />}
        {tab === 4 && <Courts courts={courtData.court} />}
      </StyledPaper>
      <AddProfileDialog open={open} setOpen={handleClick} tab={tab} />
    </>
  );
};
