import axios from 'axios';

export const Downloader = (FileKey: string, Expires: number): void => {
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      FileKey,
      Expires,
    },
  };
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;

  const putURL = `${baseServerUrl}/download-file-secured-url`;
  // get signed url
  axios
    .post(putURL, {}, options)
    // .then(r => console.log(r))
    .then(r => window.open(r.data))
    .catch(err => {
      console.log('err', err);
    });
};
