import React from 'react';
import { themeColors } from '../../constants/color/color';

export default {
  title: 'Loading Placeholders',
};

export const TableLoader = props => {
  const theme_mode = localStorage.getItem('theme_mode');
  const squareLarge = {
    backgroundColor:
      theme_mode === 'dark'
        ? themeColors.cardBackgroundDark
        : themeColors.cardBackgroundLight,
    maxWidth: props.placeholderMaxWidth || '95vw',
    boxShadow:
      props.placeholderBoxShadow ||
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    borderRadius: props.placeholderBorderRadius || 10,
    borderTop: `30px solid ${themeColors.primaryColor}`,
    paddingBottom: 5,
  };
  const titleText = {
    border: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    height: props.titleTextHeight || '1.5vh',
    position: 'relative',
    marginTop: 20,
    marginBottom: '20px',
    width: props.titleTextWidth || '90%',
    backgroundColor: props.color || 'rgba(45, 98, 143, .2)',
  };
  const line = {
    borderTop: '1.5px solid rgba(0,0,0,0.5)',
  };
  return (
    <>
      <div style={squareLarge}>
        <div style={titleText} className="shine" />
        <div style={line} />
        <div style={titleText} className="shine" />
        <div style={line} />
        <div style={titleText} className="shine" />
        <div style={line} />
        <div style={titleText} className="shine" />

        {props.noShimmer === false ? null : (
          <style>
            {`
              .shine {
                position: relative;
                overflow: hidden;
                border-radius: 3;
                color: #fff;
              }
              .shine:after {
                animation: shine 1.5s ease-in-out infinite;
                animation-fill-mode: forwards;
                content: '';
                position: absolute;
                top: -110%;
                left: -210%;
                width: 200%;
                height: 200%;
                opacity: 0;
                transform: rotate(30deg);

                background: rgba(255, 255, 255, 0.13);
                background: linear-gradient(
                  to right,
                  rgba(255, 255, 255, 0.13) 0%,
                  rgba(255, 255, 255, 0.13) 77%,
                  rgba(255, 255, 255, 0.5) 92%,
                  rgba(255, 255, 255, 0) 100%
                );
              }
              shine:active:after {
                opacity: 0;
              }
              @keyframes shine {
                10% {
                  opacity: 1;
                  top: -30%;
                  left: -30%;
                  transition-property: left, top, opacity;
                  transition-duration: 0.7s, 0.7s, 0.15s;
                  transition-timing-function: ease;
                }
                100% {
                  opacity: 0;
                  top: -30%;
                  left: -30%;
                  transition-property: left, top, opacity;
                }
              }
            `}
          </style>
        )}
      </div>
    </>
  );
};
