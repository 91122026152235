/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  FormControlLabel,
  Switch,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import DeleteOutlinedOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { DeleteAtty } from '../../../../../components/mutations';
import { useColors } from '../../../../../hooks';
import { OpCounselProfileDialog } from './opCounselProfile';

export interface CreditorProps {
  opAtty?: any;
}

const defaultProps = {
  opAtty: [],
};

const StyledDiv = styled.div<{ borderColor: string }>`
  overflow: hidden;
  border-top: 8px solid ${({ borderColor }): string => borderColor};
`;
export const OpposingAttorneys: React.FC<CreditorProps> = ({ opAtty }) => {
  const { themeColors } = useColors();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('profileRowsPerPage') as string, 10) || 5,
  );

  // -------------------------------------delete op party -------------------------------------
  const [attyId, setAttyId] = useState(null);
  const [deleteTitle, setDeleteTitle] = useState(null);
  const [deleteOpCounsel] = useMutation(DeleteAtty, {
    variables: { op_counsel_id: attyId },
  });
  // Modal Logic
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleCloseAgree = (): void => {
    setOpen(false);
    deleteOpCounsel();
  };
  const handleClickOpen = (ID, TITLE): void => {
    setOpen(true);
    setAttyId(ID);
    setDeleteTitle(TITLE);
  };
  const handleSearch = (e): void => {
    setSearch(e.target.value);
  };
  // -------------------------------------end delete op counsel ----------------------------------
  const [openOpCounselProfile, setOpenOpCounselProfile] = useState(false);
  const handleOpenOpCounselProfile = (ID): void => {
    setOpenOpCounselProfile(true);
    setAttyId(ID);
  };
  const handleCloseOpCounselProfile = (): void => {
    setOpenOpCounselProfile(false);
  };
  useEffect(() => {
    if (localStorage.getItem('profileTableDense') === 'dense') {
      setDense(true);
    }
    if (localStorage.getItem('profileTableDense') === 'notDense') {
      setDense(false);
    }
    localStorage.setItem('profileRowsPerPage', rowsPerPage.toString());
  }, []);

  const handleChangeDense = (event: {
    target: { checked: React.SetStateAction<boolean> };
  }): void => {
    setDense(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem('profileTableDense', 'dense');
    }
    if (!event.target.checked) {
      localStorage.setItem('profileTableDense', 'notDense');
    }
  };

  const handleChangePage = (
    _event: unknown,
    newPage: React.SetStateAction<number>,
  ): void => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: {
    target: { value: string };
  }): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('profileRowsPerPage', event.target.value);
    setPage(0);
  };
  return (
    <>
      <StyledDiv borderColor={themeColors.mainColors.primaryColor}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
          component="div"
          count={opAtty?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ float: 'right' }}
        />

        <div
          style={{
            float: 'left',
            marginBottom: -10,
            marginTop: 7,
            marginLeft: 15,
          }}
        >
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
          <TextField
            id="input-with-icon-search"
            onChange={handleSearch}
            placeholder="Search Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </StyledDiv>
      <div
        style={{
          borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
        }}
      />
      <Table aria-label="simple table" size={dense ? 'small' : 'medium'}>
        <TableHead>
          <TableRow style={{}}>
            <TableCell align="left">
              <Typography color="secondary">Name</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Open Cases</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Firm</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Delete</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {opAtty
            .filter(x =>
              x.op_counsel_name
                .toLowerCase()
                .includes(search?.toLowerCase() || ''),
            )
            .sort((a, b) => a.op_counsel_name.localeCompare(b.op_counsel_name))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(({ op_counsel_id, op_counsel_name, op_firm, matters }) => (
              <TableRow
                key={op_counsel_id}
                hover
                onClick={(): void => handleOpenOpCounselProfile(op_counsel_id)}
                style={{ cursor: 'pointer' }}
              >
                <>
                  <TableCell component="th" scope="row">
                    {op_counsel_name}
                  </TableCell>
                  <TableCell align="left">{matters?.length}</TableCell>
                  <TableCell align="left">{op_firm?.op_firm_name}</TableCell>
                  <TableCell align="left">
                    <DeleteOutlinedOutlined
                      color="primary"
                      onClick={(): void =>
                        handleClickOpen(op_counsel_id, op_counsel_name)
                      }
                    />
                  </TableCell>
                </>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
          component="div"
          count={opAtty?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ float: 'right' }}
        />
        <div
          style={{
            borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
          }}
        />
      </>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Attorney?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the attorney {deleteTitle}? This
            action is permanent and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={handleCloseAgree}
            color="primary"
            autoFocus
            style={{ fontWeight: 'bold' }}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
      {openOpCounselProfile && (
        <OpCounselProfileDialog
          open={openOpCounselProfile}
          handleClose={handleCloseOpCounselProfile}
          id={attyId}
        />
      )}
    </>
  );
};

OpposingAttorneys.defaultProps = defaultProps;
