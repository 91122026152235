/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { useColors } from '../../hooks';

export default {
  title: 'SVG Images',
};
const { themeColors } = useColors();

export const DarkMode = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12,18C11.11,18 10.26,17.8 9.5,17.45C11.56,16.5 13,14.42 13,12C13,9.58 11.56,7.5 9.5,6.55C10.26,6.2 11.11,6 12,6A6,6 0 0,1 18,12A6,6 0 0,1 12,18M20,8.69V4H15.31L12,0.69L8.69,4H4V8.69L0.69,12L4,15.31V20H8.69L12,23.31L15.31,20H20V15.31L23.31,12L20,8.69Z"
    />
  </svg>
);

export const CalendarClock = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M15,13H16.5V15.82L18.94,17.23L18.19,18.53L15,16.69V13M19,8H5V19H9.67C9.24,18.09 9,17.07 9,16A7,7 0 0,1 16,9C17.07,9 18.09,9.24 19,9.67V8M5,21C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3H6V1H8V3H16V1H18V3H19A2,2 0 0,1 21,5V11.1C22.24,12.36 23,14.09 23,16A7,7 0 0,1 16,23C14.09,23 12.36,22.24 11.1,21H5M16,11.15A4.85,4.85 0 0,0 11.15,16C11.15,18.68 13.32,20.85 16,20.85A4.85,4.85 0 0,0 20.85,16C20.85,13.32 18.68,11.15 16,11.15Z"
    />
  </svg>
);

export const FoldChevron = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M16.59,5.41L15.17,4L12,7.17L8.83,4L7.41,5.41L12,10M7.41,18.59L8.83,20L12,16.83L15.17,20L16.58,18.59L12,14L7.41,18.59Z"
    />
  </svg>
);

export const UnFoldChevron = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12,18.17L8.83,15L7.42,16.41L12,21L16.59,16.41L15.17,15M12,5.83L15.17,9L16.58,7.59L12,3L7.41,7.59L8.83,9L12,5.83Z"
    />
  </svg>
);

export const Gavel = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M2.3,20.28L11.9,10.68L10.5,9.26L9.78,9.97C9.39,10.36 8.76,10.36 8.37,9.97L7.66,9.26C7.27,8.87 7.27,8.24 7.66,7.85L13.32,2.19C13.71,1.8 14.34,1.8 14.73,2.19L15.44,2.9C15.83,3.29 15.83,3.92 15.44,4.31L14.73,5L16.15,6.43C16.54,6.04 17.17,6.04 17.56,6.43C17.95,6.82 17.95,7.46 17.56,7.85L18.97,9.26L19.68,8.55C20.07,8.16 20.71,8.16 21.1,8.55L21.8,9.26C22.19,9.65 22.19,10.29 21.8,10.68L16.15,16.33C15.76,16.72 15.12,16.72 14.73,16.33L14.03,15.63C13.63,15.24 13.63,14.6 14.03,14.21L14.73,13.5L13.32,12.09L3.71,21.7C3.32,22.09 2.69,22.09 2.3,21.7C1.91,21.31 1.91,20.67 2.3,20.28M20,19A2,2 0 0,1 22,21V22H12V21A2,2 0 0,1 14,19H20Z"
    />
  </svg>
);

export const Check = ({
  style = {},
  fill = themeColors.mainColors.success,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M19.78,2.2L24,6.42L8.44,22L0,13.55L4.22,9.33L8.44,13.55L19.78,2.2M19.78,5L8.44,16.36L4.22,12.19L2.81,13.55L8.44,19.17L21.19,6.42L19.78,5Z"
    />
  </svg>
);
export const Phoneicon = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 32 32',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M6.62,10.79C8.06,13.62 10.38,15.94 13.21,17.38L15.41,15.18C15.69,14.9 16.08,14.82 16.43,14.93C17.55,15.3 18.75,15.5 20,15.5A1,1 0 0,1 21,16.5V20A1,1 0 0,1 20,21A17,17 0 0,1 3,4A1,1 0 0,1 4,3H7.5A1,1 0 0,1 8.5,4C8.5,5.25 8.7,6.45 9.07,7.57C9.18,7.92 9.1,8.31 8.82,8.59L6.62,10.79Z"
    />
  </svg>
);
export const Power = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 611 719.1',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M180,179.4v24.4C108.7,246.5,61,324.5,61,413.6c0,135,109.5,244.5,244.5,244.5S550,548.7,550,413.6
      c0-89.1-47.7-167.1-119-209.9v-24.4c84.3,44.5,141.6,132.5,141.6,233.7c0,146.2-119.6,264.8-267.1,264.8S38.4,559.4,38.4,413.2
      C38.4,311.9,95.7,223.9,180,179.4z M431,137.8v21c93.9,46.3,158.5,143,158.5,254.8c0,156.8-127.2,284-284,284s-284-127.2-284-284
      c0-111.8,64.6-208.5,158.5-254.8v-21C75.3,185.5,2.5,291,2.5,413.6c0,167.3,135.7,303,303,303s303-135.7,303-303
      C608.5,291,535.7,185.5,431,137.8z M296.5,417.5V2.5H281v415H296.5z M314.5,2.5v415H330V2.5H314.5z"
    />
  </svg>
);
export const Power2 = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 797 909.8',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M398.5,906.3c-53.3,0-105.1-10.4-153.8-31c-47-19.9-89.3-48.4-125.6-84.7C82.9,754.3,54.4,712,34.5,665
		c-20.6-48.7-31-100.4-31-153.8c0-73.6,20.4-145.5,59-207.8c37.5-60.6,90.7-109.9,153.8-142.8l5.1,3.1V235l-1.6,2.9
		C127.3,298.6,72,400.8,72,511.3c0,180,146.5,326.5,326.5,326.5c180,0,326.5-146.5,326.5-326.5c0-110.5-55.3-212.7-147.9-273.3
		l-1.6-2.9v-71.2l5.1-3.1c63.1,32.9,116.3,82.2,153.8,142.8c38.6,62.3,59,134.1,59,207.8c0,53.3-10.4,105.1-31,153.8
		c-19.9,47-48.4,89.3-84.7,125.6c-36.3,36.3-78.5,64.8-125.6,84.7C503.6,895.8,451.8,906.3,398.5,906.3z M359.5,472.5L356,469V7
		l3.5-3.5h78L441,7v462l-3.5,3.5H359.5z"
    />
    <path
      fill={fill}
      d="M437.5,7v462h-78V7H437.5 M579.1,163.8C704.4,229.1,790,360.2,790,511.3c0,216.2-175.3,391.5-391.5,391.5S7,727.5,7,511.3
		c0-151.1,85.6-282.2,210.9-347.5V235C128,294,68.5,395.6,68.5,511.3c0,182.3,147.7,330,330,330s330-147.7,330-330
		C728.5,395.6,669,294,579.1,235V163.8 M437.5,0h-78l-7,7v462l7,7h78l7-7V7L437.5,0L437.5,0z M582.3,157.6l-10.2,6.2V235l3.2,5.9
		c91.6,60,146.3,161.1,146.3,270.4c0,178.1-144.9,323-323,323s-323-144.9-323-323c0-109.3,54.7-210.4,146.3-270.4l3.2-5.9v-71.2
		l-10.2-6.2c-63.7,33.2-117.3,83-155.2,144.1C20.6,364.5,0,437,0,511.3c0,53.8,10.5,106,31.3,155.1c20.1,47.5,48.8,90.1,85.4,126.7
		c36.6,36.6,79.2,65.3,126.7,85.4c49.1,20.8,101.3,31.3,155.1,31.3s106-10.5,155.1-31.3c47.5-20.1,90.1-48.8,126.7-85.4
		c36.6-36.6,65.3-79.2,85.4-126.7C786.5,617.2,797,565,797,511.3c0-74.3-20.6-146.8-59.5-209.6C699.6,240.6,646,190.7,582.3,157.6
		L582.3,157.6z"
    />
  </svg>
);
export const Power3 = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 851.4 955.5',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M425.7,919c-52.5,0-103.5-10.3-151.5-30.6c-46.3-19.6-88-47.7-123.7-83.4s-63.8-77.3-83.4-123.7
      c-20.3-48-30.6-99-30.6-151.5c0-61.3,13.9-120,41.2-174.4c26-51.9,64.1-98.1,110.2-133.7c6.5-5,14.4-7.6,22.3-7.6
      c5.5,0,11,1.2,16.1,3.7c12.5,6.1,20.4,18.8,20.4,32.8v7.9c0,11.1-5.1,21.6-13.8,28.6c-74.5,59.2-117.2,147.7-117.2,242.7
      c0,170.9,139,310,310,310s310-139,310-310c0-95-42.7-183.5-117.2-242.7c-8.7-6.9-13.8-17.4-13.8-28.6v-7.9
      c0-13.9,7.9-26.6,20.4-32.8c5.1-2.5,10.6-3.7,16.1-3.7c7.9,0,15.8,2.6,22.3,7.6c46,35.6,84.1,81.8,110.2,133.7
      c27.3,54.4,41.2,113.1,41.2,174.4c0,52.5-10.3,103.5-30.6,151.5c-19.6,46.3-47.7,88-83.4,123.7s-77.3,63.8-123.7,83.4
      C529.2,908.7,478.2,919,425.7,919z M423.7,545.3c-20.2,0-36.5-16.3-36.5-36.5V73c0-20.2,16.3-36.5,36.5-36.5h3.9
      c20.2,0,36.5,16.3,36.5,36.5v435.8c0,20.2-16.3,36.5-36.5,36.5H423.7z"
    />
    <path
      fill={fill}
      d="M427.6,73v435.8h-3.9V73H427.6 M641.2,250.6c83.4,64.5,137.2,165.6,137.2,279.2c0,194.8-157.9,352.7-352.7,352.7
      S73,724.6,73,529.8c0-113.6,53.7-214.7,137.2-279.2v7.9C130.4,322,79.2,420,79.2,529.8c0,191.3,155.1,346.5,346.5,346.5
      s346.5-155.1,346.5-346.5C772.1,420,721,322,641.2,258.6V250.6 M427.6,0h-3.9c-40.3,0-73,32.7-73,73v435.8c0,40.3,32.7,73,73,73
      h3.9c40.3,0,73-32.7,73-73V73C500.6,32.7,468,0,427.6,0L427.6,0z M641.2,177.6c-11,0-22,2.5-32.2,7.5c-25,12.3-40.8,37.7-40.8,65.5
      v7.9c0,22.2,10.1,43.3,27.6,57.1c65.7,52.3,103.4,130.3,103.4,214.1c0,150.8-122.7,273.5-273.5,273.5S152.2,680.6,152.2,529.8
      c0-83.8,37.7-161.9,103.4-214.1c17.4-13.8,27.6-34.9,27.6-57.1v-7.9c0-27.8-15.8-53.3-40.8-65.5c-10.2-5-21.2-7.5-32.2-7.5
      c-15.9,0-31.6,5.2-44.7,15.2c-25.1,19.4-48.1,41.7-68.3,66.1c-20.3,24.6-37.9,51.6-52.2,80c-14.6,29-25.8,59.7-33.3,91.3
      C3.9,462.7,0,496.2,0,529.8C0,587.3,11.3,643,33.5,695.5c21.4,50.7,52.1,96.2,91.2,135.3c39.1,39.1,84.6,69.8,135.3,91.2
      c52.5,22.2,108.3,33.5,165.7,33.5s113.2-11.3,165.7-33.5c50.7-21.4,96.2-52.1,135.3-91.2c39.1-39.1,69.8-84.6,91.2-135.3
      c22.2-52.5,33.5-108.3,33.5-165.7c0-33.6-3.9-67.1-11.7-99.5c-7.6-31.5-18.8-62.3-33.3-91.3c-14.3-28.5-31.9-55.4-52.2-80
      c-20.2-24.5-43.2-46.7-68.3-66.1C672.8,182.8,657.1,177.6,641.2,177.6L641.2,177.6z"
    />
  </svg>
);
export const Power4 = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 851.4 955.5',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M425.7,919c-52.5,0-103.5-10.3-151.5-30.6c-46.3-19.6-88-47.7-123.7-83.4s-63.8-77.3-83.4-123.7
      c-20.3-48-30.6-99-30.6-151.5c0-61.3,13.9-120,41.2-174.4c26-51.9,64.1-98.1,110.2-133.7l58.8,28.9v7.9l-13.8,28.6
      c-74.5,59.2-117.2,147.7-117.2,242.7c0,170.9,139,310,310,310s310-139,310-310c0-95-42.7-183.5-117.2-242.7l-13.8-28.6v-7.9
      l58.8-28.9c46,35.6,84.1,81.8,110.2,133.7c27.3,54.4,41.2,113.1,41.2,174.4c0,52.5-10.3,103.5-30.6,151.5
      c-19.6,46.3-47.7,88-83.4,123.7s-77.3,63.8-123.7,83.4C529.2,908.7,478.2,919,425.7,919z M423.7,545.3l-36.5-36.5V73l36.5-36.5h3.9
      L464.1,73v435.8l-36.5,36.5H423.7z"
    />
    <path
      fill={fill}
      d="M427.6,73v435.8h-3.9V73H427.6 M641.2,250.6c83.4,64.5,137.2,165.6,137.2,279.2c0,194.8-157.9,352.7-352.7,352.7
      S73,724.6,73,529.8c0-113.6,53.7-214.7,137.2-279.2v7.9C130.4,322,79.2,420,79.2,529.8c0,191.3,155.1,346.5,346.5,346.5
      s346.5-155.1,346.5-346.5C772.1,420,721,322,641.2,258.6V250.6 M427.6,0h-3.9l-73,73v435.8l73,73h3.9l73-73V73L427.6,0L427.6,0z
       M685.8,192.9l-117.6,57.8v7.9l27.6,57.1c65.7,52.3,103.4,130.3,103.4,214.1c0,150.8-122.7,273.5-273.5,273.5
      S152.2,680.6,152.2,529.8c0-83.8,37.7-161.9,103.4-214.1l27.6-57.1v-7.9l-117.6-57.8c-25.1,19.4-48.1,41.7-68.3,66.1
      c-20.3,24.6-37.9,51.6-52.2,80c-14.6,29-25.8,59.7-33.3,91.3C3.9,462.7,0,496.2,0,529.8C0,587.3,11.3,643,33.5,695.5
      c21.4,50.7,52.1,96.2,91.2,135.3c39.1,39.1,84.6,69.8,135.3,91.2c52.5,22.2,108.3,33.5,165.7,33.5s113.2-11.3,165.7-33.5
      c50.7-21.4,96.2-52.1,135.3-91.2c39.1-39.1,69.8-84.6,91.2-135.3c22.2-52.5,33.5-108.3,33.5-165.7c0-33.6-3.9-67.1-11.7-99.5
      c-7.6-31.5-18.8-62.3-33.3-91.3c-14.3-28.5-31.9-55.4-52.2-80C733.9,234.5,711,212.3,685.8,192.9L685.8,192.9z"
    />
  </svg>
);
export const SendMessage = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path fill={fill} d="M2,21L23,12L2,3V10L17,12L2,14V21Z" />
  </svg>
);
export const Delete = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z"
    />
  </svg>
);
export const DashboardSolid = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M13,3V9H21V3M13,21H21V11H13M3,21H11V15H3M3,13H11V3H3V13Z"
    />
  </svg>
);
export const CaseSolid = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M10,2H14A2,2 0 0,1 16,4V6H20A2,2 0 0,1 22,8V19A2,2 0 0,1 20,21H4C2.89,21 2,20.1 2,19V8C2,6.89 2.89,6 4,6H8V4C8,2.89 8.89,2 10,2M14,6V4H10V6H14Z"
    />
  </svg>
);
export const CaseOutline = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M20,6C20.58,6 21.05,6.2 21.42,6.59C21.8,7 22,7.45 22,8V19C22,19.55 21.8,20 21.42,20.41C21.05,20.8 20.58,21 20,21H4C3.42,21 2.95,20.8 2.58,20.41C2.2,20 2,19.55 2,19V8C2,7.45 2.2,7 2.58,6.59C2.95,6.2 3.42,6 4,6H8V4C8,3.42 8.2,2.95 8.58,2.58C8.95,2.2 9.42,2 10,2H14C14.58,2 15.05,2.2 15.42,2.58C15.8,2.95 16,3.42 16,4V6H20M4,8V19H20V8H4M14,6V4H10V6H14Z"
    />
  </svg>
);
export const People = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M13.07 10.41A5 5 0 0 0 13.07 4.59A3.39 3.39 0 0 1 15 4A3.5 3.5 0 0 1 15 11A3.39 3.39 0 0 1 13.07 10.41M5.5 7.5A3.5 3.5 0 1 1 9 11A3.5 3.5 0 0 1 5.5 7.5M7.5 7.5A1.5 1.5 0 1 0 9 6A1.5 1.5 0 0 0 7.5 7.5M16 17V19H2V17S2 13 9 13 16 17 16 17M14 17C13.86 16.22 12.67 15 9 15S4.07 16.31 4 17M15.95 13A5.32 5.32 0 0 1 18 17V19H22V17S22 13.37 15.94 13Z"
    />
  </svg>
);
export const MessagesOutline = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M13 18H3V8L11 13L19 8V13H21V6C21 4.9 20.1 4 19 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H13V18M19 6L11 11L3 6H19Z"
    />
  </svg>
);
export const CalendarOutline = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M21 17V8H7V17H21M21 3C22.1 3 23 3.9 23 5V17C23 18.1 22.1 19 21 19H7C5.89 19 5 18.1 5 17V5C5 3.9 5.9 3 7 3H8V1H10V3H18V1H20V3H21M3 21H17V23H3C1.89 23 1 22.1 1 21V9H3V21Z"
    />
  </svg>
);
export const Tasks = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z"
    />
  </svg>
);
export const ChevronLeftCircle = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M20,12A8,8 0 0,0 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12M15.4,16.6L10.8,12L15.4,7.4L14,6L8,12L14,18L15.4,16.6Z"
    />
  </svg>
);
export const SettingsOutline = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12A2,2 0 0,0 12,10M10,22C9.75,22 9.54,21.82 9.5,21.58L9.13,18.93C8.5,18.68 7.96,18.34 7.44,17.94L4.95,18.95C4.73,19.03 4.46,18.95 4.34,18.73L2.34,15.27C2.21,15.05 2.27,14.78 2.46,14.63L4.57,12.97L4.5,12L4.57,11L2.46,9.37C2.27,9.22 2.21,8.95 2.34,8.73L4.34,5.27C4.46,5.05 4.73,4.96 4.95,5.05L7.44,6.05C7.96,5.66 8.5,5.32 9.13,5.07L9.5,2.42C9.54,2.18 9.75,2 10,2H14C14.25,2 14.46,2.18 14.5,2.42L14.87,5.07C15.5,5.32 16.04,5.66 16.56,6.05L19.05,5.05C19.27,4.96 19.54,5.05 19.66,5.27L21.66,8.73C21.79,8.95 21.73,9.22 21.54,9.37L19.43,11L19.5,12L19.43,13L21.54,14.63C21.73,14.78 21.79,15.05 21.66,15.27L19.66,18.73C19.54,18.95 19.27,19.04 19.05,18.95L16.56,17.95C16.04,18.34 15.5,18.68 14.87,18.93L14.5,21.58C14.46,21.82 14.25,22 14,22H10M11.25,4L10.88,6.61C9.68,6.86 8.62,7.5 7.85,8.39L5.44,7.35L4.69,8.65L6.8,10.2C6.4,11.37 6.4,12.64 6.8,13.8L4.68,15.36L5.43,16.66L7.86,15.62C8.63,16.5 9.68,17.14 10.87,17.38L11.24,20H12.76L13.13,17.39C14.32,17.14 15.37,16.5 16.14,15.62L18.57,16.66L19.32,15.36L17.2,13.81C17.6,12.64 17.6,11.37 17.2,10.2L19.31,8.65L18.56,7.35L16.15,8.39C15.38,7.5 14.32,6.86 13.12,6.62L12.75,4H11.25Z"
    />
  </svg>
);
export const PencilOutline = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
    />
  </svg>
);
export const Feather = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M22,2C22,2 14.36,1.63 8.34,9.88C3.72,16.21 2,22 2,22L3.94,21C5.38,18.5 6.13,17.47 7.54,16C10.07,16.74 12.71,16.65 15,14C13,13.44 11.4,13.57 9.04,13.81C11.69,12 13.5,11.6 16,12L17,10C15.2,9.66 14,9.63 12.22,10.04C14.19,8.65 15.56,7.87 18,8L19.21,6.07C17.65,5.96 16.71,6.13 14.92,6.57C16.53,5.11 18,4.45 20.14,4.32C20.14,4.32 21.19,2.43 22,2Z"
    />
  </svg>
);
export const Eye = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
    />
  </svg>
);
export const Download = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M17,13L12,18L7,13H10V9H14V13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z"
    />
  </svg>
);
export const Map = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M18.25,22L15.5,19L16.66,17.82L18.25,19.41L21.84,15.82L23,17.23L18.25,22M20.5,3A0.5,0.5 0 0,1 21,3.5V13.34C20.37,13.12 19.7,13 19,13V5.7L16,6.86V13.8C15.2,14.27 14.5,14.91 14,15.68V6.87L10,5.47V17.13L13.05,18.2L13,19C13,19.46 13.05,19.92 13.15,20.35L9,18.9L3.66,20.97L3.5,21A0.5,0.5 0 0,1 3,20.5V5.38C3,5.15 3.15,4.97 3.36,4.9L9,3L15,5.1L20.34,3.03L20.5,3M5,6.46V18.31L8,17.15V5.45L5,6.46Z"
    />
  </svg>
);
export const Target = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,10.84 21.79,9.69 21.39,8.61L19.79,10.21C19.93,10.8 20,11.4 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.6,4 13.2,4.07 13.79,4.21L15.4,2.6C14.31,2.21 13.16,2 12,2M19,2L15,6V7.5L12.45,10.05C12.3,10 12.15,10 12,10A2,2 0 0,0 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.85 14,11.7 13.95,11.55L16.5,9H18L22,5H19V2M12,6A6,6 0 0,0 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12H16A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8V6Z"
    />
  </svg>
);
export const CreditCard = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"
    />
  </svg>
);

export const Cancel = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z"
    />
  </svg>
);

export const Link = ({
  style = {},
  fill = themeColors.mainColors.primaryColor,
  width = 200,
  className = '',
  viewBox = '0 0 24 24',
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ''}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={fill}
      d="M3.9,12C3.9,10.29 5.29,8.9 7,8.9H11V7H7A5,5 0 0,0 2,12A5,5 0 0,0 7,17H11V15.1H7C5.29,15.1 3.9,13.71 3.9,12M8,13H16V11H8V13M17,7H13V8.9H17C18.71,8.9 20.1,10.29 20.1,12C20.1,13.71 18.71,15.1 17,15.1H13V17H17A5,5 0 0,0 22,12A5,5 0 0,0 17,7Z"
    />
  </svg>
);
