import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { DocumentNode } from 'graphql';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, Delete, Visibility } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { ConfirmationPopper } from '..';

type IconSize = 'sm' | 'md' | 'lg';

type IconSizeObj = {
  height: number;
  width: number;
};

type BaseModifierProps = {
  type: 'Event' | 'Task' | 'Workflow';
  iconSize: IconSize;
  deleteMutation: DocumentNode;
  deleteVariablesObj: { [key: string]: string };
  onViewClick?: (e: React.MouseEvent) => void;
  onEditClick: (e: React.MouseEvent) => void;
  suppressView?: boolean;
};

const StyledWrapper = styled.span`
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  padding: 5px;
`;

const iconSizeObj = (iconSize: IconSize): IconSizeObj => {
  switch (iconSize) {
    case 'sm':
      return { height: 14, width: 14 };
    case 'md':
      return { height: 18, width: 18 };
    case 'lg':
      return { height: 22, width: 22 };
    default:
      return { height: 18, width: 18 };
  }
};

const popperTop = (iconSize: IconSize): number => {
  switch (iconSize) {
    case 'sm':
      return 30;
    case 'md':
      return 35;
    case 'lg':
      return 40;
    default:
      return 45;
  }
};

const BaseModifiers: React.FC<BaseModifierProps> = ({
  type,
  iconSize,
  deleteMutation,
  deleteVariablesObj,
  onViewClick,
  onEditClick,
  suppressView,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteItem, { loading, error, data }] = useMutation(deleteMutation);

  useEffect(() => {
    // hide confirmation on success or failure state
    if (data || error) {
      setShowConfirmation(false);
      if (error) console.error(error);
    }
  }, [error, data]);

  const onDelete = (): void => {
    deleteItem({ variables: deleteVariablesObj });
  };

  const iconStyles = useMemo(() => iconSizeObj(iconSize), [iconSize]);

  return (
    <StyledWrapper>
      {!suppressView && (
        <Tooltip title={`View ${type}`} placement="top-end">
          <StyledIconButton color="secondary" onClick={onViewClick}>
            <Visibility style={iconStyles} />
          </StyledIconButton>
        </Tooltip>
      )}
      <Tooltip title={`Edit ${type}`} placement="top-end">
        <StyledIconButton color="secondary" onClick={onEditClick}>
          <Edit style={iconStyles} />
        </StyledIconButton>
      </Tooltip>
      <Tooltip title={`Delete ${type}`} placement="top-end">
        <StyledIconButton
          color="secondary"
          onClick={(): void => setShowConfirmation(true)}
        >
          <Delete style={iconStyles} />
        </StyledIconButton>
      </Tooltip>
      <ConfirmationPopper
        in={showConfirmation}
        message="Confirm Delete:"
        top={popperTop(iconSize)}
        right={10}
        width={210}
        // keep the loader showing until it closes after data is received
        showLoader={loading || data}
        onCancelClick={(): void => setShowConfirmation(false)}
        onConfirmClick={onDelete}
      />
    </StyledWrapper>
  );
};

export { BaseModifiers };
