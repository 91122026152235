import React, { useState } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import { PostsTable } from '../../components/index.ts';
import NewPost from './newPost/newPost';
import { SitemapGenerator } from './sitemap';
import KeywordsCategories from './KeywordsCategories';

export const Posts = () => {
  const [tab, setTab] = useState(0);
  const handleChange = (event, newTab) => {
    setTab(newTab);
  };
  const paper = {
    width: '100%',
    borderRadius: 10,
    paddingBottom: 40,
  };
  const TAGS = gql`
    query TAGS {
      category(order_by: { category_name: asc_nulls_last }) {
        category_id
        category_name
      }
      tag(order_by: { tag_value: asc_nulls_last }) {
        tag_id
        tag_value
      }
    }
  `;

  const { loading, error, data } = useQuery(TAGS);
  if (loading) {
    return 'loading...';
  }
  if (error) {
    return <div>there has been an error</div>;
  }
  return (
    <Paper style={paper}>
      <Tabs
        style={{ width: '80%', paddingLeft: 5 }}
        value={tab}
        center
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleChange}
        aria-label="Case Nav Tabs"
        scrollButtons="on"
        variant="scrollable"
      >
        <Tab label="Posts" />
        <Tab label="New Post" />
        <Tab label="Categories & Tags" />
        <Tab label="Sitemap" />
      </Tabs>
      {tab === 0 ? <PostsTable /> : null}
      {tab === 1 ? <NewPost data={data} /> : null}
      {tab === 2 ? <KeywordsCategories data={data} /> : null}
      {tab === 3 ? <SitemapGenerator data={data} /> : null}
    </Paper>
  );
};
