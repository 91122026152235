import React from 'react';
import { BaseModifiers } from './BaseModifiers';
import { DELETE_WORKFLOW_TASK } from '../mutations';

type WorkflowTaskModifierProps = {
  taskId: string;
  onViewClick?: (e: React.MouseEvent) => void;
  onEditClick: (e: React.MouseEvent) => void;
  suppressView?: boolean;
};
const defaultProps = {
  suppressView: false,
};
const WorkflowTaskModifiers: React.FC<WorkflowTaskModifierProps> = ({
  taskId,
  onViewClick,
  onEditClick,
  suppressView,
}) => (
  <BaseModifiers
    type="Task"
    iconSize="md"
    deleteMutation={DELETE_WORKFLOW_TASK}
    deleteVariablesObj={{ workflow_task_id: taskId }}
    onViewClick={onViewClick}
    onEditClick={onEditClick}
    suppressView={!!suppressView}
  />
);

WorkflowTaskModifiers.defaultProps = defaultProps;

export { WorkflowTaskModifiers };
