const starksLawBlue = '#2d628f';
export const themeColors = {
  primaryColor: '#A0ACBD',
  secondaryColor: starksLawBlue,
  danger: '#d41646',
  cardBackgroundDark: 'rgba(75,75,75,1)',
  cardBackgroundLight: 'rgba(255,255,255,1)',
  backgroundDark: 'rgba(60,60,60,1)',
  backgroundLight: 'rgba(240,240,240,1)',
};
