import React from 'react';
import { useGetClientInvoices } from '../../../../hooks';
import { ExpenseInfo } from './infoBox';

export const Expenses = ({ matter, client }) => {
  const { expenses } = matter;

  const expenseSum = expenses.reduce((total, number): any => {
    return total + parseFloat(number.expense_amount);
  }, 0);
  const { invoices, loadingInvoices } = useGetClientInvoices({ client });

  let paidSum = invoices?.reduce((total, number): any => {
    return total + number?.amount_paid || 0;
  }, 0);

  paidSum /= 100;

  paidSum = parseFloat(paidSum).toFixed(2);

  return (
    <ExpenseInfo
      expenseSum={expenseSum}
      paidSum={paidSum}
      loading={loadingInvoices}
    />
  );
};
