import { gql, useQuery } from '@apollo/client';
import { Paper, Tab, Tabs, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import { useCurrentAttorney } from '../../hooks';
import { Tasks } from './sections';

const StyledPaper = styled(Paper)`
  width: 100%;
  border-radius: 10px;
  padding-bottom: 90px;
`;
const StyledContentWrapper = styled.div`
  min-height: 150px;
  width: 95%;
  margin: 40px auto 0;
  padding: 0 30px 30px;
`;
const StyledFilterBar = styled.div`
  float: right;
  margin: -30px 5% 0 0;
  display: flex;
  flex-direction: row;
  width: 50%;
  min-width: 300px;
  justify-content: space-between;
`;
const StyledFilterBarItem = styled.div`
  width: 45%;
  margin: 0 5px;
`;

export const TasksView: React.FC = () => {
  const { currentAttorney } = useCurrentAttorney();
  const getAttorneys = gql`
    query MyQuery {
      attorney(where: { attorney_active: { _eq: true } }) {
        attorney_id
        attorney_name
        attorney_role
        attorney_active
        attorney_email
      }
    }
  `;
  const { data: attorneys, loading } = useQuery(getAttorneys);
  const [assignedTo, setAssignedTo] = useState<number | undefined | null>();
  const handleAssignedTo = (_e: unknown, value): void => {
    setAssignedTo(value?.attorney_id);
  };
  useEffect(() => {
    setAssignedTo(currentAttorney?.attorney_id);
  }, [currentAttorney]);

  const [searchTerm, setSearchTerm] = useState('%');

  const handleSearchTerm = useCallback(
    (e): void => {
      setSearchTerm(`%${e.target.value}%`);
    },
    [setSearchTerm],
  );

  const debouncedHandleSearchTerm = useMemo(
    () => _.debounce(handleSearchTerm, 300),
    [handleSearchTerm],
  );

  if (loading || !currentAttorney) {
    return null;
  }
  return (
    <StyledPaper>
      <Tabs>
        <Tab label="All Tasks" />
      </Tabs>

      <>
        <StyledFilterBar>
          <StyledFilterBarItem>
            <TextField
              id="task-search"
              size="small"
              fullWidth
              label="Search"
              variant="outlined"
              onChange={debouncedHandleSearchTerm}
            />
          </StyledFilterBarItem>
          <StyledFilterBarItem>
            <Autocomplete
              id="name-filter"
              size="small"
              fullWidth
              defaultValue={attorneys?.attorney.find(
                x => x.attorney_id === currentAttorney?.attorney_id,
              )}
              options={attorneys?.attorney
                .filter(x =>
                  ['Attorney', 'Support Staff', 'System User'].includes(
                    x.attorney_role,
                  ),
                )
                .sort((a, b) => a.attorney_name.localeCompare(b.attorney_name))}
              getOptionLabel={(option): string =>
                option.attorney_role === 'Attorney'
                  ? `${option.attorney_name}, Esq.`
                  : option.attorney_name
              }
              onChange={handleAssignedTo}
              renderInput={(params): ReactNode => (
                <TextField {...params} label="Assigned To" variant="outlined" />
              )}
            />
          </StyledFilterBarItem>
        </StyledFilterBar>

        <StyledContentWrapper>
          <Tasks assignedTo={assignedTo as any} searchTerm={searchTerm} />
        </StyledContentWrapper>
      </>
    </StyledPaper>
  );
};
