import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import {
  Paper,
  Typography,
  Dialog,
  DialogContentText,
  Button,
  TextField,
  InputAdornment,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useUpdateProfile } from '../../../../../hooks';
import { boxShadows } from '../../../../../constants';
import { WinLoss } from '../../../../../components';
import { OpFirmProfileDialog } from '../opFirms/opFirmProfile';

const OpCounselQuery = gql`
  query OpCounselQuery($id: uuid) {
    op_counsel(where: { op_counsel_id: { _eq: $id } }) {
      op_counsel_id
      op_counsel_name
      op_counsel_firm_id
      op_firm {
        op_firm_address1
        op_firm_address2
        op_firm_address_city
        op_firm_address_state
        op_firm_address_zip
        op_firm_address_zip_4
        op_firm_phone
        op_firm_name
        op_firm_id
        op_firm_fax
        op_firm_email
      }
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        matter_id
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;

const StyledContentContainer = styled.div`
  padding-top: 75px;
`;
const StyledDialogContent = styled.div`
  width: 75%;
  min-width: 320px;
  padding: 5px 15px;
  /* max-width: 1200px; */
  margin: auto;
  overflow-x: hidden;
`;
const MatterSection = styled.div`
  margin: 50px auto;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
const MatterCard = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 10px 0;
  min-width: 280px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
    /* transform: scale(1.02); */
  }
`;
const MatterCardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;

export const OpCounselProfileDialog = ({ open, handleClose, id }) => {
  const { onSave } = useUpdateProfile();
  const [opCounselDetails, setOpCounselDetails] = useState<any>([]);
  const { data, loading, error } = useQuery(OpCounselQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const [openOpFirmProfile, setOpenOpFirmProfile] = useState(false);
  const [opId, setOpId] = useState(null);
  const handleOpenOpFirmProfile = (ID): void => {
    setOpenOpFirmProfile(true);
    setOpId(ID);
  };
  const handleCloseOpFirmProfile = (): void => {
    setOpenOpFirmProfile(false);
  };

  // -------------------------Edits

  // Name Change
  const [opCounselNameReady, setOpCounselNameReady] = useState(false);
  const [newOpCounselName, setNewOpCounselName] = useState('');
  const handleOpCounselNameChange = (e): void => {
    setOpCounselNameReady(true);
    setNewOpCounselName(e.target.value);
  };
  const handleSaveOpCounselName = (): void => {
    setOpCounselNameReady(false);
    onSave('OpCounselName', id, newOpCounselName);
  };

  useEffect(() => {
    if (data && id) {
      setOpCounselDetails(data?.op_counsel[0]);
      setNewOpCounselName(data?.op_counsel[0]?.op_counsel_name);
      //   setNewOpPartyStreet(data?.op_party[0]?.op_party_address);
    }
  }, [data]);

  if (loading) {
    return <>...loading</>;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            {opCounselDetails?.op_counsel_name}
          </Typography>
        </Toolbar>
      </AppBar>
      <StyledContentContainer />
      <DialogContentText>
        <StyledDialogContent>
          <TextField
            variant="filled"
            label="Name"
            value={newOpCounselName || ''}
            fullWidth
            onChange={handleOpCounselNameChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opCounselNameReady && (
                    <Button
                      onClick={handleSaveOpCounselName}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <MatterSection>
            <Typography variant="h5">Employer</Typography>
            <br />
            <CardContainer>
              <MatterCard
                onClick={(): void =>
                  handleOpenOpFirmProfile(opCounselDetails?.op_firm?.op_firm_id)
                }
              >
                <MatterCardContentContainer>
                  <div style={{ minHeight: 35, padding: 20 }}>
                    <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                      {opCounselDetails?.op_firm?.op_firm_name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: 10 }}
                    >
                      {opCounselDetails?.op_firm?.op_firm_phone ||
                        'No Phone Number'}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: 10 }}
                    >
                      {`${opCounselDetails?.op_firm?.op_firm_address1} ${opCounselDetails?.op_firm?.op_firm_address2}`}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ fontSize: 10 }}
                    >
                      {`${opCounselDetails?.op_firm?.op_firm_address_city}, ${
                        opCounselDetails?.op_firm?.op_firm_address_state
                      } ${
                        opCounselDetails?.op_firm?.op_firm_address_zip
                      } ${opCounselDetails?.op_firm?.op_firm_address_zip_4 &&
                        `- ${opCounselDetails?.op_firm?.op_firm_address_zip_4}`}`}
                    </Typography>
                  </div>
                </MatterCardContentContainer>
              </MatterCard>
            </CardContainer>
          </MatterSection>
          <MatterSection>
            <Typography variant="h5">Matters</Typography>
            <br />
            <CardContainer>
              {data?.op_counsel[0]?.matters?.map(
                ({ matter_name, matter_id, matter_closed, stage }) => {
                  return (
                    <MatterCard
                      key={matter_id}
                      onClick={(): void => {
                        const win: Window | null = window.open(
                          `/cases/${matter_id}`,
                          '_blank',
                        );
                        if (win === null) {
                          return;
                        }
                        win.focus();
                      }}
                    >
                      <MatterCardContentContainer>
                        <div>
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: 12, padding: '0 25px' }}
                          >
                            {matter_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: 10, padding: '0 25px' }}
                          >
                            {stage?.stage_name || ''}
                          </Typography>
                        </div>
                        <EndPiece>
                          <Typography variant="subtitle2">
                            {(!matter_closed && 'Open') || 'Closed'}
                          </Typography>
                        </EndPiece>
                      </MatterCardContentContainer>
                    </MatterCard>
                  );
                },
              )}
            </CardContainer>
          </MatterSection>
          <MatterSection>
            <Typography variant="h5">Statistics</Typography>
            <br />
            <WinLoss
              title="Win Rate"
              labels={['Won', 'Lost', 'Withdrawn', 'Pending']}
              data={[
                data?.op_counsel[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Won',
                ).length,
                data?.op_counsel[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Lost',
                ).length,
                data?.op_counsel[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Withdrawn',
                ).length,
                data?.op_counsel[0]?.matters
                  ?.filter(
                    x => x.stage?.stage_name !== 'Concluded/Closed-Withdrawn',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Won')
                  .filter(
                    x =>
                      x.stage?.stage_name !== 'Concluded/Closed-Dismissed-WOP',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Lost')
                  .length,
              ]}
            />
          </MatterSection>
        </StyledDialogContent>
      </DialogContentText>
      {openOpFirmProfile && (
        <OpFirmProfileDialog
          open={openOpFirmProfile}
          handleClose={handleCloseOpFirmProfile}
          id={opId}
        />
      )}
    </Dialog>
  );
};
