import axios from 'axios';

export const GetFiles = (cl, c, setLoading, setFiles): void => {
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      User: cl?.client_id,
      Matter: c?.matter_id,
    },
  };
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;
  const putURL = `${baseServerUrl}/list-case-files`;
  axios
    .post(putURL, '', options)
    .then(x => setFiles(x.data))
    .then((): void => setLoading(false));
};
