import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Document, Image, Page, StyleSheet, usePDF } from '@react-pdf/renderer';
import React from 'react';
import styled from 'styled-components';
import { useColors } from '../../../hooks';
import { Loader } from './loader';
import {
  BillTo,
  InvoiceItemsTable,
  InvoiceNo,
  InvoiceThankYouMsg,
  InvoiceTitle,
} from './parts';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
  },
  logo: {
    width: 200,
    margin: '10px auto 25px -10px',
  },
  section: { color: 'white', textAlign: 'center', margin: 30 },
});

const PDFContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 64px auto -20px;
  padding: 0 0 0 0;
  overflow: hidden;
`;
const LoadContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

// Create Document Component
const pages = (invoice: string, invoiceNo: string) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image
        style={styles.logo}
        src="https://dt84eajp0482g.cloudfront.net/logos/logo.png"
      />
      <InvoiceTitle title="Invoice" />
      <InvoiceNo invoice={invoice} invoiceNo={invoiceNo} />
      <BillTo invoice={invoice} />
      <InvoiceItemsTable invoice={invoice} />
      <InvoiceThankYouMsg />
    </Page>
  </Document>
);

export const InvoicePDF = ({ open, handlePDFClose, invoice, invoiceNo }) => {
  const [instance] = usePDF({
    document: pages(invoice, invoiceNo),
  });
  const { themeColors } = useColors();
  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;
  return (
    <Dialog fullScreen open={open}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handlePDFClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="div">
            Sound
          </Typography>
          <Button autoFocus color="inherit" onClick={handlePDFClose}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <PDFContainer>
        {(instance.loading && (
          <LoadContainer>
            <Loader
              variant="dot"
              color={themeColors.mainColors.primaryColor}
              size="xl"
            />
          </LoadContainer>
        )) || (
          <iframe
            // src={`${url}#view=fit`}
            src={`${instance.url}#view=fit` || '#'}
            style={{ width: '100%', height: '100%' }}
            frameBorder="0"
            title="Mailer Preview"
          />
        )}
      </PDFContainer>
      ;
    </Dialog>
  );
};
