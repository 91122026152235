import React from 'react';

interface DropTextProps {
  files: Array<File>;
}
export const DropText: React.FC<DropTextProps> = ({ files }) => {
  let text;
  if (files && files.length > 1) {
    text = `${files.length} files ready for upload`;
  } else if (files && files.length === 1) {
    text = `${files.length} file ready for upload`;
  } else {
    text = `Drag & Drop`;
  }
  return text;
};
