import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSubscription } from '@apollo/client';
import { fullCaseInfo, ErrorNotification } from '../../components';
import { CaseLoader } from './CaseLoader';

export const Case: React.FC = () => {
  const {
    params: { caseID },
  } = useRouteMatch();

  const { loading, error, data } = useSubscription(fullCaseInfo, {
    variables: { caseID },
  });

  if (loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }

  if (error) {
    return (
      <ErrorNotification
        text={`Failed to get matter ${caseID}, with error: ${error.message}`}
      />
    );
  }

  return data && data.matter && data.matter[0] ? (
    <CaseLoader matter={data.matter[0]} />
  ) : (
    <ErrorNotification text={`Failed to find matter: ${caseID}`} />
  );
};
