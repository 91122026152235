import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import { useCurrentTimeZone } from '../../../hooks';
import { HoverContent } from './hoverContent';

const Container = styled.div`
  display: grid;
  grid-template-columns: 25px 90%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  .MuiTooltip-tooltip {
    background-color: #f5f5f9 !important;
    color: rgba(0, 0, 0, 0.87) !important;
    border: 1px solid #dadde9;
  }
`;
const Block = styled.div<{ bg: any }>`
  background: ${({ bg }): string => bg || '#e57373'};
  height: 25px;
  width: 25px;
  border-radius: 8% 40% 8%;
`;

const InnerCalendarContainer = styled.div<{ bg: any }>`
  padding: 0;
  margin: 0;
  font-size: 11px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: white;
  overflow: hidden;
`;
const Row = styled.div`
  margin-left: 3px;
  width: 91%;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
`;

const Item = styled.div`
  text-align: left;
`;
const UserTab = styled.div`
  text-align: right;
`;
const CenterItem = styled.div`
  text-align: center;
`;
const CalendarItemTitle = styled.div`
  margin-left: 3px;
  width: 91%;
  font-size: 11px;
  text-align: left;
`;

export const InnerContent = (innerProps, event): JSX.Element => {
  const { zoneAbr } = useCurrentTimeZone();
  return (
    <Container>
      <Tooltip
        title={HoverContent(innerProps, event) as JSX.Element}
        arrow
        interactive
      >
        <Block bg={innerProps.event.backgroundColor} />
      </Tooltip>
      <InnerCalendarContainer bg={innerProps.event.backgroundColor}>
        <Row>
          <Item>
            <div>
              {`${innerProps?.timeText}`}
              <span
                style={{
                  fontSize: 7,
                  margin: '0 0 0 3px',
                  verticalAlign: 'super',
                }}
              >
                {zoneAbr}
              </span>
            </div>
          </Item>
          <CenterItem>{event?.event_county}</CenterItem>
          <UserTab>
            <div>
              {(event?.attorney?.attorney_name === 'Starks Law' && ' ') ||
                event?.attorney?.attorney_name}
            </div>
          </UserTab>
        </Row>

        <CalendarItemTitle>
          {innerProps.event.title || <>&nbsp;</>}
        </CalendarItemTitle>
      </InnerCalendarContainer>
    </Container>
  );
};
