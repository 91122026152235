import { gql } from '@apollo/client';

const ADD_CLIENT = gql`
  mutation ADD_CLIENT(
    $firstName: String
    $lastName: String
    $middleName: String
    $cell: String
    $email: String
    $street: String
    $city: String
    $state: String
    $zip: Int
    $zip4: Int
    $ssn: Int
  ) {
    insert_client(
      objects: {
        client_address_city: $city
        client_address_state: $state
        client_address_street: $street
        client_address_zip: $zip
        client_address_zip_4: $zip4
        client_email: $email
        client_firstName: $firstName
        client_lastName: $lastName
        client_middleName: $middleName
        client_phone_cell: $cell
        client_ssn_last_four: $ssn
      }
    ) {
      returning {
        client_id
      }
    }
  }
`;
export default ADD_CLIENT;
