import React from 'react';
import styled from 'styled-components';
import { BaseModifiers } from './BaseModifiers';
import { DELETE_WORKFLOW } from '../mutations';

type WorkflowModifierProps = {
  workflowId: string;
  onEditClick: (e: React.MouseEvent) => void;
};

const StyledWrapper = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const WorkflowModifiers: React.FC<WorkflowModifierProps> = ({
  workflowId,
  onEditClick,
}) => (
  <StyledWrapper>
    <BaseModifiers
      type="Workflow"
      iconSize="md"
      deleteMutation={DELETE_WORKFLOW}
      deleteVariablesObj={{ workflow_id: workflowId }}
      onEditClick={onEditClick}
      suppressView
    />
  </StyledWrapper>
);

export { WorkflowModifiers };
