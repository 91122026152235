/* eslint-disable no-multi-str */
import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Button } from '@material-ui/core';

export const NoteEditorBox = ({
  note,
  handleNoteChange,
  noteReady,
  handleSaveNotes,
}): any => {
  return (
    <>
      <Editor
        initialValue={note}
        apiKey="9h9g57gwpv98kda2tlz6pltsy0ae1muqb5dvgez27rvy2gsa"
        init={{
          menubar: true,
          plugins: [
            'advlist autolink autoresize lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor forecolor | \
                 alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | code | help',
        }}
        onChange={handleNoteChange}
      />
      {noteReady && (
        <Button onClick={handleSaveNotes} variant="outlined" color="primary">
          Save Notes
        </Button>
      )}
    </>
  );
};
