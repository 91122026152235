import { useSubscription } from '@apollo/client';
import {
  Paper,
  SwitchProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { BaseTableOptions } from '.';
import history from '../../history';
import { useColors, useCurrentAttorney } from '../../hooks';
import { MatterTypeIcon } from '../../utils/MatterTypeIcon';
import {
  GET_NEW_CASES_SUBSCRIBE,
  GET_NEW_CASES_SUBSCRIBE_NON_COMPLETE,
} from '../queries/cases/subscriptions/cases';
import { TableLoader } from '../stories/2-Placeholders.stories';

const { themeColors } = useColors();
const useStyles = makeStyles({
  tableHeader: {
    color: 'white',
    textAlign: 'center',
    backgroundColor: themeColors.mainColors.primaryColor,
  },
  Root: {
    width: '100%',
    overflowX: 'auto',
    color: themeColors.mainColors.primaryColor,
    borderRadius: 10,
  },
});

export interface CaseTableProps extends SwitchProps {
  title?: string;
  borderTop?: string | number | (string & {}) | undefined;
  pagination?: boolean;
  denseColumns?: boolean;
  denseControl?: boolean;
  searchTerm?: string;
  casesPerPage?: number;
  offset?: number;
}

const IconSpace = styled.span`
  padding: 5px;
`;

const filterSet = (matter, closed, my_cases, attorney_email) => {
  return matter
    .filter(x => (!closed ? !x?.matter_closed : true))
    .filter(x =>
      my_cases ? x?.owning_attorney.attorney_email === attorney_email! : true,
    );
};
const querySelect = (v: boolean): any => {
  if (v) {
    return GET_NEW_CASES_SUBSCRIBE;
  }
  return GET_NEW_CASES_SUBSCRIBE_NON_COMPLETE;
};

export const CaseTable: React.FC<CaseTableProps> = ({
  casesPerPage,
  title,
  borderTop,
  offset,
  pagination,
  denseColumns,
  searchTerm,
}) => {
  const classes = useStyles();
  const { currentAttorney } = useCurrentAttorney();

  const [page, setPage] = useState(0);
  const [filterSwitch, setFilterSwitch] = useState({
    closed: false,
    dense: denseColumns || localStorage.getItem('caseTableDense') === 'true',
    filterByManager: true,
    my_cases: localStorage.getItem('showMyCases') === 'true',
  });
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('caseRowsPerPage') as string, 10) || 5,
  );

  const handleChangePage = (
    _event: unknown,
    newPage: React.SetStateAction<number>,
  ): void => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: {
    target: { value: string };
  }): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('caseRowsPerPage', event.target.value);
    setPage(0);
  };
  const table = {
    color: 'white',
  };

  const { loading, error, data } = useSubscription(
    querySelect(filterSwitch.closed),
    {
      variables: {
        caseLimit: casesPerPage,
        offset,
        searchTerm,
      },
    },
  );

  const handleFilterSwitch = (n): void => {
    // console.log(filterSwitch.my_cases);
    setFilterSwitch({ ...filterSwitch, [n]: !filterSwitch[n] });

    console.log(filterSwitch.dense);

    if (n === 'dense') {
      localStorage.setItem('caseTableDense', `${!filterSwitch.dense}`);
    }
    if (n === 'my_cases') {
      localStorage.setItem('showMyCases', `${!filterSwitch.my_cases}`);
    }
  };
  if (loading) {
    return (
      <div>
        <div style={{ height: 50 }} />
        <TableLoader />
      </div>
    );
  }
  if (error) {
    return <div>there has been an error</div>;
  }

  return (
    <>
      <Paper className={classes.Root}>
        <div style={{ borderTop: borderTop && borderTop }}>
          <Typography variant="h6" className={classes.tableHeader}>
            {title && title}
          </Typography>

          <BaseTableOptions
            handleFilterSwitch={handleFilterSwitch}
            filterSwitch={filterSwitch}
          />
          {pagination && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
              component="div"
              count={
                filterSet(
                  data.matter,
                  filterSwitch.closed,
                  filterSwitch.my_cases,
                  currentAttorney?.attorney_email,
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{ float: 'right' }}
            />
          )}
        </div>
        <Table
          style={table}
          aria-label="simple table"
          size={(filterSwitch.dense && 'small') || 'medium'}
        >
          <TableHead>
            <TableRow
              style={{
                borderTop: `2px solid  ${themeColors.mainColors.primaryColor}`,
              }}
            >
              <TableCell align="left">
                <Typography color="secondary">Case Name</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography color="secondary">Client</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography color="secondary">Opened</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography color="secondary">Docket</Typography>
              </TableCell>
              <TableCell align="left">
                <Typography color="secondary">Status</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterSet(
              data.matter,
              filterSwitch.closed,
              filterSwitch.my_cases,
              currentAttorney?.attorney_email,
            )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(
                ({
                  matter_name,
                  matter_id,
                  matter_court_case_number,
                  matter_opened_date,
                  client,
                  matter_closed,
                  court,
                }) => (
                  <TableRow
                    style={{ cursor: 'pointer' }}
                    hover
                    key={matter_id}
                    onClick={(): void => history.push(`/cases/${matter_id}`)}
                  >
                    <>
                      <TableCell align="left">
                        <IconSpace>
                          <MatterTypeIcon
                            type={
                              (court?.court_name !== 'Default' &&
                                court?.court_type) ||
                              ''
                            }
                          />
                        </IconSpace>
                        {matter_name}
                      </TableCell>
                      <TableCell align="left">
                        {`${client?.client_firstName ||
                          ''} ${client?.client_lastName || ''}`}
                      </TableCell>
                      <TableCell align="left">
                        {moment(matter_opened_date).format('LLL')}
                      </TableCell>
                      <TableCell align="left">
                        {matter_court_case_number}
                      </TableCell>
                      <TableCell align="left">
                        {(matter_closed && 'CLOSED') || 'OPEN'}
                      </TableCell>
                    </>
                  </TableRow>
                ),
              )}
          </TableBody>
        </Table>
        {pagination && (
          <>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
              component="div"
              count={
                filterSet(
                  data.matter,
                  filterSwitch.closed,
                  filterSwitch.my_cases,
                  currentAttorney?.attorney_email,
                ).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                'aria-label': 'previous page',
              }}
              nextIconButtonProps={{
                'aria-label': 'next page',
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              style={{ float: 'right' }}
            />
            <div
              style={{
                borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
              }}
            />
          </>
        )}
      </Paper>
    </>
  );
};
