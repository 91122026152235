import React, { useState } from 'react';
import { Tab, Tabs, Paper } from '@material-ui/core';
import { ListInvoices } from './Sections';

export const ClientBilling: React.FC = () => {
  const [tab, setTab] = useState(0);
  const handleChange = (_event, newTab) => {
    setTab(newTab);
  };

  const paper = {
    width: '100%',
    borderRadius: 10,
    paddingBottom: 40,
  };

  return (
    <>
      <Paper style={paper}>
        <Tabs
          style={{ width: '90%', paddingLeft: 5, margin: 'auto' }}
          value={tab}
          //   center
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Case Nav Tabs"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab label="Invoices" />
          <Tab label="Payment Plans" />
        </Tabs>
        {tab === 0 ? <ListInvoices /> : null}
      </Paper>
    </>
  );
};
