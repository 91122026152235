import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  AttorneySelect,
  DatePicker,
  ErrorNotification,
  FormGrid,
  Input,
  Toggle,
  useFormFieldState,
  Modal,
} from '../..';
import { EditableMatterEvent } from '../../../utils';

type BaseEventModalProps = {
  open: boolean;
  title: string;
  formError: string;
  initialEventState?: EditableMatterEvent;
  onSave: (event: EditableMatterEvent) => void;
  onClose: () => void;
  loading: boolean;
};

const StyledToggle = styled(Toggle)`
  margin-top: 18px;
`;

const BaseEventModal: React.FC<BaseEventModalProps> = ({
  open,
  title,
  formError,
  initialEventState = {},
  onSave,
  onClose,
  loading,
}) => {
  const [eventComplete, setEventComplete] = useState(false);

  const {
    state: eventName,
    setValue: setEventName,
    setError: setEventNameError,
    setErrorText: setEventNameErrorText,
    resetState: resetEventNameState,
  } = useFormFieldState(initialEventState.event_name);
  const {
    state: eventType,
    setValue: setEventType,
    setError: setEventTypeError,
    setErrorText: setEventTypeErrorText,
    resetState: resetEventTypeState,
  } = useFormFieldState(initialEventState.event_type || undefined);
  const {
    state: eventLocation,
    setValue: setEventLocation,
    setError: setEventLocationError,
    setErrorText: setEventLocationErrorText,
    resetState: resetEventLocationState,
  } = useFormFieldState(initialEventState.event_location || undefined);
  const {
    state: eventCounty,
    setValue: setEventCounty,
    setError: setEventCountyError,
    setErrorText: setEventCountyErrorText,
    resetState: resetEventCountyState,
  } = useFormFieldState(initialEventState.event_county || undefined);
  const {
    state: attorneyId,
    setValue: setAttorneyId,
    setError: setAttorneyIdError,
    setErrorText: setAttorneyIdErrorText,
    resetState: resetAttorneyIdState,
  } = useFormFieldState();
  const {
    state: eventStart,
    setValue: setEventStart,
    setError: setEventStartError,
    setErrorText: setEventStartErrorText,
    resetState: resetEventStartState,
  } = useFormFieldState(initialEventState.event_date_time);
  const {
    state: eventEnd,
    setValue: setEventEnd,
    setError: setEventEndError,
    setErrorText: setEventEndErrorText,
    resetState: resetEventEndState,
  } = useFormFieldState(initialEventState.event_end_time || undefined);

  const isValidEvent = (): boolean => {
    return (
      !!eventName.value.length &&
      !!eventType.value.length &&
      !!eventLocation.value.length &&
      !!eventCounty.value.length &&
      !!attorneyId.value.length &&
      !!eventStart.value.length &&
      !!eventEnd.value.length &&
      moment(eventEnd.value).isAfter(moment(eventStart.value))
    );
  };

  const setErrors = (): void => {
    if (!eventName.value.length) {
      setEventNameError(true);
      setEventNameErrorText('Field is required');
    }
    if (!eventType.value.length) {
      setEventTypeError(true);
      setEventTypeErrorText('Field is required');
    }
    if (!eventLocation.value.length) {
      setEventLocationError(true);
      setEventLocationErrorText('Field is required');
    }
    if (!eventCounty.value.length) {
      setEventCountyError(true);
      setEventCountyErrorText('Field is required');
    }
    if (!attorneyId.value.length) {
      setAttorneyIdError(true);
      setAttorneyIdErrorText('Field is required');
    }
    if (!eventStart.value.length) {
      setEventStartError(true);
      setEventStartErrorText('Field is required');
    }
    if (!eventEnd.value.length) {
      setEventEndError(true);
      setEventEndErrorText('Field is required');
    }
    if (moment(eventEnd.value).isBefore(moment(eventStart.value))) {
      setEventEndError(true);
      setEventEndErrorText('End date must be after start date');
    }
  };

  const onSaveClick = (): void => {
    if (isValidEvent()) {
      onSave({
        event_owner: Number(attorneyId.value),
        event_name: eventName.value,
        event_type: eventType.value,
        event_location: eventLocation.value,
        event_county: eventCounty.value,
        event_date_time: eventStart.value,
        event_end_time: eventEnd.value,
        event_complete: eventComplete,
      });
    } else {
      setErrors();
    }
  };

  const resetState = (): void => {
    resetEventNameState();
    resetEventTypeState();
    resetEventLocationState();
    resetEventCountyState();
    resetAttorneyIdState();
    resetEventStartState();
    resetEventEndState();
    setEventComplete(false);
  };

  useEffect(() => {
    // reset state every time the modal closes
    if (!open) resetState();
  }, [open]);

  return (
    <Modal
      open={open}
      title={title}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryLoading={loading}
      includeCancelConfirmation
      onPrimaryClick={onSaveClick}
      onSecondaryClick={onClose}
      onClose={onClose}
    >
      <>
        <FormGrid>
          <Input
            label="Event name"
            value={eventName.value}
            onChange={(e): void => {
              const name = e.target.value;
              setEventName(name);
              setEventNameError(false);
            }}
            error={eventName.error}
            errorText={eventName.errorText}
          />
          <Input
            label="Event type"
            value={eventType.value}
            onChange={(e): void => {
              const type = e.target.value;
              setEventType(type);
              setEventTypeError(false);
            }}
            error={eventType.error}
            errorText={eventType.errorText}
          />
          <Input
            label="Event Location"
            value={eventLocation.value}
            onChange={(e): void => {
              const location = e.target.value;
              setEventLocation(location);
              setEventLocationError(false);
            }}
            error={eventLocation.error}
            errorText={eventLocation.errorText}
          />
          <Input
            label="Event County"
            value={eventCounty.value}
            onChange={(e): void => {
              const county = e.target.value;
              setEventCounty(county);
              setEventCountyError(false);
            }}
            error={eventCounty.error}
            errorText={eventCounty.errorText}
          />
          <AttorneySelect
            initialValue={initialEventState?.attorney?.attorney_name}
            onAttorneySelect={(attorney): void => {
              setAttorneyId(String(attorney.id));
              setAttorneyIdError(false);
            }}
            error={attorneyId.error}
            errorText={attorneyId.errorText}
            internalOnly={false}
          />
          <DatePicker
            label="Event Start Date and Time"
            value={eventStart.value}
            onChange={(date): void => {
              setEventStart(date?.toISOString() || '');
              setEventStartError(false);
            }}
            error={eventStart.error}
            errorText={eventStart.errorText}
            variant="dateTime"
          />
          <DatePicker
            label="Event End Date and Time"
            value={
              eventEnd.value ||
              moment(eventStart.value || new Date()).add(1, 'hour')
            }
            onChange={(date): void => {
              setEventEnd(date?.toISOString() || '');
              setEventEndError(false);
            }}
            error={eventEnd.error}
            errorText={eventEnd.errorText}
            variant="dateTime"
          />
          <StyledToggle
            label="Event Complete"
            onToggle={(isToggled): void => setEventComplete(isToggled)}
            initialState={initialEventState.event_complete}
          />
        </FormGrid>
        <ErrorNotification text={formError} show={!!formError.length} />
      </>
    </Modal>
  );
};

export { BaseEventModal };
