import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import { useColors } from '../../hooks';
import { MessageTable } from '../../components/index.ts';

const { themeColors } = useColors();

export const Messages = () => {
  // state for tab selections
  const [tab, setTab] = useState(0);
  const handleChange = (_event, newTab) => {
    setTab(newTab);
  };
  const [searchTerm, setSearchTerm] = useState('%');
  const handleSearch = e => {
    setSearchTerm(`%${e.target.value}%`);
  };
  const paper = {
    width: '100%',
    borderRadius: 10,
    paddingBottom: 40,
  };
  // end styling
  return (
    <>
      <Paper style={paper}>
        <Tabs
          style={{ width: '90%', paddingLeft: 5, margin: 'auto' }}
          value={tab}
          center
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Case Nav Tabs"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab label="Messages" />
          <Tab label="Create Message" />
        </Tabs>
        <div style={{ float: 'right', marginRight: '5.5%', marginTop: -45 }}>
          <TextField
            id="outlined-basic"
            fullWidth
            // style={{ width: 200 }}
            label="Search"
            // margin="normal"
            variant="outlined"
            style={{ marginTop: 7, marginLeft: 5, marginBottom: -5 }}
            onChange={handleSearch}
          />
        </div>
        {/* case info */}
        {tab === 0 ? (
          <div style={{ width: '90%', margin: 'auto', marginTop: 30 }}>
            <MessageTable
              title=" "
              borderTop={`8px solid ${themeColors.mainColors.primaryColor}`}
              pagination
              denseControl
              searchBar
              searchTerm={searchTerm}
            />
          </div>
        ) : null}
        {/* case creation screen */}
        {tab === 1 ? <></> : null}
      </Paper>
    </>
  );
};
