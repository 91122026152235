import { gql } from '@apollo/client';

const GET_EVENTS = gql`
  subscription GET_EVENTS($searchTerm: String, $searchCounty: String) {
    event(
      where: {
        _or: [
          { event_county: { _ilike: $searchCounty } }
          {
            matter: { court: { court_address_city: { _ilike: $searchCounty } } }
          }
        ]
        _and: {
          _or: [
            { matter: { court: { court_name: { _ilike: $searchTerm } } } }
            { matter: { court: { court_mdj_number: { _ilike: $searchTerm } } } }
            { matter: { court: { court_type: { _ilike: $searchTerm } } } }
          ]
        }
      }
    ) {
      event_id
      event_matter_id
      event_name
      event_type
      event_location
      event_county
      event_owner
      event_date_time
      event_end_time
      event_complete
      event_color
      event_notes
      matter {
        court {
          court_address_city
          court_county
          court_phone
        }
      }
      attorney {
        attorney_name
        attorney_login_id
        attorney_id
        attorney_extension
        attorney_email
        attorney_bar_state
        attorney_bar_id
        attorney_id_color
        attorney_role
      }
    }
  }
`;

export default GET_EVENTS;
