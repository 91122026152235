import { gql } from '@apollo/client';

export const getPosts = gql`
  subscription GET_POSTS {
    article(order_by: { article_created_at: desc }) {
      article_body
      article_created_at
      article_css
      article_id
      article_img
      article_summary
      article_tags {
        tag {
          tag_id
          tag_value
        }
      }
      article_title
      article_updated_at
      article_url_slug
      article_visibility
      attorney {
        attorney_name
        attorney_login_id
        attorney_id
      }
      category {
        category_id
        category_name
      }
      comment {
        comment_body
        comment_id
        comment_rating
        comment_title
      }
    }
  }
`;
export const getSinglePost = gql`
  query singlePost($postID: Int) {
    article(where: { article_id: { _eq: $postID } }) {
      article_body
      article_created_at
      article_css
      article_id
      article_img
      article_summary
      article_tags {
        tag {
          tag_id
          tag_value
        }
      }
      article_title
      article_updated_at
      article_url_slug
      article_visibility
      attorney {
        attorney_name
        attorney_id
        attorney_login_id
        attorney_extension
        attorney_email
        attorney_bar_state
        attorney_bar_id
      }
      category_id
      category {
        category_name
        category_id
      }
      comment {
        comment_body
        comment_id
        comment_rating
        comment_title
      }
    }
  }
`;
