import React, { useState } from 'react';
import styled from 'styled-components';
import {
  IconButton,
  Switch,
  Tooltip,
  FormControlLabel,
  Button,
} from '@material-ui/core';
import { LibraryAdd } from '@material-ui/icons';
import { EventCard } from './EventCard';
import { MatterTaskTable } from './MatterTaskTable';
import { AddEventModal, DeletedTaskModal } from '../../../../components';
import { useColors } from '../../../../hooks';
import { MatterEvent, Matter } from '../../../../utils';

const StyledAddEventButton = styled(IconButton)<{ $color: string }>`
  position: absolute;
  right: 15px;
  top: 5px;
  color: ${({ $color }): string => $color};
`;

type ActivitiesProps = {
  matter: Matter;
  events: MatterEvent[];
};
const { themeColors } = useColors();
const StyledSwitchContainer = styled.div`
  margin: 20px 30px 0px 40px;
`;

const StyledAndroid12Switch = styled(Switch)`
  padding: 8px !important;
  .MuiSwitch-track {
    border-radius: 12px !important;
    
  }
  .MuiSwitch-track::before,
  .MuiSwitch-track::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    /* padding: 0,0,0,1px; */
  }
  .MuiSwitch-track::before {
    margin-left: 3px;
     // eslint-disable-next-line prettier/prettier
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2216%22%20width%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22${themeColors.textColors.secondaryTextColor.replace(
      '#',
      '%23',
    )}%22%20d%3D%22M21%207%209%2019l-5.5-5.5%201.41-1.41L9%2016.17%2019.59%205.59%2021%207Z%22%2F%3E%3C%2Fsvg%3E');
  }
  .MuiSwitch-track::after {
    position: relative;
    float: right;
    margin-right: 3px;
    // eslint-disable-next-line prettier/prettier
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2216%22%20width%3D%2216%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22${themeColors.textColors.secondaryTextColor.replace(
      '#',
      '%23',
    )}%22%20d%3D%22M19%2013H5v-2h14v2Z%22%2F%3E%3C%2Fsvg%3E');
  }
  .MuiSwitch-thumb {
    box-shadow: none;
    width: 16px;
    height: 16px;
    margin: 2px;
  }
`;
const StyledButton = styled(Button)`
  margin: 15px;
`;

const Activities: React.FC<ActivitiesProps> = ({ matter, events }) => {
  const [openAddEventModal, setOpenAddEventModal] = useState(false);
  const [historyOpen, setHistoryOpen] = useState(false);

  const [suppressInactive, setSuppressInactive] = useState<boolean>(true);

  const handleInactive = (): void => {
    setSuppressInactive(!suppressInactive);
  };

  const { matter_id } = matter;

  const handleCloseHistory = () => {
    setHistoryOpen(false);
  };
  const handleOpenHistory = () => {
    setHistoryOpen(true);
  };

  return (
    <>
      <StyledSwitchContainer>
        <FormControlLabel
          label="Hide Inactive"
          control={
            <StyledAndroid12Switch
              checked={suppressInactive}
              onChange={handleInactive}
              color="primary"
            />
          }
        />
      </StyledSwitchContainer>

      <Tooltip title="Add Event">
        <StyledAddEventButton
          $color={themeColors.mainColors.primaryColor}
          onClick={(): void => setOpenAddEventModal(true)}
        >
          <LibraryAdd />
        </StyledAddEventButton>
      </Tooltip>
      {events
        .filter(x =>
          suppressInactive && !x?.tasks?.some(y => !y.task_completed)
            ? !x.event_complete
            : true,
        )
        .map(event => (
          <EventCard
            key={event.event_id}
            matterId={matter_id}
            event={event}
            matter={matter}
          />
        ))}
      {matter.tasks_individual && <MatterTaskTable matter={matter} />}
      <StyledButton
        variant="outlined"
        color="primary"
        onClick={handleOpenHistory}
      >
        View Archived
      </StyledButton>
      <AddEventModal
        open={openAddEventModal}
        onClose={(): void => setOpenAddEventModal(false)}
        matterId={matter_id}
      />
      {historyOpen && (
        <DeletedTaskModal
          open={historyOpen}
          matterID={matter_id}
          handleCloseHistory={handleCloseHistory}
        />
      )}
    </>
  );
};

export { Activities };
