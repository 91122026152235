import React from 'react';
import styled from 'styled-components';
import { AtomSpinner } from 'react-epic-spinners';
import { Line } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useColors } from '../../../hooks';

const StyledPaper = styled(Paper)`
  margin: 10px auto;
  width: 100%;
  min-height: 200px;
  padding: 15px 5px;
`;

export interface DefaultLineChartProps {
  fillColor?: string;
  borderColor?: string;
  title: string;
  data: any;
  unit?: any;
  end?: any;
  start?: any;
  labels: any;
  loading?: boolean;
  averageLineColor?: string;
  averageLabelBackgroundColor?: string;
  averageLabelTextColor?: string;
  average: number;
}

const { themeColors } = useColors();

const defaultProps = {
  fillColor: themeColors.chartColors.fillColor,
  borderColor: themeColors.chartColors.lineColor,
  end: new Date(),
  start: (): void => {
    const start = new Date();
    start.setDate(start.getDate() - 7); // set to 'now' minus 7 days.
    start.setHours(0, 0, 0, 0); // set to midnight.
  },
  unit: 'day',
  averageLineColor: themeColors.mainColors.primaryColor,
  averageLabelBackgroundColor: themeColors.mainColors.cardBackground,
  averageLabelTextColor: themeColors.textColors.primaryTextColor,
};

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;
export const DefaultLineChart: React.FC<DefaultLineChartProps> = ({
  fillColor,
  borderColor,
  averageLineColor,
  averageLabelBackgroundColor,
  averageLabelTextColor,
  data,
  title,
  unit,
  end,
  start,
  labels,
  loading,
  average,
}) => {
  return (
    <StyledPaper>
      {(loading && (
        <SpinnerContainer>
          <AtomSpinner color={themeColors.mainColors.primaryColor} />
        </SpinnerContainer>
      )) || (
        <Line
          plugins={[annotationPlugin]}
          data={{
            labels,
            datasets: [
              {
                fill: true,
                lineTension: 0.4,
                backgroundColor: fillColor,
                borderColor,
                borderWidth: 1.5,
                data,
                spanGaps: false,
              },
            ],
          }}
          options={{
            annotation: {
              drawTime: 'afterDatasetsDraw', // (default)
              events: ['click', 'hover'],
              dblClickSpeed: 350, // ms (default)
              annotations: [
                {
                  drawTime: 'afterDraw',
                  id: 'a-line-1',
                  type: 'line',
                  mode: 'horizontal',
                  scaleID: 'y-axis-0',
                  value: average,
                  borderColor: averageLineColor,
                  borderWidth: 0.5,
                  label: {
                    backgroundColor: averageLabelBackgroundColor,
                    fontFamily: "'Roboto', sans-serif",
                    backgroundShadowColor: 'gray',
                    shadowBlur: 3,
                    shadowOffsetY: 5,
                    shadowOffsetX: 5,
                    fontSize: 12,
                    fontStyle: '',
                    fontColor: averageLabelTextColor,
                    xPadding: 12,
                    yPadding: 5,
                    cornerRadius: 15,
                    position: 'center',
                    enabled: true,
                    content: `14 Day Average: $${average.toLocaleString()}`,
                  },
                },
              ],
            },
            title: {
              display: true,
              text: title,
              fontSize: 10,
              fontColor: themeColors.mainColors.primaryColor,
            },
            legend: {
              display: false,
              position: 'right',
              labels: {
                fontColor: themeColors.mainColors.primaryColor,
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    fontColor: themeColors.mainColors.primaryColor,
                  },
                },
              ],
              xAxes: [
                {
                  type: 'time',
                  time: {
                    tooltipFormat: 'MM/D/YYYY', // <- HERE

                    unit,
                    displayFormats: {
                      day: 'MMM D',
                    },
                  },
                  gridLines: {
                    display: false,
                  },
                  ticks: {
                    fontColor: themeColors.mainColors.primaryColor,
                    min: start,
                    max: end,
                  },
                },
              ],
            },
            maintainAspectRatio: false,
          }}
        />
      )}
    </StyledPaper>
  );
};

DefaultLineChart.defaultProps = defaultProps;
