import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

export const JudgeTableHead = () => {
  return (
    <TableHead>
      <TableRow style={{}}>
        <TableCell align="left">
          <Typography color="secondary">First Name</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Number of Cases</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Cases Won</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Friendly</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Delete</Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
