/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery, gql } from '@apollo/client';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import { boxShadows } from '../../../constants/shadows/shadows';
import { themeBorders } from '../../../constants/borders/borders';
import { PencilOutline } from '../../../components/stories/SLICONS.stories';
import { useColors } from '../../../hooks';
import { UpdateModal } from '../CaseUpdate';
import { GetOpposingFirms } from '../../../components/queries';

const InfoSection = {
  borderLeft: themeBorders.doubleLeft,
  minHeight: 150,
  width: '95%',
  margin: 'auto',
  marginTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 0,
  boxShadow: boxShadows.three,
};

const PencilButton = color => {
  return <PencilOutline width={18} fill={color} />;
};

const StyledIconButtonInline = styled(IconButton)`
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  float: right;
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 7px -20px 0 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

export const OPPOSING_COUNSEL = props => {
  const { themeColors } = useColors();
  const mode = localStorage.getItem('theme_mode');
  const { op, readOnly, titleVariant, cardTitleStyle, c } = props;
  const firmAttorneys = gql`
    query GetOpCounselList($variable: uuid) {
      op_counsel(where: { op_counsel_firm_id: { _eq: $variable } }) {
        op_counsel_id
        op_counsel_name
      }
    }
  `;
  const { loading: loadingC, errorC, data: opposingCounsel } = useQuery(
    firmAttorneys,
    {
      variables: {
        variable: c?.op_firm_id,
      },
    },
  );
  const { loading, error, data: opposingFirms } = useQuery(GetOpposingFirms);
  // new edit logic

  const tableType = 'update_matter';
  const filterTableBy = 'matter_id';
  const [openEdit, setOpenEdit] = useState(false);
  const [recordType, setRecordType] = useState();
  const [title, setTitle] = useState();
  const [isMenu, setIsMenu] = useState(false);
  const [menuData, setMenuData] = useState();
  const [text, setText] = useState();
  const handleModal = (r, t, m, d, w) => {
    setTitle(t);
    setRecordType(r);
    setIsMenu(m);
    setMenuData(d);
    setOpenEdit(!openEdit);
    setText(w);
  };
  if (loading || loadingC) {
    return <>&apos;loading...&apos;</>;
  }
  if (error || errorC) {
    return <div>there has been an error</div>;
  }
  const opFirms = opposingFirms?.op_firm
    ?.slice()
    .sort((a, b) => a.op_firm_name.localeCompare(b.op_firm_name))
    .map(({ op_firm_id, op_firm_name }) => {
      return { label: op_firm_name, value: op_firm_id };
    });

  const opCounsel = opposingCounsel?.op_counsel
    ?.slice()
    .sort((a, b) => a.op_counsel_name.localeCompare(b.op_counsel_name))
    .map(({ op_counsel_id, op_counsel_name }) => {
      return { label: op_counsel_name, value: op_counsel_id };
    });

  return (
    <>
      <Paper style={InfoSection}>
        <StyledIconButton
          m={mode}
          aria-label="toggle password visibility"
          onClick={() =>
            handleModal(
              'op_firm_id',
              'Update Opposing Firm',
              true,
              opFirms,
              c.matter_id,
            )
          }
        >
          {PencilButton(themeColors.mainColors.primaryColor)}
        </StyledIconButton>
        <Typography variant={titleVariant} style={cardTitleStyle}>
          Opposing Firm
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'center' }} />
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Attorney Name"
              fullWidth
              value={(c && c?.op_counsel?.op_counsel_name) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !op,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButtonInline
                      m={mode}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleModal(
                          'op_counsel_id',
                          'Update Opposing Counsel',
                          true,
                          opCounsel,
                          (c?.op_counsel_id && c?.op_counsel_id) || '',
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButtonInline>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Firm Name"
              fullWidth
              value={(op && op?.op_firm_name) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !op,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Firm Phone"
              fullWidth
              value={(op?.op_firm_address1 && op?.op_firm_phone) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !op,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Firm Fax"
              fullWidth
              value={(op && op?.op_firm_fax) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: (op && !op?.op_firm_fax) || false,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Firm Address"
              fullWidth
              value={
                (!op && ' ') ||
                `${op?.op_firm_address1 || ' '}${
                  op?.op_firm_address === null ? ' ' : ','
                }${op?.op_firm_address2 || ' '} ${op?.op_firm_address_city}, ${
                  op?.op_firm_address_state
                } ${op?.op_firm_address_zip}`
              }
              margin="normal"
              InputProps={{
                readOnly,
                // disabled: readOnly,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
        </Grid>
      </Paper>
      <UpdateModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        Id={c.matter_id}
        recordType={recordType}
        data={op}
        title={title}
        isMenu={isMenu}
        menuData={menuData}
        text={text}
        tableType={tableType}
        filterTableBy={filterTableBy}
      />
    </>
  );
};
