export const ParseFileSize = (size: number): string => {
  let result;
  if (size / 1000 > 1000000) {
    result = `${(size / 1000000000).toFixed(1)} GB`;
  } else if (size / 1000 > 1000) {
    result = `${(size / 1000000).toFixed(2)} MB`;
  } else {
    result = `${Math.ceil(size / 1000)} KB`;
  }
  return result;
};
