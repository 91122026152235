import { gql } from '@apollo/client';

export const GetDeletedTasks = gql`
  query ArchivedTasks($matter_id: uuid) {
    archived_tasks(where: { archived_task_matter_id: { _eq: $matter_id } }) {
      archived_task_completed
      archived_task_deleted_by_email
      archived_task_deleted_by_id
      archived_task_deleted_by_name
      archived_task_deleted_on
      archived_task_description
      archived_task_due_date
      archived_task_due_dynamic
      archived_task_dynamic_due_from
      archived_task_dynamic_lock
      archived_task_event_id
      archived_task_id
      archived_task_is_priority
      archived_task_matter_id
      archived_task_name
      archived_task_original_id
      archived_task_owner
      archived_task_trigger_workflow_id
    }
  }
`;
