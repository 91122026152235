import React from 'react';
import styled from 'styled-components';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useColors } from '../../../hooks';

type TableOptionsProps = {
  onAddTask: (e: React.MouseEvent) => void;
};

const StyledOptionsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  float: left;
  padding: 2px 0;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const StyledItem = styled.div`
  margin: 0 10px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin-left: 8px;
`;

const StyledAddIcon = styled(Add)<{ $color: string }>`
  color: ${({ $color }): string => $color};
  height: 30px;
  width: 30px;
`;

const TableOptions: React.FC<TableOptionsProps> = ({ onAddTask }) => {
  const { themeColors } = useColors();

  return (
    <StyledOptionsWrapper>
      <StyledItem>
        <Tooltip title="Add Task" placement="bottom-start">
          <StyledIconButton onClick={onAddTask}>
            <StyledAddIcon $color={themeColors.mainColors.primaryColor} />
          </StyledIconButton>
        </Tooltip>
      </StyledItem>
    </StyledOptionsWrapper>
  );
};

export { TableOptions };
