import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { ApolloProvider } from '@apollo/client';
import { client } from './components/queries/graphqlConfig/apolloClientConfig';

import { AppProvider, UserProvider } from './context';
import App from './App';

import config from './auth_config.json';
import history from './history';

const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  );
};

ReactDOM.render(
  <UserProvider>
    <ApolloProvider client={client}>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
      >
        <style>
          @import
          url(&apos;https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap&apos;);
        </style>
        <AppProvider>
          <Router history={history}>
            <App />
          </Router>
        </AppProvider>
      </Auth0Provider>
    </ApolloProvider>
  </UserProvider>,
  document.getElementById('root'),
);
