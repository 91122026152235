import { gql } from '@apollo/client';

export const GetCourts = gql`
  query GetCourts {
    court {
      court_address_city
      court_address_state
      court_address_street
      court_address_street2
      court_address_zip
      court_address_zip_4
      court_county
      court_efile_link
      court_fax
      court_id
      court_mdj_number
      court_name
      court_phone
      court_state
      court_type
    }
  }
`;

export const GetCourtsSub = gql`
  subscription GetCourts {
    court {
      court_address_city
      court_address_state
      court_address_street
      court_address_street2
      court_address_zip
      court_address_zip_4
      court_county
      court_efile_link
      court_fax
      court_id
      court_mdj_number
      court_name
      court_phone
      court_state
      court_type
      matters {
        matter_id
      }
    }
  }
`;
