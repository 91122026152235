import React from 'react';
import styled from 'styled-components';
import { Input } from './Input';
import { RadioGroup } from './RadioGroup';
import { sharedStyles } from './utils';

const StyledWrapper = styled.div`
  ${sharedStyles}
  display: flex;
  justify-content: flex-start;
`;

const StyledRadioGroup = styled(RadioGroup)``;

const StyledInput = styled(Input)`
  width: 180px;
  min-width: 180px;
`;

type DynamicPeriod = 'before' | 'after';

type DynamicDaysProps = {
  daysValue?: string;
  defaultPeriod?: DynamicPeriod;
  error?: boolean;
  errorText?: string;
  onDaysChange: (days: string) => void;
  onPeriodChange: (period: DynamicPeriod) => void;
};

const DynamicDaysField: React.FC<DynamicDaysProps> = ({
  daysValue = '0',
  defaultPeriod,
  error,
  errorText,
  onDaysChange,
  onPeriodChange,
}) => {
  return (
    <StyledWrapper>
      <StyledInput
        label="Days From Event"
        value={String(daysValue)}
        error={error}
        errorText={errorText}
        onChange={(e): void => {
          const days = e.target.value;
          onDaysChange(days);
        }}
      />
      <StyledRadioGroup
        legendText="Dynamic Period"
        options={[
          { label: 'Before', value: 'before' },
          { label: 'After', value: 'after' },
        ]}
        defaultValue={defaultPeriod}
        onValueSelected={(selectedValue: string): void => {
          onPeriodChange(selectedValue as DynamicPeriod);
        }}
      />
    </StyledWrapper>
  );
};

export { DynamicDaysField };
