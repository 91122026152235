import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import { Tooltip } from '@material-ui/core';
import {
  DynamicDaysField,
  ErrorNotification,
  FormGrid,
  Input,
  Toggle,
  WorkflowSelect,
  useFormFieldState,
  Modal,
} from '../..';
import { EditableWorkflowTask, Workflow } from '../../../utils';

type BaseWorkflowTaskModalProps = {
  open: boolean;
  title: string;
  formError: string;
  initialState?: EditableWorkflowTask;
  onSave: (task: EditableWorkflowTask) => void;
  onClose: () => void;
  loading: boolean;
};

const StyledToggle = styled(Toggle)`
  margin-top: 18px;
`;

const BaseWorkflowTaskModal: React.FC<BaseWorkflowTaskModalProps> = ({
  open,
  title,
  formError,
  initialState = {} as EditableWorkflowTask,
  onSave,
  onClose,
  loading,
}) => {
  const {
    workflow_task_name,
    workflow_task_description,
    workflow_task_dynamic_due_from,
    workflow_task_dynamic_lock,
    workflowTrigger,
    task_is_priority,
  } = initialState;

  const [taskDateLocked, setTaskDateLocked] = useState(
    !!workflow_task_dynamic_lock,
  );
  const [taskPriority, setTaskPriority] = useState(task_is_priority || false);
  const [taskDynamicPeriod, setDynamicPeriod] = useState<
    'before' | 'after' | undefined
  >(Number(workflow_task_dynamic_due_from) > 0 ? 'after' : 'before');

  const {
    state: taskName,
    setValue: setTaskName,
    setError: setTaskNameError,
    setErrorText: setTaskNameErrorText,
    resetState: resetTaskNameState,
  } = useFormFieldState(workflow_task_name || undefined);

  const {
    state: taskDescription,
    setValue: setTaskDescription,
    setError: setTaskDescriptionError,
    setErrorText: setTaskDescriptionErrorText,
    resetState: resetTaskDescriptionState,
  } = useFormFieldState(workflow_task_description || undefined);

  const {
    state: taskDynamicDays,
    setValue: setTaskDynamicDays,
    setError: setTaskDynamicDaysError,
    setErrorText: setTaskDynamicDaysErrorText,
    resetState: resetTaskDynamicDaysState,
  } = useFormFieldState(
    workflow_task_dynamic_due_from
      ? String(Math.abs(workflow_task_dynamic_due_from))
      : undefined,
  );

  const {
    state: workflowId,
    setValue: setWorkflowId,
    setError: setWorkflowIdError,
    // field isn't required. not setting error currently.
    // setErrorText: setWorkflowIdErrorText,
    // resetState: resetWorkflowIdState,
  } = useFormFieldState(workflowTrigger?.workflow_id);

  const isInteger = (value: string): boolean => {
    return (
      !!value.length &&
      Number(value) === parseInt(value, 10) &&
      Number(value) >= 0
    );
  };

  const convertDynamicDays = (): number => {
    const days = taskDynamicDays.value;
    if (isInteger(days)) {
      const intDays = Number(days);
      return taskDynamicPeriod === 'after' ? intDays : -intDays;
    }
    return 0;
  };

  const isValidTask = (): boolean => {
    return !!taskName.value.length && !!taskDescription.value.length;
  };

  const setErrors = (): void => {
    if (!taskName.value.length) {
      setTaskNameError(true);
      setTaskNameErrorText('Field is required');
    }
    if (!taskDescription.value.length) {
      setTaskDescriptionError(true);
      setTaskDescriptionErrorText('Field is required');
    }
    if (!isInteger(taskDynamicDays.value)) {
      setTaskDynamicDaysError(true);
      setTaskDynamicDaysErrorText('Must be a positive integer or zero');
    }
  };

  const onSaveClick = (): void => {
    if (isValidTask()) {
      onSave({
        workflow_task_name: taskName.value,
        workflow_task_description: taskDescription.value,
        workflow_task_dynamic_due_from: convertDynamicDays(),
        workflow_task_dynamic_lock: taskDateLocked,
        trigger_workflow_id: workflowId.value || null,
        task_is_priority: taskPriority,
      });
    } else {
      setErrors();
    }
  };

  const resetState = (): void => {
    resetTaskNameState();
    resetTaskDescriptionState();
    resetTaskDynamicDaysState();
    setDynamicPeriod(undefined);
  };

  useEffect(() => {
    // reset state every time the modal closes
    if (!open) resetState();
  }, [open]);

  return (
    <Modal
      open={open}
      title={title}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryLoading={loading}
      includeCancelConfirmation
      onPrimaryClick={onSaveClick}
      onSecondaryClick={onClose}
      onClose={onClose}
    >
      <>
        <FormGrid>
          <Input
            label="Task name"
            value={taskName.value}
            onChange={(e): void => {
              const name = e.target.value;
              setTaskName(name);
              setTaskNameError(false);
            }}
            error={taskName.error}
            errorText={taskName.errorText}
          />
          <div />

          <DynamicDaysField
            daysValue={taskDynamicDays.value}
            defaultPeriod={taskDynamicPeriod}
            error={taskDynamicDays.error}
            errorText={taskDynamicDays.errorText}
            onDaysChange={(days): void => {
              setTaskDynamicDays(days);
              setTaskDynamicDaysError(false);
            }}
            onPeriodChange={(period): void => {
              setDynamicPeriod(period);
            }}
          />

          <StyledToggle
            label={
              (
                <Tooltip title="When selected, date will not change when an associated event date is changed">
                  <span>Lock Date</span>
                </Tooltip>
              ) as any
            }
            onToggle={(isToggled): void => setTaskDateLocked(isToggled)}
            initialState={workflow_task_dynamic_lock}
          />

          <WorkflowSelect
            label="Workflow Trigger"
            initialValue={workflowTrigger?.workflow_name || undefined}
            onWorkflowSelect={(workflow?: Workflow): void => {
              setWorkflowId(workflow ? String(workflow.workflow_id) : '');
              setWorkflowIdError(false);
            }}
            error={workflowId.error}
            errorText={workflowId.errorText}
          />
          <StyledToggle
            label="High Priority Task"
            onToggle={(isToggled): void => setTaskPriority(isToggled)}
            initialState={initialState.task_is_priority}
          />
        </FormGrid>
        <ErrorNotification text={formError} show={!!formError.length} />
        <Editor
          initialValue={taskDescription?.value}
          apiKey="9h9g57gwpv98kda2tlz6pltsy0ae1muqb5dvgez27rvy2gsa"
          init={{
            height: 500,
            menubar: true,
            plugins: [
              'advlist autolink autoresize lists link image charmap print preview anchor',
              'searchreplace visualblocks code',
              'insertdatetime media table paste code wordcount',
            ],
            toolbar: [
              'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify |',
              'bullist numlist outdent indent | code | help',
            ],
          }}
          onChange={(e): void => {
            const type = e.target;
            setTaskDescription(type.getContent());
            setTaskDescriptionError(false);
          }}
        />
      </>
    </Modal>
  );
};

export { BaseWorkflowTaskModal };
