import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DatePicker } from '../../../../../components/FormComponents/DatePicker';
import { useCreateInvoice } from '../../../../../hooks';
import { StarksLawGrid } from '../../../../../utils';

export const AddInvoiceForm = ({ open, handleClose, client, matter }): any => {
  const [amount, setAmount] = useState(0);
  const [checked, setChecked] = useState(false);
  const [description, setDescription] = useState('');
  const [selectedDate, setSelectedDate] = useState('');

  const { onCreateInvoice, data, loading } = useCreateInvoice();
  useEffect(() => {
    if (data && !loading) {
      console.log(data, 'data retrieved');
    }
  }, [data, loading]);

  const handleSubmitInvoice = (): void => {
    onCreateInvoice(
      amount * 100,
      client.client_id,
      selectedDate,
      description,
      checked,
      matter.matter_id,
    );
    handleClose();
  };
  const handleAmountChange = (e): void => {
    setAmount(e.target.value);
  };
  const handleInitPaymentCheck = (e): void => {
    setChecked(!checked);
  };
  const handleDescriptionChange = (e): void => {
    setDescription(e.target.value);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Invoice</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Please be sure .
          </DialogContentText> */}
          <StarksLawGrid>
            <TextField
              autoFocus
              margin="dense"
              id="amount"
              label="Invoice Amount"
              type="text"
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              variant="outlined"
              onChange={handleAmountChange}
              size="small"
            />

            <TextField
              onChange={handleDescriptionChange}
              id="description"
              type="text"
              variant="outlined"
              margin="dense"
              label="Description"
              size="small"
            />
            <FormControlLabel
              // sx={{ mt: 1 }}
              control={
                <Switch
                  checked={checked}
                  onChange={handleInitPaymentCheck}
                  size="small"
                />
              }
              label="Initial Payment"
            />
          </StarksLawGrid>
          <DatePicker
            variant="dateOnly"
            label="Due Date"
            value={selectedDate}
            onChange={(date): void => {
              setSelectedDate(date?.toISOString().split('T', 1)[0] || '');
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitInvoice}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
