import {
  FormControlLabel,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { Add, NoteAdd } from '@material-ui/icons';
import React from 'react';
import styled, { css } from 'styled-components';
import { useColors } from '../../../hooks';

const { themeColors } = useColors();

const StyledOptionsWrapper = styled.span`
  display: flex;
  flex-direction: row;
  float: left;
  padding: 2px 0;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const StyledItem = styled.div`
  margin: 0 10px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 6px;
  margin-left: 8px;
`;

const baseIconStyles = css`
  color: ${themeColors.mainColors.primaryColor};
  height: 30px;
  width: 30px;
`;

const StyledAddIcon = styled(Add)`
  ${baseIconStyles}
`;

const StyledAddWorkflowIcon = styled(NoteAdd)`
  ${baseIconStyles}
`;

type Filters = {
  completed: boolean;
  overdue: boolean;
  inProgress: boolean;
};

type TableOptionsProps = {
  onAddTask: () => void;
  onAddWorkflow: () => void;
  filterSwitch: Filters;
  handleFilterSwitch: (text: string) => void;
  disableAddWorkflow: boolean;
  showCompletedSwitch?: boolean;
};

const TableOptions: React.FC<TableOptionsProps> = ({
  onAddTask,
  onAddWorkflow,
  filterSwitch,
  handleFilterSwitch,
  disableAddWorkflow,
  showCompletedSwitch = true,
}) => {
  return (
    <StyledOptionsWrapper>
      <StyledItem>
        <Tooltip title="Add Task" placement="bottom-start">
          <StyledIconButton onClick={onAddTask}>
            <StyledAddIcon />
          </StyledIconButton>
        </Tooltip>
        {!disableAddWorkflow && (
          <Tooltip title="Add Workflow" placement="bottom-start">
            <StyledIconButton onClick={onAddWorkflow}>
              <StyledAddWorkflowIcon />
            </StyledIconButton>
          </Tooltip>
        )}
      </StyledItem>
      <StyledItem>
        {showCompletedSwitch && (
          <FormControlLabel
            control={
              <Switch
                checked={filterSwitch.completed}
                onChange={(): void => handleFilterSwitch('completed')}
                name="Show Completed"
                color="primary"
                size="small"
              />
            }
            label="Completed"
          />
        )}
        <FormControlLabel
          control={
            <Switch
              checked={filterSwitch.overdue}
              onChange={(): void => handleFilterSwitch('overdue')}
              name="Show Overdue"
              color="primary"
              size="small"
            />
          }
          label="Overdue"
        />
        <FormControlLabel
          control={
            <Switch
              checked={filterSwitch.inProgress}
              onChange={(): void => handleFilterSwitch('inProgress')}
              name="Show Pending"
              color="primary"
              size="small"
            />
          }
          label="Pending"
        />
      </StyledItem>
    </StyledOptionsWrapper>
  );
};

export { TableOptions };
