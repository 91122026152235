import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, DefaultMap } from '../..';
import { CalendarInvite, MatterEvent } from '../../../utils';
import { boxShadows } from '../../../constants';

type EventModalProps = {
  open: boolean;
  event: MatterEvent | null;
  showEditButton?: boolean;
  onEditClick?: (e: React.MouseEvent) => void;
  onClose: () => void;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Grid = styled.div`
  margin: 0 auto 15px;
  width: 95%;
  display: grid;
  grid-template-columns: 50% 50%;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 8px 0;
`;

const StyledLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding-right: 8px;
`;

const StyledValue = styled.span`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
`;

const StyledMap = styled.div`
  display: flex;
  align-items: center;
  box-shadow: ${boxShadows.two};
  border-radius: 10px;
  margin: 15px auto;
  overflow: hidden;
`;
const StyledButton = styled(Button as any)`
  display: flex;
  align-items: center;
  width: 45%;
  min-width: 200px;
  margin: 0 auto;
`;

type TextPairProps = {
  label: string;
  value: string;
};

const TextPair: React.FC<TextPairProps> = ({ label, value }) => (
  <StyledRow>
    <StyledLabel>{label}</StyledLabel>
    <StyledValue>{value}</StyledValue>
  </StyledRow>
);

const ViewEventModal: React.FC<EventModalProps> = ({
  open,
  event,
  showEditButton = true,
  onEditClick,
  onClose,
}) => {
  const [buildDirections, setBuildDirections] = useState(false);
  const [pendingInvite, setPendingInvite] = useState<boolean>(false);

  const sendInvite = async (): Promise<void> => {
    setPendingInvite(true);
    await CalendarInvite(event);
    setPendingInvite(false);
  };

  const getDirections = (): void => {
    setBuildDirections(true);
  };
  const closeModal = (): void => {
    setBuildDirections(false);
    onClose();
  };
  return (
    <Modal
      open={open}
      title="View Event"
      primaryButtonText={showEditButton ? 'Edit' : undefined}
      secondaryButtonText="Close"
      onPrimaryClick={onEditClick}
      onSecondaryClick={closeModal}
      onClose={closeModal}
    >
      <>
        {event ? (
          <StyledContainer>
            <Grid>
              <TextPair label="Event Name:" value={event.event_name} />
              <TextPair label="Event Type:" value={event.event_type || ''} />
              <TextPair
                label="Event Location:"
                value={event.event_location || ''}
              />
              <TextPair
                label="Event County:"
                value={event.event_county || ''}
              />
              <TextPair
                label="Event Manager:"
                value={(event.attorney && event.attorney.attorney_name) || ''}
              />
              <TextPair
                label="Status:"
                value={event.event_complete ? 'Completed' : 'Incomplete'}
              />
              {event?.matter?.court?.court_phone && (
                <>
                  <TextPair
                    label="Court Phone:"
                    value={event?.matter?.court?.court_phone}
                  />
                </>
              )}
            </Grid>
            <Grid>
              <StyledButton
                href={`https://admin.starks.law/cases/${event?.event_matter_id}`}
                variant="outlined"
                color="primary"
                // target="_blank"
              >
                Open Case
              </StyledButton>
              <StyledButton
                onClick={(): Promise<any> => sendInvite()}
                variant="outlined"
                color="primary"
                disabled={pendingInvite}
              >
                Send Calendar Invite
              </StyledButton>
            </Grid>
            <StyledMap>
              <DefaultMap
                eventLocation={event.event_location || 'Pittsburgh, PA'}
                buildDirections={buildDirections}
              />
            </StyledMap>

            <StyledButton
              onClick={getDirections}
              variant="outlined"
              color="primary"
            >
              Get Directions
            </StyledButton>
          </StyledContainer>
        ) : (
          <span>No event provided</span>
        )}
      </>
    </Modal>
  );
};

export { ViewEventModal };
