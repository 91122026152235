/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Chip,
  Collapse,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { boxShadows } from '../../../constants';
import { useColors } from '../../../hooks';
import { FoldChevron, UnFoldChevron } from '../../stories/SLICONS.stories';

const matterStageText: React.FC = (matterStage: any) => {
  let text;
  if (matterStage?.toLowerCase() === 'pleadings') {
    text = 'Pleadings';
  } else if (matterStage?.toLowerCase() === 'initial pleadings') {
    text = 'Initial Pleadings';
  } else if (matterStage?.toLowerCase() === 'pre-litigation') {
    text = 'Pre-Litigation';
  } else if (matterStage?.toLowerCase() === 'litigation') {
    text = 'litigation';
  } else if (matterStage?.toLowerCase() === 'post-litigation') {
    text = 'post-Litigation';
  } else if (matterStage?.toLowerCase() === 'concluded/closed-won') {
    text = 'Concluded/Closed';
  } else if (matterStage?.toLowerCase() === 'concluded/closed-lost') {
    text = 'Concluded/Closed';
  } else if (matterStage?.toLowerCase() === 'concluded/closed-withdrawn') {
    text = 'Concluded/Closed';
  } else {
    text = 'Conflict Checking';
  }
  return text;
};

const CardBackground = styled.div<{ cardBackground: string }>`
  background-color: ${({ cardBackground }): string => cardBackground};
`;

const StyledHorizontalLine = styled.div<{ lineColor: string }>`
  width: 100%;
  background-color: ${({ lineColor }): string => lineColor};
  height: 1px;
`;

const StyledVerticalLine = styled.div<{ lineColor: string }>`
  border-left: 1px solid ${({ lineColor }): string => lineColor};
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-items: center;
  justify-content: center;
  /* width: 17%; */
`;

const StyledChip = styled(Chip)<{ chipColor }>`
  color: ${({ chipColor }): string => chipColor};
  border: 1px solid ${({ chipColor }): string => chipColor};
  margin: 10px auto;
`;

const StyledContainer = styled(Paper)<{ boxShadow: any }>`
  width: 95vw;
  max-width: 600px;
  margin: 15px 0;
  border-radius: 2px;
  box-shadow: ${({ boxShadow }): string => boxShadow.one};
`;
const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 80px;
`;
const StyledTypography = styled(Typography)`
  text-align: center;
  margin: auto;
`;

export const CaseCard: React.FC<any> = ({
  matter_id,
  matter_name,
  matter_opened_date,
  matter_closed,
  matter_details,
  owning_attorney,
  stage,
}) => {
  const { themeColors } = useColors();
  const [expanded, setExpanded] = useState<any>(false);
  const handleExpandClick = (key: string) => {
    setExpanded({ [key]: !expanded[key] });
  };
  const chipColor = matter_closed
    ? themeColors.mainColors.danger
    : themeColors.mainColors.success;

  return (
    <StyledContainer boxShadow={boxShadows}>
      <StyledRow>
        <StyledTypography variant="h6">{matter_name}</StyledTypography>
        <div>
          <StyledVerticalLine
            lineColor={themeColors.componentColors.dividerColorDark}
          >
            <StyledChip
              label={matter_closed ? 'Closed' : 'Open'}
              variant="outlined"
              chipColor={chipColor}
            />
          </StyledVerticalLine>
        </div>
      </StyledRow>

      <StyledHorizontalLine
        lineColor={themeColors.componentColors.dividerColorDark}
      />
      <div style={{ width: '100%', textAlign: 'center' }}>
        <CardBackground cardBackground={themeColors.mainColors.background}>
          <Collapse
            in={expanded[matter_id]}
            timeout="auto"
            style={{ width: '95%', margin: 'auto', textAlign: 'left' }}
            unmountOnExit
          >
            <div
              style={{
                marginTop: 15,
                marginBottom: 15,
                textAlign: 'left',
              }}
            >
              <div>
                <Tooltip
                  title={
                    <>
                      <Typography variant="body2">
                        This is the stage your case is at in the overall
                        workflow. There are 6 separate stages.
                      </Typography>
                      <Typography variant="caption">
                        <ul>
                          <li>--Conflict Checking</li>
                          <span>
                            This stage consist of checking to see if there
                            exists any conflicts of interest that would prevent
                            us from representing you. This stage typically
                            clears in 1-3 business days.
                          </span>
                          <br /> <br />
                          <li>--Initial Pleadings</li>
                          <span>
                            During this stage, any applicable documents are
                            submitted to the court. This typically includes, at
                            minimum, an Entry of Appearance notifying the court
                            of our representation of you. This stage typically
                            clears in 1-3 business days.
                          </span>
                          <br /> <br />
                          <li>--Pre-Litigation</li>
                          <span>
                            Depending on the case type, this stage can be
                            somewhat lengthy with long periods of waiting. This
                            stage includes discovery, if applicable, or simply
                            waiting for the court to schedule a hearing/trial.
                            This stage typically clears between 1 week and 3
                            months.
                          </span>
                          <br /> <br />
                          <li>--Litigation</li>
                          <span>
                            This stage includes the time period after all
                            pre-litigation tasks have been completed up until
                            the time of the actual trial or hearing. This stage
                            typically clears between 1 week to 1 month.
                          </span>
                          <br /> <br />
                          <li>--Post-Litigation</li>
                          <span>
                            Post litigation includes the time period after the
                            date of disposition to the end of the appeal window.
                            This stage typically clears in 30 days.
                          </span>
                          <br /> <br />
                          <li>--Concluded/Closed</li>
                          <span>
                            The final stage. Cases marked as Concluded/Closed
                            have reached their final result. Cases in this stage
                            are no longer active. This stage is permanent.
                          </span>
                        </ul>
                      </Typography>
                    </>
                  }
                  aria-label="add"
                >
                  <Typography variant="body1" style={{ display: 'inline' }}>
                    Stage:{' '}
                    <StyledChip
                      label={matterStageText(stage?.stage_name)}
                      size="small"
                      variant="outlined"
                      chipColor={chipColor}
                    />
                  </Typography>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      <div>This is the date your case was opened</div>
                      <br />
                      <div>
                        This date does not necessarily reflect the date you
                        first approached the firm about your legal matter. This
                        date is the date in which the matter was officially
                        opened
                      </div>
                    </Typography>
                  }
                >
                  <Typography variant="body1" style={{ display: 'inline' }}>
                    Opened:{' '}
                    <StyledChip
                      label={moment(matter_opened_date).format('MMMM Do YYYY')}
                      size="small"
                      variant="outlined"
                      chipColor={chipColor}
                    />
                  </Typography>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      <div>This is the attorney handling your case.</div>
                      <br />
                      <div>
                        This attorney will be the most knowledgeable attorney
                        about your specific legal matter. This attorney,
                        combined with the support staff assisting the attorney,
                        will be the best source of answers to any questions you
                        may have.
                      </div>
                    </Typography>
                  }
                >
                  <Typography variant="body1">
                    Case Manager:{' '}
                    {`${owning_attorney?.attorney_name}, Esq.` ||
                      'No details added at this time'}
                  </Typography>
                </Tooltip>
              </div>
              <div>
                <Tooltip
                  title={
                    <Typography variant="caption">
                      <div>This contains the description of your case.</div>
                      <br />
                      <div>
                        This description is simply the notes added to the case
                        during your consultation. This description is not
                        comprehensive and does not include any additional
                        information gained after your initial consultation.
                      </div>
                    </Typography>
                  }
                >
                  <Typography variant="body1">
                    Case Details:{' '}
                    {(matter_details && matter_details) ||
                      'No details added at this time'}
                  </Typography>
                </Tooltip>
              </div>
            </div>
          </Collapse>
        </CardBackground>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => handleExpandClick(matter_id)}
        >
          <IconButton
            style={{ margin: '1px calc(((100vh + 100vw) / 2) * .06)' }}
          >
            {!expanded[matter_id] ? (
              <UnFoldChevron width={24} />
            ) : (
              <FoldChevron width={24} />
            )}
          </IconButton>
        </div>
      </div>
    </StyledContainer>
  );
};
