import { gql } from '@apollo/client';

export const GetJudges = gql`
  query GetJudges {
    judge {
      judge_id
      judge_first_name
      judge_last_name
      judge_middle_name
      judge_undesired
      judge_notes
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;

export const GetJudgesSub = gql`
  subscription GetJudges {
    judge {
      judge_id
      judge_first_name
      judge_last_name
      judge_middle_name
      judge_undesired
      judge_notes
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;
