/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { Modal } from '../..';
import { Matter, MatterTask } from '../../../utils';

type TaskModalProps = {
  open: boolean;
  task: MatterTask | null;
  matter?: Matter;
  individual?: boolean;
  showEditButton?: boolean;
  onEditClick?: (e: React.MouseEvent) => void;
  onClose: () => void;
};

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin: 8px 0;
`;

const StyledLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  padding-right: 8px;
`;

const StyledValue = styled.span`
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
`;

type TextPairProps = {
  label: string;
  value: string;
};

const TextPair: React.FC<TextPairProps> = ({ label, value }) => (
  <StyledRow>
    <StyledLabel>{label}</StyledLabel>
    <StyledValue>{value}</StyledValue>
  </StyledRow>
);

const TaskDescription = t => {
  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: t.task_description || '',
        }}
      />
    </>
  );
};

const StyledDescription = styled.div`
  margin: 30px auto;
  width: 100%;
  overflow: hidden;
`;

const ViewTaskModal: React.FC<TaskModalProps> = ({
  open,
  task,
  showEditButton = true,
  onEditClick,
  onClose,
  matter,
  individual,
}) => {
  return (
    <Modal
      open={open}
      title="View Task"
      primaryButtonText={showEditButton ? 'Edit' : undefined}
      secondaryButtonText="Close"
      onPrimaryClick={onEditClick}
      onSecondaryClick={onClose}
      onClose={onClose}
    >
      <>
        {task ? (
          <StyledContainer>
            <TextPair label="Task Name:" value={task.task_name} />
            <TextPair
              label="Task Due:"
              value={
                task.task_due
                  ? moment(task.task_due).format('ddd, MMM DD YYYY')
                  : ''
              }
            />
            <TextPair
              label="Task Assigned To:"
              value={
                task.attorney?.attorney_name
                  ? `${task.attorney.attorney_name}, Esq.`
                  : ''
              }
            />
            <TextPair
              label="Status:"
              value={task.task_completed ? 'Completed' : 'Incomplete'}
            />

            <TextPair
              label="Matter:"
              value={
                matter?.matter_name ||
                task.matter?.matter_name ||
                'No matter assigned to this task'
              }
            />

            <TextPair
              label="Matter Owner:"
              value={
                matter?.owning_attorney?.attorney_name ||
                task.matter?.owning_attorney?.attorney_name ||
                'No attorney has been assigned to this case or the task is not assigned to a case'
              }
            />
            {!individual && (
              <TextPair
                label="Linked Event:"
                value={task.event?.event_name || matter?.events[0].event_name}
              />
            )}
            <StyledDescription>
              {/* <TextField
                id="Task-Description"
                label="Description"
                fullWidth
                multiline
                // rows={4}

                InputProps={{ value: TaskDescription(task) }}
                variant="outlined"
                style={{ margin: '15px auto' }}
              /> */}
              {TaskDescription(task)}
            </StyledDescription>
            {task?.matter?.matter_id && (
              <Button
                href={`/cases/${task?.matter?.matter_id || ''}`}
                // target="_blank"
                variant="outlined"
                color="primary"
              >
                Go To Matter
              </Button>
            )}
          </StyledContainer>
        ) : (
          <span>No task provided</span>
        )}
      </>
    </Modal>
  );
};

export { ViewTaskModal };
