import { FormControlLabel, Switch } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const StyledOptionsWrapper = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
`;
const StyledItem = styled.div`
  margin: 0 10px;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
`;

type Filters = {
  closed?: boolean;
  dense?: boolean;
  my_cases?: boolean;
};

type TableOptionsProps = {
  filterSwitch: Filters;
  handleFilterSwitch: (text: string) => void;
};

export const BaseTableOptions: React.FC<TableOptionsProps> = ({
  filterSwitch,
  handleFilterSwitch,
}) => {
  return (
    <StyledOptionsWrapper>
      <StyledItem>
        <FormControlLabel
          control={
            <Switch
              checked={filterSwitch.dense}
              onChange={(): void => handleFilterSwitch('dense')}
              name="Closed"
              color="primary"
              size="small"
            />
          }
          label="Dense"
        />
      </StyledItem>
      <StyledItem>
        <FormControlLabel
          control={
            <Switch
              checked={filterSwitch.closed}
              onChange={(): void => handleFilterSwitch('closed')}
              name="Closed"
              color="primary"
              size="small"
            />
          }
          label="Closed"
        />
      </StyledItem>
      <StyledItem>
        <FormControlLabel
          control={
            <Switch
              checked={filterSwitch.my_cases}
              onChange={(): void => handleFilterSwitch('my_cases')}
              name="My Cases"
              color="primary"
              size="small"
            />
          }
          label="My Cases"
        />
      </StyledItem>
    </StyledOptionsWrapper>
  );
};
