import React from 'react';
import styled from 'styled-components';
import { Grow, GrowProps, IconButton, Typography } from '@material-ui/core';
import { CheckCircleOutline, CancelOutlined } from '@material-ui/icons';
import { PulseLoader as Loader } from 'react-spinners';
import { boxShadows } from '../../constants';
import { useColors } from '../../hooks';

interface PopperProps extends GrowProps {
  message: string;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  width?: number;
  onCancelClick: (e: React.MouseEvent) => void;
  onConfirmClick: (e: React.MouseEvent) => void;
  showLoader?: boolean;
}

const StyledGrow = styled(Grow)<
  Pick<PopperProps, 'top' | 'bottom' | 'left' | 'right' | 'width'>
>`
  position: absolute;
  ${({ top }): string => (top ? `top: ${top}px` : '')};
  ${({ bottom }): string => (bottom ? `bottom: ${bottom}px` : '')};
  ${({ right }): string => (right ? `right: ${right}px` : '')};
  ${({ left }): string => (left ? `left: ${left}px` : '')};
  ${({ width }): string => (width ? `width: ${width}px` : '')};
`;

const StyledContainer = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 6px 4px 12px;
  margin: auto;
  background: ${({ bgColor }) => bgColor};
  border-radius: 4px;
  box-shadow: ${boxShadows.two};
  z-index: 2;
`;

const StyledText = styled(Typography)`
  font-weight: bold;
`;

const StyledIconButton = styled(IconButton)`
  padding: 6px;
`;

const iconStyles = `
  width: 20px;
  height: 20px;
`;

const StyledConfirmIcon = styled(CheckCircleOutline)`
  ${iconStyles}
  color: green;
`;

const StyledCancelIcon = styled(CancelOutlined)`
  ${iconStyles}
  color: red;
`;

const ConfirmationPopper: React.FC<PopperProps> = ({
  message,
  onCancelClick,
  onConfirmClick,
  showLoader,
  ...props
}) => {
  const { themeColors } = useColors();
  return (
    <StyledGrow {...props}>
      <StyledContainer bgColor={themeColors.mainColors.cardBackground}>
        <StyledText variant="body2">{message}</StyledText>
        {showLoader ? (
          <Loader color={themeColors.mainColors.secondaryColor} size={8} />
        ) : (
          <span>
            <StyledIconButton onClick={onConfirmClick}>
              <StyledConfirmIcon />
            </StyledIconButton>
            <StyledIconButton onClick={onCancelClick}>
              <StyledCancelIcon />
            </StyledIconButton>
          </span>
        )}
      </StyledContainer>
    </StyledGrow>
  );
};

export { ConfirmationPopper };
