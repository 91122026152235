import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useMutation } from '@apollo/client';
import {
  FormControlLabel,
  TextField,
  Typography,
  Button,
  StepLabel,
  Step,
  Stepper,
  makeStyles,
  Grid,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import ADD_CLIENT from '../../../components/mutations/clients/addClient';
import OP_PARTY from '../../../components/mutations/opParty/opParty';
import { OP_FIRM, ADD_COURT, NEW_CASE } from '../../../components/mutations';

const courtType = [
  { type: 'Common Pleas' },
  { type: 'Magistrate' },
  { type: 'Municipal' },
];
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Party & Connections', 'Case Details', 'Review'];
}
export const CreateCaseStepper = ({
  clientList,
  courtList,
  firmList,
  matterTypeList,
  userID,
  setFirm,
  setCourt,
  setClient,
  client,
  court,
  firm,
  opPartyList,
  setOpParty,
  opParty,
  matter,
  setMatter,
}) => {
  const classes = useStyles();
  const steps = getSteps();

  const [addMatter] = useMutation(NEW_CASE, {
    variables: {
      attorney_owner_id: userID,
      matter_client_id: matter.matterClientID,
      matter_type_id: matter.matterTypeId,
      matter_court_case_number: matter.matterCourtCaseNumber,
      matter_court_id: matter.matterCourtID,
      matter_name: matter.matterName,
      op_firm_id: matter.opFirmID,
      op_party_id: matter.opPartyID,
      matter_details: matter.matterDetails,
    },
  });

  const [addClient] = useMutation(ADD_CLIENT, {
    update: (proxy, mutationResult) => {
      setClient({
        ...client,
        clientID: mutationResult.data.insert_client.returning[0].client_id,
      });
    },
    variables: {
      firstName: client.firstName,
      lastName: client.lastName,
      middleName: client.middleName,
      cell: client.cellPhone,
      email: client.email,
      street: client.streetAddress,
      city: client.city,
      state: client.state,
      zip: client.zip,
      zip4: client.zip4,
      ssn: client.ssn,
    },
  });
  const [addParty] = useMutation(OP_PARTY, {
    update: (proxy, mutationResult) => {
      setOpParty({
        ...opParty,
        partyID: mutationResult.data.insert_op_party.returning[0].op_party_id,
      });
    },
    variables: {
      op_party_address: opParty.streetAddress,
      op_party_address2: opParty.streetAddress2,
      op_party_address_city: opParty.city,
      op_party_address_state: opParty.state,
      op_party_address_zip: opParty.zip,
      op_party_address_zip_4: opParty.zip_4,
      op_party_email: opParty.email,
      op_party_fax: opParty.fax,
      op_party_name: opParty.name,
      op_party_phone: opParty.phone,
    },
  });

  const [addFirm] = useMutation(OP_FIRM, {
    update: (proxy, mutationResult) => {
      setFirm({
        ...firm,
        firmID: mutationResult.data.insert_op_firm.returning[0].op_firm_id,
      });
    },
    variables: {
      op_firm_address: firm.streetAddress,
      op_firm_address2: firm.streetAddress2,
      op_firm_address_city: firm.city,
      op_firm_address_state: firm.state,
      op_firm_address_zip: firm.zip,
      op_firm_address_zip_4: firm.zip_4,
      op_firm_email: firm.email,
      op_firm_fax: firm.fax,
      op_firm_name: firm.name,
      op_firm_phone: firm.phone,
    },
  });

  const [addCourt] = useMutation(ADD_COURT, {
    update: (proxy, mutationResult) => {
      setCourt({
        ...court,
        courtID: mutationResult.data.insert_court.returning[0].court_id,
      });
    },
    variables: {
      court_address_city: court.city,
      court_address_state: court.state,
      court_address_street: court.streetAddress,
      court_address_street2: court.streetAddress2,
      court_address_zip: court.zip,
      court_county: court.county,
      court_efile_link: court.efile,
      court_fax: court.fax,
      court_mdj_number: court.mdjNumber,
      court_name: court.courtName,
      court_phone: court.phone,
      court_state: court.state,
      court_type: court.type,
    },
  });
  const mergeItems = () => {
    setMatter({
      ...matter,
      opPartyID: opParty.partyID,
      opFirmID: firm.firmID,
      matterCourtID: court.courtID,
      attorneyOwnerID: userID,
      matterClientID: client.clientID,
    });
  };
  const [validation, setValidation] = useState({
    selectClient: false,
    // selectParty: false,
    // selectFirm: false,
    // selectCourt: false,
    any: false,
  });
  const [newClient, setNewClient] = useState({
    checked: false,
  });
  const handleChange = name => event => {
    setNewClient({ ...newClient, [name]: event.target.checked });
    if (event.target.checked === true) {
      setClient('');
    }
  };
  const [newCourt, setNewCourt] = useState({
    checked: false,
  });
  const handleChangeNewCourt = name => event => {
    setNewCourt({ ...newCourt, [name]: event.target.checked });
    if (event.target.checked === true) {
      setCourt('');
    }
  };
  const [newFirm, setNewFirm] = useState({
    checked: false,
  });
  const handleChangeNewFirm = name => event => {
    setNewFirm({ ...newFirm, [name]: event.target.checked });
    if (event.target.checked === true) {
      setFirm('');
    }
  };
  const [newOpParty, setNewOpParty] = React.useState({
    checked: false,
  });
  const handleChangeNewParty = name => event => {
    setNewOpParty({ ...newOpParty, [name]: event.target.checked });
    if (event.target.checked === true) {
      setOpParty('');
    }
  };
  // pagination
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    if (validation.any === false) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      if (activeStep === 1) {
        mergeItems();
      }
      if (activeStep === 2) {
        addMatter();
      }
    }
    if (newClient.checked && activeStep === 0) {
      addClient();
    }
    if (newOpParty.checked && activeStep === 0) {
      addParty();
    }
    if (newFirm.checked && activeStep === 0) {
      addFirm();
    }
    if (newCourt.checked && activeStep === 0) {
      addCourt();
    }
  };
  const handleNextValidate = () => {
    if (!newClient.checked && !client.clientID) {
      setValidation({ ...validation, selectClient: true, any: true });
      // handleAlert('you must select a client or add one');
    }
    if (!newCourt.checked && !court.courtID) {
      setValidation({ ...validation, selectCourt: true, any: true });
    } else {
      handleNext();
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  // newClient Function
  const handleCreateClient = name => e => {
    setClient({ ...client, [name]: e.target.value });
  };
  // Select Existing Client.  If none, user will add all fields in next section/screen
  const handleClientSelect = name => (_event, value) => {
    if (!value) {
      setClient('');
    } else if (value.client_id.length) {
      setClient({ ...client, [name]: value.client_id });
    }
    setValidation({ ...validation, selectClient: false, any: false });
  };

  // newOpParty Function
  const handleCreateOpParty = name => e => {
    setOpParty({ ...opParty, [name]: e.target.value });
  };
  // Select Existing OpParty.  If none, user will add all fields in next section/screen
  const handleOpPartySelect = name => (_event, value) => {
    if (!value) {
      setOpParty('');
    } else if (value.op_party_id.length) {
      setOpParty({ ...opParty, [name]: value.op_party_id });
      setValidation({ ...validation, selectParty: false, any: false });
    }
  };

  // NewCourt Function
  const handleCreateCourt = name => e => {
    setCourt({ ...court, [name]: e.target.value });
  };
  // Select Existing Court.  If none, user will add all fields in next section/screen
  const handleCourtSelect = name => (_event, value) => {
    if (!value) {
      setCourt('');
    } else if (value.court_id.length) {
      setCourt({ ...court, [name]: value.court_id });
      setValidation({ ...validation, selectCourt: false, any: false });
    }
  };
  const handleCourtType = name => (_event, value) => {
    setCourt({ ...court, [name]: value.type });
  };

  // NewFirm Function
  const handleCreateFirm = name => e => {
    setFirm({ ...firm, [name]: e.target.value });
  };
  // Select Existing Firm.  If none, user will add all fields in next section/screen
  const handleFirmSelect = name => (_event, value) => {
    if (!value) {
      setFirm('');
    } else if (value.op_firm_id.length) {
      setFirm({ ...firm, [name]: value.op_firm_id });
      setValidation({ ...validation, selectFirm: false, any: false });
    }
  };

  // Next Screen Case Details
  const handleChangeMatter = name => e => {
    setMatter({ ...matter, [name]: e.target.value });
  };

  const handleMatterTypeSelect = name => (_event, value) => {
    if (!value) {
      setMatter({ ...matter, [name]: null });
    } else if (value) {
      setMatter({ ...matter, [name]: value.matter_type_id });
    }
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <div style={{ margin: 'auto', width: '90%' }}>
            {/* get client */}
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ width: '100%', margin: 'auto' }}
            >
              {!newClient.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4} />
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Autocomplete
                      id="client-id"
                      options={clientList}
                      getOptionLabel={option =>
                        `${option.client_firstName} ${option.client_lastName}`
                      }
                      onChange={handleClientSelect('clientID')}
                      autoHighlight
                      autoSelect
                      disabled={newClient.checked}
                      style={{ width: '100%' }}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select Client"
                          variant="outlined"
                          fullWidth
                          autoHighlight
                          autoSelect
                          error={validation.selectClient}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newClient.checked}
                      onChange={handleChange('checked')}
                      value="checked"
                      color="primary"
                    />
                  }
                  label="New Client"
                />
              </Grid>
              {!newClient.checked ? (
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2} />
              ) : null}
              {newClient.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="client-first"
                      label="First Name"
                      variant="outlined"
                      value={client.firstName}
                      fullWidth
                      onChange={handleCreateClient('firstName')}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
                    <TextField
                      id="client-middle"
                      label="Middle Initial"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('middleName')}
                      value={client.middleName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="client-last"
                      label="Last Name"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('lastName')}
                      value={client.lastName}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="client-phone"
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('cellPhone')}
                      value={client.cellPhone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="client-email"
                      label="E-Mail"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('email')}
                      value={client.email}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="client-dob"
                      label="DOB"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('DOB')}
                      value={client.DOB}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="client-ssn"
                      label="SSN Last 4"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('ssn')}
                      value={client.ssn}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                    <TextField
                      id="client-street"
                      label="Street"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('streetAddress')}
                      value={client.streetAddress}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="client-city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('city')}
                      value={client.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      id="client-state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('state')}
                      value={client.state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <TextField
                      id="client-zip"
                      label="Zip"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('zip')}
                      value={client.zip}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                    <TextField
                      id="client-zip-4"
                      label="+4"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateClient('zip4')}
                      value={client.zip4}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>

            {/* Get Op Party */}
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ width: '100%', margin: 'auto' }}
            >
              {!newOpParty.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4} />
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Autocomplete
                      id="firm-select"
                      options={opPartyList}
                      getOptionLabel={option => option.op_party_name}
                      onChange={handleOpPartySelect('partyID')}
                      autoHighlight
                      // disabled={newClient.checked === true ? true : false}
                      style={{ width: '100%' }}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select Opposing Party"
                          variant="outlined"
                          fullWidth
                          // error={validation.selectParty}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newOpParty.checked}
                      onChange={handleChangeNewParty('checked')}
                      value="checked"
                      color="primary"
                    />
                  }
                  label="New Party"
                />
              </Grid>
              {!newOpParty.checked ? (
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2} />
              ) : null}
              {newOpParty.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                    <TextField
                      id="op-party-name"
                      label="Name of Opposing Party"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('name')}
                      value={opParty.name}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="opParty-phone"
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('phone')}
                      value={opParty.phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="opParty-fax"
                      label="Fax"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('fax')}
                      value={opParty.fax}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="opParty-street"
                      label="Street"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('streetAddress')}
                      value={opParty.streetAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="opParty-street2"
                      label="Suite/Bldg"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('streetAddress2')}
                      value={opParty.streetAddress2}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="Op-city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('city')}
                      value={opParty.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      id="Op-state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('state')}
                      value={opParty.state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <TextField
                      id="Op-zip"
                      label="Zip"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('zip')}
                      value={opParty.zip}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                    <TextField
                      id="Op-zip-4"
                      label="+4"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('zip4')}
                      value={opParty.zip4}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>

            {/* Get Op Party Firm */}
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ width: '100%', margin: 'auto' }}
            >
              {!newFirm.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4} />
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Autocomplete
                      id="firm-select"
                      options={firmList}
                      getOptionLabel={option => option.op_firm_name}
                      onChange={handleFirmSelect('firmID')}
                      autoHighlight
                      // disabled={newClient.checked === true ? true : false}
                      style={{ width: '100%' }}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select Opposing Firm"
                          variant="outlined"
                          fullWidth
                          // error={validation.selectFirm}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newFirm.checked}
                      onChange={handleChangeNewFirm('checked')}
                      value="checked"
                      color="primary"
                    />
                  }
                  label="New Firm"
                />
              </Grid>
              {!newFirm.checked ? (
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2} />
              ) : null}
              {newFirm.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                    <TextField
                      id="newFirm-name"
                      label="Firm Name"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateFirm('name')}
                      value={firm.name}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="firm-phone"
                      label="Phone"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateFirm('phone')}
                      value={firm.phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="firm-fax"
                      label="Fax"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateFirm('fax')}
                      value={firm.fax}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="firm-street"
                      label="Street"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateFirm('streetAddress')}
                      value={firm.streetAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="firm-street2"
                      label="Ste/Bldg/Fl"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateFirm('streetAddress2')}
                      value={firm.streetAddress2}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="Op-city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('city')}
                      value={firm.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      id="Op-state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('state')}
                      value={firm.state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <TextField
                      id="Op-zip"
                      label="Zip"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('cityStateZzipip')}
                      value={firm.zip}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                    <TextField
                      id="Op-zip-4"
                      label="+4"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateOpParty('zip4')}
                      value={firm.zip4}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>

            {/* Get Court */}
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ width: '100%', margin: 'auto' }}
            >
              {!newCourt.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={3} lg={4} xl={4} />
                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Autocomplete
                      id="combo-box-demo"
                      options={courtList}
                      getOptionLabel={option => option.court_name}
                      onChange={handleCourtSelect('courtID')}
                      autoHighlight
                      // disabled={newClient.checked === true ? true : false}
                      style={{ width: '100%' }}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Select Court"
                          variant="outlined"
                          fullWidth
                          error={validation.selectCourt}
                        />
                      )}
                    />
                  </Grid>
                </>
              ) : null}
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newCourt.checked}
                      onChange={handleChangeNewCourt('checked')}
                      value="checked"
                      color="primary"
                    />
                  }
                  label="New Court"
                />
              </Grid>
              {!newCourt.checked ? (
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2} />
              ) : null}
              {newCourt.checked ? (
                <>
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="court-name"
                      label="Court Name"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('courtName')}
                      value={court.courtName}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <Autocomplete
                      id="court-type"
                      options={courtType}
                      getOptionLabel={option => option.type}
                      onChange={handleCourtType('type')}
                      autoHighlight
                      style={{ width: '100%' }}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Court Type"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      id="court-county"
                      label="County"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('county')}
                      value={court.county}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="court-phone"
                      label="Court Phone"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('phone')}
                      value={court.phone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="court-fax"
                      label="Court Fax"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('fax')}
                      value={court.fax}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="court-street"
                      label="Court Street"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('streetAddress')}
                      value={court.streetAddress}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                    <TextField
                      id="court-street-2"
                      label="Ste/Bldg/Fl"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('streetAddress2')}
                      value={court.streetAddress2}
                    />
                  </Grid>
                  <Grid item xs={0} sm={0} md={0} lg={2} xl={2} />
                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                    <TextField
                      id="court-city"
                      label="City"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('city')}
                      value={court.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                    <TextField
                      id="court-state"
                      label="State"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('state')}
                      value={court.state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                    <TextField
                      id="court-zip"
                      label="Zip"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('zip')}
                      value={court.zip}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={1} xl={1}>
                    <TextField
                      id="court-zip-4"
                      label="+4"
                      variant="outlined"
                      fullWidth
                      onChange={handleCreateCourt('zip4')}
                      value={court.zip4}
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </div>
        );
      case 1:
        return (
          <div style={{ width: '80%', margin: 'auto' }}>
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ width: '100%', margin: 'auto' }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="case-name"
                  label="Case Name"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeMatter('matterName')}
                  value={matter.matterName}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  id="case-docket"
                  label="Docket Number"
                  variant="outlined"
                  fullWidth
                  onChange={handleChangeMatter('matterCourtCaseNumber')}
                  value={matter.matterCourtCaseNumber}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                <Autocomplete
                  id="client-id"
                  options={matterTypeList}
                  getOptionLabel={option => option.matter_type_name_full}
                  onChange={handleMatterTypeSelect('matterTypeId')}
                  autoHighlight
                  autoSelect
                  style={{ width: '100%' }}
                  fullWidth
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Select Matter Type"
                      variant="outlined"
                      fullWidth
                      autoHighlight
                      autoSelect
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              direction="row"
              style={{ width: '100%', margin: 'auto' }}
            >
              <Grid item xs={12}>
                <TextField
                  id="case-docket"
                  label="Case Details"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="6"
                  onChange={handleChangeMatter('matterDetails')}
                  value={matter.matterDetails}
                />
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <>
            <div>MatterDetails: {matter.matterDetails}</div>
            <div>MatterDetails: {matter.opFirmID}</div>
          </>
        );
      default:
        return 'Unknown stepIndex';
    }
  };
  return (
    <>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
              <div>
                <div
                  style={{
                    margin: 'auto',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNextValidate}
                  >
                    {/* TODO: This needs to be cleaned up */}
                    {/* eslint-disable-next-line */}
                    {activeStep === steps.length - 1
                      ? 'Finish'
                      : // eslint-disable-next-line
                      newClient.checked === true && activeStep === 0
                      ? 'Save'
                      : newCourt.checked === true && activeStep === 0
                      ? 'Save'
                      : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
