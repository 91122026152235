import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useQuery } from '@apollo/client';
import { getSinglePost } from '../../components/index';
import { PostLoader } from './postLoader';

export interface PostProps {
  match: any;
}

export const Post: React.FC<PostProps> = props => {
  const { postID } = props.match.params;
  const { loading, error, data } = useQuery(getSinglePost, {
    variables: { postID },
  });
  if (loading) {
    return (
      <div>
        <LinearProgress />
      </div>
    );
  }
  if (error) {
    return <div>There has been an error in the API call</div>;
  }
  return (
    <>
      <PostLoader data={data} />
    </>
  );
};
