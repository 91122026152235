import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

export const DeleteJudgeDialog: React.FC<any> = ({
  open,
  handleClose,
  deleteTitle,
  handleCloseAgree,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete This Judge?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete judge {deleteTitle}? This action is
            permanent and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={handleCloseAgree}
            color="primary"
            autoFocus
            style={{ fontWeight: 'bold' }}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
