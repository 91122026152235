import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Editor } from '@tinymce/tinymce-react';
import {
  AttorneySelect,
  DatePicker,
  DynamicDaysField,
  ErrorNotification,
  FormGrid,
  Input,
  RadioGroup,
  Toggle,
  WorkflowSelect,
  useFormFieldState,
  Modal,
} from '../..';
import {
  EditableMatterTask,
  generateDynamicDateString,
  Workflow,
} from '../../../utils';

type BaseTaskModalProps = {
  open: boolean;
  title: string;
  formError: string;
  eventDate?: string;
  initialTaskState?: EditableMatterTask;
  onSave: (task: EditableMatterTask) => void;
  onClose: () => void;
  loading: boolean;
};

const StyledToggle = styled(Toggle)`
  margin-top: 18px;
`;

const BaseTaskModal: React.FC<BaseTaskModalProps> = ({
  open,
  title,
  formError,
  eventDate,
  initialTaskState = {} as EditableMatterTask,
  onSave,
  onClose,
  loading,
}) => {
  const {
    task_name,
    task_description,
    task_due,
    task_due_dynamic,
    task_dynamic_due_from,
    task_completed,
    task_is_priority,
  } = initialTaskState;

  const [taskDueDynamic, setTaskDueDynamic] = useState(
    task_due_dynamic || false,
  );

  const [taskDynamicPeriod, setDynamicPeriod] = useState<
    'before' | 'after' | undefined
  >(task_due_dynamic && Number(task_dynamic_due_from) > 0 ? 'after' : 'before');

  const [workflowTrigger, setWorkflowTrigger] = useState<
    Workflow | undefined
  >();

  const [taskCompleted, setTaskCompleted] = useState(task_completed || false);
  const [taskPriority, setTaskPriority] = useState(task_is_priority || false);

  const {
    state: taskName,
    setValue: setTaskName,
    setError: setTaskNameError,
    setErrorText: setTaskNameErrorText,
    resetState: resetTaskNameState,
  } = useFormFieldState(task_name);
  const {
    state: taskDescription,
    setValue: setTaskDescription,
    setError: setTaskDescriptionError,
    setErrorText: setTaskDescriptionErrorText,
    resetState: resetTaskDescriptionState,
  } = useFormFieldState(task_description || undefined);
  const {
    state: attorneyId,
    setValue: setAttorneyId,
    setError: setAttorneyIdError,
    setErrorText: setAttorneyIdErrorText,
    resetState: resetAttorneyIdState,
  } = useFormFieldState();
  const {
    state: taskDue,
    setValue: setTaskDue,
    setError: setTaskDueError,
    setErrorText: setTaskDueErrorText,
    resetState: resetTaskDueState,
  } = useFormFieldState(task_due);
  const {
    state: taskDynamicDays,
    setValue: setTaskDynamicDays,
    setError: setTaskDynamicDaysError,
    setErrorText: setTaskDynamicDaysErrorText,
    resetState: resetTaskDynamicDaysState,
  } = useFormFieldState(
    task_dynamic_due_from ? String(Math.abs(task_dynamic_due_from)) : undefined,
  );

  const isInteger = (value: string): boolean => {
    return (
      !!value.length &&
      Number(value) === parseInt(value, 10) &&
      Number(value) >= 0
    );
  };

  const convertDynamicDays = (): number => {
    const days = taskDynamicDays.value;
    if (isInteger(days)) {
      const intDays = Number(days);
      return taskDynamicPeriod === 'after' ? intDays : -intDays;
    }
    return 0;
  };

  const isValidDueDate = (): boolean => {
    return taskDueDynamic
      ? isInteger(taskDynamicDays.value)
      : !!taskDue.value.length;
  };

  const isValidTask = (): boolean => {
    return (
      !!taskName.value.length &&
      !!taskDescription.value.length &&
      !!attorneyId.value.length &&
      isValidDueDate()
    );
  };

  const setErrors = (): void => {
    if (!taskName.value.length) {
      setTaskNameError(true);
      setTaskNameErrorText('Field is required');
    }
    if (!taskDescription.value.length) {
      setTaskDescriptionError(true);
      setTaskDescriptionErrorText('Field is required');
    }
    if (!attorneyId.value.length) {
      setAttorneyIdError(true);
      setAttorneyIdErrorText('Field is required');
    }
    if (taskDueDynamic && !isValidDueDate()) {
      setTaskDynamicDaysError(true);
      setTaskDynamicDaysErrorText('Must be a positive integer or zero');
    }
    if (!taskDueDynamic && !isValidDueDate()) {
      setTaskDueError(true);
      setTaskDueErrorText('Field is required');
    }
  };

  const onSaveClick = (): void => {
    if (isValidTask()) {
      onSave({
        task_name: taskName.value,
        task_description: taskDescription.value,
        task_due: taskDue.value,
        task_due_dynamic: taskDueDynamic,
        task_dynamic_due_from: taskDueDynamic
          ? convertDynamicDays()
          : undefined,
        task_owner: Number(attorneyId.value),
        task_completed: taskCompleted,
        trigger_workflow_id: workflowTrigger?.workflow_id || null,
        task_is_priority: taskPriority,
        workflowTrigger,
      });
    } else {
      setErrors();
    }
  };

  const resetState = (): void => {
    resetTaskNameState();
    resetTaskDescriptionState();
    resetAttorneyIdState();
    resetTaskDueState();
    resetTaskDynamicDaysState();
    setDynamicPeriod(undefined);
    setTaskCompleted(false);
  };

  useEffect(() => {
    const { value } = taskDynamicDays;
    if (eventDate && isInteger(value)) {
      setTaskDue(generateDynamicDateString(eventDate, convertDynamicDays()));
    }
  }, [taskDynamicDays, taskDynamicPeriod, eventDate]);

  useEffect(() => {
    // reset state every time the modal closes
    if (!open) resetState();
  }, [open]);

  return (
    <Modal
      open={open}
      title={title}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryLoading={loading}
      includeCancelConfirmation
      onPrimaryClick={onSaveClick}
      onSecondaryClick={onClose}
      onClose={onClose}
    >
      <>
        <FormGrid>
          <Input
            label="Task name"
            value={taskName.value}
            onChange={(e): void => {
              const name = e.target.value;
              setTaskName(name);
              setTaskNameError(false);
            }}
            error={taskName.error}
            errorText={taskName.errorText}
          />

          {/* <Input
            label="Task description"
            value={taskDescription.value}
            onChange={(e): void => {
              const type = e.target.value;
              setTaskDescription(type);
              setTaskDescriptionError(false);
            }}
            error={taskDescription.error}
            errorText={taskDescription.errorText}
          /> */}
          <AttorneySelect
            initialValue={initialTaskState?.attorney?.attorney_name}
            onAttorneySelect={(attorney): void => {
              setAttorneyId(String(attorney.id));
              setAttorneyIdError(false);
            }}
            error={attorneyId.error}
            errorText={attorneyId.errorText}
          />
          {eventDate ? (
            <RadioGroup
              legendText="Task Due Date Type"
              options={[
                { label: 'Static', value: 'static' },
                { label: 'Dynamic', value: 'dynamic' },
              ]}
              defaultValue={taskDueDynamic ? 'dynamic' : 'static'}
              onValueSelected={(selectedValue: string): void => {
                setTaskDueDynamic(selectedValue === 'dynamic');
              }}
            />
          ) : null}
          <>
            {taskDueDynamic && (
              <DynamicDaysField
                daysValue={taskDynamicDays.value}
                defaultPeriod={taskDynamicPeriod}
                error={taskDynamicDays.error}
                errorText={taskDynamicDays.errorText}
                onDaysChange={(days): void => {
                  setTaskDynamicDays(days);
                  setTaskDynamicDaysError(false);
                }}
                onPeriodChange={(period): void => {
                  setDynamicPeriod(period);
                }}
              />
            )}
            {!taskDueDynamic && (
              <DatePicker
                label="Task Due Date"
                value={taskDue.value}
                onChange={(date): void => {
                  setTaskDue(date?.toISOString() || '');
                  setTaskDueError(false);
                }}
                defaultToNow={!taskDue?.value}
                defaultValue={taskDue?.value}
                error={taskDue.error}
                errorText={taskDue.errorText}
                variant="dateOnly"
              />
            )}
          </>
          <WorkflowSelect
            label="Workflow Trigger"
            initialValue={
              initialTaskState?.workflowTrigger?.workflow_name || undefined
            }
            onWorkflowSelect={(workflow?: Workflow): void => {
              setWorkflowTrigger(workflow);
            }}
          />
          <StyledToggle
            label="Task Completed"
            onToggle={(isToggled): void => setTaskCompleted(isToggled)}
            initialState={initialTaskState.task_completed}
          />
          <StyledToggle
            label="High Priority Task"
            onToggle={(isToggled): void => setTaskPriority(isToggled)}
            initialState={initialTaskState.task_is_priority}
          />
        </FormGrid>
        <ErrorNotification text={formError} show={!!formError.length} />

        <Editor
          initialValue={initialTaskState?.task_description || ''}
          apiKey="9h9g57gwpv98kda2tlz6pltsy0ae1muqb5dvgez27rvy2gsa"
          init={{
            height: 500,
            menubar: true,
            plugins: [
              'advlist autolink autoresize lists link image charmap print preview anchor',
              'searchreplace visualblocks code',
              'insertdatetime media table paste code wordcount',
            ],
            toolbar: [
              'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify |',
              'bullist numlist outdent indent | code | help',
            ],
          }}
          onChange={(e): void => {
            const type = e.target;
            setTaskDescription(type.getContent());
            setTaskDescriptionError(false);
          }}
        />
      </>
    </Modal>
  );
};

export { BaseTaskModal };
