import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { BaseWorkflowModal } from './BaseWorkflowModal';
import { EDIT_WORKFLOW } from '../../mutations';
import { Workflow, EditableWorkflow } from '../../../utils';

type WorkflowModalProps = {
  open: boolean;
  onClose: () => void;
  initialState?: Workflow;
};

const EditWorkflowModal: React.FC<WorkflowModalProps> = ({
  open,
  onClose,
  initialState,
}) => {
  const [formError, setFormError] = useState('');
  const [editEvent, { data, loading, error }] = useMutation(EDIT_WORKFLOW);

  useEffect(() => {
    if (!loading && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = (workflowState: EditableWorkflow): void => {
    if (!initialState?.workflow_id) {
      setFormError('Workflow ID not detected');
    } else {
      setFormError('');
      editEvent({
        variables: {
          workflow_id: initialState.workflow_id,
          workflow_name: workflowState.workflow_name,
          workflow_description: workflowState.workflow_description,
        },
      });
    }
  };

  /**
   * We're only allowing the modal to mount when open is true.
   * This ensures that the initial state will not be set before mounting.
   * We do this because initial state can only be set once.
   */
  return (
    <>
      {open && (
        <BaseWorkflowModal
          open={open}
          title="Edit Workflow"
          formError={formError}
          initialState={initialState}
          onSave={onSave}
          onClose={onClose}
          loading={loading}
        />
      )}
    </>
  );
};

export { EditWorkflowModal };
