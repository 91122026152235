import React from 'react';
import { BaseModifiers } from './BaseModifiers';
import { DELETE_TASK } from '../mutations';

type TaskModifierProps = {
  taskId: string;
  onViewClick: (e: React.MouseEvent) => void;
  onEditClick: (e: React.MouseEvent) => void;
  suppressView?: boolean;
};
const defaultProps = {
  suppressView: false,
};
const TaskModifiers: React.FC<TaskModifierProps> = ({
  taskId,
  onViewClick,
  onEditClick,
  suppressView,
}) => (
  <BaseModifiers
    type="Task"
    iconSize="md"
    deleteMutation={DELETE_TASK}
    deleteVariablesObj={{ task_id: taskId }}
    onViewClick={onViewClick}
    onEditClick={onEditClick}
    suppressView={suppressView || false}
  />
);

TaskModifiers.defaultProps = defaultProps;

export { TaskModifiers };
