import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { Workflow } from '../../../../utils';

type WorkflowDetailProps = {
  workflow: Workflow;
};

const StyledDescriptorText = styled(Typography)`
  font-weight: bold;
`;

const StyledInfoText = styled(Typography)`
  /* padding-bottom: 8px; */
`;

type TextPairProps = {
  descriptor: string;
  info?: string;
};

const TextPair: React.FC<TextPairProps> = ({ descriptor, info }) => (
  <Grid item xs={12} md={6}>
    <StyledDescriptorText variant="body2">{descriptor}</StyledDescriptorText>
    <StyledInfoText variant="body2">{info}</StyledInfoText>
  </Grid>
);

const WorkflowDetails: React.FC<WorkflowDetailProps> = ({ workflow }) => {
  const { workflow_name, workflow_description } = workflow;

  return (
    <Grid
      container
      style={{ width: '94.5%', margin: 'auto', paddingBottom: 5 }}
    >
      <TextPair descriptor={workflow_name || ''} />
      <TextPair descriptor="Description:" info={workflow_description || ''} />
    </Grid>
  );
};

export { WorkflowDetails };
