import React, { useEffect, useState } from 'react';
import { useSubscription, useMutation } from '@apollo/client';
import {
  Table,
  FormControlLabel,
  Checkbox,
  Switch,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TablePagination,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import DeleteOutlinedOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import moment from 'moment';
import { deleteArticle, switchVisibility, getPosts } from '..';
import { useColors } from '../../hooks';
import history from '../../history';

const { themeColors } = useColors();

const useStyles = makeStyles({
  tableHeader: {
    color: 'white',
    textAlign: 'center',
    backgroundColor: themeColors.mainColors.primaryColor,
  },
  root: {
    width: '98%',
    overflowX: 'auto',
    color: themeColors.mainColors.primaryColor,
    borderRadius: 10,
    margin: 'auto',
    marginTop: 50,
  },
});

export const PostsTable: React.FC = () => {
  const [articleDelete, setArticleDelete] = useState(999999999999999);
  const [articleDeleteTitle, setArticleDeleteTitle] = useState('');

  const [deleteArticleOnClick] = useMutation(deleteArticle, {
    variables: { article_id: articleDelete },
  });
  // Modal Logic
  const [open, setOpen] = React.useState(false);
  const handleClose = (): void => {
    setOpen(false);
  };
  const handleCloseAgree = (): void => {
    setOpen(false);
    deleteArticleOnClick();
    history.goBack();
  };
  const handleClickOpen = (ID, TITLE): void => {
    setOpen(true);
    setArticleDelete(ID);
    setArticleDeleteTitle(TITLE);
  };

  const [toggleVisibility] = useMutation(switchVisibility);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('tableRowsPerPage') as string, 10) || 10,
  );

  useEffect(() => {
    if (localStorage.getItem('postTableDense') === 'dense') {
      setDense(true);
    }
    if (localStorage.getItem('postTableDense') === 'notDense') {
      setDense(false);
    }
    localStorage.setItem('tableRowsPerPage', rowsPerPage.toString());
  }, []);

  const handleChangeDense = (event): void => {
    setDense(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem('postTableDense', 'dense');
    }
    if (!event.target.checked) {
      localStorage.setItem('postTableDense', 'notDense');
    }
  };
  const handleChangePage = (_event, newPage): void => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('tableRowsPerPage', event.target.value);
    setPage(0);
  };
  const { loading, error, data } = useSubscription(getPosts);
  if (loading) {
    return <>loading...</>;
  }
  if (error) {
    return <div>there has been an error</div>;
  }

  const table = {
    color: 'white',
    borderTop: `3px solid ${themeColors.mainColors.primaryColor}`,
  };
  return (
    <Paper className={classes.root}>
      <div
        style={{
          borderTop: `8px solid ${themeColors.mainColors.primaryColor}`,
        }}
      >
        <Typography variant="h6" className={classes.tableHeader} />

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
          component="div"
          count={data.article.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          style={{ float: 'right' }}
        />

        <div
          style={{
            float: 'left',
            marginBottom: -10,
            marginTop: 7,
            marginLeft: 15,
          }}
        >
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </div>
      </div>
      <Table
        style={table}
        aria-label="simple table"
        size={dense ? 'small' : 'medium'}
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">
              <Typography color="secondary">Title</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Written</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Author</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Link</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Img</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Visible</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Delete</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.article
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              ({
                article_created_at,
                article_id,
                article_img,
                article_title,
                article_url_slug,
                article_visibility,
                attorney,
              }) => (
                <TableRow key={article_id} hover style={{ cursor: 'pointer' }}>
                  <TableCell
                    component="th"
                    scope="row"
                    onClick={(): void => history.push(`/posts/${article_id}`)}
                  >
                    {article_title}
                  </TableCell>
                  <TableCell align="left">
                    {moment(article_created_at).format('LLL')}
                  </TableCell>
                  <TableCell align="left">{attorney.attorney_name}</TableCell>
                  <TableCell align="left" style={{ margin: 0, padding: 0 }}>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://starks.law/articles/${article_url_slug}`}
                      style={{
                        textDecoration: 'none',
                        color: 'rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ margin: 0, padding: '2.5px 10px' }}
                      >
                        View On Site
                      </Button>
                    </a>
                  </TableCell>
                  <TableCell align="center" style={{ margin: 0, padding: 0 }}>
                    <div
                      style={{
                        // maxHeight: '10px',
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      <img
                        src={`https://dt84eajp0482g.cloudfront.net/svgimages/postimages/${article_img}`}
                        alt="Article"
                        style={{
                          display: 'flex',
                          margin: dense ? '2px auto' : '0 auto',
                          maxHeight: dense ? 27.5 : '100%',
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center">
                    <FormControlLabel
                      style={{ margin: 0, height: 1 }}
                      label=""
                      control={
                        <Checkbox
                          icon={<VisibilityOffTwoToneIcon fontSize="large" />}
                          checkedIcon={
                            <VisibilityTwoToneIcon fontSize="large" />
                          }
                          checked={article_visibility}
                          onChange={(): void => {
                            if (article_visibility === true) {
                              toggleVisibility({
                                variables: {
                                  article_id,
                                  article_visibility: false,
                                },
                              });
                            } else {
                              toggleVisibility({
                                variables: {
                                  article_id,
                                  article_visibility: true,
                                },
                              });
                            }
                          }}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell align="center">
                    <DeleteOutlinedOutlined
                      color="primary"
                      onClick={(): void =>
                        handleClickOpen(article_id, article_title)
                      }
                    />
                  </TableCell>
                </TableRow>
              ),
            )}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
        component="div"
        count={data.article.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        style={{ float: 'right' }}
      />
      <div
        style={{
          borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
        }}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Article?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the article {articleDeleteTitle}?
            This action is permanent and cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={handleCloseAgree}
            color="primary"
            autoFocus
            style={{ fontWeight: 'bold' }}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};
