/* eslint-disable react/no-danger */
import React, { ReactNode, useState, useCallback } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import {
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Chip,
  Button,
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Checkbox,
} from '@material-ui/core';
import {
  PriorityHighTwoTone,
  Autorenew,
  HistoryTwoTone,
  Close as CloseIcon,
} from '@material-ui/icons';
import { TaskModifiers } from '../..';
import { MatterTask, useTaskFilter, GetInitials } from '../../../utils';
import {
  useColors,
  useAddSelectedWorkflow,
  useCurrentAttorney,
} from '../../../hooks';

type TaskTableProps = {
  tasks: MatterTask[];
  onViewTask: (taskId: string) => void;
  onEditTask: (taskId: string) => void;
  loading?: boolean;
  filterCompleted?: boolean;
  filterInProgress?: boolean;
  filterOverdue?: boolean;
  caseMatter?: any;
  isSelected?: any;
  handleMultiSelect?: any;
};

const defaultProps = {
  filterCompleted: true,
  filterInProgress: false,
  filterOverdue: false,
};

const StyledTableCell = styled(TableCell)`
  :hover {
    cursor: pointer;
  }
`;

const getStatusIndicator = (task_due, complete, c): ReactNode => {
  const today = moment(new Date()).format('MM/DD/YYYY');
  const taskDate = moment(task_due).format('MM/DD/YYYY');
  let indicatorText;
  let color;
  if (today === taskDate && !complete) {
    indicatorText = 'Due Today';
    color = c.mainColors.warning;
  } else if (moment(today).isAfter(taskDate) && !complete) {
    indicatorText = 'Past Due';
    color = c.mainColors.danger;
  } else if (complete) {
    indicatorText = 'Completed';
    color = c.mainColors.success;
  } else {
    indicatorText = `Due in ${Math.ceil(
      (new Date(taskDate).getTime() - new Date(today).getTime()) /
        (1000 * 3600 * 24),
    )} ${
      (new Date(taskDate).getTime() - new Date(today).getTime()) /
        (1000 * 3600 * 24) ===
      1
        ? 'Day'
        : 'Days'
    }`;
    color = c.mainColors.primaryColor;
  }
  return (
    <Chip
      label={indicatorText}
      variant="outlined"
      style={{ color, border: `1px solid ${color}`, cursor: 'pointer' }}
    />
  );
};

const CaseNameAbbreviate = (string: string): string => {
  if (string) {
    const caseName = string
      .replace('  ', ' ')
      .trim()
      .toUpperCase();
    const preV = caseName.split(' V. ')[0];
    const postV = caseName.split(' V. ')[1];
    return `${preV?.substring(0, 11)}... V. ${postV?.substring(0, 11)}...`;
  }
  return '';
};

const UpdateTaskMutation = gql`
  mutation taskComplete($id: uuid, $status: Boolean) {
    update_task(
      where: { task_id: { _eq: $id } }
      _set: { task_completed: $status }
    ) {
      affected_rows
    }
  }
`;
const GetHistory = gql`
  query GetHistory($id: uuid) {
    task_change_history(where: { task_id: { _eq: $id } }) {
      change_task_completed_from
      change_task_completed_to
      change_task_description_from
      change_task_description_to
      change_task_due_from
      change_task_due_to
      change_task_is_priority_from
      change_task_is_priority_to
      change_task_name_from
      change_task_name_to
      change_task_owner_from
      change_task_owner_to
      change_trigger_workflow_id_from
      change_trigger_workflow_id_to
      date_of_mod
      modifying_individual_email
      modifying_individual_id
      modifying_individual_name
      task_change_history_id
      task_id
      task_row_changed
    }
  }
`;
const StyledWarn = styled(PriorityHighTwoTone)<{ IconColor: string }>`
  color: ${({ IconColor }): string => IconColor};
  float: right;
  animation: pulse-red 3s infinite;
  border-radius: 50%;
  @keyframes pulse-red {
    0% {
      box-shadow: 0 0 0 0 ${({ IconColor }): string => IconColor};
    }
    50% {
      transform: scale(1.2);
      box-shadow: 0 0 0 6px ${({ IconColor }): string => IconColor}00;
    }
    100% {
      box-shadow: 0 0 0 0 ${({ IconColor }): string => IconColor}00;
    }
  }
`;
const StyledAuto = styled(Autorenew)<{ AutoRenewIconColor: string }>`
  color: ${({ AutoRenewIconColor }): string => AutoRenewIconColor};
  float: left;
  animation: pulse-blue 3s infinite;
  border-radius: 50%;
  @keyframes pulse-blue {
    0% {
      box-shadow: 0 0 0 0
        ${({ AutoRenewIconColor }): string => AutoRenewIconColor};
    }

    50% {
      transform: scale(1.2), rotate(180deg);
      box-shadow: 0 0 0 6px
        ${({ AutoRenewIconColor }): string => AutoRenewIconColor}00;
    }

    100% {
      transform: rotate(180deg);
      box-shadow: 0 0 0 0
        ${({ AutoRenewIconColor }): string => AutoRenewIconColor}00;
    }
  }
`;
const StyledHistory = styled(HistoryTwoTone)<{ HistoryIconIconColor: string }>`
  color: ${({ HistoryIconIconColor }): string => HistoryIconIconColor};
  float: right;
  cursor: pointer;
  /* margin-right: 15px;
  animation: pulse-blue 3s infinite;
  @keyframes pulse-blue {
    0% {
      box-shadow: 0 0 0 0
        ${({ HistoryIconIconColor }): string => HistoryIconIconColor};
    }

    50% {
      transform: scale(1.2), rotate(180deg);
      box-shadow: 0 0 0 6px
        ${({ HistoryIconIconColor }): string => HistoryIconIconColor}00;
    }

    100% {
      transform: rotate(180deg);
      box-shadow: 0 0 0 0
        ${({ HistoryIconIconColor }): string => HistoryIconIconColor}00;
    }
  } */
`;

const StyledHistoryContainer = styled.div`
  margin: 25px auto;
  width: 95vw;
`;
const TaskTableBody: React.FC<TaskTableProps> = ({
  tasks,
  onViewTask,
  onEditTask,
  filterCompleted,
  filterInProgress,
  filterOverdue,
  caseMatter,
  isSelected,
  handleMultiSelect,
}) => {
  const [historyOpen, setHistoryOpen] = useState<boolean>(false);
  const [getHistory, { data: historyData, error, loading }] = useLazyQuery(
    GetHistory,
  );
  const handleHistory = id => {
    getHistory({ variables: { id } });
    setHistoryOpen(true);
  };

  const handleCloseHistory = () => {
    setHistoryOpen(false);
  };

  const logStuff = (): void => {
    console.log('workflow applied');
  };
  const onSuccess = useCallback(() => {
    logStuff();
  }, [logStuff]);

  const bubinga = (e): void => {
    console.log(e);
  };

  const onError = useCallback(
    (addWorkflowsError: string) => {
      bubinga(addWorkflowsError);
    },
    [bubinga],
  );

  const { onSelection, loading: addingWorkflows } = useAddSelectedWorkflow({
    onSuccess,
    onError,
  });

  const { currentAttorney } = useCurrentAttorney();

  const { themeColors } = useColors();
  const [completeDialog, setCompleteDialog] = useState<boolean>(false);

  const [focusedMatter, setFocusedMatter] = useState<[] | null>(null);
  const [focusedEvent, setFocusedEvent] = useState<[] | null>(null);
  const [focusedWorkflow, setFocusedWorkflow] = useState<[] | null>(null);

  const [completeTaskId, setCompleteTaskId] = useState<string>('none');
  const [completeTaskName, setCompleteTaskName] = useState<string>('none');
  const [completeTaskCurrentState, setCompleteTaskCurrentState] = useState<
    boolean
  >();

  const applyWorkflow = (matter, event, workflowTrigger): void => {
    if (matter && workflowTrigger && workflowTrigger.workflow_id) {
      onSelection({
        matter,
        event,
        workflow: workflowTrigger,
        attorney: {
          id: currentAttorney!.attorney_id,
          name: currentAttorney!.attorney_name,
        },
      });
    }
  };

  const handleCompleteSwitch = (
    id,
    name,
    currentState,
    matter,
    workflow,
    event,
  ): void => {
    setCompleteTaskName(name);
    setCompleteTaskId(id);
    setCompleteTaskCurrentState(currentState);
    setCompleteDialog(!completeDialog);
    setFocusedMatter(matter);
    setFocusedEvent(event);
    setFocusedWorkflow(workflow);
  };

  const [changeTaskStatus] = useMutation(UpdateTaskMutation, {
    variables: {
      id: completeTaskId,
      status: !completeTaskCurrentState,
    },
  });
  const handleConfirmTaskStatusChange = (): void => {
    changeTaskStatus();
    setCompleteDialog(!completeDialog);
    if (!completeTaskCurrentState) {
      applyWorkflow(focusedMatter, focusedEvent, focusedWorkflow);
    }
  };
  return (
    <>
      <TableBody>
        {useTaskFilter(
          tasks,
          filterCompleted,
          filterOverdue,
          filterInProgress,
        ).map(
          ({
            task_id,
            task_name,
            task_completed,
            task_description,
            task_is_priority,
            task_due,
            attorney,
            matter,
            workflowTrigger,
            trigger_workflow_id,
            event,
          }) => (
            <TableRow key={task_id} hover>
              <>
                <TableCell padding="checkbox">
                  <Checkbox
                    key={task_id}
                    color="primary"
                    checked={isSelected.task_id}
                    onChange={(): void => handleMultiSelect(task_id)}
                    inputProps={{
                      'aria-labelledby': task_id,
                    }}
                  />
                </TableCell>
                <Tooltip
                  style={{ maxWidth: '100vw' }}
                  interactive
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: task_description || '',
                      }}
                    />
                  }
                >
                  <StyledTableCell
                    align="left"
                    onClick={(): void => onViewTask(task_id)}
                  >
                    {task_name}
                    {task_is_priority && (
                      <StyledWarn IconColor={themeColors.mainColors.danger} />
                    )}
                  </StyledTableCell>
                </Tooltip>
                <StyledTableCell
                  align="left"
                  onClick={(): void => onViewTask(task_id)}
                >
                  {moment(task_due).format('MMM D, YYYY')}
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  onClick={(): void => onViewTask(task_id)}
                >
                  <Tooltip title={attorney?.attorney_name || 'ss'}>
                    <div>{GetInitials(attorney?.attorney_name)}</div>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  onClick={(): void => onViewTask(task_id)}
                >
                  {getStatusIndicator(task_due, task_completed, themeColors)}
                </StyledTableCell>
                <TableCell align="left">
                  {matter && (
                    <Button
                      href={`/cases/${matter?.matter_id}`}
                      // target="_blank"
                      variant="outlined"
                      color="primary"
                      fullWidth
                    >
                      {CaseNameAbbreviate(matter?.matter_name || '')}
                    </Button>
                  )}
                </TableCell>
                <TableCell align="right" style={{ width: 275 }}>
                  {trigger_workflow_id && (
                    <Tooltip title="this task has a trigger event on complete.">
                      <StyledAuto
                        AutoRenewIconColor={themeColors.mainColors.info}
                        fontSize="large"
                      />
                    </Tooltip>
                  )}
                  <Tooltip title="Complete">
                    <Switch
                      size="small"
                      checked={task_completed}
                      onChange={(): void =>
                        handleCompleteSwitch(
                          task_id,
                          task_name,
                          task_completed,
                          matter || caseMatter,
                          workflowTrigger,
                          event,
                        )
                      }
                    />
                  </Tooltip>

                  <TaskModifiers
                    taskId={task_id}
                    onViewClick={(): void => onViewTask(task_id)}
                    onEditClick={(): void => onEditTask(task_id)}
                    suppressView
                  />
                  <Tooltip title="view history">
                    <StyledHistory
                      onClick={(): void => handleHistory(task_id)}
                      HistoryIconIconColor={themeColors.mainColors.info}
                      fontSize="large"
                    />
                  </Tooltip>
                </TableCell>
              </>
            </TableRow>
          ),
        )}
      </TableBody>
      {!loading && (
        <Dialog
          fullScreen
          open={historyOpen}
          // onClose={handleCloseClientProfile}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseHistory}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Task History</Typography>
            </Toolbar>
          </AppBar>
          <br />
          <br />
          <br />
          {historyData?.task_change_history?.map(
            ({
              task_change_history_id,
              date_of_mod,
              change_task_completed_from,
              change_task_completed_to,
              change_task_description_from,
              change_task_description_to,
              change_task_due_from,
              change_task_due_to,
              change_task_is_priority_from,
              change_task_is_priority_to,
              change_task_name_from,
              change_task_name_to,
              change_task_owner_from,
              change_task_owner_to,
              change_trigger_workflow_id_from,
              change_trigger_workflow_id_to,
              modifying_individual_email,
              modifying_individual_id,
              modifying_individual_name,
              task_id,
              task_row_changed,
            }) => {
              return (
                <>
                  <StyledHistoryContainer key={task_change_history_id}>
                    <Typography variant="body2">
                      Date of Change:{' '}
                      {moment(date_of_mod).format(
                        'dddd, MMMM Do YYYY, h:mm:ss a',
                      )}
                    </Typography>
                    <Typography variant="body2">
                      Modified By: {modifying_individual_name}
                    </Typography>
                    {change_task_completed_from && (
                      <Typography variant="body2">
                        Changed complete from: {change_task_completed_from} to{' '}
                        {change_task_completed_to}
                      </Typography>
                    )}
                    {change_task_description_from && (
                      <Typography variant="body2">
                        Changed description from: {change_task_description_from}{' '}
                        to {change_task_description_to}
                      </Typography>
                    )}
                    {change_task_due_from && (
                      <Typography variant="body2">
                        Changed due date from: {change_task_due_from} to{' '}
                        {change_task_due_to}
                      </Typography>
                    )}
                    {change_task_is_priority_from && (
                      <Typography variant="body2">
                        Changed priority from: {change_task_is_priority_from} to{' '}
                        {change_task_is_priority_to}
                      </Typography>
                    )}
                    {change_task_name_from && (
                      <Typography variant="body2">
                        Changed task name from: {change_task_name_from} to{' '}
                        {change_task_name_to}
                      </Typography>
                    )}
                    {change_task_owner_from && (
                      <Typography variant="body2">
                        Changed task owner from: {change_task_owner_from} to{' '}
                        {change_task_owner_to}
                      </Typography>
                    )}
                    {change_trigger_workflow_id_from && (
                      <Typography variant="body2">
                        Changed workflow trigger from:{' '}
                        {change_trigger_workflow_id_from}
                        to {change_trigger_workflow_id_to}
                      </Typography>
                    )}
                    <br />
                  </StyledHistoryContainer>
                </>
              );
            },
          )}
        </Dialog>
      )}
      <Dialog
        onClose={(): void => setCompleteDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={completeDialog}
      >
        <DialogTitle id="alert-dialog-title">
          Mark Task &apos;{completeTaskName}&apos;{' '}
          {(completeTaskCurrentState && 'Incomplete') || 'Complete'}?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clicking continue will cause the task to be marked{' '}
            {(completeTaskCurrentState && 'Incomplete') || 'Complete'}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => setCompleteDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmTaskStatusChange}
            color="primary"
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
TaskTableBody.defaultProps = defaultProps;

export { TaskTableBody };
