import {
  DatePicker as MuiDatePicker,
  DateTimePicker as MuiDateTimePicker,
} from '@material-ui/pickers';
import moment, { Moment } from 'moment';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useIndexer } from '../../context';
import { sharedStyles } from './utils';

const StyledDatePicker = styled(MuiDatePicker)`
  ${sharedStyles}
`;

const StyledDateTimePicker = styled(MuiDateTimePicker)`
  ${sharedStyles}
`;

type PickerVariant = 'dateOnly' | 'dateTime';

type InputProps = {
  label: string;
  value: string | Moment;
  error?: boolean;
  errorText?: string;
  onChange: (date: Moment | null) => void;
  variant: PickerVariant;
  defaultValue?: string | Moment;
  defaultToNow?: boolean;
};

const DatePicker: React.FC<InputProps> = ({
  label,
  value,
  error,
  errorText,
  onChange,
  variant,
  defaultValue,
  defaultToNow,
}) => {
  const componentIndex = useIndexer();

  const inputId = useMemo(() => `date-time-picker-${componentIndex}`, [
    componentIndex,
  ]);

  useEffect(() => {
    // this takes precedence over defaultValue
    if (defaultToNow) {
      onChange(moment());
    } else if (defaultValue) {
      if (typeof defaultValue === 'string') {
        onChange(moment(defaultValue));
      } else {
        onChange(defaultValue);
      }
    }
  }, []);

  const localDefault = useMemo(() => {
    // this takes precedence over defaultValue
    if (defaultToNow) {
      return moment();
    }
    if (defaultValue) {
      return defaultValue;
    }
    return undefined;
  }, [defaultValue, defaultToNow]);

  return (
    <>
      {variant === 'dateOnly' && (
        <StyledDatePicker
          id={inputId}
          label={label}
          value={value}
          defaultValue={localDefault}
          error={error}
          helperText={error ? errorText : ''}
          margin="normal"
          variant="inline"
          inputVariant="outlined"
          onChange={onChange}
          autoOk
        />
      )}
      {variant === 'dateTime' && (
        <StyledDateTimePicker
          id={inputId}
          label={label}
          value={value}
          defaultValue={localDefault}
          error={error}
          helperText={error ? errorText : ''}
          margin="normal"
          variant="inline"
          inputVariant="outlined"
          onChange={onChange}
          autoOk
        />
      )}
    </>
  );
};

export { DatePicker };
