import React, { useMemo } from 'react';
import { Download as DownloadIcon } from '@bit/starkslawpc.core.icons';
import { Typography } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { AxiosResponse } from 'axios';
import { ParseFileSize, Downloader as Download } from '../../../../../utils';

interface FileSystemNavigatorProps {
  files: string[] | AxiosResponse | any;
  prefix: string;
}

const ItemContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 75% 25%;
  max-width: 900px;
`;
const InteractiveItem = styled.div`
  float: right;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 25%;
`;

const LabelEndItem = styled.div`
  float: right;
  text-align: right;
`;

export const FileSystemNavigator: React.FC<FileSystemNavigatorProps> = ({
  files,
  prefix,
}) => {
  const f = useMemo(
    () =>
      files?.items?.map(({ ETag, Key, Size, StorageClass }) => {
        return { ETag, Key: Key.replace(prefix, ''), Size, StorageClass };
      }),
    [files],
  );

  return (
    <>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem nodeId="1" label="Client Folder">
          {(f.length &&
            f?.map(({ ETag, Key, Size, StorageClass }) => {
              return (
                <ItemContainer key={ETag}>
                  <TreeItem
                    nodeId={ETag}
                    onClick={(): void => Download(prefix + Key, 3)}
                    label={
                      <LabelContainer>
                        <Typography variant="body2">{Key}</Typography>
                        <LabelEndItem>
                          <Typography variant="caption" color="inherit">
                            {`${ParseFileSize(Size)} | ${StorageClass}`}
                          </Typography>
                        </LabelEndItem>
                      </LabelContainer>
                    }
                  />
                  <InteractiveItem
                    onClick={(): void => Download(prefix + Key, 3)}
                  >
                    <DownloadIcon width={24} style={{ cursor: 'pointer' }} />
                  </InteractiveItem>
                </ItemContainer>
              );
            })) || <TreeItem nodeId="199**9" label="No Files Available" />}
        </TreeItem>
      </TreeView>
    </>
  );
};
