import React, { useState, useCallback, useMemo } from 'react';
import {
  TextField,
  MobileStepper,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import styled from 'styled-components';
import _ from 'lodash';

import { ClientList } from './clientList';

export { ClientProfile } from './clientProfile';

const GridContainer = styled.div`
  display: grid;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(428px, 1fr));
  margin: 10px auto;
`;
const SearchContainer = styled.div`
  width: 90vw;
  min-width: 300px;
  max-width: 425px;
  margin: auto;
`;

const BodyContainer = styled.div`
  position: relative;
  top: -40px;
`;

const StyledMobileStepper = styled(MobileStepper)<{ isMobile: boolean }>`
  margin-left: ${({ isMobile }): string => (isMobile && '0px') || '70px'};
`;

export const Clients: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('%');
  const [activeStep, setActiveStep] = React.useState(0);
  const [numPages, setNumPages] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleNext = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('clientsPerPage') as string, 10) || 8,
  );
  const handleSearchTerm = useCallback(
    (e): void => {
      setSearchTerm(`%${e.target.value}%`);
      setActiveStep(0);
    },
    [setSearchTerm],
  );

  const debouncedHandleSearchTerm = useMemo(
    () => _.debounce(handleSearchTerm, 300),
    [handleSearchTerm],
  );

  return (
    <>
      <BodyContainer>
        <SearchContainer>
          <TextField
            fullWidth
            variant="filled"
            color="primary"
            label="Search"
            placeholder="Search"
            onChange={debouncedHandleSearchTerm}
          />
        </SearchContainer>

        <GridContainer>
          <ClientList
            searchTerm={searchTerm}
            page={activeStep}
            rowsPerPage={rowsPerPage}
            setNumPages={setNumPages}
          />
        </GridContainer>
      </BodyContainer>
      <StyledMobileStepper
        isMobile={isMobile}
        variant="progress"
        steps={numPages}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === numPages - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
    </>
  );
};
