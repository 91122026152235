import React from 'react';
import { TablePagination } from '@material-ui/core';

export const JudgeTablePagination: React.FC<any> = ({
  judges,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
      component="div"
      count={judges?.length}
      rowsPerPage={rowsPerPage}
      page={page}
      backIconButtonProps={{
        'aria-label': 'previous page',
      }}
      nextIconButtonProps={{
        'aria-label': 'next page',
      }}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      style={{ float: 'right' }}
    />
  );
};
