import React from 'react';
import styled from 'styled-components';
import { AtomSpinner } from 'react-epic-spinners';
import { Doughnut } from 'react-chartjs-2';
import { Paper } from '@material-ui/core';
import { useColors } from '../../../hooks';

const { themeColors } = useColors();

const StyledPaper = styled(Paper)`
  width: 100%;
  margin: 25px auto;
  padding: 10px;
`;
interface WinLossProps {
  loading?: boolean;
  data?: number[];
  title?: string;
  labels?: string[];
}

const defaultProps = {
  data: [300, 50],
  title: 'Weekly Win Rate',
  labels: ['Won', 'Lost'],
};

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

export const WinLoss: React.FC<WinLossProps> = ({
  loading,
  data,
  title,
  labels,
}) => {
  return (
    <StyledPaper>
      {(loading && (
        <SpinnerContainer>
          <AtomSpinner color={themeColors.mainColors.primaryColor} />
        </SpinnerContainer>
      )) || (
        <Doughnut
          data={{
            labels,
            datasets: [
              {
                data,
                backgroundColor: [
                  `${themeColors.mainColors.success}50`,
                  `${themeColors.mainColors.danger}50`,
                  `${themeColors.mainColors.info}50`,
                  `${themeColors.mainColors.warning}50`,
                ],
                borderColor: themeColors.mainColors.secondaryColor,
                borderWidth: 0.5,
                hoverBackgroundColor: [
                  themeColors.mainColors.success,
                  themeColors.mainColors.danger,
                  themeColors.mainColors.info,
                  themeColors.mainColors.warning,
                ],
              },
            ],
          }}
          options={{
            title: {
              display: true,
              text: `${title}`,
              fontSize: 10,
              fontColor: themeColors.mainColors.primaryColor,
            },
            legend: {
              labels: {
                fontColor: themeColors.mainColors.primaryColor,
              },
            },
            maintainAspectRatio: false,
          }}
        />
      )}
    </StyledPaper>
  );
};
WinLoss.defaultProps = defaultProps;
