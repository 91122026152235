type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  Update = 'UPDATE_USER',
}

// Product

type UserType = {
  id: any;
  userRole?: string;
  SLuserId?: string;
  Auth0UserId?: string;
  email?: string;
  mailingStreet?: string;
  mailingState?: string;
  mailingCity?: string;
  mailingZip?: number | null | undefined;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  stripeId?: string;
};

type UserPayload = {
  [Types.Update]: {
    id: any;
    userRole?: string;
    SLuserId?: string;
    Auth0UserId?: string;
    email?: string;
    mailingStreet?: string;
    mailingState?: string;
    mailingCity?: string;
    mailingZip?: number | null | undefined;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    stripeId?: string;
  };
};

export type UserActions = ActionMap<UserPayload>[keyof ActionMap<UserPayload>];

export const userReducer = (state: UserType, action: UserActions) => {
  switch (action.type) {
    case Types.Update:
      return {
        id: action.payload.id,
        userRole: action.payload.userRole,
        SLuserId: action.payload.SLuserId,
        Auth0UserId: action.payload.Auth0UserId,
        email: action.payload.email,
        mailingStreet: action.payload.mailingStreet,
        mailingState: action.payload.mailingState,
        mailingCity: action.payload.mailingCity,
        mailingZip: action.payload.mailingZip,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        middleName: action.payload.middleName,
        stripeId: action.payload.stripeId,
      };
    default:
      return state;
  }
};
