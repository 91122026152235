import { gql } from '@apollo/client';

const GET_MESSAGES = gql`
  subscription MySubscription($searchTerm: String) {
    message(
      order_by: { message_created_at: desc }
      where: {
        _or: [
          { message_body: { _ilike: $searchTerm } }
          { message_contact_firstName: { _ilike: $searchTerm } }
          { message_contact_lastName: { _ilike: $searchTerm } }
          { message_email: { _ilike: $searchTerm } }
        ]
      }
    ) {
      message_body
      message_contact_firstName
      message_contact_lastName
      message_created_at
      message_email
      message_id
      message_response_sent
      message_sent_from
    }
  }
`;

export default GET_MESSAGES;
