/* eslint-disable react/no-array-index-key */
import { useSubscription } from '@apollo/client';
import {
  CaseOutline,
  CreditCard,
  SendMessage,
} from '@bit/starkslawpc.core.icons';
import { Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect } from 'react';
import { AtomSpinner } from 'react-epic-spinners';
import styled from 'styled-components';
import { getClientsSubscription } from '../../components/queries';
import { boxShadows } from '../../constants';
import history from '../../history';
import { useColors } from '../../hooks';
import { GetInitials } from '../../utils';

const CardContainer = styled(Paper)`
  width: 90vw;
  min-width: 300px;
  max-width: 425px;
  height: 420px;
  display: grid;
  grid-template-rows: 3px auto;
  border-radius: 5px;
  overflow: hidden;
  margin: 7px auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.three};
    transform: translate(0px, -2px) scale(1.02);
    /* transform: scale(1.02); */
  }
`;

const CardTopBorder = styled.div<{ color: string }>`
  background-color: ${({ color }): string => color};
`;

const CardMainInfoSegment = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;
const Avatar = styled.div<{ color: string }>`
  border-radius: 50%;
  background-color: ${({ color }): string => color};
  width: 80px;
  height: 80px;
  color: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
`;

const AvatarText = styled.div`
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ButtonRow = styled.div`
  margin: 25px auto;
  width: 225px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const ButtonItemContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const InfoRow = styled.div`
  width: 300px;
  display: grid;
  margin: 5px auto;
  grid-gap: 5px;
  grid-template-columns: 110px 190px;
  text-align: center;
`;

interface ClientListProps {
  searchTerm?: string;
  page: number;
  rowsPerPage: number;
  setNumPages: (x: number) => void;
}

export const ClientList: React.FC<ClientListProps> = ({
  searchTerm,
  page,
  rowsPerPage,
  setNumPages,
}) => {
  const { themeColors } = useColors();

  const wildCard = (searchTerm && searchTerm) || '%';

  const { loading: loadingClients, error, data } = useSubscription(
    getClientsSubscription,
    {
      variables: { searchTerm: wildCard },
    },
  );
  useEffect(() => {
    if (data) {
      setNumPages(Math.ceil(data?.client?.length / rowsPerPage));
    }
  }, [data, searchTerm]);
  if (error) {
    console.assert(!error, error);
  }

  return (
    <>
      {loadingClients &&
        Array(5)
          .fill(5)
          .map((_, i) => (
            <CardContainer key={i}>
              <CardTopBorder color={themeColors.mainColors.primaryColor} />
              <AtomSpinner
                size={128}
                color={themeColors.mainColors.primaryColor}
              />
            </CardContainer>
          ))}
      {!loadingClients &&
        data?.client
          ?.sort((a, b) =>
            a?.client_lastName?.localeCompare(b?.client_lastName),
          )
          ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(
            ({
              client_id,
              client_firstName: firstName,
              client_lastName: lastName,
              client_address_street: street,
              client_address_city: city,
              client_address_state: state,
              client_address_zip: zip,
              client_email: email,
              client_phone_cell: phone,
              client_created_date: added,
            }) => {
              return (
                <>
                  <CardContainer
                    key={client_id}
                    onClick={(): void => history.push(`/clients/${client_id}`)}
                  >
                    <CardTopBorder
                      color={themeColors.mainColors.primaryColor}
                    />

                    <CardMainInfoSegment>
                      <Avatar color={themeColors.mainColors.primaryColor}>
                        <AvatarText>
                          <Typography variant="h5">
                            {GetInitials(
                              `${(firstName && firstName) || ''} ${(lastName &&
                                lastName) ||
                                ''}`,
                            )}
                          </Typography>
                        </AvatarText>
                      </Avatar>
                      <Typography variant="h5" component="div">
                        {`${(firstName && firstName) || ''} ${lastName}`}
                      </Typography>
                      <Typography variant="caption" component="div">
                        {(street &&
                          `${(street && street) || ''}, ${(city && city) ||
                            ''}, ${(state && state.toUpperCase()) ||
                            ''} ${(zip && zip) || ''}`) ||
                          'No Address Available'}
                      </Typography>
                      <ButtonRow>
                        <ButtonItemContainer>
                          <CaseOutline
                            fill={themeColors.mainColors.primaryColor}
                            width={48}
                          />
                        </ButtonItemContainer>
                        <ButtonItemContainer>
                          <CreditCard
                            fill={themeColors.mainColors.primaryColor}
                            width={48}
                          />
                        </ButtonItemContainer>
                        <ButtonItemContainer>
                          <SendMessage
                            fill={themeColors.mainColors.primaryColor}
                            width={48}
                          />
                        </ButtonItemContainer>
                      </ButtonRow>
                      <InfoRow>
                        <Typography
                          variant="body2"
                          style={{ textAlign: 'right', color: 'lightgray' }}
                        >
                          Phone:
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: 'left' }}
                        >
                          {(phone && phone) || '--'}
                        </Typography>
                      </InfoRow>
                      <InfoRow>
                        <Typography
                          variant="body2"
                          style={{ textAlign: 'right', color: 'lightgray' }}
                        >
                          Email:
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: 'left' }}
                        >
                          {(email && email) || '--'}
                        </Typography>
                      </InfoRow>
                      <InfoRow>
                        <Typography
                          variant="body2"
                          style={{ textAlign: 'right', color: 'lightgray' }}
                        >
                          Added:
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: 'left' }}
                        >
                          {moment(added).format('MMM Do, YYYY')}
                        </Typography>
                      </InfoRow>
                    </CardMainInfoSegment>
                  </CardContainer>
                </>
              );
            },
          )}
    </>
  );
};
