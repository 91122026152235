import { gql, useSubscription } from '@apollo/client';
import React from 'react';
import { TaskTable } from '../../../components';

export interface TaskProps {
  assignedTo?: string | undefined;
  searchTerm?: string | undefined | null;
}

const TasksQuery = gql`
  subscription Tasks_with_name_filter($attorney: Int, $searchTerm: String) {
    task(
      where: {
        task_owner: { _eq: $attorney }
        _and: {
          _or: [
            { task_name: { _ilike: $searchTerm } }
            { task_description: { _ilike: $searchTerm } }
          ]
        }
      }
    ) {
      task_completed
      task_due
      task_id
      task_due_dynamic
      task_dynamic_due_from
      task_description
      task_name
      task_is_priority
      trigger_workflow_id
      workflowTrigger {
        workflow_description
        workflow_id
        workflow_name
        workflow_tasks {
          workflow_task_description
          workflow_task_dynamic_due_from
          workflow_task_dynamic_lock
          workflow_task_id
          workflow_task_name
          workflow_task_workflow_id
          trigger_workflow_id
          task_is_priority
        }
      }
      event {
        event_id
        event_date_time
        event_end_time
        event_length
        event_location
        event_matter_id
        event_name
        event_owner
      }
      attorney {
        attorney_name
      }
      matter {
        attorney_owner_id
        matter_name
        matter_id
        owning_attorney {
          attorney_name
        }
        events {
          event_date_time
          event_id
        }
      }
      task_event_id
    }
  }
`;

const TasksQueryNoSearch = gql`
  subscription Tasks_without_name_filter($attorney: Int) {
    task(
      where: {
        task_owner: { _eq: $attorney }
        _and: { task_completed: { _eq: false } }
      }
    ) {
      task_completed
      task_due
      task_id
      task_due_dynamic
      task_dynamic_due_from
      task_description
      task_name
      task_is_priority
      trigger_workflow_id
      workflowTrigger {
        workflow_description
        workflow_id
        workflow_name
        workflow_tasks {
          workflow_task_description
          workflow_task_dynamic_due_from
          workflow_task_dynamic_lock
          workflow_task_id
          workflow_task_name
          workflow_task_workflow_id
          trigger_workflow_id
          task_is_priority
        }
      }
      event {
        event_id
        event_date_time
        event_end_time
        event_length
        event_location
        event_matter_id
        event_name
        event_owner
      }
      attorney {
        attorney_name
      }
      matter {
        attorney_owner_id
        matter_name
        matter_id
        owning_attorney {
          attorney_name
        }
        events {
          event_date_time
          event_id
        }
      }
      task_event_id
    }
  }
`;

export const Tasks: React.FC<TaskProps> = ({ assignedTo, searchTerm }) => {
  let variables = { attorney: assignedTo || '%', searchTerm };
  const taskSwitch = (): any => {
    if (
      searchTerm === '%' ||
      searchTerm === null ||
      searchTerm === undefined ||
      searchTerm === '%%'
    ) {
      console.log('using no filter');
      variables = { attorney: assignedTo || '%' } as any;
      return TasksQueryNoSearch;
    }
    console.log('now using filter');
    return TasksQuery;
  };
  const { loading, error, data } = useSubscription(taskSwitch(), {
    variables,
  });
  if (error) {
    return <div>there has been an error</div>;
  }
  return (
    <TaskTable
      tasks={data?.task || []}
      loading={loading}
      showCompletedSwitch={false}
    />
  );
};
