import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import moment from 'moment';
import styled from 'styled-components';
import { useCurrentAttorney } from '../../../../hooks';
import { NoteEditorBox } from '../../../../utils/NoteEditor';

const mutationBody = gql`
  mutation AddMatterNote($matter_id: uuid, $note: String) {
    update_matter(
      where: { matter_id: { _eq: $matter_id } }
      _set: { matter_notes: $note }
    ) {
      affected_rows
      returning {
        matter_notes
      }
    }
  }
`;

const StyledNotesBody = styled.div`
  margin: 25px auto;
  width: 90%;
`;

export const Notes = matter => {
  const { currentAttorney } = useCurrentAttorney();
  const { matter_notes, matter_id } = matter?.matter;
  const [note, setNote] = useState('');
  const [noteReady, setNoteReady] = useState(false);
  useEffect(() => {
    setNote(matter_notes ?? '');
  }, [matter_notes]);

  const handleNoteChange = (e): void => {
    setNoteReady(true);
    setNote(
      `${e.target.getContent()} ${(e.target.getContent().length > 0 &&
        `<span><h6 style="color: gray;font-size: .5rem"><em>--Edited/Added by ${
          currentAttorney?.attorney_name
        } on ${moment()}</em></h6></span>`) ||
        ''}`,
    );
  };

  const [saveNote] = useMutation(mutationBody, {
    variables: {
      matter_id,
      note,
    },
  });
  const handleSaveNotes = (): void => {
    setNoteReady(false);
    saveNote();
  };

  return (
    <StyledNotesBody>
      <NoteEditorBox
        note={note}
        handleNoteChange={handleNoteChange}
        noteReady={noteReady}
        handleSaveNotes={handleSaveNotes}
      />
    </StyledNotesBody>
  );
};
