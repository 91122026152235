import { gql } from '@apollo/client';

const GET_SINGLE_MESSAGE = gql`
  query SingleMessageQuery($messageID: Int) {
    message(
      where: { message_id: { _eq: $messageID } }
      order_by: { message_created_at: asc }
    ) {
      message_body
      message_contact_firstName
      message_contact_lastName
      message_created_at
      message_email
      message_id
      message_response_sent
      message_sent_from
      message_phone
    }
  }
`;

export default GET_SINGLE_MESSAGE;
