import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useColors } from '../../hooks';

const StyledEmptyBody = styled.tbody<{ bgColor: string }>`
  width: 100%;
  background: ${({ bgColor }): string => bgColor};
  padding: 10px;
`;

const StyledEmptyRow = styled.tr<{ bgColor: string }>`
  width: 100%;
  background: ${({ bgColor }): string => bgColor};
  padding: 10px;
`;

const StyledEmptyCell = styled.td<{ bgColor: string }>`
  width: 100%;
  background: ${({ bgColor }): string => bgColor};
  padding: 10px;
`;

const EmptyTasksBody: React.FC = () => {
  const { themeColors } = useColors();

  const { cardBackground } = themeColors.mainColors;

  return (
    <StyledEmptyBody bgColor={cardBackground}>
      <StyledEmptyRow bgColor={cardBackground}>
        <StyledEmptyCell bgColor={cardBackground}>
          <Typography variant="body1">
            No Tasks. Click add button above to add one.
          </Typography>
        </StyledEmptyCell>
      </StyledEmptyRow>
    </StyledEmptyBody>
  );
};

export { EmptyTasksBody };
