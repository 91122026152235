import React from 'react';
import styled from 'styled-components';
import { AtomSpinner } from 'react-epic-spinners';
import { Paper, Typography } from '@material-ui/core';
import { useColors } from '../../hooks';

export interface TextAnalyticsCardProps {
  title: string;
  data: string | number;
  change: number;
  loading?: boolean;
}

const { themeColors } = useColors();

const defaultProps = {
  title: 'This is a text analytics card',
  data: '47',
};

const StyledPaper = styled(Paper)`
  width: 100%;
  margin: 25px;
`;

const StyledTitle = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  text-align: left;
  font-size: 25px;
  padding: 10px;
  color: ${themeColors.mainColors.primaryColor};
`;

const StyledData = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  text-align: center;
  font-size: 35px;
  padding: 10px;
  color: ${themeColors.mainColors.secondaryColor};
`;
const StyledChanged = styled(Typography)`
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  text-align: right;
  font-size: 12px;
  padding: 10px;
`;
const StyledSpan = styled(Typography)<{ colorValue: number }>`
  color: ${({ colorValue }): string =>
    ` ${(colorValue > 0 && 'green') || themeColors.mainColors.danger}`};
  display: inline;
`;
const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

export const TextAnalyticsCard: React.FC<TextAnalyticsCardProps> = ({
  title,
  data,
  change,
  loading,
}) => {
  return (
    <StyledPaper>
      <StyledTitle variant="h1">{title}</StyledTitle>
      <StyledData variant="body1">
        {(loading && (
          <SpinnerContainer>
            <AtomSpinner color={themeColors.mainColors.primaryColor} />
          </SpinnerContainer>
        )) ||
          data}
      </StyledData>
      <StyledChanged variant="body1">
        Change since last period:
        <StyledSpan colorValue={change}> {change}</StyledSpan>
      </StyledChanged>
    </StyledPaper>
  );
};

TextAnalyticsCard.defaultProps = defaultProps;
