import React from 'react';
import styled from 'styled-components';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CheckoutForm, CheckoutFormProps } from './CheckoutForm';
import { boxShadows } from '../../../../constants/shadows/shadows';
import { useColors } from '../../../../hooks';

const pK = process.env.REACT_APP_STRIPE_PK;
const stripePromise = loadStripe(pK as string);

const StyledCardElement = styled.div<{
  borderColor: string;
  failureColor: string;
}>`
  .StripeElement {
    /* box-sizing: border-box; */
    /* width: 35vw;
    min-width: 175px; */
    /* max-width: 450px; */
    /* height: 40px; */

    padding: 20px 5px;
    margin: 0;

    border: 1px solid #b1b1b1;
    border-radius: 4px;
    /* background-color: white; */

    /* box-shadow: ${boxShadows.one}; */
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }

  .StripeElement:hover:not(:focus) {
    border: 1px solid black;
  }
  .StripeElement--focus {
    border: 2px solid ${({ borderColor }): string => borderColor};
    pointer-events: none;
  }
  .StripeElement--invalid {
    border-color: ${({ failureColor }): string => failureColor};
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const Payment: React.FC<CheckoutFormProps> = ({
  chargeAmount,
  customerId,
  paymentIntentId,
}) => {
  const { themeColors } = useColors();
  return (
    <StyledCardElement
      borderColor={themeColors.mainColors.primaryColor}
      failureColor={themeColors.mainColors.danger}
    >
      <Elements stripe={stripePromise}>
        <CheckoutForm
          chargeAmount={chargeAmount}
          customerId={customerId}
          paymentIntentId={paymentIntentId}
        />
      </Elements>
    </StyledCardElement>
  );
};
