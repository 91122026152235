/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import { ChromePicker } from 'react-color';
import { useCurrentAttorney, useUpdateProfile } from '../../../hooks';

const StyledContainer = styled.div`
  max-width: 900px;
  margin: 15px 15px;
  padding: 10px;
`;

const StyledAddressBlock = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  @media only screen and (max-width: 675px) {
    grid-template-columns: 1fr;
  }
`;

const StyledColorBlock = styled.div`
  margin-top: 15px;
`;

const StyledTypography = styled(Typography)`
  margin: 35px 0;
`;

export const UserSettings = () => {
  const { currentAttorney } = useCurrentAttorney();
  const { onSave } = useUpdateProfile();
  const [saveReady, setSaveReady] = useState({
    OfficeStreet: false,
    OfficeState: false,
    OfficeSuite: false,
    OfficeCity: false,
    OfficeZip: false,

    HomeStreet: false,
    HomeState: false,
    HomeSuite: false,
    HomeCity: false,
    HomeZip: false,

    Color: false,
  });
  const [newData, setNewData] = useState({
    OfficeStreet: '',
    OfficeState: '',
    OfficeSuite: '',
    OfficeCity: '',
    OfficeZip: '',

    HomeStreet: '',
    HomeState: '',
    HomeSuite: '',
    HomeCity: '',
    HomeZip: '',

    Color: '',
  });

  const handleChange = type => (e): void => {
    switch (type) {
      case 'OfficeStreet':
        setSaveReady({ ...saveReady, OfficeStreet: true });
        setNewData({ ...newData, OfficeStreet: e.target.value });
        break;
      case 'OfficeSuite':
        setSaveReady({ ...saveReady, OfficeSuite: true });
        setNewData({ ...newData, OfficeSuite: e.target.value });
        break;
      case 'OfficeCity':
        setSaveReady({ ...saveReady, OfficeCity: true });
        setNewData({ ...newData, OfficeCity: e.target.value });
        break;
      case 'OfficeState':
        setSaveReady({ ...saveReady, OfficeState: true });
        setNewData({ ...newData, OfficeState: e.target.value });
        break;
      case 'OfficeZip':
        setSaveReady({ ...saveReady, OfficeZip: true });
        setNewData({ ...newData, OfficeZip: e.target.value });
        break;
      case 'HomeStreet':
        setSaveReady({ ...saveReady, HomeStreet: true });
        setNewData({ ...newData, HomeStreet: e.target.value });
        break;
      case 'HomeSuite':
        setSaveReady({ ...saveReady, HomeSuite: true });
        setNewData({ ...newData, HomeSuite: e.target.value });
        break;
      case 'HomeCity':
        setSaveReady({ ...saveReady, HomeCity: true });
        setNewData({ ...newData, HomeCity: e.target.value });
        break;
      case 'HomeState':
        setSaveReady({ ...saveReady, HomeState: true });
        setNewData({ ...newData, HomeState: e.target.value });
        break;
      case 'HomeZip':
        setSaveReady({ ...saveReady, HomeZip: true });
        setNewData({ ...newData, HomeZip: e.target.value });
        break;
      case 'Color':
        setSaveReady({ ...saveReady, Color: true });
        setNewData({
          ...newData,
          Color: `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`,
        });
        break;
      default:
        break;
    }
  };

  const handleSave = (type): void => {
    switch (type) {
      case 'OfficeStreet':
        setSaveReady({ ...saveReady, OfficeStreet: false });
        onSave(
          'AttyProfileOfficeStreet',
          currentAttorney!.attorney_id,
          newData.OfficeStreet,
        );
        break;
      case 'OfficeSuite':
        setSaveReady({ ...saveReady, OfficeSuite: false });
        onSave(
          'AttyProfileOfficeSuite',
          currentAttorney!.attorney_id,
          newData.OfficeSuite,
        );
        break;
      case 'OfficeCity':
        setSaveReady({ ...saveReady, OfficeCity: false });
        onSave(
          'AttyProfileOfficeCity',
          currentAttorney!.attorney_id,
          newData.OfficeCity,
        );
        break;
      case 'OfficeState':
        setSaveReady({ ...saveReady, OfficeState: false });
        onSave(
          'AttyProfileOfficeState',
          currentAttorney!.attorney_id,
          newData.OfficeState,
        );
        break;
      case 'OfficeZip':
        setSaveReady({ ...saveReady, OfficeZip: false });
        onSave(
          'AttyProfileOfficeZip',
          currentAttorney!.attorney_id,
          newData.OfficeZip,
        );
        break;

      case 'HomeStreet':
        setSaveReady({ ...saveReady, HomeStreet: false });
        onSave(
          'AttyProfileHomeStreet',
          currentAttorney!.attorney_id,
          newData.HomeStreet,
        );
        break;
      case 'HomeSuite':
        setSaveReady({ ...saveReady, HomeSuite: false });
        onSave(
          'AttyProfileHomeSuite',
          currentAttorney!.attorney_id,
          newData.HomeSuite,
        );
        break;
      case 'HomeCity':
        setSaveReady({ ...saveReady, HomeCity: false });
        onSave(
          'AttyProfileHomeCity',
          currentAttorney!.attorney_id,
          newData.HomeCity,
        );
        break;
      case 'HomeState':
        setSaveReady({ ...saveReady, HomeState: false });
        onSave(
          'AttyProfileHomeState',
          currentAttorney!.attorney_id,
          newData.HomeState,
        );
        break;
      case 'HomeZip':
        setSaveReady({ ...saveReady, HomeZip: false });
        onSave(
          'AttyProfileHomeZip',
          currentAttorney!.attorney_id,
          newData.HomeZip,
        );
        break;
      case 'Color':
        setSaveReady({ ...saveReady, Color: false });
        onSave('AttyProfileColor', currentAttorney!.attorney_id, newData.Color);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setNewData({
      ...newData,
      OfficeStreet: currentAttorney?.attorney_office_street || '',
      OfficeSuite: currentAttorney?.attorney_office_suite || '',
      OfficeCity: currentAttorney?.attorney_office_city || '',
      OfficeState: currentAttorney?.attorney_office_state || '',
      OfficeZip: currentAttorney?.attorney_office_zip || '',

      HomeStreet: currentAttorney?.attorney_home_street || '',
      HomeCity: currentAttorney?.attorney_home_city || '',
      HomeState: currentAttorney?.attorney_home_state || '',
      HomeZip: currentAttorney?.attorney_home_zip || '',
      HomeSuite: currentAttorney?.attorney_home_suite || '',

      Color: currentAttorney?.attorney_id_color || '',
    });
  }, [currentAttorney]);

  return (
    <StyledContainer>
      <StyledTypography variant="h5">Office Address</StyledTypography>
      <StyledAddressBlock>
        <TextField
          variant="filled"
          label="Street"
          value={newData.OfficeStreet || ''}
          fullWidth
          onChange={handleChange('OfficeStreet')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.OfficeStreet &&
                  newData.OfficeStreet !==
                    currentAttorney?.attorney_office_street && (
                    <Button
                      onClick={(): void => handleSave('OfficeStreet')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="filled"
          label="Suite"
          value={newData.OfficeSuite || ''}
          fullWidth
          onChange={handleChange('OfficeSuite')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.OfficeSuite &&
                  newData.OfficeSuite !==
                    currentAttorney?.attorney_office_suite && (
                    <Button
                      onClick={(): void => handleSave('OfficeSuite')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />
      </StyledAddressBlock>
      <StyledAddressBlock>
        <TextField
          variant="filled"
          label="City"
          value={newData.OfficeCity || ''}
          fullWidth
          onChange={handleChange('OfficeCity')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.OfficeCity &&
                  newData.OfficeCity !==
                    currentAttorney?.attorney_office_city && (
                    <Button
                      onClick={(): void => handleSave('OfficeCity')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="filled"
          label="State"
          value={newData.OfficeState || ''}
          fullWidth
          onChange={handleChange('OfficeState')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.OfficeState &&
                  newData.OfficeState !==
                    currentAttorney?.attorney_office_state && (
                    <Button
                      onClick={(): void => handleSave('OfficeState')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="filled"
          label="Zip"
          value={newData.OfficeZip || ''}
          fullWidth
          onChange={handleChange('OfficeZip')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.OfficeZip &&
                  newData.OfficeZip !==
                    currentAttorney?.attorney_office_zip && (
                    <Button
                      onClick={(): void => handleSave('OfficeZip')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />
      </StyledAddressBlock>
      {/* Home Address */}
      <StyledTypography variant="h5">Home Address</StyledTypography>
      <StyledAddressBlock>
        <TextField
          variant="filled"
          label="Street"
          value={newData.HomeStreet || ''}
          fullWidth
          onChange={handleChange('HomeStreet')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.HomeStreet &&
                  newData.HomeStreet !==
                    currentAttorney?.attorney_home_street && (
                    <Button
                      onClick={(): void => handleSave('HomeStreet')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />
        <TextField
          variant="filled"
          label="Suite"
          value={newData.HomeSuite || ''}
          fullWidth
          onChange={handleChange('HomeSuite')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.HomeSuite &&
                  newData.HomeSuite !==
                    currentAttorney?.attorney_home_suite && (
                    <Button
                      onClick={(): void => handleSave('HomeSuite')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />
      </StyledAddressBlock>
      <StyledAddressBlock>
        <TextField
          variant="filled"
          label="City"
          value={newData.HomeCity || ''}
          fullWidth
          onChange={handleChange('HomeCity')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.HomeCity &&
                  newData.HomeCity !== currentAttorney?.attorney_home_city && (
                    <Button
                      onClick={(): void => handleSave('HomeCity')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="filled"
          label="State"
          value={newData.HomeState || ''}
          fullWidth
          onChange={handleChange('HomeState')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.HomeState &&
                  newData.HomeState !==
                    currentAttorney?.attorney_home_state && (
                    <Button
                      onClick={(): void => handleSave('HomeState')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />

        <TextField
          variant="filled"
          label="Zip"
          value={newData.HomeZip || ''}
          fullWidth
          onChange={handleChange('HomeZip')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {saveReady.HomeZip &&
                  newData.HomeZip !== currentAttorney?.attorney_home_zip && (
                    <Button
                      onClick={(): void => handleSave('HomeZip')}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
              </InputAdornment>
            ),
          }}
        />
      </StyledAddressBlock>
      <StyledTypography variant="h5">ID Color</StyledTypography>
      <StyledColorBlock>
        <ChromePicker
          color={newData.Color}
          onChange={handleChange('Color')}
          disableAlpha
        />
        {saveReady.Color &&
          newData.Color !== currentAttorney?.attorney_id_color && (
            <>
              <Button
                onClick={(): void => handleSave('Color')}
                variant="outlined"
                color="primary"
                style={{ marginTop: 10 }}
              >
                Save
              </Button>
            </>
          )}
      </StyledColorBlock>
    </StyledContainer>
  );
};
