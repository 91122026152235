import React, { useContext, useEffect, useState } from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { CaseCard } from '../../components';
import { getClientInfo } from '../../components/queries/clients';
import { UserContext } from '../../context';
import { useColors } from '../../hooks';

export const ClientDashboard: React.FC = () => {
  const { themeColors } = useColors();
  const { state } = useContext(UserContext);
  const { user, isAuthenticated } = useAuth0();
  const [clientData, setClientData] = useState<any>(null);
  const { loading, data } = useQuery(getClientInfo, {
    variables: { client_id: user?.email },
  });
  useEffect(() => {
    setClientData(data);
  }, [data, loading, clientData]);
  if (loading || !isAuthenticated) {
    return <LinearProgress />;
  }
  const StyledTitle = styled(Typography)`
    color: ${themeColors.mainColors.primaryColor};
    margin: 15px 0;
  `;

  return (
    <>
      <StyledTitle variant="h4">My Cases</StyledTitle>
      {(clientData?.client[0]?.matters.length !== 0 &&
        clientData?.client[0]?.matters?.map(
          ({
            matter_appealed,
            matter_caption,
            matter_closed,
            matter_court_case_number,
            matter_court_id,
            matter_details,
            matter_id,
            matter_name,
            matter_opened_date,
            matter_value,
            matter_stage,
            matter_disposition_date,
            op_firm_id,
            op_counsel_id,
            attorney_owner_id,
            matter_result,
            owning_attorney,
            stage,
          }) => (
            <CaseCard
              key={matter_id}
              matter_id={matter_id}
              matter_name={matter_name}
              matter_caption={matter_caption}
              matter_opened_date={matter_opened_date}
              matter_closed={matter_closed}
              matter_stage={matter_stage}
              matter_value={matter_value}
              matter_details={matter_details}
              matter_disposition_date={matter_disposition_date}
              matter_appealed={matter_appealed}
              matter_court_case_number={matter_court_case_number}
              matter_court_id={matter_court_id}
              op_firm_id={op_firm_id}
              op_counsel_id={op_counsel_id}
              attorney_owner_id={attorney_owner_id}
              matter_result={matter_result}
              owning_attorney={owning_attorney}
              stage={stage}
            />
          ),
        )) ||
        'you have no matters currently available'}
    </>
  );
};
