import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
  TablePagination,
} from '@material-ui/core';
import { useSubscription } from '@apollo/client';
import { useColors } from '../../hooks';
import GET_MESSAGES from '../queries/messages/getMessagesSubscribe';
import { TableLoader } from '../stories/2-Placeholders.stories';
import history from '../../history';

const { themeColors } = useColors();

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
    color: `${themeColors.mainColors.primaryColor}`,
    borderRadius: 10,
  },
  table: {
    color: 'white',
  },
  tableHeader: {
    color: 'white',
    textAlign: 'center',
    backgroundColor: themeColors.mainColors.primaryColor,
  },
});

export interface MessageTableProps {
  title?: string;
  borderTop?: string;
  pagination?: boolean;
  denseColumns?: boolean;
  denseControl?: boolean;
  searchTerm?: string;
}

export const MessageTable: React.FC<MessageTableProps> = ({
  title,
  borderTop,
  pagination,
  denseColumns,
  denseControl,
  searchTerm,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(denseColumns || false);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('messageRowsPerPage') as string, 10) || 5,
  );
  useEffect(() => {
    if (localStorage.getItem('messageTableDense') === 'dense') {
      setDense(true);
    }
    if (localStorage.getItem('messageTableDense') === 'notDense') {
      setDense(false);
    }
    localStorage.setItem('messageRowsPerPage', rowsPerPage.toString());
  }, []);

  const handleChangeDense = (event: {
    target: { checked: React.SetStateAction<boolean> };
  }): void => {
    setDense(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem('messageTableDense', 'dense');
    }
    if (!event.target.checked) {
      localStorage.setItem('messageTableDense', 'notDense');
    }
  };

  const handleChangePage = (
    _event: unknown,
    newPage: React.SetStateAction<number>,
  ): void => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: {
    target: { value: string };
  }): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('messageRowsPerPage', event.target.value);
    setPage(0);
  };
  const table = {
    color: 'white',
  };
  const { loading, error, data } = useSubscription(GET_MESSAGES, {
    variables: { searchTerm },
  });
  if (loading) {
    return <TableLoader />;
  }
  if (error) {
    return <div>there has been an error</div>;
  }
  return (
    <Paper className={classes.root}>
      <div style={{ borderTop: borderTop || '' }}>
        <Typography variant="h6" className={classes.tableHeader}>
          {title || 'New Messages'}
        </Typography>
        {pagination ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
            component="div"
            count={data.message.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ float: 'right' }}
          />
        ) : null}
        {denseControl ? (
          <div
            style={{
              float: 'left',
              marginBottom: -10,
              marginTop: 7,
              marginLeft: 15,
            }}
          >
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </div>
        ) : null}
      </div>
      <Table
        style={table}
        aria-label="simple table"
        size={dense ? 'small' : 'medium'}
      >
        <TableHead>
          <TableRow
            style={{
              borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
            }}
          >
            <TableCell align="left">
              <Typography color="secondary">Name</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Email</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Sent On</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Sent From</Typography>
            </TableCell>
            <TableCell align="left">
              <Typography color="secondary">Excerpt</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.message
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(
              ({
                message_body,
                message_id,
                message_response_sent,
                message_contact_firstName,
                message_contact_lastName,
                message_email,
                message_sent_from,
                message_created_at,
              }) => (
                <TableRow
                  key={message_id}
                  hover
                  onClick={(): void => history.push(`/messages/${message_id}`)}
                  style={{ cursor: 'pointer' }}
                >
                  {// get messages marked as "not returned"
                  !message_response_sent && (
                    <>
                      <TableCell component="th" scope="row">
                        {`${message_contact_firstName} ${message_contact_lastName}`}
                      </TableCell>
                      <TableCell align="left">{message_email}</TableCell>
                      <TableCell align="left">{message_created_at}</TableCell>
                      <TableCell align="left">{message_sent_from}</TableCell>
                      <TableCell align="left">
                        {message_body.substring(0, 100)}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              ),
            )}
        </TableBody>
      </Table>
      {pagination && (
        <>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 150, 200]}
            component="div"
            count={data.message.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            style={{ float: 'right' }}
          />
          <div
            style={{
              borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
            }}
          />
        </>
      )}
    </Paper>
  );
};
