import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@apollo/client';
import { CreateCaseStepper } from './createCaseStepper';
import { CLIENTS_FIRMS_COURTS } from '../../../components/queries/CLIENTS_FIRMS_COURTS';

export const CreateCase = () => {
  // Client Details
  const [client, setClient] = useState({
    clientID: null,
    DOB: null,
    firstName: null,
    lastName: null,
    middleName: null,
    cellPhone: null,
    email: null,
    homePhone: null,
    streetAddress: null,
    city: null,
    state: null,
    zip: null,
    zip4: null,
    ssn: null,
  });

  // Court Details
  const [court, setCourt] = useState({
    courtID: null,
    courtName: null,
    county: null,
    streetAddress: null,
    streetAddress2: null,
    city: null,
    state: null,
    zip: null,
    zip4: null,
    type: null,
    phone: null,
    fax: null,
    efile: null,
    mdjNumber: null,
  });

  // Op Firm
  const [firm, setFirm] = useState({
    firmID: null,
    name: null,
    streetAddress: null,
    streetAddress2: null,
    city: null,
    state: null,
    zip: null,
    zip4: null,
    phone: null,
    fax: null,
    email: null,
  });
  // Op Party
  const [opParty, setOpParty] = useState({
    partyID: null,
    name: null,
    streetAddress: null,
    streetAddress2: null,
    city: null,
    state: null,
    zip: null,
    zip4: null,
    phone: null,
    fax: null,
    email: null,
  });
  // Op Counsel
  const [, setOpcounsel] = useState({
    ID: null,
    fullName: null,
    firmID: null,
  });
  const [matter, setMatter] = useState({
    matterClientID: null,
    matterDetails: null,
    matterCaption: null,
    matterTypeId: null,
    matterCourtCaseNumber: null,
    matterInternalCaseNumber: null,
    matterCourtID: null,
    matterName: null,
    attorneyOwnerID: null,
    opFirmID: null,
    opCounselID: null,
    opPartyID: null,
  });
  const { user } = useAuth0();
  const { loading, error, data } = useQuery(CLIENTS_FIRMS_COURTS);
  if (loading) {
    return (
      <div>
        <div style={{ height: 50 }} />
        ...loading
      </div>
    );
  }
  if (error) {
    return <div>there has been an error</div>;
  }
  const clientList = data.client;
  const courtList = data.court;
  const opPartyList = data.op_party;
  const firmList = data.op_firm;
  const matterTypeList = data.matter_type;
  const userID = user.sub;

  return (
    <>
      <CreateCaseStepper
        clientList={clientList}
        courtList={courtList}
        firmList={firmList}
        userID={userID}
        setOpcounsel={setOpcounsel}
        setFirm={setFirm}
        setCourt={setCourt}
        setClient={setClient}
        client={client}
        court={court}
        firm={firm}
        opPartyList={opPartyList}
        setOpParty={setOpParty}
        opParty={opParty}
        matter={matter}
        setMatter={setMatter}
        matterTypeList={matterTypeList}
      />
    </>
  );
};
