import React, { useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Dialog } from '@material-ui/core';
import { useColors } from '../../../../../hooks';
import { CheckoutForm } from './checkoutForm';

const pK = process.env.REACT_APP_STRIPE_PK;
const baseServerUrl = process.env.REACT_APP_SERVER_URL;

const stripePromise = loadStripe(pK as string);

interface ClientBillingProps {
  client_firstName: string;
  client_lastName: string | null;
  client_email: string;
  client_phone_cell: string | null;
  client_address_city: string | null;
  client_address_street: string | null;
  client_address_state: string | null;
  client_address_zip: number | null;
  client_stripe_id: string | null;
}

interface PaymentFormProps {
  chargeAmount: number;
  invoiceId: string;
  toggle: (
    amt?: number,
    clientData?: ClientBillingProps,
    id?: number,
    t?: boolean,
  ) => void;
  open: boolean;
  client: ClientBillingProps;
}

const StyledCardElement = styled.div<{
  borderColor: string;
  failureColor: string;
}>`
  width: 100%;
  .StripeElement {
    padding: 20px 5px;
    margin: 0;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement:hover:not(:focus) {
    border: 1px solid black;
  }
  .StripeElement--focus {
    border: 2px solid ${({ borderColor }): string => borderColor};
    pointer-events: none;
  }
  .StripeElement--invalid {
    border-color: ${({ failureColor }): string => failureColor};
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export const PaymentModal: React.FC<PaymentFormProps> = ({
  open,
  toggle,
  chargeAmount,
  invoiceId,
  client,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [clientSecret, setClientSecret] = React.useState();

  useEffect(() => {
    axios
      .post(`${baseServerUrl}/client-secret`, {
        customer: client?.client_stripe_id,
        amount: chargeAmount,
        invoiceId,
        // paymentIntentId,
      })
      .then(d => setClientSecret(d.data.client_secret))
      .finally(() => setLoading(false));
  }, []);
  const { themeColors } = useColors();
  return (
    <>
      <Dialog open={open} onClose={toggle as any} fullWidth>
        {!loading &&
          ((
            <StyledCardElement
              borderColor={themeColors.mainColors.primaryColor}
              failureColor={themeColors.mainColors.danger}
            >
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  clientSecret={clientSecret!}
                  chargeAmount={chargeAmount}
                  toggle={toggle}
                  open={open}
                  client={client}
                />
              </Elements>
            </StyledCardElement>
          ) ||
            '..loading')}
      </Dialog>
    </>
  );
};
