import React, { useEffect, useState } from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from '@material-ui/lab';
import { PhoneTwoTone, EmailTwoTone, SmsTwoTone } from '@material-ui/icons';
import { useQuery, gql } from '@apollo/client';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import { boxShadows } from '../../constants';
import history from '../../history';
import { BILLING } from '../../components/InvoiceList';

const Profile = gql`
  query ClientProfile($id: uuid) {
    client(where: { client_id: { _eq: $id } }) {
      client_address_city
      client_address_state
      client_address_street
      client_address_zip
      client_address_zip_4
      client_created_date
      client_email
      client_firstName
      client_lastName
      client_id
      client_login_id
      client_middleName
      client_phone_cell
      client_phone_home
      client_ssn_last_four
      client_stripe_id
      matters {
        attorney_owner_id
        matter_name
        matter_closed
        matter_id
        owning_attorney {
          attorney_id
          attorney_name
        }
        stage {
          stage_name
        }
      }
    }
  }
`;

interface ClientProfile {
  match: any;
  client_id: any;
}

const StyledPaper = styled(Paper)`
  border-radius: 4px;
  min-height: 300px;
  padding: 15px;
`;

const InfoRow = styled.div`
  display: grid;
  grid-row-gap: 25px;
  grid-template-columns: 150px 1fr;
  text-align: left;
`;
const SectionContainer = styled.div`
  padding: 25px;
`;
const MatterCard = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 15px 0;
  min-width: 280px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
    /* transform: scale(1.02); */
  }
`;
const MatterCardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;
const TimelineContainer = styled.div`
  max-width: 1200px;
  margin: auto;
`;
const TimelineCard = styled(Paper)`
  padding: 15px;
  box-shadow: ${boxShadows.three};
`;
const SfButton = styled.div`
  margin: 35px 0 35px;
`;
const Loader = (): string => {
  return 'loading...';
};
export const ClientProfile: React.FC<ClientProfile> = props => {
  // match.params.profileID

  const { match, client_id } = props;

  const finalID = (): any => {
    if (client_id) {
      return client_id;
    }
    return match.params.clientID;
  };
  const { loading, error, data } = useQuery(Profile, {
    variables: { id: finalID() },
  });
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;
  const [comm, setComm] = useState<any>([]);
  const [sfId, setSfId] = useState<string | null>();
  const [loadingComm, setLoadingComm] = useState(true);
  const client = data?.client?.[0];
  // const { invoices, loadingInvoices } = useGetClientInvoices({ client });
  const getCommunications = async (): Promise<void> => {
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    };
    const body = { id: client?.client_id, phone: client?.client_phone_cell };
    const url = `${baseServerUrl}/get-client-communications`;
    await axios.post(url, body, options).then(d => {
      setComm(d?.data?.ClientInfo?.records?.[0]?.ActivityHistories?.records);
      setComm(prevState => prevState && [...prevState, ...d?.data?.SMSHistory]);
      setSfId(d?.data?.ClientInfo?.records?.[0]?.Id);
    });
    // .then(d => setInvoices(d.data.data))
    // .then(() => setLoadingInvoices(false));
  };
  useEffect(() => {
    if (data?.client?.[0]) {
      getCommunications();
      setLoadingComm(false);
    }
  }, [data?.client?.[0]]);
  if (!loadingComm && comm) {
    // console.log(comm);
  }
  const main = () => {
    const communications = comm?.sort(
      (a, b) =>
        (moment(b.ActivityDate).format('YYYY-MM-DD') >
          moment(a.ActivityDate).format('YYYY-MM-DD') &&
          1) ||
        -1,
    );
    return (
      <>
        <SectionContainer>
          <Typography variant="h5">
            {(!loading &&
              `${client?.client_firstName} ${(client?.middleName &&
                client?.middleName) ||
                ''}${(client?.client_lastName && client?.client_lastName) ||
                ''}`) ||
              Loader()}
          </Typography>
          <br />
          <InfoRow>
            <Typography variant="body2" style={{ color: 'lightgray' }}>
              Phone:
            </Typography>
            <Typography variant="body2">
              {(client?.client_phone_cell && client?.client_phone_cell) || '--'}
            </Typography>
          </InfoRow>
          <InfoRow>
            <Typography variant="body2" style={{ color: 'lightgray' }}>
              Email:
            </Typography>
            <Typography variant="body2">
              {(client?.client_email && client?.client_email) || '--'}
            </Typography>
          </InfoRow>
          <InfoRow>
            <Typography variant="body2" style={{ color: 'lightgray' }}>
              Added:
            </Typography>
            <Typography variant="body2">
              {moment(client?.client_created_date).format('MMM Do, YYYY')}
            </Typography>
          </InfoRow>
          <SfButton>
            <Button
              href={`https://starkslaw.lightning.force.com/lightning/r/Contact/${sfId}/view`}
              target="_blank"
              variant="outlined"
              color="primary"
            >
              Salesforce Profile
            </Button>
          </SfButton>
          <SfButton>
            <Button
              href={`https://dashboard.stripe.com/customers/${client?.client_stripe_id}`}
              target="_blank"
              variant="outlined"
              color="primary"
            >
              Stripe Profile
            </Button>
          </SfButton>
        </SectionContainer>
        <SectionContainer>
          <Typography variant="h5">Matters</Typography>
          <br />
          {client?.matters?.map(
            ({ matter_name, matter_id, matter_closed, stage }) => {
              return (
                <MatterCard
                  key={matter_id}
                  onClick={(): void => history.push(`/cases/${matter_id}`)}
                >
                  <MatterCardContentContainer>
                    <Typography
                      variant="subtitle2"
                      style={{ fontSize: 12, padding: '0 25px' }}
                    >
                      {matter_name}
                    </Typography>
                    <EndPiece>
                      <Typography variant="subtitle2">
                        {(!matter_closed && 'Open') || 'Closed'}
                      </Typography>
                    </EndPiece>
                  </MatterCardContentContainer>
                </MatterCard>
              );
            },
          )}
        </SectionContainer>
        <SectionContainer>
          <Typography variant="h5">Billing</Typography>
          <br />
          <BILLING client={client} />
        </SectionContainer>
        <SectionContainer>
          <Typography variant="h5">Communication History</Typography>
          <TimelineContainer>
            <Timeline align="alternate">
              {communications?.map(
                ({
                  ActivityDate,
                  ActivitySubtype,
                  ActivityType,
                  CallDisposition,
                  CallDurationInSeconds,
                  CallObject,
                  CallType,
                  Description,
                  Location,
                  OwnerId,
                  Status,
                  Subject,
                }) => {
                  return (
                    <>
                      <TimelineItem>
                        <TimelineOppositeContent>
                          <Typography variant="body2" color="textSecondary">
                            {moment(ActivityDate).format('MMM DD, YYYY')}
                          </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot>
                            {ActivitySubtype === 'Call' && (
                              <PhoneTwoTone color="primary" />
                            )}
                            {ActivitySubtype === 'Email' && (
                              <EmailTwoTone color="primary" />
                            )}
                            {ActivitySubtype === 'SMS' && (
                              <SmsTwoTone color="primary" />
                            )}
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <TimelineCard>
                            <Typography variant="h6" component="h1">
                              {ActivitySubtype === 'Call' &&
                                `${CallType ??
                                  (CallType || '')} ${ActivitySubtype}`}
                              {ActivitySubtype === 'SMS' &&
                                `${CallType} ${ActivitySubtype}`}
                              {ActivitySubtype !== 'SMS' &&
                                ActivitySubtype !== 'Call' &&
                                ActivitySubtype}
                            </Typography>
                            <Typography variant="body2" align="justify">
                              {Description}
                            </Typography>
                            {ActivitySubtype === 'Call' && (
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                              >
                                Call Duration:{' '}
                                {moment
                                  .duration({
                                    seconds: CallDurationInSeconds,
                                  })
                                  .humanize()}
                              </Typography>
                            )}
                          </TimelineCard>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  );
                },
              ) || (
                <>
                  <TimelineItem>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="textSecondary">
                        N/A
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <TimelineCard>
                        <Typography variant="h6" component="h1">
                          No Data
                        </Typography>
                        <Typography variant="body2" align="justify">
                          No Communications founds
                        </Typography>
                      </TimelineCard>
                    </TimelineContent>
                  </TimelineItem>
                </>
              )}
            </Timeline>
          </TimelineContainer>
        </SectionContainer>
      </>
    );
  };
  return <>{(!client_id && <StyledPaper>{main()}</StyledPaper>) || main()}</>;
};
