import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { LoadingButton, ConfirmationPopper } from '..';

type ModalProps = {
  open: boolean;
  title: string;
  // Will only show if text is provided.
  primaryButtonText?: string;
  // Will only show if text is provided.
  secondaryButtonText?: string;
  primaryLoading?: boolean;
  includeCancelConfirmation?: boolean;
  onPrimaryClick?: (e: React.MouseEvent) => void;
  onSecondaryClick?: (e: React.MouseEvent) => void;
  onClose: () => void;
  children: React.ReactElement;
};

const StyledButton = styled(LoadingButton)`
  font-weight: bold;
`;

const StyledPopperWrapper = styled.span`
  position: relative;
`;

const Modal: React.FC<ModalProps> = ({
  open,
  title,
  primaryButtonText,
  secondaryButtonText,
  primaryLoading,
  includeCancelConfirmation,
  onPrimaryClick,
  onSecondaryClick,
  onClose,
  children,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      disableBackdropClick
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {!!secondaryButtonText && !!onSecondaryClick && (
          // swapped colors because primary color is dull (better for secondary button)
          <StyledPopperWrapper>
            <StyledButton
              onClick={(e): void => {
                if (includeCancelConfirmation) {
                  setShowConfirmation(true);
                } else {
                  onSecondaryClick(e);
                }
              }}
              color="primary"
            >
              {secondaryButtonText}
            </StyledButton>
            <ConfirmationPopper
              in={showConfirmation}
              message="Confirm Cancel:"
              top={-50}
              right={10}
              width={210}
              onCancelClick={(): void => setShowConfirmation(false)}
              onConfirmClick={(e): void => {
                setShowConfirmation(false);
                onSecondaryClick(e);
              }}
            />
          </StyledPopperWrapper>
        )}
        {!!primaryButtonText && !!onPrimaryClick && (
          // swapped colors because secondary color is brighter (better for primary button)
          <StyledButton
            onClick={onPrimaryClick}
            color="secondary"
            loading={primaryLoading}
          >
            {primaryButtonText}
          </StyledButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export { Modal };
