import React from 'react';
import styled from 'styled-components';
import { BaseModifiers } from './BaseModifiers';
import { DELETE_EVENT } from '../mutations';

type EventModifierProps = {
  eventId: string;
  onViewClick: (e: React.MouseEvent) => void;
  onEditClick: (e: React.MouseEvent) => void;
};

const StyledWrapper = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const EventModifiers: React.FC<EventModifierProps> = ({
  eventId,
  onViewClick,
  onEditClick,
}) => (
  <StyledWrapper>
    <BaseModifiers
      type="Event"
      iconSize="md"
      deleteMutation={DELETE_EVENT}
      deleteVariablesObj={{ event_id: eventId }}
      onViewClick={onViewClick}
      onEditClick={onEditClick}
    />
  </StyledWrapper>
);

export { EventModifiers };
