import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { BaseWorkflowTaskModal } from './BaseWorkflowTaskModal';
import { ADD_WORKFLOW_TASK } from '../../mutations';
import { Workflow, EditableWorkflowTask } from '../../../utils';

type AddWorkflowTaskModalProps = {
  open: boolean;
  workflow: Workflow;
  onClose: () => void;
};

const AddWorkflowTaskModal: React.FC<AddWorkflowTaskModalProps> = ({
  open,
  workflow,
  onClose,
}) => {
  const [formError, setFormError] = useState('');
  const [addWorkflowTask, { data, loading, error }] = useMutation(
    ADD_WORKFLOW_TASK,
  );

  useEffect(() => {
    if (!loading && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = (taskState: EditableWorkflowTask): void => {
    setFormError('');
    addWorkflowTask({
      variables: {
        workflow_task_workflow_id: workflow.workflow_id,
        workflow_task_name: taskState.workflow_task_name,
        workflow_task_description: taskState.workflow_task_description,
        workflow_task_dynamic_due_from:
          taskState.workflow_task_dynamic_due_from,
        workflow_task_dynamic_lock: taskState.workflow_task_dynamic_lock,
        trigger_workflow_id: taskState.trigger_workflow_id || null,
        task_is_priority: taskState.task_is_priority,
      },
    });
  };

  return (
    <BaseWorkflowTaskModal
      open={open}
      title="Create Workflow Task"
      formError={formError}
      onSave={onSave}
      onClose={onClose}
      loading={loading}
    />
  );
};

export { AddWorkflowTaskModal };
