import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import {
  LinearProgress,
  Paper,
  Typography,
  Chip,
  Tooltip,
  Fade,
  Collapse,
} from '@material-ui/core';
import {
  Download,
  Cancel,
  Check,
  Link as LinkIcon,
  SendMessage,
  Delete,
} from '../../../../components/stories/SLICONS.stories';
import { boxShadows } from '../../../../constants';
import { useColors } from '../../../../hooks';

const InvoiceContainer = styled.div`
  /* width: 90vw; */
  max-width: 1200px;
  margin: 35px 5px;
  display: grid;
  grid-gap: 1rem;
`;
const Invoice = styled(Paper)<{ mode: string }>`
  display: grid;
  grid-template-columns: 30px auto 90px 90px;
  overflow: hidden;
  box-shadow: ${({ mode }): string =>
    (mode === 'dark' && boxShadows.three) || boxShadows.one};
`;
const INumber = styled.div`
  margin: 10px;
`;
const InvoiceRow = styled.div`
  margin: 5px 15px;
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  text-align: left;
`;
const StyledChip = styled(Chip)<{ chipColor }>`
  color: ${({ chipColor }): string => chipColor};
  border: 1px solid ${({ chipColor }): string => chipColor};
  margin: 10px 5px 10px auto;
  align-self: 'flex-end';
`;

const EndButtonContainer = styled.div<{ bg: string }>`
  background-color: ${({ bg }): string => bg};
  position: relative;
  cursor: pointer;
  /* :last-child {
    border-radius: 0 4px 4px 0;
  } */
`;
const EndButton = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
`;
const Clickables = styled.div`
  max-width: 300px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: 0 0 10px 0;
`;
const InvoiceTypeTab = styled.div<{ tabColor: string }>`
  color: #fff;
  background-color: ${({ tabColor }) => tabColor};
  overflow: hidden;
  position: relative;
  h5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
`;

const chipColor: React.FC<string> = (
  status?: string | null,
  themeColors?: any,
) => {
  let chipStatusColor;
  if (status?.toLowerCase() === 'paid') {
    chipStatusColor = themeColors.mainColors.success;
  }
  if (status?.toLowerCase() === 'open') {
    chipStatusColor = themeColors.mainColors.primaryColor;
  }
  if (status?.toLowerCase() === 'due') {
    chipStatusColor = themeColors.mainColors.warning;
  }
  if (status?.toLowerCase() === 'overdue') {
    chipStatusColor = themeColors.mainColors.danger;
  }
  if (status?.toLowerCase() === 'none') {
    chipStatusColor = themeColors.mainColors.primaryColor;
  }
  return chipStatusColor;
};

export const CaseInvoiceList = ({
  loading,
  invoices,
  matter,
  filterByCase = false,
  filterVoided = false,
  voided,
  payOrSend,
  deleteOrVoid,
}): any => {
  const { themeColors } = useColors();
  return (
    <>
      <InvoiceContainer>
        {(!loading &&
          (
            (filterVoided &&
              filterByCase &&
              invoices
                ?.filter(i => i?.status !== 'void')
                .filter(i => i?.metadata?.sf_op_id === matter.sf_op_id)) ||
            (!filterVoided &&
              filterByCase &&
              invoices?.filter(
                i => i?.metadata?.sf_op_id === matter.sf_op_id,
              )) ||
            (filterVoided &&
              !filterByCase &&
              invoices?.filter(i => i?.status !== 'void')) ||
            invoices
          )?.map(
            (
              {
                id,
                amount_due,
                status,
                amount_remaining,
                hosted_invoice_url,
                metadata,
                due_date,
                invoice_pdf,
                number,
              },
              index,
            ) => (
              <>
                <Collapse
                  in={!voided[id]}
                  timeout={1000}
                  unmountOnExit
                  key={id.toString()}
                >
                  <Fade in={!voided[id]} timeout={1000} unmountOnExit>
                    <Invoice mode={localStorage.getItem('theme_mode') as any}>
                      <InvoiceTypeTab
                        tabColor={themeColors.mainColors.primaryColor}
                      >
                        <Typography variant="h5">
                          {(metadata?.initial_payment === 'True' &&
                            'Initial') ||
                            'Monthly'}
                        </Typography>
                      </InvoiceTypeTab>
                      <div>
                        <InvoiceRow>
                          <INumber>
                            <Typography variant="h6" component="div">
                              {number}
                            </Typography>
                          </INumber>
                          {status !== 'paid' && (
                            <StyledChip
                              label={status}
                              variant="outlined"
                              chipColor={chipColor(status, themeColors)}
                            />
                          )}
                        </InvoiceRow>
                        <InvoiceRow>
                          <Typography variant="body2" component="div">
                            Invoice Total: $
                            {(amount_due / 100)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </Typography>
                          <Typography variant="subtitle2" component="div">
                            Due:{' '}
                            <strong>
                              {moment.unix(due_date).format('MMM DD, YYYY')}
                            </strong>
                          </Typography>
                        </InvoiceRow>
                        <InvoiceRow>
                          <Typography variant="subtitle2" component="div">
                            Remaining Due:
                            <strong>
                              {' '}
                              $
                              {(amount_remaining / 100)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </strong>
                          </Typography>
                          <Clickables>
                            <Typography variant="body1" component="div">
                              <Chip
                                component="a"
                                href={invoice_pdf}
                                clickable
                                avatar={<Download width={8} fill="black" />}
                                variant="outlined"
                                label="Invoice"
                              />
                            </Typography>
                            <Typography variant="body1" component="div">
                              <Chip
                                component={'a' as any}
                                target="_blank"
                                href={hosted_invoice_url}
                                clickable
                                avatar={<LinkIcon width={8} fill="black" />}
                                variant="outlined"
                                label="Checkout"
                              />
                            </Typography>
                          </Clickables>
                        </InvoiceRow>
                      </div>
                      {(status !== 'paid' && (
                        <EndButtonContainer
                          bg="#FF6961"
                          onClick={(): void => deleteOrVoid(status, id) as any}
                        >
                          <EndButton>
                            {(status === 'draft' && (
                              <Delete width={24} fill="#fff" />
                            )) || <Cancel width={24} fill="#fff" />}
                            <Typography variant="body2">
                              {(status === 'draft' && 'Delete') || 'Void'}
                            </Typography>
                          </EndButton>
                        </EndButtonContainer>
                      )) || <EndButtonContainer bg="ffffff00" />}
                      <Tooltip
                        title={
                          (status === 'draft' &&
                            'Finalize the invoice and send to the client') ||
                          (status !== 'paid' &&
                            'Mark the invoice as paid. Use this option if you collected payment outside of Stripe') ||
                          'Invoice Paid'
                        }
                      >
                        <EndButtonContainer
                          bg={
                            (status !== 'paid' && '#071833') ||
                            themeColors.mainColors.success
                          }
                          onClick={(): void =>
                            status !== 'paid' && (payOrSend(status, id) as any)
                          }
                        >
                          <EndButton>
                            {(status === 'draft' && (
                              <SendMessage width={24} fill="#fff" />
                            )) ||
                              (status !== 'paid' && (
                                <Check width={24} fill="#fff" />
                              ))}
                            {(status === 'paid' && 'Paid') || (
                              <Typography variant="body2">
                                {(status === 'draft' && 'Send Invoice') ||
                                  'Mark Paid'}
                              </Typography>
                            )}
                          </EndButton>
                        </EndButtonContainer>
                      </Tooltip>
                    </Invoice>
                  </Fade>
                </Collapse>
              </>
            ),
          )) || <LinearProgress />}
      </InvoiceContainer>
    </>
  );
};
