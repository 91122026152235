import { gql } from '@apollo/client';

export const CLIENTS_FIRMS_COURTS = gql`
  query CLIENTS_FIRMS_COURTS {
    client {
      client_firstName
      client_lastName
      client_id
    }
    court {
      court_id
      court_name
    }
    op_firm {
      op_firm_id
      op_firm_name
      op_counsels {
        op_counsel_id
        op_counsel_name
      }
    }
    op_party {
      op_party_name
      op_party_id
    }
    matter_type {
      matter_type_id
      matter_type_name_full
      matter_type_description
      matter_type_name_short
    }
  }
`;
