/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@material-ui/core';
import {
  WarningTwoTone,
  NoteTwoTone,
  PhoneAndroidTwoTone,
  RemoveRedEyeTwoTone,
} from '@material-ui/icons';
import { themeBorders, boxShadows } from '../../../constants';
import { PencilOutline } from '../../../components/stories/SLICONS.stories';
import { useColors } from '../../../hooks';
import { GetCourts, GetJudges } from '../../../components/queries';
import { UpdateModal } from '../CaseUpdate';
import { JudgeProfileDialog } from '../../settings/profiles/sections/judge/judgeProfileDialog';

const InfoSection = {
  borderLeft: themeBorders.doubleLeft,
  minHeight: 150,
  width: '95%',
  margin: 'auto',
  marginTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 0,
  boxShadow: boxShadows.three,
};

const PencilButton = color => {
  return <PencilOutline width={18} fill={color} />;
};

const StyledIconButtonInline = styled(IconButton)`
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

const StyledIconButton = styled(IconButton)`
  position: relative;
  float: right;
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 7px -20px 0 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

const StyledPhoneIconButton = styled(IconButton)`
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;
const StyledPhoneAndroidTwoTone = styled(PhoneAndroidTwoTone)`
  color: ${props => props.c};
`;
const StyledWarn = styled(WarningTwoTone)`
  color: ${props => props.c};
`;
const StyledJudgeProfile = styled(RemoveRedEyeTwoTone)`
  color: ${props => props.c};
  cursor: pointer;
`;
const StyledNote = styled(NoteTwoTone)`
  cursor: pointer;
`;

export const COURT = props => {
  const [openJudgeProfile, setOpenJudgeProfile] = useState(false);
  const handleOpenJudgeProfile = () => {
    setOpenJudgeProfile(true);
  };
  const handleCloseJudgeProfile = () => {
    setOpenJudgeProfile(false);
  };
  const { themeColors } = useColors();
  const mode = localStorage.getItem('theme_mode');
  const { data: courtData } = useQuery(GetCourts);
  const { data: judgeData } = useQuery(GetJudges);

  // new edit logic

  const { co, readOnly, titleVariant, cardTitleStyle, c, judge } = props;
  const tableType = 'update_matter';
  const filterTableBy = 'matter_id';
  const [openEdit, setOpenEdit] = useState(false);
  const [recordType, setRecordType] = useState();
  const [title, setTitle] = useState();
  const [isMenu, setIsMenu] = useState(false);
  const [menuData, setMenuData] = useState();
  const [text, setText] = useState();
  const handleModal = useCallback((r, t, m, d, w) => {
    setTitle(t);
    setRecordType(r);
    setIsMenu(m);
    setMenuData(d);
    setText(w);
    setOpenEdit(!openEdit);
  }, []);
  const Courts = useCallback(
    courtData?.court?.map(({ court_id, court_name }) => {
      return { label: court_name, value: court_id };
    }),
    [courtData],
  );
  const Judges = useCallback(
    judgeData?.judge
      ?.slice()
      .sort((a, b) =>
        a.judge_last_name?.trim().localeCompare(b.judge_last_name?.trim()),
      )
      .map(
        ({
          judge_id,
          judge_first_name,
          judge_middle_name,
          judge_last_name,
        }) => {
          return {
            label:
              (['Arbitration Panel', 'Applicable'].includes(
                judge_last_name || judge_first_name,
              ) &&
                `${judge_first_name} ${judge_middle_name ||
                  ''} ${judge_last_name || ''}`.replace(/\s{2,}/g, ' ')) ||
              `${judge_last_name}, ${judge_middle_name ||
                ''} ${judge_first_name || ''}`.replace(/\s{2,}/g, ' '),
            value: judge_id,
          };
        },
      ),
    [judgeData],
  );

  return (
    <>
      <Paper style={InfoSection}>
        <StyledIconButton
          m={mode}
          aria-label="toggle password visibility"
          onClick={() =>
            handleModal(
              'matter_court_id',
              'Update Court',
              true,
              Courts,
              co?.court_name,
            )
          }
        >
          {PencilButton(themeColors.mainColors.primaryColor)}
        </StyledIconButton>
        <Typography variant={titleVariant} style={cardTitleStyle}>
          Court
        </Typography>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} style={{ textAlign: 'center' }} /> */}
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-judge-input"
              label="Court Judge"
              fullWidth
              value={`${c?.judge?.judge_first_name || ''} ${c?.judge
                ?.judge_middle_name || ''} ${c?.judge?.judge_last_name ||
                ''}`.replace(/\s{2,}/g, ' ')}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: judge && !judge,
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      onClick={handleOpenJudgeProfile}
                    >
                      <Tooltip title="Click to View Profile">
                        <StyledJudgeProfile
                          c={themeColors.mainColors.primaryColor}
                        />
                      </Tooltip>
                    </InputAdornment>
                    {c?.judge?.judge_undesired && (
                      <InputAdornment position="start">
                        <Tooltip title="This judge has been known to rule against us and is not a preferred judge">
                          <StyledWarn c={themeColors.mainColors.danger} />
                        </Tooltip>
                      </InputAdornment>
                    )}
                  </>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButtonInline
                      m={mode}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleModal(
                          'matter_judge_id',
                          'Update Judge',
                          true,
                          Judges,
                          (c.judge && c.judge.judge_first_name) || '',
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButtonInline>
                    {c?.judge?.judge_notes && (
                      <Tooltip title={c?.judge?.judge_notes}>
                        <StyledNote color="primary" />
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-name"
              label="Court Name"
              fullWidth
              value={co?.court_name}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_name,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-type"
              label="Court Type"
              fullWidth
              value={(co && co.court_type) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_type,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-phone"
              label="Court Phone"
              fullWidth
              value={(co && co.court_phone) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_phone,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledPhoneIconButton
                      onClick={() =>
                        window.open(
                          `https://starkslaw.3cx.us:5001/webclient/#/call?phone=${co.court_phone}`,
                        )
                      }
                    >
                      <StyledPhoneAndroidTwoTone
                        c={themeColors.mainColors.primaryColor}
                      />
                    </StyledPhoneIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-fax"
              label="Court Fax"
              fullWidth
              value={(co && co.court_fax) || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_fax,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-address"
              label="Court Address"
              fullWidth
              value={
                (co &&
                  co.court_address_street &&
                  `${co.court_address_street}, ${co.court_address_city}, ${co.court_address_state} ${co.court_address_zip}`) ||
                ' '
              }
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_address_street,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-county"
              label="Court County"
              fullWidth
              value={(co && co.court_county) || ''}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_address_street,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="court-efile-link"
              label="E-File Link"
              fullWidth
              value={(co && co.court_efile_link) || ''}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: co && !co.court_address_street,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
        </Grid>
      </Paper>
      <UpdateModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        Id={c.matter_id}
        recordType={recordType}
        data={co}
        title={title}
        isMenu={isMenu}
        menuData={menuData}
        text={text}
        tableType={tableType}
        filterTableBy={filterTableBy}
      />
      {openJudgeProfile && (
        <JudgeProfileDialog
          open={openJudgeProfile}
          handleClose={handleCloseJudgeProfile}
          id={c.judge.judge_id}
        />
      )}
    </>
  );
};
