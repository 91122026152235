import React, { useState } from 'react';
import moment from 'moment';
import {
  Paper,
  Chip,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  Download,
  Link as LinkIcon,
  CalendarClock,
  CreditCard,
} from '../../stories/SLICONS.stories';
import { boxShadows } from '../../../constants';
import { useColors } from '../../../hooks';
import { Payment } from '../../../views/customerViews/billing/Checkout';

const StyledChip = styled(Chip)<{ chipColor }>`
  color: ${({ chipColor }): string => chipColor};
  border: 1px solid ${({ chipColor }): string => chipColor};
  margin: 10px 5px 10px auto;
  align-self: 'flex-end';
`;
const StyledDialogContent = styled(DialogContent)<{ fullScreen: boolean }>`
  width: ${({ fullScreen }): string => (!fullScreen ? '50vw' : '100vw')};
  max-width: 600;
  margin: 'auto';
`;
const Invoice = styled(Paper)<{ mode: string; fullScreen: boolean }>`
  display: grid;
  grid-template-columns: ${({ fullScreen }): string =>
    (fullScreen && '20px auto 35px 90px') || '30px auto 35px 90px'};
  overflow: hidden;
  box-shadow: ${({ mode }): string =>
    (mode === 'dark' && boxShadows.three) || boxShadows.one};
`;
const INumber = styled.div`
  margin: 10px;
`;
const InvoiceRow = styled.div`
  margin: 5px 15px;
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  text-align: left;
`;
const EndButtonContainer = styled.div<{ bg: string }>`
  background-color: ${({ bg }): string => bg};
  position: relative;
  cursor: pointer;
  /* :last-child {
    border-radius: 0 4px 4px 0;
  } */
`;
const EndButton = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
`;
const Clickables = styled.div`
  max-width: 300px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: 0 0 10px 0;
`;
const InvoiceTypeTab = styled.div<{ tabColor: string }>`
  color: #fff;
  background-color: ${({ tabColor }): string => tabColor};
  overflow: hidden;
  position: relative;
  h5,
  h6 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
`;

const InvoiceContainer = styled.div`
  width: 90vw;
  max-width: 1200px;
  margin: 35px auto;
  display: grid;
  grid-gap: 1rem;
`;

export const InvoiceCard: React.FC<any> = ({
  invoiceId,
  invoice_amount,
  invoice_status,
  amount_remaining,
  customerId,
  paymentIntentId,
  invoiceURL,
  invoice_pdf,
  due_date,
  metadata,
  invoiceNumber,
  lines,
}) => {
  const { themeColors } = useColors();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [payDialog, setPayDialog] = useState<boolean>(false);
  const handlePay = (): void => {
    setPayDialog(true);
  };

  const chipColor: React.FC<string> = (status: string) => {
    let chipStatusColor;
    if (status.toLowerCase() === 'paid') {
      chipStatusColor = themeColors.mainColors.success;
    }
    if (status.toLowerCase() === 'open') {
      chipStatusColor = themeColors.mainColors.primaryColor;
    }
    if (status.toLowerCase() === 'due') {
      chipStatusColor = themeColors.mainColors.warning;
    }
    if (status.toLowerCase() === 'overdue') {
      chipStatusColor = themeColors.mainColors.danger;
    }
    return chipStatusColor;
  };
  return (
    <>
      <InvoiceContainer>
        <Invoice
          key={invoiceId}
          mode={localStorage.getItem('theme_mode') as any}
          fullScreen={fullScreen}
        >
          <InvoiceTypeTab tabColor={themeColors.mainColors.primaryColor}>
            <Typography variant={(!fullScreen && 'h5') || 'h6'}>
              {(metadata?.initial_payment?.toLowerCase()?.includes('true') &&
                'Initial') ||
                'Monthly'}
            </Typography>
          </InvoiceTypeTab>
          <div>
            <InvoiceRow>
              <INumber>
                <Typography variant="h6" component="div">
                  {(!fullScreen && invoiceNumber) ||
                    invoiceNumber?.split('-')[1]}
                </Typography>
              </INumber>
              {invoice_status !== 'paid' && !fullScreen && (
                <StyledChip
                  label={invoice_status}
                  variant="outlined"
                  chipColor={chipColor(invoice_status, themeColors)}
                />
              )}
            </InvoiceRow>
            <InvoiceRow>
              <Typography variant="body2" component="div">
                Total: $
                {(lines?.data?.[0].amount / 100 || invoice_amount / 100)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </Typography>
              <Typography variant="subtitle2" component="div">
                Due:{' '}
                <strong>
                  {(invoice_status !== 'draft' &&
                    moment.unix(due_date).format('MMM DD, YYYY')) ||
                    'N/A'}
                </strong>
              </Typography>
            </InvoiceRow>
            <InvoiceRow>
              <Typography variant="subtitle2" component="div">
                Balance:
                <strong>
                  {' '}
                  $
                  {(amount_remaining / 100)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </strong>
              </Typography>
              {!fullScreen && (
                <Clickables>
                  <Typography variant="body1" component="div">
                    <Chip
                      component="a"
                      href={invoice_pdf}
                      clickable
                      avatar={<Download width={8} fill="black" />}
                      variant="outlined"
                      label="Invoice"
                    />
                  </Typography>

                  <Typography variant="body1" component="div">
                    <Chip
                      component={'a' as any}
                      target="_blank"
                      href={invoiceURL}
                      clickable
                      avatar={<LinkIcon width={8} fill="black" />}
                      variant="outlined"
                      label="Checkout"
                    />
                  </Typography>
                </Clickables>
              )}
            </InvoiceRow>
          </div>
          {(invoice_status !== 'paid' &&
            invoice_status !== 'draft' &&
            moment.unix(due_date).isBefore(moment()) && (
              <EndButtonContainer
                bg="#FF6961"
                // onClick={(): void => deleteOrVoid(invoice_status, id) as any}
              >
                <EndButton>
                  <CalendarClock width={24} fill="#fff" />
                  <Typography variant="body2">Late</Typography>
                </EndButton>
              </EndButtonContainer>
            )) || <EndButtonContainer bg="ffffff00" />}
          <Tooltip
            title={
              (invoice_status === 'draft' &&
                'The invoice has yet to be finalized and cannot be paid.') ||
              (invoice_status !== 'paid' && 'Pay your invoice') ||
              'Invoice Paid'
            }
          >
            <EndButtonContainer
              bg={
                (invoice_status !== 'paid' && '#071833') ||
                themeColors.mainColors.success
              }
              onClick={(): void =>
                invoice_status !== 'paid' &&
                invoice_status !== 'draft' &&
                (handlePay() as any)
              }
            >
              <EndButton>
                {(invoice_status === 'draft' && (
                  <CalendarClock width={24} fill="#fff" />
                )) ||
                  (invoice_status !== 'paid' && (
                    <CreditCard width={24} fill="#fff" />
                  ))}
                {(invoice_status === 'paid' && 'Paid') || (
                  <Typography variant="body2">
                    {(invoice_status === 'draft' && 'Upcoming') || 'Pay Now'}
                  </Typography>
                )}
              </EndButton>
            </EndButtonContainer>
          </Tooltip>
        </Invoice>
      </InvoiceContainer>
      <Dialog
        open={payDialog}
        maxWidth="lg"
        onClose={(): void => setPayDialog(false)}
        aria-labelledby="form-dialog-title"
        fullScreen={fullScreen}
      >
        <StyledDialogContent fullScreen={fullScreen}>
          <Payment
            chargeAmount={amount_remaining}
            customerId={customerId}
            paymentIntentId={paymentIntentId}
          />
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={(): void => setPayDialog(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
