import React from 'react';
import { Paper, Button } from '@material-ui/core';
import { useSubscription } from '@apollo/client';
import { boxShadows, themeBorders } from '../../../constants';
import { getPosts } from '../../../components/index.ts';

const SitemapGenerator = () => {
  const { loading, error, data } = useSubscription(getPosts);
  if (loading) {
    return 'loading...';
  }
  if (error) {
    return <div>there has been an error</div>;
  }
  const PaperStyle = {
    padding: 30,
    borderLeft: themeBorders.doubleLeft,
    margin: 30,
    boxShadow: boxShadows.one,
  };

  const Sitemap = `<?xml version="1.0" encoding="UTF-8"?>
  <urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${data.article
    .map(
      ({ article_url_slug, article_updated_at }) =>
        `<url>
      <loc>https://www.starks.law/articles/${article_url_slug}/</loc>
      <lastmod>${article_updated_at}</lastmod>
      <changefreq>weekly</changefreq>
      <priority>0.8</priority>
    </url>`,
    )
    .join('')}
  </urlset>`;

  const downloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([Sitemap], { type: 'application/xml' });
    element.href = URL.createObjectURL(file);
    element.download = 'sitemap-articles.xml';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  return (
    <>
      <Paper style={PaperStyle}>
        <Button
          variant="outlined"
          component="span"
          color="primary"
          style={{ height: '100%', maxWidth: '20vw' }}
          onClick={downloadTxtFile}
        >
          Generate Sitemap
        </Button>
      </Paper>
      <Paper style={PaperStyle}>
        <div>
          {`<?xml version="1.0" encoding="UTF-8"?>`}
          <br />
          {`<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">`}
          <br />
        </div>
        {data.article.map(
          ({ article_url_slug, article_id, article_updated_at }) => (
            <div key={article_id}>
              <div style={{ marginLeft: '40px' }}>{`<url>`}</div>
              <div
                style={{ marginLeft: '80px' }}
              >{`<loc>https://www.starks.law/articles/${article_url_slug}/<loc>`}</div>
              <div
                style={{ marginLeft: '80px' }}
              >{`<lastmod>${article_updated_at}</lastmod>`}</div>
              <div
                style={{ marginLeft: '80px' }}
              >{`<changefreq>monthly</changefreq>`}</div>
              <div
                style={{ marginLeft: '80px' }}
              >{`<priority>0.8</priority>`}</div>
              <div style={{ marginLeft: '40px' }}>{`</url>`}</div>
            </div>
          ),
        )}
        {`</urlset>`}
      </Paper>
    </>
  );
};
export default SitemapGenerator;
