import axios from 'axios';

export const CalendarInvite = async (event): Promise<any> => {
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;
  const url = `${baseServerUrl}/send-calendar-invite`;
  const startTime = new Date(event?.event_date_time);
  const endTime = new Date(event?.event_end_time);

  //   setup variables
  const attendee_email = event?.attorney?.attorney_email;
  const subject = `Event Invite: ${event?.event_name}`;
  const description =
    (event?.event_notes?.length > 0 && event.event_notes) || 'Calendar Invite';
  const location = event?.event_location;
  const start_year = startTime.getFullYear();
  const start_month = startTime.getMonth() + 1;
  const start_day = startTime.getDate();
  const start_hour = startTime.getHours();
  const start_minute = startTime.getMinutes();
  const end_year = endTime.getFullYear();
  const end_month = endTime.getMonth() + 1;
  const end_day = endTime.getDate();
  const end_hour = endTime.getHours();
  const end_minute = endTime.getMinutes();
  const timezone = 'America/New_York';

  // create data payload
  const body = {
    attendee_email,
    subject,
    location,
    start_year,
    start_month,
    start_day,
    start_hour,
    start_minute,
    description,
    end_year,
    end_month,
    end_day,
    end_hour,
    end_minute,
    timezone,
  };
  return axios.post(url, body);
};
