import React from 'react';
import { Grid, TextField, Switch, FormControlLabel } from '@material-ui/core';

export const JudgeProfile = ({ handleChangeJudgeProfile, judgeProfile }) => {
  return (
    <>
      <Grid item xs={12} md={5} style={{ textAlign: 'left' }}>
        <TextField
          id="judge-first-text"
          label="First Name"
          onChange={handleChangeJudgeProfile('judgeFirstName')}
          fullWidth
          value={judgeProfile?.judgeFirstName || null}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ textAlign: 'left' }}>
        <TextField
          id="judge-middle-text"
          label="Middle Name"
          onChange={handleChangeJudgeProfile('judgeMiddleName')}
          fullWidth
          value={judgeProfile?.judgeMiddleName || null}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={5} style={{ textAlign: 'left' }}>
        <TextField
          id="judge-last-text"
          label="Last Name"
          onChange={handleChangeJudgeProfile('judgeLastName')}
          fullWidth
          value={judgeProfile?.justLastName || null}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <FormControlLabel
          control={
            <Switch
              checked={judgeProfile?.judgeUndesired || false}
              onChange={handleChangeJudgeProfile('judgeUndesired')}
              color="primary"
            />
          }
          label="Undesired Judge?"
        />
      </Grid>
    </>
  );
};
