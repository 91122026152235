import { gql } from '@apollo/client';

export const GET_NEW_CASES_SUBSCRIBE = gql`
  subscription GET_NEW_CASES_SUBSCRIBE(
    $caseLimit: Int
    $offset: Int
    $searchTerm: String
  ) {
    matter(
      order_by: { matter_opened_date: desc }
      offset: $offset
      limit: $caseLimit
      where: {
        _or: [
          { matter_court_case_number: { _ilike: $searchTerm } }
          { matter_caption: { _ilike: $searchTerm } }
          {
            client: {
              client_lastName: { _ilike: $searchTerm }
              client_firstName: { _ilike: $searchTerm }
            }
          }
          { matter_name: { _ilike: $searchTerm } }
        ]
      }
    ) {
      matter_caption
      matter_name
      matter_id
      matter_closed
      matter_court_case_number
      matter_opened_date
      matter_docket_link
      owning_attorney {
        attorney_email
      }
      client {
        client_firstName
        client_middleName
        client_lastName
        client_id
      }
      court {
        court_name
        court_type
        court_county
      }
      expenses {
        expense_added_by
        expense_amount
        expense_category
        expense_client_billable
        expense_id
        expense_matter_id
        expense_name
      }
    }
  }
`;

export const GET_NEW_CASES_SUBSCRIBE_NON_COMPLETE = gql`
  subscription GET_NEW_CASES_SUBSCRIBE(
    $caseLimit: Int
    $offset: Int
    $searchTerm: String
  ) {
    matter(
      order_by: { matter_opened_date: desc }
      offset: $offset
      limit: $caseLimit
      where: {
        _and: { matter_closed: { _eq: false } }
        _or: [
          { matter_court_case_number: { _ilike: $searchTerm } }
          { matter_caption: { _ilike: $searchTerm } }
          {
            client: {
              client_lastName: { _ilike: $searchTerm }
              client_firstName: { _ilike: $searchTerm }
            }
          }
          { matter_name: { _ilike: $searchTerm } }
        ]
      }
    ) {
      matter_caption
      matter_name
      matter_id
      matter_closed
      matter_court_case_number
      matter_opened_date
      matter_docket_link
      owning_attorney {
        attorney_email
      }
      client {
        client_firstName
        client_middleName
        client_lastName
        client_id
      }
      court {
        court_name
        court_type
        court_county
      }
      expenses {
        expense_added_by
        expense_amount
        expense_category
        expense_client_billable
        expense_id
        expense_matter_id
        expense_name
      }
    }
  }
`;
