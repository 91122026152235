import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  Tooltip,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Paper,
  Slide,
} from '@material-ui/core';
// eslint-disable-next-line import/no-unresolved
import { TransitionProps } from '@material-ui/core/transitions';
import { Close as CloseIcon } from '@material-ui/icons';
import { GetDeletedTasks } from '../../queries';

const StyledHistoryContainer = styled(Paper)`
  margin: 25px;
  min-width: 25vw;
  max-width: 600px;
  padding: 15px;
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeletedTaskModal: React.FC<any> = ({
  open,
  handleCloseHistory,
  matterID,
}) => {
  const {
    data: deletedTasksData,
    loading: deletedTasksLoading,
  } = useQuery(GetDeletedTasks, { variables: { matter_id: matterID } });

  if (deletedTasksLoading) {
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        loading....
      </>
    );
  }
  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition as any}>
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseHistory}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Task History</Typography>
        </Toolbar>
      </AppBar>
      ((
      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {deletedTasksData?.archived_tasks?.map(
          ({
            archived_task_completed,
            archived_task_deleted_by_email,
            archived_task_deleted_by_id,
            archived_task_deleted_by_name,
            archived_task_deleted_on,
            archived_task_description,
            archived_task_due_date,
            archived_task_due_dynamic,
            archived_task_dynamic_due_from,
            archived_task_dynamic_lock,
            archived_task_event_id,
            archived_task_id,
            archived_task_is_priority,
            archived_task_matter_id,
            archived_task_name,
            archived_task_original_id,
            archived_task_owner,
            archived_task_trigger_workflow_id,
          }) => {
            return (
              <Tooltip
                title={
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: archived_task_description,
                    }}
                  />
                }
                key={archived_task_id}
              >
                <StyledHistoryContainer>
                  <Typography variant="body2">
                    Task Name: <b>{archived_task_name}</b>
                  </Typography>

                  <Typography variant="body2">
                    Deleted On:{' '}
                    <b>
                      {moment(archived_task_deleted_on).format(
                        'dddd, MMMM Do YYYY, h:mm:ss a',
                      )}
                    </b>{' '}
                    by <b>{archived_task_deleted_by_name}</b>
                  </Typography>
                </StyledHistoryContainer>
              </Tooltip>
            );
          },
        )}
      </>
    </Dialog>
  );
};

export { DeletedTaskModal };
