import { gql } from '@apollo/client';

export const GetOpposingFirms = gql`
  query GetOpposingFirms {
    op_firm {
      op_firm_address1
      op_firm_address2
      op_firm_address_city
      op_firm_address_state
      op_firm_address_zip
      op_firm_address_zip_4
      op_firm_email
      op_firm_fax
      op_firm_id
      op_firm_name
      op_firm_phone
      matters(where: { matter_closed: { _eq: false } }) {
        matter_id
      }
    }
  }
`;

export const GetOpposingFirmsSub = gql`
  subscription GetOpposingFirms {
    op_firm {
      op_firm_address1
      op_firm_address2
      op_firm_address_city
      op_firm_address_state
      op_firm_address_zip
      op_firm_address_zip_4
      op_firm_email
      op_firm_fax
      op_firm_id
      op_firm_name
      op_firm_phone
      matters(where: { matter_closed: { _eq: false } }) {
        matter_id
      }
    }
  }
`;
