import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const GetFirms = gql`
  query GetFirms {
    op_firm {
      op_firm_id
      op_firm_name
    }
  }
`;

export const OpCounselProfile = ({ handleOpAttyProfile, attyProfile }) => {
  const { loading, data } = useQuery(GetFirms);
  if (loading) {
    return <>loading...</>;
  }

  const { op_firm } = data;
  return (
    <>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <TextField
          id="judge-first-text"
          label="Full Name"
          onChange={handleOpAttyProfile('op_counsel_name')}
          fullWidth
          value={attyProfile?.judgeFirstName || null}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'left' }}>
        <Autocomplete
          id="firm-select"
          fullWidth
          onChange={handleOpAttyProfile('op_counsel_firm_id')}
          options={op_firm}
          getOptionLabel={(option: any): string => option?.op_firm_name}
          renderInput={params => (
            <TextField {...params} label="Select Firm" variant="outlined" />
          )}
        />
      </Grid>
    </>
  );
};
