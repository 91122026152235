import axios from 'axios';

const baseStripeServerUrl = process.env.REACT_APP_SERVER_URL;
const Authorization = `Bearer ${localStorage.getItem('id_token')}`;

// stripe invoices

export const finalizeInvoice = async (id: string): Promise<void> => {
  const options = {
    headers: {
      Authorization,
    },
  };
  const body = { id };
  const url = `${baseStripeServerUrl}/finalize-invoice`;
  await axios.post(url, body, options);
};

export const markPaid = async (id: string): Promise<void> => {
  const options = {
    headers: {
      Authorization,
    },
  };
  const body = { id };
  const url = `${baseStripeServerUrl}/mark-paid`;
  await axios.post(url, body, options);
};

export const deleteDraft = async (id: string): Promise<void> => {
  // setLoadingInvoices(true);
  const options = {
    headers: {
      Authorization,
    },
  };
  const body = { id };
  const url = `${baseStripeServerUrl}/delete-invoice`;
  await axios.post(url, body, options);
};

export const voidInvoice = async (id: string): Promise<void> => {
  // setLoadingInvoices(true);
  const options = {
    headers: {
      Authorization,
    },
  };
  const body = { id };
  const url = `${baseStripeServerUrl}/void-invoice`;
  await axios.post(url, body, options);
};
