import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import {
  Paper,
  Typography,
  Dialog,
  DialogContentText,
  Button,
  TextField,
  InputAdornment,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useUpdateProfile } from '../../../../../hooks';
import { boxShadows } from '../../../../../constants';
import { WinLoss } from '../../../../../components';

const OpPartyQuery = gql`
  query OpPartyQuery($id: uuid) {
    op_party(where: { op_party_id: { _eq: $id } }) {
      op_party_address
      op_party_address2
      op_party_address_city
      op_party_address_state
      op_party_address_zip
      op_party_address_zip_4
      op_party_email
      op_party_fax
      op_party_id
      op_party_name
      op_party_phone
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        matter_id
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;

const StyledContentContainer = styled.div`
  padding-top: 75px;
`;
const StyledDialogContent = styled.div`
  width: 75%;
  min-width: 320px;
  padding: 5px 15px;
  /* max-width: 1200px; */
  margin: auto;
  overflow-x: hidden;
`;

const MatterSection = styled.div`
  margin: 50px auto;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
const MatterCard = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 10px 0;
  min-width: 280px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
    /* transform: scale(1.02); */
  }
`;
const MatterCardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;

export const OpPartyProfileDialog = ({ open, handleClose, id }) => {
  const { onSave } = useUpdateProfile();
  const [opPartyDetails, setOpPartyDetails] = useState<any>([]);
  const { data, loading, error } = useQuery(OpPartyQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  // -------------------------Edits

  // Name Change
  const [opPartyNameReady, setOpPartyNameReady] = useState(false);
  const [newOpPartyName, setNewOpPartyName] = useState('');
  const handleOpPartyNameChange = (e): void => {
    setOpPartyNameReady(true);
    setNewOpPartyName(e.target.value);
  };
  const handleSaveOpPartyName = (): void => {
    setOpPartyNameReady(false);
    onSave('OpPartyName', id, newOpPartyName);
  };

  // Street Change
  const [opPartyStreetReady, setOpPartyStreetReady] = useState(false);
  const [newOpPartyStreet, setNewOpPartyStreet] = useState('');
  const handleOpPartyStreetChange = (e): void => {
    setOpPartyStreetReady(true);
    setNewOpPartyStreet(e.target.value);
  };
  const handleSaveOpPartyStreet = (): void => {
    setOpPartyStreetReady(false);
    onSave('OpPartyStreet', id, newOpPartyStreet);
  };

  // Suite Change
  const [opPartySuiteReady, setOpPartySuiteReady] = useState(false);
  const [newOpPartySuite, setNewOpPartySuite] = useState('');
  const handleOpPartySuiteChange = (e): void => {
    setOpPartySuiteReady(true);
    setNewOpPartySuite(e.target.value);
  };
  const handleSaveOpPartySuite = (): void => {
    setOpPartySuiteReady(false);
    onSave('OpPartySuite', id, newOpPartySuite);
  };

  // City Change
  const [opPartyCityReady, setOpPartyCityReady] = useState(false);
  const [newOpPartyCity, setNewOpPartyCity] = useState('');
  const handleOpPartyCityChange = (e): void => {
    setOpPartyCityReady(true);
    setNewOpPartyCity(e.target.value);
  };
  const handleSaveOpPartyCity = (): void => {
    setOpPartyCityReady(false);
    onSave('OpPartyCity', id, newOpPartyCity);
  };

  // State Change
  const [opPartyStateReady, setOpPartyStateReady] = useState(false);
  const [newOpPartyState, setNewOpPartyState] = useState('');
  const handleOpPartyStateChange = (e): void => {
    setOpPartyStateReady(true);
    setNewOpPartyState(e.target.value);
  };
  const handleSaveOpPartyState = (): void => {
    setOpPartyStateReady(false);
    onSave('OpPartyState', id, newOpPartyState);
  };

  // Zip Change
  const [opPartyZipReady, setOpPartyZipReady] = useState(false);
  const [newOpPartyZip, setNewOpPartyZip] = useState<number | null>(null);
  const handleOpPartyZipChange = (e): void => {
    setOpPartyZipReady(true);
    setNewOpPartyZip(e.target.value);
  };
  const handleSaveOpPartyZip = (): void => {
    setOpPartyZipReady(false);
    onSave('OpPartyZip', id, newOpPartyZip || null);
  };

  // Zip4 Change
  const [opPartyZip4Ready, setOpPartyZip4Ready] = useState(false);
  const [newOpPartyZip4, setNewOpPartyZip4] = useState<number | null>(null);
  const handleOpPartyZip4Change = (e): void => {
    setOpPartyZip4Ready(true);
    setNewOpPartyZip4(e.target.value);
  };
  const handleSaveOpPartyZip4 = (): void => {
    setOpPartyZip4Ready(false);
    onSave('OpPartyZip4', id, newOpPartyZip4 || null);
  };

  // Email Change
  const [opPartyEmailReady, setOpPartyEmailReady] = useState(false);
  const [newOpPartyEmail, setNewOpPartyEmail] = useState('');
  const handleOpPartyEmailChange = (e): void => {
    setOpPartyEmailReady(true);
    setNewOpPartyEmail(e.target.value);
  };
  const handleSaveOpPartyEmail = (): void => {
    setOpPartyEmailReady(false);
    onSave('OpPartyEmail', id, newOpPartyEmail);
  };

  // Fax Change
  const [opPartyFaxReady, setOpPartyFaxReady] = useState(false);
  const [newOpPartyFax, setNewOpPartyFax] = useState('');
  const handleOpPartyFaxChange = (e): void => {
    setOpPartyFaxReady(true);
    setNewOpPartyFax(e.target.value);
  };
  const handleSaveOpPartyFax = (): void => {
    setOpPartyFaxReady(false);
    onSave('OpPartyFax', id, newOpPartyFax);
  };

  // Phone Change
  const [opPartyPhoneReady, setOpPartyPhoneReady] = useState(false);
  const [newOpPartyPhone, setNewOpPartyPhone] = useState('');
  const handleOpPartyPhoneChange = (e): void => {
    setOpPartyPhoneReady(true);
    setNewOpPartyPhone(e.target.value);
  };
  const handleSaveOpPartyPhone = (): void => {
    setOpPartyPhoneReady(false);
    onSave('OpPartyPhone', id, newOpPartyPhone);
  };

  useEffect(() => {
    if (data && id) {
      setOpPartyDetails(data?.op_party[0]);
      setNewOpPartyName(data?.op_party[0]?.op_party_name);
      setNewOpPartyStreet(data?.op_party[0]?.op_party_address);
      setNewOpPartySuite(data?.op_party[0]?.op_party_address2);
      setNewOpPartyCity(data?.op_party[0]?.op_party_address_city);
      setNewOpPartyState(data?.op_party[0]?.op_party_address_state);
      setNewOpPartyZip(data?.op_party[0]?.op_party_address_zip);
      setNewOpPartyZip4(data?.op_party[0]?.op_party_address_zip_4);
      setNewOpPartyEmail(data?.op_party[0]?.op_party_email);
      setNewOpPartyFax(data?.op_party[0]?.op_party_fax);
      setNewOpPartyPhone(data?.op_party[0]?.op_party_phone);
      //   setJudgeDetails(data?.judge[0]);
      //   setNewFirstName(data?.judge[0]?.judge_first_name);
      //   setNewLastName(data?.judge[0]?.judge_last_name);
      //   setNewMiddleName(data?.judge[0]?.judge_middle_name);
      //   setNewNotes(data?.judge[0]?.judge_notes);
      //   setUndesiredNew(data?.judge[0]?.judge_undesired);
    }
  }, [data]);

  if (loading) {
    return <>...loading</>;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{opPartyDetails?.op_party_name}</Typography>
        </Toolbar>
      </AppBar>
      <StyledContentContainer />
      <DialogContentText>
        <StyledDialogContent>
          <TextField
            variant="filled"
            label="Name"
            value={newOpPartyName || ''}
            fullWidth
            onChange={handleOpPartyNameChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyNameReady && (
                    <Button
                      onClick={handleSaveOpPartyName}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Street"
            value={newOpPartyStreet || ''}
            fullWidth
            onChange={handleOpPartyStreetChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyStreetReady && (
                    <Button
                      onClick={handleSaveOpPartyStreet}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Suite"
            value={newOpPartySuite || ''}
            fullWidth
            onChange={handleOpPartySuiteChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartySuiteReady && (
                    <Button
                      onClick={handleSaveOpPartySuite}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="City"
            value={newOpPartyCity || ''}
            fullWidth
            onChange={handleOpPartyCityChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyCityReady && (
                    <Button
                      onClick={handleSaveOpPartyCity}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="State"
            value={newOpPartyState || ''}
            fullWidth
            onChange={handleOpPartyStateChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyStateReady && (
                    <Button
                      onClick={handleSaveOpPartyState}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Zip"
            value={newOpPartyZip}
            fullWidth
            onChange={handleOpPartyZipChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyZipReady && (
                    <Button
                      onClick={handleSaveOpPartyZip}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Zip4"
            value={newOpPartyZip4}
            fullWidth
            onChange={handleOpPartyZip4Change}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyZip4Ready && (
                    <Button
                      onClick={handleSaveOpPartyZip4}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Email"
            value={newOpPartyEmail || ''}
            fullWidth
            onChange={handleOpPartyEmailChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyEmailReady && (
                    <Button
                      onClick={handleSaveOpPartyEmail}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Phone"
            value={newOpPartyPhone || ''}
            fullWidth
            onChange={handleOpPartyPhoneChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyPhoneReady && (
                    <Button
                      onClick={handleSaveOpPartyPhone}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Fax"
            value={newOpPartyFax || ''}
            fullWidth
            onChange={handleOpPartyFaxChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opPartyFaxReady && (
                    <Button
                      onClick={handleSaveOpPartyFax}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <MatterSection>
            <Typography variant="h5">Matters</Typography>
            <br />
            <CardContainer>
              {data?.op_party[0]?.matters?.map(
                ({ matter_name, matter_id, matter_closed, stage }) => {
                  return (
                    <MatterCard
                      key={matter_id}
                      onClick={(): void => {
                        const win: Window | null = window.open(
                          `/cases/${matter_id}`,
                          '_blank',
                        );
                        if (win === null) {
                          return;
                        }
                        win.focus();
                      }}
                    >
                      <MatterCardContentContainer>
                        <div>
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: 12, padding: '0 25px' }}
                          >
                            {matter_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: 10, padding: '0 25px' }}
                          >
                            {stage?.stage_name || ''}
                          </Typography>
                        </div>
                        <EndPiece>
                          <Typography variant="subtitle2">
                            {(!matter_closed && 'Open') || 'Closed'}
                          </Typography>
                        </EndPiece>
                      </MatterCardContentContainer>
                    </MatterCard>
                  );
                },
              )}
            </CardContainer>
            <Typography variant="h5">Statistics</Typography>
            <WinLoss
              title="Win Rate"
              labels={['Won', 'Lost', 'Withdrawn', 'Pending']}
              data={[
                data?.op_party[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Won',
                ).length,
                data?.op_party[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Lost',
                ).length,
                data?.op_party[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Withdrawn',
                ).length,
                data?.op_party[0]?.matters
                  ?.filter(
                    x => x.stage?.stage_name !== 'Concluded/Closed-Withdrawn',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Won')
                  .filter(
                    x =>
                      x.stage?.stage_name !== 'Concluded/Closed-Dismissed-WOP',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Lost')
                  .length,
              ]}
            />
          </MatterSection>
        </StyledDialogContent>
      </DialogContentText>
    </Dialog>
  );
};
