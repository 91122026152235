import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AxiosResponse } from 'axios';
import { Upload, FileSystemNavigator } from './shared';
import { GetFiles } from '../../../../utils';

const Container = styled.div`
  width: 90%;
  margin: 15px auto;
`;

export const DOCUMENTS = ({ cl, c }) => {
  const [files, setFiles] = useState<AxiosResponse>();
  const [reload, setReload] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    GetFiles(cl, c, setLoading, setFiles);
  }, [reload]);
  return (
    <Container>
      <Upload
        clientID={cl?.client_id}
        matterID={c?.matter_id}
        reload={reload}
        setReload={setReload}
      />
      {(loading && 'loading') || (
        <FileSystemNavigator
          files={files || []}
          prefix={`case-files/${cl.client_id}/${c.matter_id}/`}
        />
      )}
    </Container>
  );
};
