import { Button } from '@material-ui/core';
import React from 'react';
import { useColors } from '../../../../../hooks';
import {
  CreditCard,
  Check,
} from '../../../../../components/stories/SLICONS.stories';

interface ButtonIconProps {
  paymentSuccessful: boolean;
  processing: boolean;
}
export interface PayButtonProps extends ButtonIconProps {
  chargeAmount: number;
}

const PayButtonText: React.FC<PayButtonProps> = ({
  chargeAmount,
  paymentSuccessful,
  processing,
}) => {
  let text;
  if (!paymentSuccessful && processing) {
    text = 'Processing';
  }
  if (paymentSuccessful) {
    text = 'Success';
  }
  if (!paymentSuccessful && !processing) {
    text = `Pay $${chargeAmount / 100} Now`;
  }
  return <>{text}</>;
};
const ButtonIcon: React.FC<ButtonIconProps> = ({
  paymentSuccessful,
  processing,
}) => {
  let icon: any = <CreditCard width={24} />;
  if (!paymentSuccessful && processing) {
    icon = (
      <img
        src="https://dt84eajp0482g.cloudfront.net/svgimages/postimages/loaderSquares.svg"
        alt="payment processing indicator"
      />
    );
  }
  if (paymentSuccessful) {
    icon = <Check width={24} />;
  }
  return icon;
};
export const PayButton: React.FC<PayButtonProps> = ({
  chargeAmount,
  paymentSuccessful,
  processing,
}) => {
  const { themeColors } = useColors();
  return (
    <Button
      variant="outlined"
      type="submit"
      fullWidth
      color="primary"
      startIcon={
        <ButtonIcon
          paymentSuccessful={paymentSuccessful}
          processing={processing}
        />
      }
      disabled={processing}
      style={{
        // margin: '15px 0 15px 0',
        height: 50,
        borderColor: themeColors.mainColors.secondaryColor,
        color: themeColors.mainColors.primaryColor,
        // hover: { backgroundColor: themeColors.mainColors.danger },
      }}
    >
      <PayButtonText
        chargeAmount={chargeAmount}
        paymentSuccessful={paymentSuccessful}
        processing={processing}
      />
    </Button>
  );
};
