/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Typography, Paper } from '@material-ui/core';
import { AtomSpinner } from 'react-epic-spinners';
import moment from 'moment';
import _, { toInteger } from 'lodash';
import { useQuery, gql } from '@apollo/client';
import {
  DefaultLineChart,
  WinLoss,
  TextAnalyticsCard,
  ViewEventModal,
  EditEventModal,
  InfoCard,
  ViewTaskModal,
} from '../../components';
import { MatterEvent, MatterTask } from '../../utils/types';
import { useColors, useCurrentAttorney, useMuiTheme } from '../../hooks';

const stats = gql`
  query MatterStats($articleFrom: timestamptz, $matterFrom: timestamptz) {
    matter(where: { matter_opened_date: { _gte: $matterFrom } }) {
      matter_opened_date
      matter_value
    }
    article(where: { article_created_at: { _gte: $articleFrom } }) {
      article_id
    }
  }
`;
const QueryCompletedTasks = gql`
  query MyQuery($user_email: String, $start_date: timestamptz) {
    task_change_history(
      where: {
        modifying_individual_email: { _eq: $user_email }
        change_task_completed_to: { _eq: true }
        date_of_mod: { _gte: $start_date }
      }
    ) {
      change_task_completed_from
      change_task_completed_to
      date_of_mod
    }
  }
`;

const StyledMain = styled.main<{ minWidth: number }>`
  margin: auto;
  width: 95%;
  display: grid;
  grid-column-gap: 2.5%;
  justify-items: center;
  justify-content: space-around;
  grid-template-columns: ${({ minWidth }): string =>
    `repeat(auto-fit, minmax(${minWidth}px, 1fr))`};
  grid-auto-rows: 1fr;
  overflow: hidden;
`;

const StyledTitleContainer = styled(Paper)<{ mode: string }>`
  margin: auto;
  width: 95%;
  font-weight: 100;
  border-radius: none;
  text-align: left;
  box-shadow: none;
  background-color: inherit;
  color: ${({ mode }): string =>
    (mode === 'dark' && 'white') || 'rgb(48, 54, 77)'};
`;

const CardContainer = styled.div`
  margin: auto;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100%;
  margin: auto;
  justify-content: center;
  align-items: center;
`;

// get specificed prior day to use for fetching graphql history of matters and articles
const priorDays = (days: number): string => {
  const start = new Date();
  start.setDate(start.getDate() - days);
  start.setHours(0, 0, 0, 0);
  return moment(start).format('YYYY-MM-DD');
};
interface ChartDataProps {
  date: string;
  value: number;
}

export const Dashboard: React.FC = () => {
  const { currentAttorney } = useCurrentAttorney();
  const { themeColors } = useColors();
  const { mode } = useMuiTheme();
  const [openViewTaskModal, setOpenViewTaskModal] = useState(false);
  const [viewedTask, setViewedTask] = useState<MatterTask | null>(null);

  const handleOpenTask = t => {
    setViewedTask(t);
    setOpenViewTaskModal(true);
  };
  const [openViewEvent, setViewEventOpen] = useState(false);
  const [openEditEvent, setEditEventOpen] = useState(false);
  const [viewedEvent, setViewedEvent] = useState<MatterEvent | null>(null);
  const handleOpen = (event: MatterEvent): void => {
    setViewEventOpen(true);
    setViewedEvent(event);
  };
  const { fillColor } = themeColors.chartColors;
  const borderColor = themeColors.chartColors.lineColor;

  const [articleDays, setArticleDays] = useState<number>(7);
  const [matterDays, setMatterDays] = useState<number>(14);

  // fetch articles and matters for graphing data
  const { loading, data } = useQuery(stats, {
    variables: {
      articleFrom: priorDays(articleDays - 1),
      matterFrom: priorDays(matterDays - 1),
    },
  });

  const matterNumber = data?.matter?.length;
  const articleNumber = data?.article?.length;
  const lastDate: string = priorDays(0);
  const [chartData, setChartData] = useState<ChartDataProps[]>([]);

  useEffect(() => {
    const newChartData = [...chartData];
    for (let i = 0; i < matterDays; i += 1) {
      const d = new Date();
      d.setDate(d.getDate() - i);
      newChartData.push({ date: moment(d).format('YYYY-MM-DD'), value: 0 });
    }
    setChartData(newChartData);
  }, []);

  const Values = (): [] => {
    const amountData: any = [];
    if (!loading && data && chartData.length > 0) {
      for (const d of chartData) {
        let sum = 0;
        for (const m of data.matter) {
          if (d.date === moment(m.matter_opened_date).format('YYYY-MM-DD')) {
            sum += m.matter_value;
          }
        }
        amountData.push(sum);
      }
    }
    return amountData;
  };

  const dailyAverage = (): any => {
    // eslint-disable-next-line no-return-assign, operator-assignment, no-param-reassign
    const sum = data?.matter?.reduce((a, v) => (a = a + v.matter_value), 0);
    return Math.round(sum / 14).toFixed(2);
  };

  console.log(dailyAverage());

  const { loading: loadingTaskCompleted, data: taskCompletedData } = useQuery(
    QueryCompletedTasks,
    {
      variables: {
        user_email: currentAttorney?.attorney_email,
        start_date: priorDays(7),
      },
    },
  );
  const labels = (chartData && chartData?.map(({ date }) => date)) || [];
  return (
    <>
      <StyledTitleContainer mode={mode}>
        <Typography variant="h5">
          Welcome, {currentAttorney?.attorney_name.split(' ', 1)[0]}
        </Typography>
      </StyledTitleContainer>

      <StyledMain minWidth={250}>
        <TextAnalyticsCard
          title="Cases Opened Last 14 Days"
          data={matterNumber}
          change={15}
          loading={loading}
        />
        <TextAnalyticsCard
          title="Posts Written This Week"
          data={articleNumber}
          change={2}
          loading={loading}
        />
        {/* <WinLoss loading={loading} /> */}
        <TextAnalyticsCard
          title="Tasks Completed Last 7 Days"
          data={taskCompletedData?.task_change_history?.length}
          loading={loadingTaskCompleted}
          change={7}
        />
      </StyledMain>
      <StyledMain minWidth={350}>
        <DefaultLineChart
          end={lastDate}
          labels={labels}
          data={Values()}
          title={`New Case Value - Last ${matterDays} Days`}
          fillColor={fillColor}
          borderColor={borderColor}
          loading={loading}
          average={dailyAverage()}
        />
      </StyledMain>
      <StyledTitleContainer mode={mode}>
        <Typography variant="h5">Your Hearings</Typography>
      </StyledTitleContainer>
      <CardContainer>
        {(!loading &&
          currentAttorney?.events
            ?.filter(
              a =>
                moment(a.event_date_time).format('MM-DD-YYYY') ===
                moment(new Date()).format('MM-DD-YYYY'),
            )
            .map(e => {
              return (
                <InfoCard
                  key={e.event_id}
                  title={e.event_name}
                  subtitle1={e.event_type || ''}
                  subtitle2={e.event_location || ''}
                  endText={moment(e.event_date_time).format('h:mm A')}
                  onClick={(): void => handleOpen(e)}
                />
              );
            })) || (
          <SpinnerContainer>
            <AtomSpinner color={themeColors.mainColors.primaryColor} />
          </SpinnerContainer>
        )}
      </CardContainer>
      <StyledTitleContainer mode={mode}>
        <Typography variant="h5">Your Tasks</Typography>
      </StyledTitleContainer>
      <CardContainer>
        {(!loading &&
          currentAttorney?.tasks
            ?.filter(a => {
              const today = moment(new Date()).format('MM/DD/YYYY');
              const taskDate = moment(a.task_due).format('MM/DD/YYYY');
              let include;
              if (today === taskDate) {
                include = 1;
              } else if (moment(today).isAfter(taskDate)) {
                include = -1;
              } else {
                include = 0;
              }
              return include;
            })
            .map(t => {
              return (
                <InfoCard
                  key={t.task_id}
                  title={t.task_name}
                  subtitle1={t?.matter?.matter_name}
                  subtitle2={t?.matter?.matter_stage}
                  endText={moment(t?.task_due).format('DD MMM')}
                  onClick={(): void => handleOpenTask(t)}
                  isPriority={t.task_is_priority}
                />
              );
            })) || (
          <SpinnerContainer>
            <AtomSpinner color={themeColors.mainColors.primaryColor} />
          </SpinnerContainer>
        )}
      </CardContainer>

      <ViewEventModal
        open={openViewEvent}
        onEditClick={(): void => {
          setViewEventOpen(false);
          setEditEventOpen(true);
        }}
        onClose={(): void => setViewEventOpen(false)}
        event={viewedEvent}
      />
      {viewedEvent && (
        <EditEventModal
          open={openEditEvent}
          matterId={viewedEvent.event_matter_id}
          initialState={viewedEvent}
          onClose={(): void => setEditEventOpen(false)}
        />
      )}

      <ViewTaskModal
        open={openViewTaskModal}
        matter={viewedTask?.matter as any}
        task={viewedTask}
        individual={!viewedTask?.event?.event_name}
        onClose={(): void => setOpenViewTaskModal(false)}
      />
    </>
  );
};
