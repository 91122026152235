import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { LibraryAdd } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  useColors,
  useDeleteInvoice,
  useFinalizeInvoice,
  useGetInvoices,
  useMarkInvoicePaid,
  useUpdateInvoiceAmount,
  useVoidInvoice,
} from '../../../../hooks';
import {
  deleteDraft,
  finalizeInvoice,
  markPaid,
  voidInvoice,
} from '../../../../utils/StripeInvoiceActions';
import { Client, Matter } from '../../../../utils/types';
import { CaseInvoiceList } from './listInvoices';
import { AddInvoiceForm } from './modals/addInvoiceForm';
import { SLCaseInvoiceList } from './newInvoiceList';

interface BillingProps {
  client: Client | undefined;
  matter: Matter;
}
const baseServerUrl = process.env.REACT_APP_SERVER_URL;

const StyledHeading = styled.div`
  margin: 5px, auto, 5px, 125px;
  padding-left: 35px;
`;

const StyledAddWorkflowButton = styled(IconButton)<{ $color: string }>`
  position: absolute;
  right: 15px;
  top: 5px;
  color: ${({ $color }): string => $color};
`;

export const BILLING: React.FC<BillingProps> = ({ client, matter }) => {
  const { themeColors } = useColors();
  const { data: newInvoices, loading: loadingNewInvoices } = useGetInvoices(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    client!.client_id!,
  );
  const { onUpdateAmount, loading } = useUpdateInvoiceAmount();
  const { onFinalizeInvoice, loading: loadingFinalize } = useFinalizeInvoice();
  const { onVoidInvoice, loading: loadingVoid } = useVoidInvoice();
  const { onDeleteInvoice } = useDeleteInvoice();
  const { onMarkInvoicePaid } = useMarkInvoicePaid();

  const [invoices, setInvoices] = useState<[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true);

  const [voided, setVoided] = useState({});
  const voidAnimate = (key): void => {
    setVoided({ ...voided, [key]: true });
  };

  const getInvoices = useMemo(
    () => async (): Promise<void> => {
      const options = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('id_token')}`,
        },
      };
      const body = { id: client?.client_stripe_id };
      const url = `${baseServerUrl}/get-client-invoices`;
      await axios
        .post(url, body, options)
        .then(d => setInvoices(d.data.data))
        .then(() => setLoadingInvoices(false));
    },
    [client?.client_stripe_id],
  );

  // old stripe pay, void, delete or send logic
  const StripePayOrSend = (status, id): void => {
    if (status === 'draft') {
      finalizeInvoice(id).then(() => getInvoices());
    } else {
      markPaid(id).then(() => getInvoices());
    }
  };

  const StripeDeleteOrVoid = (status, id): void => {
    if (status === 'draft') {
      deleteDraft(id);
      voidAnimate(id);
    } else {
      voidInvoice(id);
      voidAnimate(id);
    }
  };

  // new pay, void, delete or send logic.
  const payOrSend = (status, id, amt): void => {
    if (status === 'Draft') {
      onFinalizeInvoice(id);
    } else {
      onMarkInvoicePaid(id);
    }
  };

  const deleteOrVoid = (status, id): void => {
    if (status === 'Draft') {
      onDeleteInvoice(id);
      voidAnimate(id);
    } else {
      onVoidInvoice(id);
      voidAnimate(id);
    }
  };

  const [addInvoiceOpen, setAddInvoiceOpen] = useState<boolean>(false);

  const handleToggleAddInvoice = (): void => {
    setAddInvoiceOpen(!addInvoiceOpen);
  };

  useEffect(() => {
    if (client?.client_stripe_id) {
      getInvoices();
    }
  }, []);
  if (loadingNewInvoices || loadingNewInvoices) {
    console.log('loading new invoices');
  }
  return (
    <>
      <Tooltip title="Add Workflow">
        <StyledAddWorkflowButton
          $color={themeColors.mainColors.primaryColor}
          onClick={(): void => handleToggleAddInvoice()}
        >
          <LibraryAdd />
        </StyledAddWorkflowButton>
      </Tooltip>
      <StyledHeading>
        <Typography variant="h4" gutterBottom>
          Internal Invoices
        </Typography>
      </StyledHeading>

      <SLCaseInvoiceList
        loading={loadingNewInvoices}
        invoices={newInvoices?.invoice}
        matter={matter}
        filterVoided
        filterByCase
        voided={voided}
        payOrSend={payOrSend}
        deleteOrVoid={deleteOrVoid}
      />

      <StyledHeading>
        <Typography variant="h4" gutterBottom>
          Stripe(Deprecated)
        </Typography>
      </StyledHeading>

      <CaseInvoiceList
        loading={loadingInvoices}
        invoices={invoices}
        matter={matter}
        filterVoided
        filterByCase
        voided={voided}
        payOrSend={StripePayOrSend}
        deleteOrVoid={StripeDeleteOrVoid}
      />
      <AddInvoiceForm
        open={addInvoiceOpen}
        handleClose={handleToggleAddInvoice}
        client={client}
        matter={matter}
      />
    </>
  );
};
