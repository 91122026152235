/* eslint-disable no-plusplus */
import React from 'react';
import styled from 'styled-components';
import {
  TableBody,
  TableCell,
  TableRow,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { DeleteOutlineOutlined } from '@material-ui/icons';
import { useColors } from '../../../../../hooks';

const StyledChip = styled(Chip)<{ chipColor }>`
  color: ${({ chipColor }): string => chipColor};
  border: 1px solid ${({ chipColor }): string => chipColor};
  margin: 0 auto;
  padding: 0;
  align-self: 'flex-end';
`;

export const JudgeTableBody = ({
  judges,
  rowsPerPage,
  page,
  search,
  handleClickOpenDelete,
  handleOpenJudgeProfile,
}) => {
  const { themeColors } = useColors();
  const countIt = m => {
    let counter = 0;
    for (let i = 0; i < m.length; i++) {
      if (m[i].matter_result === 'won') counter++;
    }
    return counter;
  };
  return (
    <TableBody>
      {judges
        ?.filter(x =>
          x.judge_last_name
            ?.toLowerCase()
            .includes(search?.toLowerCase() || ''),
        )
        .sort((a, b) => a.judge_last_name.localeCompare(b.judge_last_name))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(
          ({
            judge_id,
            judge_first_name,
            judge_middle_name,
            judge_last_name,
            judge_undesired,
            judge_notes,
            matters,
          }) => (
            <TableRow
              key={judge_id}
              hover
              // onClick={(): void => history.push(`/messages/${message_id}`)}
              style={{ cursor: 'pointer' }}
              onClick={(): void => handleOpenJudgeProfile(judge_id)}
            >
              <>
                <Tooltip
                  title={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: judge_notes || '',
                      }}
                    />
                  }
                >
                  <TableCell component="th" scope="row">
                    {(judge_last_name && `${judge_last_name.trim()}, `) || ''}
                    {(judge_first_name && judge_first_name.trim()) || ''}
                    {(judge_middle_name && ` ${judge_middle_name.trim()} `) ||
                      ' '}
                  </TableCell>
                </Tooltip>
                <TableCell align="left">{matters?.length || 0}</TableCell>
                <TableCell align="left">{countIt(matters)}</TableCell>
                <TableCell align="left">
                  {(judge_undesired && (
                    <StyledChip
                      label="Unfriendly"
                      clickable
                      variant="outlined"
                      chipColor={themeColors.mainColors.warning}
                    />
                  )) || (
                    <StyledChip
                      label="Friendly"
                      clickable
                      variant="outlined"
                      chipColor={themeColors.mainColors.success}
                    />
                  )}
                </TableCell>
                <TableCell align="left">
                  <DeleteOutlineOutlined
                    color="primary"
                    onClick={(): void =>
                      handleClickOpenDelete(judge_id, judge_first_name)
                    }
                  />
                </TableCell>
              </>
            </TableRow>
          ),
        )}
    </TableBody>
  );
};
