import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { BaseWorkflowTaskModal } from './BaseWorkflowTaskModal';
import { EDIT_WORKFLOW_TASK } from '../../mutations';
import { Workflow, WorkflowTask, EditableWorkflowTask } from '../../../utils';

type WorkflowTaskModalProps = {
  open: boolean;
  workflow: Workflow;
  onClose: () => void;
  initialState?: WorkflowTask;
};

const EditWorkflowTaskModal: React.FC<WorkflowTaskModalProps> = ({
  open,
  onClose,
  workflow,
  initialState,
}) => {
  const [formError, setFormError] = useState('');
  const [editTask, { data, loading, error }] = useMutation(EDIT_WORKFLOW_TASK);

  useEffect(() => {
    if (!loading && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = (taskState: EditableWorkflowTask): void => {
    if (!initialState?.workflow_task_id) {
      setFormError('Workflow Task ID not detected');
    } else {
      setFormError('');
      editTask({
        variables: {
          workflow_task_workflow_id: workflow.workflow_id,
          workflow_task_id: initialState.workflow_task_id,
          workflow_task_name: taskState.workflow_task_name,
          workflow_task_description: taskState.workflow_task_description,
          workflow_task_dynamic_due_from:
            taskState.workflow_task_dynamic_due_from,
          workflow_task_dynamic_lock: taskState.workflow_task_dynamic_lock,
          trigger_workflow_id: taskState.trigger_workflow_id,
          task_is_priority: taskState.task_is_priority,
        },
      });
    }
  };

  /**
   * We're only allowing the modal to mount when open is true.
   * This ensures that the initial state will not be set before mounting.
   * We do this because initial state can only be set once.
   */
  return (
    <>
      {open && (
        <BaseWorkflowTaskModal
          open={open}
          title="Edit Workflow Task"
          formError={formError}
          initialState={initialState}
          onSave={onSave}
          onClose={onClose}
          loading={loading}
        />
      )}
    </>
  );
};

export { EditWorkflowTaskModal };
