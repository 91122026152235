import React, { useState } from 'react';
import styled from 'styled-components';
import { Paper, Grid, Collapse, Button } from '@material-ui/core';
import { WorkflowDetails } from './WorkflowDetails';
import {
  EditWorkflowModal,
  WorkflowTaskTable,
  WorkflowModifiers,
} from '../../../../components';
import { themeBorders, boxShadows } from '../../../../constants';
import { Workflow } from '../../../../utils';

type WorkflowCardProps = {
  workflow: Workflow;
};

const StyledContainer = styled(Paper)`
  position: relative;
  /* min-height: 150px; */
  width: 90%;
  margin: 40px auto 0;
  padding: 20px 2.5px 2px;
  box-shadow: ${boxShadows.three};
  border-left: ${themeBorders.doubleLeft};
  /* cursor: pointer;
  :hover {
    transform: translate(0px, -2px);
  } */
`;
const StyledButton = styled(Button)`
  margin: 13px auto 2px;
`;

const WorkflowCard: React.FC<WorkflowCardProps> = ({ workflow }) => {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [cardOpen, setCardOpen] = useState<boolean>(false);
  return (
    <StyledContainer>
      <WorkflowModifiers
        workflowId={workflow.workflow_id}
        onEditClick={(): void => setOpenEditModal(true)}
      />
      <Grid container spacing={2}>
        <WorkflowDetails workflow={workflow} />
        <div style={{ width: '90%', margin: 'auto' }}>
          <Collapse in={cardOpen} timeout="auto" unmountOnExit>
            <WorkflowTaskTable
              workflow={workflow}
              tasks={workflow.workflow_tasks || []}
            />
          </Collapse>
        </div>
      </Grid>
      <StyledButton
        onClick={(): void => setCardOpen(!cardOpen)}
        variant="outlined"
        color="primary"
      >
        {(!cardOpen && 'View Tasks') || 'Close'}
      </StyledButton>
      <EditWorkflowModal
        open={openEditModal}
        onClose={(): void => setOpenEditModal(false)}
        initialState={workflow}
      />
    </StyledContainer>
  );
};

export { WorkflowCard };
