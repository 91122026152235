import React from 'react';
import styled from 'styled-components';
import { Check, Remove } from '@material-ui/icons';
import { useColors } from '../../../../../hooks';

type EventChipProps = {
  complete: boolean;
};

const { themeColors } = useColors();
const StyledChip = styled.div<EventChipProps>`
  position: absolute;
  display: flex;
  align-items: center;
  width: fit-content;
  top: 10px;
  left: 10px;
  height: 26px;
  border: ${({ complete }): string =>
    complete
      ? `1px solid ${themeColors.mainColors.success}`
      : `1px solid ${themeColors.mainColors.warning}`};
  border-radius: 13px;
  padding: 0 13px;
  background: transparent;
`;

const StyledText = styled.span<EventChipProps>`
  font-size: 12px;
  font-weight: bold;
  color: ${({ complete }): string =>
    complete
      ? `${themeColors.mainColors.success}`
      : `${themeColors.mainColors.warning}`};
  text-transform: uppercase;
  margin-right: 8px;
`;

const StyledCheck = styled(Check)`
  width: 15px;
  height: 15px;
  color: ${themeColors.mainColors.success};
`;

const StyledRemove = styled(Remove)`
  width: 15px;
  height: 15px;
  color: ${themeColors.mainColors.warning};
`;

const EventChip: React.FC<EventChipProps> = ({ complete }) => {
  return (
    <StyledChip complete={complete}>
      <StyledText complete={complete}>
        {complete ? 'Completed' : 'Incomplete'}
      </StyledText>
      <>{complete ? <StyledCheck /> : <StyledRemove />}</>
    </StyledChip>
  );
};

export { EventChip };
