import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { FingerprintSpinner } from 'react-epic-spinners';
import { Table } from '@material-ui/core';
import { WorkflowTaskTableHead } from './TableHead';
import { WorkflowTaskTableBody } from './TableBody';
import { TableOptions } from './TableOptions';
import { EmptyTasksBody } from '../EmptyTasksBody';
import { AddWorkflowTaskModal, EditWorkflowTaskModal } from '../../Modals';
import { boxShadows } from '../../../constants/shadows/shadows';
import { useColors } from '../../../hooks';
import { Workflow, WorkflowTask } from '../../../utils/types';

type WorkflowTaskTableProps = {
  workflow: Workflow;
  tasks: WorkflowTask[];
  loading?: boolean;
};

const StyledSectionWrapper = styled.section<{ $topBorderColor: string }>`
  width: 100%;
  border-radius: 10px;
  padding: 0 0 30px 0;
  box-shadow: ${boxShadows.one};
  border-top: 7px solid ${({ $topBorderColor }): string => $topBorderColor};
`;

const StyledTable = styled(Table)<{ $topBorderColor: string }>`
  width: 100%;
  border-top: 2px solid ${({ $topBorderColor }): string => $topBorderColor};
`;

const StyledDiv = styled.div<{ borderColor: string }>`
  border-top: 2px solid ${({ borderColor }): string => borderColor};
`;

const LoadContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 25px 0 25px 0;
  margin: auto;
  justify-content: center;
  align-content: center;
`;

const WorkflowTaskTable: React.FC<WorkflowTaskTableProps> = ({
  workflow,
  tasks,
  loading,
}) => {
  const { themeColors } = useColors();

  const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
  const [openEditTaskModal, setOpenEditTaskModal] = useState(false);
  const [viewedTask, setViewedTask] = useState<WorkflowTask | null>(null);

  const findTaskById = (taskId: string): WorkflowTask | undefined => {
    return tasks.find(task => task.workflow_task_id === taskId);
  };

  const sortedTasks = useMemo(() => {
    return tasks.slice().sort((a, b) => {
      const aFrom = a.workflow_task_dynamic_due_from;
      const bFrom = b.workflow_task_dynamic_due_from;
      if (!aFrom && !bFrom) return 0;
      if (!aFrom) return -1;
      if (!bFrom) return 1;
      return aFrom - bFrom;
    });
  }, [tasks]);

  return (
    <StyledSectionWrapper $topBorderColor={themeColors.mainColors.primaryColor}>
      <TableOptions onAddTask={(): void => setOpenAddTaskModal(true)} />

      <StyledTable
        $topBorderColor={themeColors.mainColors.primaryColor}
        size="small"
      >
        <colgroup>
          <col width="20px" />
          <col width="calc(100% - 20px - 80px- 80px - 120px)" />
          <col width="80px" />
          <col width="80px" />
          <col width="120px" />
        </colgroup>
        {(loading && (
          <LoadContainer>
            <FingerprintSpinner
              color={themeColors.mainColors.primaryColor}
              size={75}
              animationDuration={2000}
            />
          </LoadContainer>
        )) || (
          <>
            <WorkflowTaskTableHead />
            <WorkflowTaskTableBody
              tasks={sortedTasks}
              onEditTask={(taskId: string): void => {
                setViewedTask(findTaskById(taskId) || null);
                setOpenEditTaskModal(true);
              }}
            />
            {!tasks.length && <EmptyTasksBody />}
          </>
        )}
      </StyledTable>

      <AddWorkflowTaskModal
        open={openAddTaskModal}
        workflow={workflow}
        onClose={(): void => setOpenAddTaskModal(false)}
      />
      <EditWorkflowTaskModal
        open={openEditTaskModal}
        workflow={workflow}
        onClose={(): void => setOpenEditTaskModal(false)}
        initialState={viewedTask || undefined}
      />
      <StyledDiv borderColor={themeColors.mainColors.primaryColor} />
    </StyledSectionWrapper>
  );
};

export { WorkflowTaskTable };
