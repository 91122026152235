import { Paper, Tab, Tabs, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { CaseTable } from '../../components/index';
import { useColors } from '../../hooks';
import { CreateCase } from './CreateCase/CreateCase';

export const Cases: React.FC<any> = () => {
  const { themeColors } = useColors();

  // state for tab selections
  const [tab, setTab] = useState(0);
  const handleChange = (_event, newTab) => {
    setTab(newTab);
  };
  const [searchTerm, setSearchTerm] = useState('%');
  const handleSearch = e => {
    setSearchTerm(`%${e.target.value}%`);
  };
  const paper = {
    width: '100%',
    borderRadius: 10,
    paddingBottom: 40,
  };
  // end styling
  return (
    <>
      <Paper style={paper}>
        <Tabs
          style={{ width: '90%', paddingLeft: 5, margin: 'auto' }}
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="Case Nav Tabs"
          scrollButtons="on"
          variant="scrollable"
        >
          <Tab label="Cases" />
          <Tab label="New Case" />
        </Tabs>
        {tab === 0 ? (
          <div style={{ float: 'right', marginRight: '5.5%', marginTop: -45 }}>
            <TextField
              id="outlined-basic"
              fullWidth
              label="Search"
              variant="outlined"
              style={{ marginTop: 7, marginLeft: 5, marginBottom: -5 }}
              onChange={handleSearch}
            />
          </div>
        ) : null}
        {/* case info */}
        {tab === 0 ? (
          <div style={{ width: '90%', margin: 'auto', marginTop: 30 }}>
            <CaseTable
              borderTop={`8px solid ${themeColors.mainColors.primaryColor}`}
              pagination
              denseControl
              // searchBar
              searchTerm={searchTerm}
            />
          </div>
        ) : null}
        {/* case creation screen */}
        {tab === 1 ? (
          <>
            <CreateCase />
          </>
        ) : null}
      </Paper>
    </>
  );
};
