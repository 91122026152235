import React, { useState, useEffect, useCallback } from 'react';
import {
  FormGrid,
  Modal,
  ErrorNotification,
  WorkflowSelect,
  AttorneySelect,
} from '../..';
import { Attorney } from '../../FormComponents/AttorneySelect';
import { Workflow, Matter, MatterEvent } from '../../../utils';
import { useAddSelectedWorkflow } from '../../../hooks';

type SelectWorkflowModalProps = {
  open: boolean;
  matter: Matter;
  event?: MatterEvent;
  onClose: () => void;
};

const SelectWorkflowModal: React.FC<SelectWorkflowModalProps> = ({
  open,
  matter,
  event,
  onClose,
}) => {
  const [selectedWorkflow, setSelectedWorkflow] = useState<
    Workflow | undefined
  >();

  const [selectedAttorney, setSelectedAttorney] = useState<
    Attorney | undefined
  >();

  const [formError, setFormError] = useState('');

  useEffect(() => {
    if (selectedWorkflow && selectedAttorney && formError) {
      setFormError('');
    }
  }, [selectedWorkflow, selectedAttorney, formError, setFormError]);

  const onSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const onError = useCallback(
    (error: string) => {
      setFormError(error);
    },
    [setFormError],
  );

  const { onSelection, loading } = useAddSelectedWorkflow({
    onSuccess,
    onError,
  });

  const onSelectClick = (): void => {
    if (selectedWorkflow && selectedAttorney) {
      onSelection({
        workflow: selectedWorkflow,
        attorney: selectedAttorney,
        matter,
        event,
      });
    } else {
      setFormError('Please enter required information');
    }
  };

  return (
    <Modal
      open={open}
      title="Select Workflow"
      primaryButtonText="Select"
      secondaryButtonText="Cancel"
      includeCancelConfirmation
      primaryLoading={loading}
      onPrimaryClick={onSelectClick}
      onSecondaryClick={onClose}
      onClose={onClose}
    >
      <>
        <FormGrid>
          <WorkflowSelect
            error={!!formError && !selectedWorkflow}
            errorText="Field is required"
            onWorkflowSelect={(workflow?: Workflow): void => {
              setSelectedWorkflow(workflow);
            }}
          />
          <AttorneySelect
            error={!!formError && !selectedAttorney}
            errorText="Field is required"
            onAttorneySelect={(attorney: Attorney): void => {
              setSelectedAttorney(attorney);
            }}
            defaultToCurrentAttorney
          />
        </FormGrid>
        <ErrorNotification text={formError} show={!!formError.length} />
      </>
    </Modal>
  );
};

export { SelectWorkflowModal };
