/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  IconButton,
} from '@material-ui/core';
import { boxShadows } from '../../../constants/shadows/shadows';
import { themeBorders } from '../../../constants/borders/borders';
import { useColors } from '../../../hooks';
import { PencilOutline } from '../../../components/stories/SLICONS.stories';
import { UpdateModal } from '../CaseUpdate';
import { GetOpposingParties } from '../../../components/queries/cases';

const StyledIconButton = styled(IconButton)`
  position: relative;
  float: right;
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 7px -20px 0 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

const InfoSection = {
  borderLeft: themeBorders.doubleLeft,
  minHeight: 150,
  width: '95%',
  margin: 'auto',
  marginTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 0,
  boxShadow: boxShadows.three,
};

const PencilButton = color => {
  return <PencilOutline width={18} fill={color} />;
};

export const OPPOSING_PARTY = props => {
  const { themeColors } = useColors();
  const mode = localStorage.getItem('theme_mode');
  const { loading, error, data: opposingParties } = useQuery(
    GetOpposingParties,
  );

  // new edit logic

  const { pa, readOnly, titleVariant, cardTitleStyle, c } = props;
  const tableType = 'update_matter';
  const filterTableBy = 'matter_id';
  const [openEdit, setOpenEdit] = useState(false);
  const [recordType, setRecordType] = useState();
  const [title, setTitle] = useState();
  const [isMenu, setIsMenu] = useState(false);
  const [menuData, setMenuData] = useState();
  const [text, setText] = useState();
  const handleModal = (r, t, m, d, w) => {
    setTitle(t);
    setRecordType(r);
    setIsMenu(m);
    setMenuData(d);
    setOpenEdit(!openEdit);
    setText(w);
  };
  if (loading) {
    return <>&apos;loading...&apos;</>;
  }
  if (error) {
    return <div>there has been an error</div>;
  }
  const opParties = opposingParties.op_party
    .slice()
    .sort((a, b) => a.op_party_name.localeCompare(b.op_party_name))
    .map(({ op_party_id, op_party_name }) => {
      return { label: op_party_name, value: op_party_id };
    });
  return (
    <>
      <Paper style={InfoSection}>
        <StyledIconButton
          m={mode}
          aria-label="toggle password visibility"
          onClick={() =>
            handleModal(
              'op_party_id',
              'Update Opposing Party',
              true,
              opParties,
              c.matter_id,
            )
          }
        >
          {PencilButton(themeColors.mainColors.primaryColor)}
        </StyledIconButton>
        <Typography variant={titleVariant} style={cardTitleStyle}>
          Opposing Party
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'center' }} />
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Opposing Party Name"
              fullWidth
              value={pa?.op_party_name || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !pa?.op_party_name,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Phone"
              fullWidth
              value={pa?.op_party_phone || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !pa?.op_party_phone,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Fax"
              fullWidth
              value={pa?.op_party_fax || ' '}
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !pa?.op_party_fax,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Address"
              fullWidth
              value={
                (pa &&
                  `${pa.op_party_address}${
                    pa.op_party_address2 === null ? ' ' : ', '
                  }${pa.op_party_address_city}, ${pa.op_party_address_state} ${
                    pa.op_party_address_zip
                  }`) ||
                ' '
              }
              margin="normal"
              InputProps={{
                readOnly,
                disabled: !pa?.op_party_address,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
        </Grid>
      </Paper>
      <UpdateModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        Id={c.matter_id}
        recordType={recordType}
        data={pa}
        title={title}
        isMenu={isMenu}
        menuData={menuData}
        text={text}
        tableType={tableType}
        filterTableBy={filterTableBy}
      />
    </>
  );
};
