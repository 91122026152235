import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 36,
  },
  billTo: {
    marginTop: 20,
    paddingBottom: 3,
    fontFamily: 'Helvetica-Oblique',
  },
});

export const BillTo = ({ invoice }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Bill To:</Text>
    <Text>
      {invoice.client?.client_firstName} {invoice.client?.client_lastName}
    </Text>
    <Text>{invoice.client?.client_address_street}</Text>
    <Text>
      {invoice.client?.client_address_city},{' '}
      {invoice.client?.client_address_state}{' '}
      {invoice.client?.client_address_zip}
    </Text>
    <Text>{invoice.client?.client_phone_cell}</Text>
    <Text>{invoice.client?.client_email}</Text>
  </View>
);
