import { gql } from '@apollo/client';

export const GetStages = gql`
  query Stages($role: String) {
    stage {
      stage_description
      stage_id
      stage_name
    }
  }
`;
