import styled from 'styled-components';
import { MainColorProps } from '../../../../../hooks/useColor';

export const DropContainer = styled.div<{
  isDragActive: boolean;
  color: MainColorProps;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 15px auto;
  min-height: 200px;
  background-color: ${({ isDragActive, color }): string =>
    (isDragActive && `${color.success}`) || 'lightgrey'};
`;
export const UploadContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  margin: 15px auto;
`;

export const LoaderContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
