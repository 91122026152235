/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import axios from 'axios';
import { LinearProgress, Typography } from '@material-ui/core';
import { UserContext } from '../../../../context';
import { getClientInfo } from '../../../../components/queries/clients';
import { InvoiceCard } from '../../../../components';

const baseServerUrl = process.env.REACT_APP_SERVER_URL;

export const ListInvoices: React.FC = () => {
  const { state } = useContext(UserContext);
  const customerId = state.user.stripeId || null;
  const [invoices, setInvoices] = useState<[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true);

  const { loading } = useQuery(getClientInfo, {
    variables: { client_id: state.user.email },
  });

  const getInvoices = async () => {
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
    };
    const body = { id: state.user.stripeId };
    const url = `${baseServerUrl}/get-client-invoices`;
    await axios
      .post(url, body, options)
      .then(d => setInvoices(d.data.data))
      .then(() => setLoadingInvoices(false));
  };

  useEffect(() => {
    if (customerId) {
      getInvoices();
    }
  }, [customerId]);

  if (loading || loadingInvoices) {
    return <LinearProgress />;
  }

  return (
    <>
      {invoices?.map(
        ({
          id,
          amount_due,
          status,
          lines,
          amount_remaining,
          payment_intent,
          hosted_invoice_url,
          invoice_pdf,
          due_date,
          metadata,
          number,
        }) => (
          <InvoiceCard
            key={id}
            invoiceId={id}
            invoice_amount={amount_due}
            invoice_status={status}
            lines={lines}
            amount_remaining={amount_remaining}
            customerId={customerId}
            paymentIntentId={payment_intent}
            invoiceURL={hosted_invoice_url}
            invoice_pdf={invoice_pdf}
            due_date={due_date}
            metadata={metadata}
            invoiceNumber={number}
          />
        ),
      ) || <Typography variant="body1">No invoices to display</Typography>}
    </>
  );
};
