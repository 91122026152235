import React, { useState } from 'react';
import { useSubscription } from '@apollo/client';
import { Button } from '@material-ui/core';
import {
  Calendar as SharedCalendar,
  ViewEventModal,
  EditEventModal,
} from '../../components';
import GET_EVENTS from '../../components/queries/events/eventsSubscription';
import { MatterEvent } from '../../utils/types';

type FirmCalendarProps = {
  searchTerm: string;
  searchCounty: string;
  showComplete: boolean;
};

const FirmCalendar: React.FC<FirmCalendarProps> = ({
  searchTerm,
  searchCounty,
  showComplete,
}) => {
  const { loading, error, data } = useSubscription(GET_EVENTS, {
    variables: {
      searchTerm,
      searchCounty,
    },
  });

  const [openViewEvent, setViewEventOpen] = useState(false);
  const [openEditEvent, setEditEventOpen] = useState(false);
  const [viewedEvent, setViewedEvent] = useState<MatterEvent | null>(null);

  const handleOpen = (event: MatterEvent): void => {
    setViewEventOpen(true);
    setViewedEvent(event);
  };

  if (error) {
    return <span>{`Failed to fetch events with error: ${error}`}</span>;
  }
  return (
    <>
      <SharedCalendar
        events={
          (!showComplete &&
            data?.event.filter(x => x.event_complete === false)) ||
          data?.event ||
          []
        }
        onViewEvent={handleOpen}
      />
      <ViewEventModal
        open={openViewEvent}
        onEditClick={(): void => {
          setViewEventOpen(false);
          setEditEventOpen(true);
        }}
        onClose={(): void => setViewEventOpen(false)}
        event={viewedEvent}
      />
      {viewedEvent && (
        <EditEventModal
          open={openEditEvent}
          matterId={viewedEvent.event_matter_id}
          initialState={viewedEvent}
          onClose={(): void => setEditEventOpen(false)}
        />
      )}
    </>
  );
};

export { FirmCalendar };
