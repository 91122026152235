/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import * as moment from 'moment';
import styled from 'styled-components';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import { FileCopyTwoTone } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { themeBorders } from '../../../constants/borders/borders';
import { useColors } from '../../../hooks';
import { boxShadows } from '../../../constants/shadows/shadows';
import { PencilOutline } from '../../../components/stories/SLICONS.stories';
import {
  ATTORNEYS,
  GetCaseTypes,
  GetStages,
} from '../../../components/queries';
import { UpdateModal } from '../CaseUpdate';

const StyledIconButton = styled(IconButton)`
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

const InfoSection = {
  borderLeft: themeBorders.doubleLeft,
  minHeight: 150,
  width: '95%',
  margin: 'auto',
  marginTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 0,
  boxShadow: boxShadows.three,
};

const StyledDropdownText = styled.div`
  #case-status-input {
    color: ${p =>
      (p.text === 'ACTIVE' && `${p.c.mainColors.success}`) ||
      p.c.mainColors.danger};
  }
`;
const StyledFileCopyTwoTone = styled(FileCopyTwoTone)`
  cursor: pointer;
`;

const PencilButton = color => {
  return <PencilOutline width={18} fill={color} />;
};
export const GENERAL = props => {
  const { themeColors } = useColors();
  const mode = localStorage.getItem('theme_mode');
  const { loading, error, data } = useQuery(ATTORNEYS, {
    variables: {
      role: 'Attorney',
    },
  });
  const { loading: loadingCaseTypes, data: caseTypes } = useQuery(GetCaseTypes);
  const { loading: loadingStages, data: stageTypes } = useQuery(GetStages);

  const copyCodeToClipboard = t => {
    document.querySelector(t).select();
    document.execCommand('copy');
  };
  // new edit logic
  const [openEdit, setOpenEdit] = useState(false);
  const { c, readOnly, titleVariant, cardTitleStyle, at } = props;
  const [recordType, setRecordType] = useState();
  const [title, setTitle] = useState();
  const [isMenu, setIsMenu] = useState(false);
  const [menuData, setMenuData] = useState();
  const [text, setText] = useState();
  const handleModal = (r, t, m, d, w) => {
    setTitle(t);
    setRecordType(r);
    setIsMenu(m);
    setMenuData(d);
    setOpenEdit(!openEdit);
    setText(w);
  };
  if (loading || loadingCaseTypes || loadingStages) {
    return <>&apos;loading...&apos;</>;
  }
  if (error) {
    return <div>there has been an error</div>;
  }

  const attorneyMenu = data?.attorney
    ?.filter(x => ['Attorney'].includes(x.attorney_role) && x.attorney_active)
    .map(({ attorney_name, attorney_login_id }) => {
      return { label: attorney_name, value: attorney_login_id };
    });
  const stageMenu = stageTypes?.stage?.map(({ stage_name, stage_id }) => {
    return { label: stage_name, value: stage_id };
  });
  const caseTypeMenu = caseTypes?.matter_type
    ?.slice()
    .sort((a, b) =>
      a.matter_type_name_full.localeCompare(b.matter_type_name_full),
    )
    .map(({ matter_type_name_full, matter_type_id }) => {
      return { label: matter_type_name_full, value: matter_type_id };
    });
  const toggleMenu = [
    { value: true, label: 'Closed' },
    { value: false, label: 'Active' },
  ];
  return (
    <>
      <Paper style={InfoSection} elevation={2}>
        <Typography variant={titleVariant} style={cardTitleStyle}>
          General Info
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="case-name-input"
              label="Matter Name"
              value={c?.matter_name}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Edit">
                      <StyledIconButton
                        m={mode}
                        aria-label="Change Matter Name"
                        onClick={() =>
                          handleModal(
                            'matter_name',
                            'Matter Name',
                            false,
                            [],
                            c.matter_name || '',
                          )
                        }
                      >
                        {PencilButton(themeColors.mainColors.primaryColor)}
                      </StyledIconButton>
                    </Tooltip>
                    <Tooltip title="Copy to Clipboard">
                      <StyledFileCopyTwoTone
                        color="primary"
                        onClick={() => copyCodeToClipboard('#case-name-input')}
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <StyledDropdownText
              text={(c.matter_closed && 'CLOSED') || 'ACTIVE'}
              c={themeColors}
            >
              <TextField
                id="case-status-input"
                label="Case Status"
                value={(c.matter_closed && 'CLOSED') || 'ACTIVE'}
                margin="normal"
                fullWidth
                InputProps={{
                  readOnly,
                  endAdornment: (
                    <InputAdornment position="end">
                      <StyledIconButton
                        m={mode}
                        aria-label="Change Case Status"
                        onClick={() =>
                          handleModal(
                            'matter_closed',
                            'Case Status',
                            true,
                            toggleMenu,
                          )
                        }
                      >
                        {PencilButton(themeColors.mainColors.primaryColor)}
                      </StyledIconButton>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                color="secondary"
              />
            </StyledDropdownText>
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="case-number-input"
              label="Docket"
              fullWidth
              value={c.matter_court_case_number || ''}
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      m={mode}
                      aria-label="Change Case Number"
                      onClick={() =>
                        handleModal(
                          'matter_court_case_number',
                          'Update Docket',
                          false,
                          [],
                          c.matter_court_case_number || '',
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                    <Tooltip title="Copy to Clipboard">
                      <StyledFileCopyTwoTone
                        color="primary"
                        onClick={() =>
                          copyCodeToClipboard('#case-number-input')
                        }
                      />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="opened-date-input"
              label="Case Opened"
              fullWidth
              value={moment(c.matter_opened_date).format('LLL')}
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      m={mode}
                      aria-label="Edit Opened Date"
                      onClick={() =>
                        handleModal(
                          'matter_opened_date',
                          'Case Creation Date',
                          false,
                          [],
                          (c.matter_opened_date &&
                            moment(c.matter_opened_date).format('LLL')) ||
                            '',
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="matter-type-input"
              label="Case Type"
              fullWidth
              value={c?.matter_type?.matter_type_name_full}
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      m={mode}
                      aria-label="Change Matter Type"
                      onClick={() =>
                        handleModal(
                          'matter_type_id',
                          'Matter Type',
                          true,
                          caseTypeMenu,
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <TextField
              id="attorney-name-input"
              label="Case Manager"
              fullWidth
              value={`${at.attorney_name}, Esq.`}
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      m={mode}
                      aria-label="Change Case Manager"
                      onClick={() =>
                        handleModal(
                          'attorney_owner_id',
                          'Case Manager',
                          true,
                          attorneyMenu,
                          c.attorney_owner_id,
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            <TextField
              id="case-stage-input"
              label="Case Stage"
              fullWidth
              value={(c.stage && c.stage?.stage_name) || ''}
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      m={mode}
                      aria-label="Change Case Stage"
                      onClick={() =>
                        handleModal(
                          'matter_stage_id',
                          'Case Stage',
                          true,
                          stageMenu,
                          c.matter_stage_id,
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'left' }}>
            <TextField
              id="matter-details-input"
              label="Case Overview - Client Visible"
              fullWidth
              value={(c.matter_details ?? c.matter_details) || ''}
              multiline
              rows="6"
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      m={mode}
                      aria-label="Edit Matter Details"
                      onClick={() =>
                        handleModal(
                          'matter_details',
                          'Update Details',
                          false,
                          [],
                          (c.matter_details && c.matter_details) || '',
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </Grid>
          <div>
            <Button
              color="primary"
              variant="outlined"
              href={c?.matter_docket_link}
              target="_blank"
              style={{ margin: 15 }}
            >
              Docket Link
            </Button>
            <StyledIconButton
              m={mode}
              aria-label="Change Docket Link"
              onClick={() =>
                handleModal(
                  'matter_docket_link',
                  'Docket Link',
                  false,
                  [],
                  c.matter_docket_link ?? '',
                )
              }
            >
              {PencilButton(themeColors.mainColors.primaryColor)}
            </StyledIconButton>
          </div>
        </Grid>
      </Paper>
      <UpdateModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        Id={c.matter_id}
        recordType={recordType}
        attorney={data}
        data={c}
        title={title}
        isMenu={isMenu}
        menuData={menuData}
        text={text}
      />
    </>
  );
};
