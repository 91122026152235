import React, { useState } from 'react';
import styled from 'styled-components';
import { Paper, Tabs, Tab } from '@material-ui/core';
import { Profiles } from './profiles';
import { Workflows } from './workflows';
import { UserSettings } from './user';

const StyledPaper = styled(Paper)`
  position: relative;
  min-height: 80vh;
  width: 100%;
  border-radius: 10;
  padding-bottom: 40px;
`;

interface SettingsProps {
  label?: string;
}

const tabValues = Object.freeze({
  userSettings: 'user_settings' as const,
  globalSettings: 'global_settings' as const,
  profiles: 'profiles' as const,
  workflows: 'workflows' as const,
});

const tabValuesArray = Object.values(tabValues);

type TabValues = typeof tabValuesArray[number];

export const Settings: React.FC<SettingsProps> = () => {
  const [tab, setTab] = useState<TabValues>('user_settings');

  const handleChange = (_event: unknown, newTab: TabValues): void => {
    setTab(newTab);
  };

  return (
    <>
      <StyledPaper>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          textColor="primary"
          indicatorColor="primary"
          aria-label="Settings Nav Tabs"
        >
          <Tab value={tabValues.userSettings} label="User Settings" />
          <Tab value={tabValues.globalSettings} label="Global Settings" />
          <Tab value={tabValues.profiles} label="Profiles" />
          <Tab value={tabValues.workflows} label="Workflows" />
        </Tabs>
        {tab === 'user_settings' && <UserSettings />}
        {tab === 'profiles' && <Profiles />}
        {tab === 'workflows' && <Workflows />}
      </StyledPaper>
    </>
  );
};
