import React, { useState, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import { Paper, Grid, Switch, FormControlLabel } from '@material-ui/core';
import { FirmCalendar } from './FirmCalendar';

const StyledPaper = styled(Paper)`
  width: 100%;
  margin: 0 auto 35px;
  padding-bottom: 35px;
`;

const StyledSwitchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 5px 0px;
`;

const CalendarContainer: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('%');
  const [searchCounty, setSearchCounty] = useState('%');

  const handleSearchTerm = useCallback(
    (value): void => {
      setSearchTerm(`%${value}%`);
    },
    [setSearchTerm],
  );

  const handleSearchCounty = useCallback(
    (value): void => {
      setSearchCounty(`%${value}%`);
    },
    [setSearchCounty],
  );

  const debouncedHandleSearchTerm = useMemo(
    () => _.debounce(handleSearchTerm, 500),
    [handleSearchTerm],
  );

  const debouncedHandleSearchCounty = useMemo(
    () => _.debounce(handleSearchCounty, 500),
    [handleSearchCounty],
  );

  const [showComplete, setShowComplete] = useState<boolean>(false);

  const handleShowComplete = () => {
    setShowComplete(!showComplete);
  };

  return (
    <StyledPaper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5}>
          <div style={{ width: '90%', margin: 'auto' }}>
            <TextField
              id="outlined-basic"
              placeholder="Court Name, MDJ #, Judge, Court Type"
              label="Judge or Court"
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={(e): void => {
                debouncedHandleSearchTerm(e.target.value);
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              verticalAlign: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <div>And/Or</div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <div style={{ width: '90%', margin: 'auto' }}>
            <TextField
              id="outlined-basic"
              placeholder="County or City"
              label="County or City"
              margin="normal"
              variant="outlined"
              fullWidth
              onChange={(e): void => {
                debouncedHandleSearchCounty(e.target.value);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <StyledSwitchContainer>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={showComplete}
              onChange={handleShowComplete}
              color="primary"
            />
          }
          label="Show Completed"
        />
      </StyledSwitchContainer>
      <FirmCalendar
        searchTerm={searchTerm}
        searchCounty={searchCounty}
        showComplete={showComplete}
      />
    </StyledPaper>
  );
};

export { CalendarContainer };
