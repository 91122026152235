import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { BaseWorkflowModal } from './BaseWorkflowModal';
import { ADD_WORKFLOW } from '../../mutations';
import { EditableWorkflow } from '../../../utils';

type WorkflowModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddWorkflowModal: React.FC<WorkflowModalProps> = ({ open, onClose }) => {
  const [formError, setFormError] = useState('');
  const [addEvent, { data, loading, error }] = useMutation(ADD_WORKFLOW);

  useEffect(() => {
    if (!loading && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = (workflowState: EditableWorkflow): void => {
    setFormError('');
    addEvent({
      variables: {
        workflow_name: workflowState.workflow_name,
        workflow_description: workflowState.workflow_description,
      },
    });
  };

  return (
    <BaseWorkflowModal
      open={open}
      title="Create Workflow"
      formError={formError}
      onSave={onSave}
      onClose={onClose}
      loading={loading}
    />
  );
};

export { AddWorkflowModal };
