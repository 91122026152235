import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
} from '@material-ui/core';

type RadioOption = {
  label: string;
  value: string;
};

type RadioGroupProps = {
  legendText: string;
  options: RadioOption[];
  /**
   * Defaults to the first item in the
   * list if no default value is provided
   */
  defaultValue?: string;
  onValueSelected: (value: string) => void;
  className?: string;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  legendText,
  options,
  defaultValue,
  onValueSelected,
  className,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    defaultValue || options[0].value,
  );

  return (
    <FormControl component="fieldset" style={{ paddingLeft: 12 }}>
      <FormLabel component="legend">{legendText}</FormLabel>
      <MuiRadioGroup
        row
        className={className}
        name={legendText}
        value={selectedValue}
        onChange={(e): void => {
          const { value } = e.target;
          setSelectedValue(value);
          onValueSelected(value);
        }}
      >
        <>
          {options.map(
            (item): React.ReactElement => (
              <FormControlLabel
                key={item.value}
                value={item.value}
                control={<Radio />}
                label={item.label}
              />
            ),
          )}
        </>
      </MuiRadioGroup>
    </FormControl>
  );
};

export { RadioGroup };
