import React from 'react';
import FullCalendar from '@fullcalendar/react';
import { Button, Typography } from '@material-ui/core';
import moment from 'moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatterEvent } from '../../../utils/types';
import { useCurrentTimeZone } from '../../../hooks';
import { InnerContent } from './itemContent';
import '../../../css/core/main.css';
import '../../../css/daygrid/main.css';

export type CalendarProps = {
  events: MatterEvent[];
  onViewEvent: (event: MatterEvent) => void;
  suppressHeader?: boolean;
};

const { zoneFull } = useCurrentTimeZone();

export const Calendar: React.FC<CalendarProps> = ({
  events,
  onViewEvent,
  suppressHeader,
}) => {
  const findEvent = (eventId: string): MatterEvent | undefined => {
    return events.find((event): boolean => event.event_id === eventId);
  };

  return (
    <div style={{ margin: '0 15px', marginTop: 45 }}>
      <Typography component="div" variant="body2">
        Time Zone: {zoneFull}
      </Typography>

      <FullCalendar
        headerToolbar={
          (!suppressHeader && {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,future30',
          }) || {
            left: 'prev,next',
            center: '',
            right: '',
          }
        }
        views={
          (!suppressHeader && {
            future30: {
              type: 'dayGridMonth',
              duration: { weeks: 4 },
              buttonText: 'Future30',
              fixedWeekCount: false,
            },
          }) ||
          {}
        }
        initialView={(!suppressHeader && 'future30') || 'timeGridDay'}
        initialDate={moment(new Date()).format('YYYY-MM-DD')}
        weekends={false}
        editable
        fixedWeekCount={false}
        // timeZone="Local"
        nowIndicator
        dayMaxEventRows // for all non-TimeGrid views
        displayEventEnd
        scrollTime="07:00:00"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          // interactionPlugin,
        ]}
        // themeSystem="bootstrap"
        moreLinkContent={(arg: any): JSX.Element => {
          return (
            <Button variant="outlined" color="primary">
              +{arg?.num} Events Hidden
            </Button>
          );
        }}
        moreLinkClick="day"
        eventContent={(calProps: any): JSX.Element => {
          const event = findEvent(calProps?.event?.id);
          return <div>{InnerContent(calProps, event)}</div>;
        }}
        events={events.map(
          ({
            event_id,
            event_name,
            event_date_time,
            event_end_time,
            event_color,
            attorney,
          }) => ({
            id: event_id,
            title: event_name || '',
            start: event_date_time || '',
            end: event_end_time || '',
            backgroundColor: attorney?.attorney_id_color || event_color,
          }),
        )}
        eventClick={({ event }): void => {
          const foundEvent = findEvent(event.id);
          if (foundEvent) onViewEvent(foundEvent);
        }}
      />
    </div>
  );
};
