import React from 'react';
import { Grid, TextField } from '@material-ui/core';

export const OpFirmProfile = ({ handleChangeOpFirmProfile, opFirmProfile }) => {
  return (
    <>
      <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-name-input"
          label="Opposing Firm"
          onChange={handleChangeOpFirmProfile('name')}
          fullWidth
          value={opFirmProfile?.name}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-phone-input"
          label="Phone"
          onChange={handleChangeOpFirmProfile('phone')}
          fullWidth
          value={opFirmProfile?.phone}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-fax-input"
          label="Fax"
          onChange={handleChangeOpFirmProfile('fax')}
          fullWidth
          value={opFirmProfile?.fax}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={9} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-address-input"
          label="Address"
          onChange={handleChangeOpFirmProfile('address')}
          fullWidth
          value={opFirmProfile?.address}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-address2-input"
          label="Ste/Bldg/Fl"
          onChange={handleChangeOpFirmProfile('address2')}
          fullWidth
          value={opFirmProfile?.address2}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-city-input"
          label="City"
          onChange={handleChangeOpFirmProfile('city')}
          fullWidth
          value={opFirmProfile?.city}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-state-input"
          label="State"
          onChange={handleChangeOpFirmProfile('state')}
          fullWidth
          value={opFirmProfile?.state}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip-input"
          label="Zip"
          onChange={handleChangeOpFirmProfile('zip')}
          fullWidth
          value={opFirmProfile?.zip}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip4-input"
          label="+4"
          onChange={handleChangeOpFirmProfile('zip4')}
          fullWidth
          value={opFirmProfile?.zip4}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
    </>
  );
};
