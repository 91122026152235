import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { useIndexer } from '../../context';
import { sharedStyles } from './utils';

const StyledInput = styled(TextField)`
  ${sharedStyles}
`;

type InputProps = {
  label: string;
  value: string;
  error?: boolean;
  errorText?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
};

const Input: React.FC<InputProps> = ({
  label,
  value,
  error,
  errorText,
  onChange,
  className,
}) => {
  const componentIndex = useIndexer();

  const inputId = useMemo(() => `input-field-${componentIndex}`, [
    componentIndex,
  ]);

  return (
    <StyledInput
      id={inputId}
      className={className}
      label={label}
      value={value}
      error={error}
      helperText={error ? errorText : ''}
      margin="normal"
      variant="outlined"
      onChange={onChange}
    />
  );
};

export { Input };
