import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import {
  Paper,
  Typography,
  Dialog,
  DialogContentText,
  Button,
  TextField,
  InputAdornment,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useUpdateProfile } from '../../../../../hooks';
import { boxShadows } from '../../../../../constants';
import { WinLoss } from '../../../../../components';

const CourtQuery = gql`
  query CourtQuery($id: uuid) {
    court(where: { court_id: { _eq: $id } }) {
      court_address_city
      court_address_state
      court_address_street
      court_address_street2
      court_address_zip
      court_address_zip_4
      court_county
      court_efile_link
      court_fax
      court_mdj_number
      court_name
      court_phone
      court_type
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        matter_id
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;

const StyledContentContainer = styled.div`
  padding-top: 75px;
`;
const StyledDialogContent = styled.div`
  width: 75%;
  min-width: 320px;
  padding: 5px 15px;
  /* max-width: 1200px; */
  margin: auto;
  overflow-x: hidden;
`;
const MatterSection = styled.div`
  margin: 50px auto;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
const MatterCard = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 10px 0;
  min-width: 280px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
    /* transform: scale(1.02); */
  }
`;
const MatterCardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;

export const CourtProfileDialog = ({ open, handleClose, id }) => {
  const { onSave } = useUpdateProfile();
  const [courtDetails, setCourtDetails] = useState<any>([]);
  const { data, loading, error } = useQuery(CourtQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  // -------------------------Edits

  // Name Change
  const [courtNameReady, setCourtNameReady] = useState(false);
  const [newCourtName, setNewCourtName] = useState('');
  const handleCourtNameChange = (e): void => {
    setCourtNameReady(true);
    setNewCourtName(e.target.value);
  };
  const handleSaveCourtName = (): void => {
    setCourtNameReady(false);
    onSave('CourtName', id, newCourtName);
  };

  useEffect(() => {
    if (data && id) {
      setCourtDetails(data?.court[0]);
      setNewCourtName(data?.court[0].court_name);
    }
  }, [data]);

  if (loading) {
    return <>...loading</>;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{courtDetails?.court_name}</Typography>
        </Toolbar>
      </AppBar>
      <StyledContentContainer />
      <DialogContentText>
        <StyledDialogContent>
          <TextField
            variant="filled"
            label="Name"
            value={newCourtName || ''}
            fullWidth
            onChange={handleCourtNameChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {courtNameReady && (
                    <Button
                      onClick={handleSaveCourtName}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <MatterSection>
            <Typography variant="h5">Matters</Typography>
            <br />
            <CardContainer>
              {data?.court[0]?.matters?.map(
                ({ matter_name, matter_id, matter_closed, stage }) => {
                  return (
                    <MatterCard
                      key={matter_id}
                      onClick={(): void => {
                        const win: Window | null = window.open(
                          `/cases/${matter_id}`,
                          '_blank',
                        );
                        if (win === null) {
                          return;
                        }
                        win.focus();
                      }}
                    >
                      <MatterCardContentContainer>
                        <div>
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: 12, padding: '0 25px' }}
                          >
                            {matter_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: 10, padding: '0 25px' }}
                          >
                            {stage?.stage_name || ''}
                          </Typography>
                        </div>
                        <EndPiece>
                          <Typography variant="subtitle2">
                            {(!matter_closed && 'Open') || 'Closed'}
                          </Typography>
                        </EndPiece>
                      </MatterCardContentContainer>
                    </MatterCard>
                  );
                },
              )}
            </CardContainer>
          </MatterSection>
          <MatterSection>
            <Typography variant="h5">Statistics</Typography>
            <br />
            <WinLoss
              title="Win Rate"
              labels={['Won', 'Lost', 'Withdrawn', 'Pending']}
              data={[
                data?.court[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Won',
                ).length,
                data?.court[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Lost',
                ).length,
                data?.court[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Withdrawn',
                ).length,
                data?.court[0]?.matters
                  ?.filter(
                    x => x.stage?.stage_name !== 'Concluded/Closed-Withdrawn',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Won')
                  .filter(
                    x =>
                      x.stage?.stage_name !== 'Concluded/Closed-Dismissed-WOP',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Lost')
                  .length,
              ]}
            />
          </MatterSection>
        </StyledDialogContent>
      </DialogContentText>
    </Dialog>
  );
};
