import React from 'react';
import { Grid } from '@material-ui/core';

const FormGrid: React.FC = ({ children }) => (
  <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
    <>
      {React.Children.map(children, Child =>
        Child ? (
          <Grid item xs={12} md={6}>
            {Child}
          </Grid>
        ) : null,
      )}
    </>
  </Grid>
);

export { FormGrid };
