import React from 'react';
import styled from 'styled-components';
import {
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
} from '@material-ui/core';
import { useColors } from '../../../hooks';

const { themeColors } = useColors();

const StyledTableHead = styled(TableHead)`
/* border-top: 2px solid ${themeColors.mainColors.primaryColor}; */
`;

const TaskTableHead: React.FC = () => {
  const [selectAll, setSelectAll] = React.useState(false);
  return (
    <StyledTableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={selectAll}
            onClick={(): void => setSelectAll(!selectAll)}
            // inputProps={{
            //   'aria-labelledby': labelId,
            // }}
          />
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Task</Typography>
        </TableCell>
        {/* <TableCell align="left">
        <Typography color="secondary">Description</Typography>
      </TableCell> */}
        <TableCell align="left">
          <Typography color="secondary">Due On</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Assigned To</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Status</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography color="secondary">Matter</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography color="secondary">Options</Typography>
        </TableCell>
      </TableRow>
    </StyledTableHead>
  );
};

export { TaskTableHead };
