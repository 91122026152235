import { gql, useQuery } from '@apollo/client';
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { WinLoss } from '../../../../../components';
import { boxShadows } from '../../../../../constants';
import { useUpdateProfile } from '../../../../../hooks';

const JudgeQuery = gql`
  query JudgeQuery($id: uuid) {
    judge(where: { judge_id: { _eq: $id } }) {
      judge_first_name
      judge_id
      judge_last_name
      judge_middle_name
      judge_notes
      judge_undesired
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        matter_id
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;

const StyledContentContainer = styled.div`
  padding-top: 75px;
`;
const StyledDialogContent = styled.div`
  width: 75%;
  min-width: 320px;
  padding: 5px 15px;
  /* max-width: 1200px; */
  margin: auto;
  overflow-x: hidden;
`;
const StyledItem = styled.div`
  min-width: 200px;
`;
const StyledNotesItem = styled.div`
  min-width: 200px;
  margin: 10px auto;
`;
const StyledNameContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  @media only screen and (max-width: 675px) {
    grid-template-columns: 1fr;
  }
`;
const MatterSection = styled.div`
  margin: 50px auto;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
const MatterCard = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 10px 0;
  min-width: 280px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
    /* transform: scale(1.02); */
  }
`;
const MatterCardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;

export const JudgeProfileDialog: React.FC<any> = ({
  open,
  handleClose,
  id,
}) => {
  const { onSave } = useUpdateProfile();
  const [judgeDetails, setJudgeDetails] = useState<any>([]);
  const { data, loading, error } = useQuery(JudgeQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  // -------------------------Edits

  // First Name Change
  const [firstNameReady, setFirstNameReady] = useState(false);
  const [newFirstName, setNewFirstName] = useState('');
  const handleFirstNameChange = (e): void => {
    setFirstNameReady(true);
    setNewFirstName(e.target.value);
  };
  const handleSaveFirstName = (): void => {
    setFirstNameReady(false);
    onSave('JudgeFirst', id, newFirstName);
  };

  // Last Name Change
  const [lastNameReady, setLastNameReady] = useState(false);
  const [newLastName, setNewLastName] = useState('');
  const handleLastNameChange = (e): void => {
    setLastNameReady(true);
    setNewLastName(e.target.value);
  };

  const handleSaveLastName = (): void => {
    setLastNameReady(false);
    onSave('JudgeLast', id, newLastName);
  };

  // Middle Name Change
  const [middleNameReady, setMiddleNameReady] = useState(false);
  const [newMiddleName, setNewMiddleName] = useState('');
  const handleMiddleNameChange = (e): void => {
    setMiddleNameReady(true);
    setNewMiddleName(e.target.value);
  };

  const handleSaveMiddleName = (): void => {
    setMiddleNameReady(false);
    onSave('JudgeMiddle', id, newMiddleName);
  };

  // Notes Change
  const [notesReady, setNotesReady] = useState(false);
  const [newNotes, setNewNotes] = useState('');
  const handleNotesChange = (e): void => {
    setNotesReady(true);
    setNewNotes(e.target.value);
  };

  const handleSaveNotes = (): void => {
    setNotesReady(false);
    onSave('JudgeNotes', id, newNotes);
  };

  // undesired change
  const [undesiredNew, setUndesiredNew] = useState<boolean>();

  useEffect(() => {
    if (data && id) {
      setJudgeDetails(data?.judge[0]);
      setNewFirstName(data?.judge[0]?.judge_first_name);
      setNewLastName(data?.judge[0]?.judge_last_name);
      setNewMiddleName(data?.judge[0]?.judge_middle_name);
      setNewNotes(data?.judge[0]?.judge_notes);
      setUndesiredNew(data?.judge[0]?.judge_undesired);
    }
  }, [data]);

  if (loading) {
    return <>...loading</>;
  }
  console.log('judge deets: ', judgeDetails);

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <AppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              {`Judge ${judgeDetails?.judge_first_name} ${judgeDetails?.judge_last_name}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <StyledContentContainer />
        <DialogContentText>
          <StyledDialogContent>
            <StyledNameContainer>
              <StyledItem>
                <TextField
                  variant="filled"
                  label="First Name"
                  value={newFirstName || ''}
                  fullWidth
                  onChange={handleFirstNameChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {firstNameReady && (
                          <Button
                            onClick={handleSaveFirstName}
                            variant="outlined"
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledItem>
              <StyledItem>
                <TextField
                  variant="filled"
                  label="Middle Name"
                  value={newMiddleName || ''}
                  fullWidth
                  onChange={handleMiddleNameChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {middleNameReady && (
                          <Button
                            onClick={handleSaveMiddleName}
                            variant="outlined"
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledItem>
              <StyledItem>
                <TextField
                  variant="filled"
                  label="Last Name"
                  value={newLastName || ''}
                  fullWidth
                  onChange={handleLastNameChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {lastNameReady && (
                          <Button
                            onClick={handleSaveLastName}
                            variant="outlined"
                            color="primary"
                          >
                            Save
                          </Button>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledItem>
            </StyledNameContainer>
            <StyledNotesItem>
              <TextField
                variant="filled"
                label="Notes"
                value={newNotes || ''}
                multiline
                rowsMax={8}
                fullWidth
                onChange={handleNotesChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {notesReady && (
                        <Button
                          onClick={handleSaveNotes}
                          variant="outlined"
                          color="primary"
                        >
                          Save
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </StyledNotesItem>
            <FormControlLabel
              control={
                <Switch
                  checked={undesiredNew}
                  onChange={(): void => {
                    onSave('JudgeUndesired', id, !undesiredNew);
                    setUndesiredNew(!undesiredNew);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Undesired"
            />
            <MatterSection>
              <Typography variant="h5">Matters</Typography>
              <br />
              <CardContainer>
                {data?.judge[0]?.matters?.map(
                  ({ matter_name, matter_id, matter_closed, stage }) => {
                    return (
                      <MatterCard
                        key={matter_id}
                        onClick={(): void => {
                          const win: Window | null = window.open(
                            `/cases/${matter_id}`,
                            '_blank',
                          );
                          if (win === null) {
                            return;
                          }
                          win.focus();
                        }}
                      >
                        <MatterCardContentContainer>
                          <div>
                            <Typography
                              variant="subtitle2"
                              style={{ fontSize: 12, padding: '0 25px' }}
                            >
                              {matter_name}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              style={{ fontSize: 10, padding: '0 25px' }}
                            >
                              {stage?.stage_name || ''}
                            </Typography>
                          </div>
                          <EndPiece>
                            <Typography variant="subtitle2">
                              {(!matter_closed && 'Open') || 'Closed'}
                            </Typography>
                          </EndPiece>
                        </MatterCardContentContainer>
                      </MatterCard>
                    );
                  },
                )}
              </CardContainer>
              <Typography variant="h5">Statistics</Typography>
              <WinLoss
                title="Win Rate"
                labels={['Won', 'Lost', 'Withdrawn', 'Pending']}
                data={[
                  data?.judge[0]?.matters?.filter(
                    x => x.stage?.stage_name === 'Concluded/Closed-Won',
                  ).length,
                  data?.judge[0]?.matters?.filter(
                    x => x.stage?.stage_name === 'Concluded/Closed-Lost',
                  ).length,
                  data?.judge[0]?.matters?.filter(
                    x => x.stage?.stage_name === 'Concluded/Closed-Withdrawn',
                  ).length,
                  data?.judge[0]?.matters
                    ?.filter(
                      x => x.stage?.stage_name !== 'Concluded/Closed-Withdrawn',
                    )
                    .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Won')
                    .filter(
                      x =>
                        x.stage?.stage_name !==
                        'Concluded/Closed-Dismissed-WOP',
                    )
                    .filter(
                      x => x.stage?.stage_name !== 'Concluded/Closed-Lost',
                    ).length,
                ]}
              />
            </MatterSection>
          </StyledDialogContent>
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            autoFocus
            style={{ fontWeight: 'bold' }}
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
