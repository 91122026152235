import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { TaskTable } from '../../../../components';
import { Matter } from '../../../../utils';

const StyledWrapper = styled.div`
  margin: 40px;
`;

const StyledHeading = styled(Typography)`
  margin-left: 6px;
  margin-bottom: 8px;
`;

type MatterTaskTableProps = {
  matter: Matter;
};

const MatterTaskTable: React.FC<MatterTaskTableProps> = ({ matter }) => {
  const { tasks_individual } = matter;

  return (
    <StyledWrapper>
      <StyledHeading variant="h5" color="primary">
        Matter Tasks
      </StyledHeading>
      {matter.tasks_individual && (
        <TaskTable tasks={tasks_individual || []} matter={matter} individual />
      )}
    </StyledWrapper>
  );
};

export { MatterTaskTable };
