import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { BaseEventModal } from './BaseEditableEventModal';
import { ADD_EVENT } from '../../mutations';
import { EditableMatterEvent } from '../../../utils';

type EventModalProps = {
  open: boolean;
  matterId: string;
  onClose: () => void;
};

const AddEventModal: React.FC<EventModalProps> = ({
  open,
  onClose,
  matterId,
}) => {
  const [formError, setFormError] = useState('');
  const [addEvent, { data, loading, error }] = useMutation(ADD_EVENT);

  useEffect(() => {
    if (!loading && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = (eventState: EditableMatterEvent): void => {
    setFormError('');
    addEvent({
      variables: {
        event_matter_id: matterId,
        event_name: eventState.event_name,
        event_location: eventState.event_location,
        event_county: eventState.event_county,
        event_owner: eventState.event_owner,
        event_type: eventState.event_type,
        event_date_time: eventState.event_date_time,
        event_end_time: eventState.event_end_time,
      },
    });
  };

  return (
    <BaseEventModal
      open={open}
      title="Create Event"
      formError={formError}
      onSave={onSave}
      onClose={onClose}
      loading={loading}
    />
  );
};

export { AddEventModal };
