import React, { createContext, useReducer, Dispatch } from 'react';
import { userReducer, UserActions } from './reducers';

type UserType = {
  id: any;
  userRole?: string;
  SLuserId?: string;
  Auth0UserId?: string;
  email?: string;
  mailingStreet?: string;
  mailingState?: string;
  mailingCity?: string;
  mailingZip?: number | null | undefined;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  stripeId?: string;
};

type InitialStateType = {
  user: UserType;
};

const initialState = {
  user: {
    id: '',
    userRole: 'client',
    SLuserId: '',
    Auth0UserId: '',
    email: '',
    stripeId: '',
  },
};

const UserContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<UserActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = ({ user }: InitialStateType, action: UserActions) => ({
  user: userReducer(user, action),
});

const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
