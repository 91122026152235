import React from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';

const WorkflowTaskTableHead: React.FC = () => (
  <TableHead>
    <TableRow>
      <TableCell align="left">
        <Typography color="secondary">Priority</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography color="secondary">Task</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography color="secondary">Due From</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography color="secondary">Date Locked</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography color="secondary">Options</Typography>
      </TableCell>
    </TableRow>
  </TableHead>
);

export { WorkflowTaskTableHead };
