import { gql } from '@apollo/client';

export const WORKFLOWS = gql`
  query WORKFLOWS($role: String) {
    workflow {
      workflow_id
      workflow_name
      workflow_description
      workflow_tasks {
        workflow_task_id
        workflow_task_name
        workflow_task_description
        workflow_task_dynamic_due_from
        workflow_task_dynamic_lock
        workflow_task_workflow_id
        trigger_workflow_id
        task_is_priority
      }
    }
  }
`;
