import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useQuery } from '@apollo/client';
import GET_SINGLE_MESSAGE from '../../components/queries/messages/getSingleMessageQuery';
import { MessageLoader } from './messageLoader';

export const Message = props => {
  const messageID = props.match.params.messageID;
  const { loading, error, data } = useQuery(GET_SINGLE_MESSAGE, {
    variables: { messageID },
  });
  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <div>There has been an error in the API call</div>;
  }
  return (
    <>
      <MessageLoader data={data} />
    </>
  );
};
