import axios from 'axios';

export const Uploader = (
  data: any,
  user: string,
  matter: string,
  reload: number,
  setReload: any,
  setOpen: any,
  setProgress: any,
  handleClearFiles: any,
): void => {
  const options = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      User: user,
      Matter: matter,
    },
    onUploadProgress: progressEvent => {
      const { loaded, total } = progressEvent;
      const percent = Math.round((loaded * 100) / total);
      setProgress(percent);
    },
  };
  const baseServerUrl = process.env.REACT_APP_SERVER_URL;
  const formData = new FormData();
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < data.length; i++) {
    formData.append('file', data[i]);
  }

  const putURL = `${baseServerUrl}/case-files`;
  setOpen(true);
  axios
    .post(putURL, formData, options)
    .then(() => setOpen(false))
    .then(() => handleClearFiles())
    .then(() => setReload(reload + 1))

    .catch(err => {
      console.log('err', err);
    });
};
