import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import { useIndexer } from '../../context';
import { useCurrentAttorney } from '../../hooks';
import { ATTORNEYS_ALL } from '../queries';
import { sharedStyles } from './utils';

const StyledFormControl = styled(FormControl)`
  ${sharedStyles}
`;

export type Attorney = {
  id: number;
  name: string;
};

type AttorneySelectProps = {
  onAttorneySelect: (attorney: Attorney) => void;
  initialValue?: string;
  defaultToCurrentAttorney?: boolean;
  error?: boolean;
  errorText?: string;
  internalOnly?: boolean;
};

const AttorneySelect: React.FC<AttorneySelectProps> = ({
  onAttorneySelect,
  initialValue,
  defaultToCurrentAttorney,
  error,
  errorText = 'Please select an attorney',
  internalOnly = true,
}) => {
  const componentIndex = useIndexer();

  const { data, error: attorney_data_error } = useQuery(ATTORNEYS_ALL, {});

  const { currentAttorney } = useCurrentAttorney();

  const [attorneyName, setAttorneyName] = useState(initialValue || '');

  const labelId = useMemo(() => `attorney-select-label-${componentIndex}`, [
    componentIndex,
  ]);

  const selectId = useMemo(() => `attorney-select-${componentIndex}`, [
    componentIndex,
  ]);

  const findAttorney = (name: string): Attorney => {
    const foundAttorney = data.attorney.find(item => {
      return name === item.attorney_name;
    });
    return {
      id: foundAttorney.attorney_id,
      name: foundAttorney.attorney_name,
    };
  };

  useEffect(() => {
    if (defaultToCurrentAttorney && currentAttorney) {
      setAttorneyName(currentAttorney.attorney_name);
    }
  }, [currentAttorney]);

  useEffect(() => {
    if (data && attorneyName) {
      onAttorneySelect(findAttorney(attorneyName));
    }
  }, [data, attorneyName]);
  const attorneyTypeArray = ['Attorney', 'Support Staff', 'System User'];
  return (
    <>
      {data ? (
        <StyledFormControl variant="outlined" error={error}>
          <InputLabel id={labelId} htmlFor={selectId}>
            Attorney
          </InputLabel>
          <Select
            labelId={labelId}
            id={selectId}
            value={attorneyName}
            onChange={(e): void => {
              const name = e.target.value as string;
              setAttorneyName(name);
            }}
            labelWidth={70}
          >
            {data.attorney
              .slice()
              .filter(x =>
                internalOnly
                  ? attorneyTypeArray.includes(x.attorney_role) &&
                    x.attorney_active
                  : x.attorney_active,
              )
              .sort((a, b) => a.attorney_name.localeCompare(b.attorney_name))
              .map(({ attorney_id, attorney_name, attorney_role }) => (
                <MenuItem key={attorney_id} value={attorney_name}>
                  {attorney_role === 'Attorney'
                    ? `${attorney_name}, Esq. `
                    : attorney_name}
                </MenuItem>
              ))}
          </Select>
          {error && <FormHelperText>{errorText}</FormHelperText>}
        </StyledFormControl>
      ) : null}
    </>
  );
};

export { AttorneySelect };
