import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { MatterEvent } from '../../../../../utils';

type EventDetailsProps = {
  event: MatterEvent;
};

const StyledDescriptorText = styled(Typography)`
  font-weight: bold;
`;

const StyledInfoText = styled(Typography)`
  padding-bottom: 8px;
`;

type TextPairProps = {
  descriptor: string;
  info: string;
};

const TextPair: React.FC<TextPairProps> = ({ descriptor, info }) => (
  <Grid item xs={12} md={6}>
    <StyledDescriptorText variant="body2">{descriptor}</StyledDescriptorText>
    <StyledInfoText variant="body2">{info}</StyledInfoText>
  </Grid>
);

const EventDetails: React.FC<EventDetailsProps> = ({ event }) => {
  const {
    event_date_time,
    event_location,
    attorney,
    event_county,
    event_type,
  } = event;

  return (
    <Grid
      container
      style={{ width: '94.5%', margin: 'auto', paddingBottom: 20 }}
    >
      <TextPair
        descriptor="Event Date:"
        info={moment(event_date_time).format('ddd, MMM DD YYYY')}
      />
      <TextPair
        descriptor="Time:"
        info={moment(event_date_time).format('h:mm A')}
      />
      <TextPair descriptor="Event County:" info={event_county || ''} />
      <TextPair descriptor="Event Type:" info={event_type || ''} />
      <TextPair descriptor="Attendee" info={`${attorney?.attorney_name}`} />
      <TextPair
        descriptor="Attendee Type"
        info={`${attorney?.attorney_role}`}
      />
      <TextPair descriptor="Location:" info={event_location || ''} />
    </Grid>
  );
};

export { EventDetails };
