import { useEffect } from 'react';
import { gql, useSubscription } from '@apollo/client';
import { Workflow } from '../../../utils';

export const workflowSubscription = gql`
  subscription WorkflowSubscription {
    workflow {
      workflow_description
      workflow_id
      workflow_name
      workflow_tasks {
        task_is_priority
        workflow_task_description
        workflow_task_dynamic_due_from
        workflow_task_dynamic_lock
        workflow_task_id
        workflow_task_name
        workflow_task_workflow_id
        trigger_workflow_id
        workflowTrigger {
          workflow_description
          workflow_id
          workflow_name
          workflow_tasks {
            workflow_task_description
            workflow_task_dynamic_due_from
            workflow_task_dynamic_lock
            workflow_task_id
            workflow_task_name
            workflow_task_workflow_id
            trigger_workflow_id
          }
        }
      }
    }
  }
`;

type WorkflowValues = {
  workflows?: Workflow[];
  loading: boolean;
};

export const useWorkflows = (): WorkflowValues => {
  const { loading, error, data } = useSubscription<{ workflow: Workflow[] }>(
    workflowSubscription,
  );

  useEffect(() => {
    if (error) {
      console.error('Error occurred in useWorkflows subscription: ', error);
    }
  }, [error]);

  return { workflows: data?.workflow, loading };
};
