import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Editor } from '@tinymce/tinymce-react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import { useColors } from '../../hooks';
import { boxShadows } from '../../constants/shadows/shadows';

const { themeColors } = useColors();

export const PostLoader = props => {
  const bodytext = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 30,
  };
  const [body, setBody] = useState(props.data.article[0].article_body);
  const handleEditorChange = e => {
    setBody(e.target.getContent());
  };
  const [title, setTitle] = useState(props.data.article[0].article_title);
  const handleTitleChange = e => {
    setTitle(e.target.value);
  };
  const [summary, setSummary] = useState(props.data.article[0].article_summary);
  const handleSummaryChange = e => {
    setSummary(e.target.value);
  };
  const [url, setUrl] = useState(props.data.article[0].article_url_slug);
  const handleUrlChange = e => {
    setUrl(e.target.value);
  };
  const [category, setCategory] = useState(
    props.data.article[0].category.category_name,
  );
  const handleCategoryChange = e => {
    setCategory(e.target.value);
  };
  const handleDelete = () => {
    // console.info("You clicked the delete icon.");
  };
  return (
    <Paper>
      <div style={bodytext}>
        <div className="poststyling">
          <div style={{ height: 30 }} />
          <div style={{ margin: 'auto' }}>
            <Paper
              style={{
                padding: 30,
                borderLeft: `20px double ${themeColors.mainColors.primaryColor}`,
                margin: 'auto',
                marginBottom: 45,
                boxShadow: boxShadows.three,
              }}
            >
              <TextField
                id="outlined-basic"
                label="Title"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleTitleChange}
                defaultValue={props.data.article[0].article_title}
                value={title}
              />
              <TextField
                id="outlined-basic"
                label="Summary"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                rows="4"
                onChange={handleSummaryChange}
                defaultValue={props.data.article[0].article_summary}
                value={summary}
              />
              <TextField
                id="outlined-basic"
                label="Category"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleCategoryChange}
                defaultValue={props.data.article[0].category.category_name}
                value={category}
              />
              <TextField
                id="outlined-basic"
                label="URL Slug"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleUrlChange}
                defaultValue={props.data.article[0].article_url_slug}
                value={url}
              />
              <div>
                {props.data.article[0].article_tags.map(({ tag }) => (
                  <Chip
                    key={tag.tag_id}
                    label={tag.tag_value}
                    style={{ marginRight: 5 }}
                    color="secondary"
                    onDelete={handleDelete}
                  />
                ))}
                <Chip
                  label="Add Keyword"
                  style={{ marginRight: 5, marginTop: 5 }}
                  color="secondary"
                />
              </div>
            </Paper>
          </div>
          <Paper
            style={{
              padding: 30,
              borderLeft: `20px double ${themeColors.mainColors.primaryColor}`,
              boxShadow: boxShadows.three,
            }}
          >
            <Editor
              initialValue={props.data.article[0].article_body}
              apiKey="9h9g57gwpv98kda2tlz6pltsy0ae1muqb5dvgez27rvy2gsa"
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink autoresize lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code | help',
              }}
              onChange={handleEditorChange}
            />
          </Paper>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            props.data.article[0].article_css ||
            `
      .poststyling p {
          text-align: justify;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          font-size: 1.15em;
          font-family: 'Quattrocento', serif;
          }
        .poststyling ul {
          text-align: left;
        }
        .poststyling tbody {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          }
        .poststyling h2 {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          font-weight: 400;
          font-size: 1.7em;
          text-align: left;
          font-family: "trajan-pro-3", serif;
          }
        .poststyling h3 {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          text-align: left;
          font-family: "trajan-pro-3", serif;
          font-size: 1.45em;
          }
        .poststyling h1 {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          text-align: left;
          font-family: "trajan-pro-3", serif;
          }
        .poststyling img {
          float: right;
          margin-left: 15px;
          margin-bottom: 15px;
          width: 25vw;
          min-width: 225px;
          height: auto;
          border-radius: 10px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }`,
        }}
      />
    </Paper>
  );
};
