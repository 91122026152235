/* eslint-disable react/no-danger */
import React from 'react';
import { Button } from '@material-ui/core';
import { MatterEvent } from '../../../utils/types';

export interface HoverContentProps {
  calendarProps: any;
  event: MatterEvent | undefined;
}
export const HoverContent: React.FC<HoverContentProps> = (
  calendarProps: any,
  event: MatterEvent,
): JSX.Element => {
  const event_html =
    ((event?.event_notes?.length as any) > 0 && event.event_notes) ||
    'No Notes';
  return (
    <>
      <div>{calendarProps?.event?.title}</div>
      <div dangerouslySetInnerHTML={{ __html: event_html }} />
      <br />
      <div>
        <Button
          href={`/cases/${event?.event_matter_id}`}
          target="_blank"
          size="small"
          fullWidth
          variant="outlined"
          color="secondary"
        >
          Go To Matter
        </Button>
      </div>
      <br />
    </>
  );
};
