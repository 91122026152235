import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Paper, Grid, Typography } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import { EventChip } from './EventChip';
import { EventDetails } from './EventDetails';
import {
  ViewEventModal,
  EditEventModal,
  EventModifiers,
  TaskTable,
} from '../../../../../components';
import { themeBorders, boxShadows } from '../../../../../constants';
import { useColors, useCurrentAttorney } from '../../../../../hooks';
import { Matter, MatterEvent } from '../../../../../utils';
import { NoteEditorBox } from '../../../../../utils/NoteEditor';

type EventCardProps = {
  matterId: string;
  event: MatterEvent;
  matter: Matter;
};

const { themeColors } = useColors();

const StyledContainer = styled(Paper)`
  position: relative;
  border-left: ${themeBorders.doubleLeft};
  min-height: 150px;
  width: 95%;
  margin: 40px auto 0;
  padding: 0 30px 30px;
  box-shadow: ${boxShadows.three};
`;

const StyledCardTitle = styled(Typography)`
  position: relative;
  padding: 5px;
  margin: auto;
  text-align: center;
  background-color: ${themeColors.mainColors.primaryColor};
  border-radius: 0px 0px 10px 10px;
  box-shadow: ${boxShadows.one};
  min-width: 8vw;
  max-width: 200px;
  top: -1px;
  margin-bottom: 15px;
  color: white;
`;
const StyledNotes = styled.div`
  margin: 15px auto;
  width: 100%;
`;

const mutationBody = gql`
  mutation AddNote($event_id: uuid, $note: String) {
    update_event(
      where: { event_id: { _eq: $event_id } }
      _set: { event_notes: $note }
    ) {
      affected_rows
      returning {
        event_notes
      }
    }
  }
`;

const EventCard: React.FC<EventCardProps> = ({ matterId, event, matter }) => {
  const { currentAttorney } = useCurrentAttorney();
  const [openViewModal, setOpenViewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const { event_id, event_name, event_complete, tasks, event_notes } = event;

  const [note, setNote] = useState('');

  useEffect(() => {
    setNote(event_notes ?? '');
  }, [event_notes]);

  const [noteReady, setNoteReady] = useState(false);

  const handleNoteChange = (e): void => {
    setNoteReady(true);
    setNote(
      `${e.target.getContent()} ${(e.target.getContent().length > 0 &&
        `<span><h6 style="color: gray;font-size: .5rem"><em>--Edited/Added by ${
          currentAttorney?.attorney_name
        } on ${moment()}</em></h6></span>`) ||
        ''}`,
    );
  };
  const [saveNote] = useMutation(mutationBody, {
    variables: {
      event_id,
      note,
    },
  });
  const handleSaveNotes = (): void => {
    setNoteReady(false);
    saveNote();
  };

  return (
    <StyledContainer>
      <EventModifiers
        eventId={event_id}
        onViewClick={(): void => setOpenViewModal(true)}
        onEditClick={(): void => setOpenEditModal(true)}
      />
      <Grid container spacing={2}>
        <>
          <StyledCardTitle variant="h6">{event_name}</StyledCardTitle>
          <EventChip complete={!!event_complete} />
          <EventDetails event={event} />
          <TaskTable tasks={tasks || []} event={event} matter={matter} />
          <StyledNotes>
            {/* <TextField
              variant="filled"
              label="Notes/Results"
              value={note}
              multiline
              rowsMax={4}
              fullWidth
              onChange={handleNoteChange}
            /> */}
            <NoteEditorBox
              note={note}
              handleNoteChange={handleNoteChange}
              noteReady={noteReady}
              handleSaveNotes={handleSaveNotes}
            />
          </StyledNotes>
        </>
      </Grid>
      <ViewEventModal
        open={openViewModal}
        event={event}
        onClose={(): void => setOpenViewModal(false)}
        onEditClick={(): void => {
          setOpenViewModal(false);
          setOpenEditModal(true);
        }}
      />
      <EditEventModal
        open={openEditModal}
        onClose={(): void => setOpenEditModal(false)}
        matterId={matterId}
        initialState={event}
      />
    </StyledContainer>
  );
};

export { EventCard };
