import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

const database_url = process.env.REACT_APP_DATABASE_URL;
const database_url_subscription =
  process.env.REACT_APP_DATABASE_URL_SUBSCRIPTIONS;

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${localStorage.getItem('id_token')}` || null,
    },
  }));

  return forward(operation);
});

const httpLink = new HttpLink({ uri: `${database_url}` });
const wsLink = new WebSocketLink({
  uri: `${database_url_subscription}`,
  options: {
    reconnect: true,
    connectionParams: async () => {
      const authorization = await localStorage.getItem('id_token');
      return {
        headers: {
          Authorization: authorization ? `Bearer ${authorization}` : '',
        },
      };
    },
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authMiddleware.concat(httpLink),
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const restartWss = () => {
  wsLink.subscriptionClient.tryReconnect();
};

export { client, restartWss };
