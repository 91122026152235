import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import {
  Paper,
  Typography,
  Dialog,
  DialogContentText,
  Button,
  TextField,
  InputAdornment,
  AppBar,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useUpdateProfile } from '../../../../../hooks';
import { boxShadows } from '../../../../../constants';
import { WinLoss } from '../../../../../components';

const OpFirmQuery = gql`
  query OpPartyQuery($id: uuid) {
    op_firm(where: { op_firm_id: { _eq: $id } }) {
      op_firm_address1
      op_firm_address2
      op_firm_address_city
      op_firm_address_state
      op_firm_address_zip
      op_firm_address_zip_4
      op_firm_email
      op_firm_fax
      op_firm_id
      op_firm_name
      op_firm_phone
      matters {
        stage {
          stage_name
          stage_description
        }
        matter_type {
          matter_type_name_full
          matter_type_description
        }
        matter_court_case_number
        matter_name
        matter_opened_date
        matter_closed
        matter_id
        client {
          client_id
          client_firstName
          client_lastName
        }
      }
    }
  }
`;

const StyledContentContainer = styled.div`
  padding-top: 75px;
`;
const StyledDialogContent = styled.div`
  width: 75%;
  min-width: 320px;
  padding: 5px 15px;
  /* max-width: 1200px; */
  margin: auto;
  overflow-x: hidden;
`;
const MatterSection = styled.div`
  margin: 50px auto;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
`;
const MatterCard = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 10px 0;
  min-width: 280px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
    /* transform: scale(1.02); */
  }
`;
const MatterCardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;

export const OpFirmProfileDialog = ({ open, handleClose, id }) => {
  const { onSave } = useUpdateProfile();
  const [opFirmDetails, setOpFirmDetails] = useState<any>([]);
  const { data, loading, error } = useQuery(OpFirmQuery, {
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  // -------------------------Edits

  // Name Change
  const [opFirmNameReady, setOpFirmNameReady] = useState(false);
  const [newOpFirmName, setNewOpFirmName] = useState('');
  const handleOpFirmNameChange = (e): void => {
    setOpFirmNameReady(true);
    setNewOpFirmName(e.target.value);
  };
  const handleSaveOpFirmName = (): void => {
    setOpFirmNameReady(false);
    onSave('OpFirmName', id, newOpFirmName);
  };

  // Street Change
  const [opFirmStreetReady, setOpFirmStreetReady] = useState(false);
  const [newOpFirmStreet, setNewOpFirmStreet] = useState('');
  const handleOpFirmStreetChange = (e): void => {
    setOpFirmStreetReady(true);
    setNewOpFirmStreet(e.target.value);
  };
  const handleSaveOpFirmStreet = (): void => {
    setOpFirmStreetReady(false);
    onSave('OpFirmStreet', id, newOpFirmStreet);
  };

  // Suite Change
  const [opFirmSuiteReady, setOpFirmSuiteReady] = useState(false);
  const [newOpFirmSuite, setNewOpFirmSuite] = useState('');
  const handleOpFirmSuiteChange = (e): void => {
    setOpFirmSuiteReady(true);
    setNewOpFirmSuite(e.target.value);
  };
  const handleSaveOpFirmSuite = (): void => {
    setOpFirmSuiteReady(false);
    onSave('OpFirmSuite', id, newOpFirmSuite);
  };

  // City Change
  const [opFirmCityReady, setOpFirmCityReady] = useState(false);
  const [newOpFirmCity, setNewOpFirmCity] = useState('');
  const handleOpFirmCityChange = (e): void => {
    setOpFirmCityReady(true);
    setNewOpFirmCity(e.target.value);
  };
  const handleSaveOpFirmCity = (): void => {
    setOpFirmCityReady(false);
    onSave('OpFirmCity', id, newOpFirmCity);
  };

  // State Change
  const [opFirmStateReady, setOpFirmStateReady] = useState(false);
  const [newOpFirmState, setNewOpFirmState] = useState('');
  const handleOpFirmStateChange = (e): void => {
    setOpFirmStateReady(true);
    setNewOpFirmState(e.target.value);
  };
  const handleSaveOpFirmState = (): void => {
    setOpFirmStateReady(false);
    onSave('OpFirmState', id, newOpFirmState);
  };

  // Zip Change
  const [opFirmZipReady, setOpFirmZipReady] = useState(false);
  const [newOpFirmZip, setNewOpFirmZip] = useState<number | null>(null);
  const handleOpFirmZipChange = (e): void => {
    setOpFirmZipReady(true);
    setNewOpFirmZip(e.target.value);
  };
  const handleSaveOpFirmZip = (): void => {
    setOpFirmZipReady(false);
    onSave('OpFirmZip', id, newOpFirmZip || null);
  };

  // Zip4 Change
  const [opFirmZip4Ready, setOpFirmZip4Ready] = useState(false);
  const [newOpFirmZip4, setNewOpFirmZip4] = useState<number | null>(null);
  const handleOpFirmZip4Change = (e): void => {
    setOpFirmZip4Ready(true);
    setNewOpFirmZip4(e.target.value);
  };
  const handleSaveOpFirmZip4 = (): void => {
    setOpFirmZip4Ready(false);
    onSave('OpFirmZip4', id, newOpFirmZip4 || null);
  };

  // Email Change
  const [opFirmEmailReady, setOpFirmEmailReady] = useState(false);
  const [newOpFirmEmail, setNewOpFirmEmail] = useState('');
  const handleOpFirmEmailChange = (e): void => {
    setOpFirmEmailReady(true);
    setNewOpFirmEmail(e.target.value);
  };
  const handleSaveOpFirmEmail = (): void => {
    setOpFirmEmailReady(false);
    onSave('OpFirmEmail', id, newOpFirmEmail);
  };

  // Fax Change
  const [opFirmFaxReady, setOpFirmFaxReady] = useState(false);
  const [newOpFirmFax, setNewOpFirmFax] = useState('');
  const handleOpFirmFaxChange = (e): void => {
    setOpFirmFaxReady(true);
    setNewOpFirmFax(e.target.value);
  };
  const handleSaveOpFirmFax = (): void => {
    setOpFirmFaxReady(false);
    onSave('OpFirmFax', id, newOpFirmFax);
  };

  // Phone Change
  const [opFirmPhoneReady, setOpFirmPhoneReady] = useState(false);
  const [newOpFirmPhone, setNewOpFirmPhone] = useState('');
  const handleOpFirmPhoneChange = (e): void => {
    setOpFirmPhoneReady(true);
    setNewOpFirmPhone(e.target.value);
  };
  const handleSaveOpFirmPhone = (): void => {
    setOpFirmPhoneReady(false);
    onSave('OpFirmPhone', id, newOpFirmPhone);
  };

  useEffect(() => {
    if (data && id) {
      setOpFirmDetails(data?.op_firm[0]);
      setNewOpFirmName(data?.op_firm[0]?.op_firm_name);
      setNewOpFirmStreet(data?.op_firm[0]?.op_firm_address1);
      setNewOpFirmSuite(data?.op_firm[0]?.op_firm_address2);
      setNewOpFirmCity(data?.op_firm[0]?.op_firm_address_city);
      setNewOpFirmState(data?.op_firm[0]?.op_firm_address_state);
      setNewOpFirmZip(data?.op_firm[0]?.op_firm_address_zip);
      setNewOpFirmZip4(data?.op_firm[0]?.op_firm_address_zip_4);
      setNewOpFirmEmail(data?.op_firm[0]?.op_firm_email);
      setNewOpFirmFax(data?.op_firm[0]?.op_firm_fax);
      setNewOpFirmPhone(data?.op_firm[0]?.op_firm_phone);
    }
  }, [data]);

  if (loading) {
    return <>...loading</>;
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{opFirmDetails?.op_firm_name}</Typography>
        </Toolbar>
      </AppBar>
      <StyledContentContainer />
      <DialogContentText>
        <StyledDialogContent>
          <TextField
            variant="filled"
            label="Name"
            value={newOpFirmName || ''}
            fullWidth
            onChange={handleOpFirmNameChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmNameReady && (
                    <Button
                      onClick={handleSaveOpFirmName}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Street"
            value={newOpFirmStreet || ''}
            fullWidth
            onChange={handleOpFirmStreetChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmStreetReady && (
                    <Button
                      onClick={handleSaveOpFirmStreet}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Suite"
            value={newOpFirmSuite || ''}
            fullWidth
            onChange={handleOpFirmSuiteChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmSuiteReady && (
                    <Button
                      onClick={handleSaveOpFirmSuite}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="City"
            value={newOpFirmCity || ''}
            fullWidth
            onChange={handleOpFirmCityChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmCityReady && (
                    <Button
                      onClick={handleSaveOpFirmCity}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="State"
            value={newOpFirmState || ''}
            fullWidth
            onChange={handleOpFirmStateChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmStateReady && (
                    <Button
                      onClick={handleSaveOpFirmState}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Zip"
            value={newOpFirmZip}
            fullWidth
            onChange={handleOpFirmZipChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmZipReady && (
                    <Button
                      onClick={handleSaveOpFirmZip}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Zip4"
            value={newOpFirmZip4}
            fullWidth
            onChange={handleOpFirmZip4Change}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmZip4Ready && (
                    <Button
                      onClick={handleSaveOpFirmZip4}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Email"
            value={newOpFirmEmail || ''}
            fullWidth
            onChange={handleOpFirmEmailChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmEmailReady && (
                    <Button
                      onClick={handleSaveOpFirmEmail}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Phone"
            value={newOpFirmPhone || ''}
            fullWidth
            onChange={handleOpFirmPhoneChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmPhoneReady && (
                    <Button
                      onClick={handleSaveOpFirmPhone}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <TextField
            variant="filled"
            label="Fax"
            value={newOpFirmFax || ''}
            fullWidth
            onChange={handleOpFirmFaxChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {opFirmFaxReady && (
                    <Button
                      onClick={handleSaveOpFirmFax}
                      variant="outlined"
                      color="primary"
                    >
                      Save
                    </Button>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <MatterSection>
            <Typography variant="h5">Matters</Typography>
            <br />
            <CardContainer>
              {data?.op_firm[0]?.matters?.map(
                ({ matter_name, matter_id, matter_closed, stage }) => {
                  return (
                    <MatterCard
                      key={matter_id}
                      onClick={(): void => {
                        const win: Window | null = window.open(
                          `/cases/${matter_id}`,
                          '_blank',
                        );
                        if (win === null) {
                          return;
                        }
                        win.focus();
                      }}
                    >
                      <MatterCardContentContainer>
                        <div>
                          <Typography
                            variant="subtitle2"
                            style={{ fontSize: 12, padding: '0 25px' }}
                          >
                            {matter_name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            style={{ fontSize: 10, padding: '0 25px' }}
                          >
                            {stage?.stage_name || ''}
                          </Typography>
                        </div>
                        <EndPiece>
                          <Typography variant="subtitle2">
                            {(!matter_closed && 'Open') || 'Closed'}
                          </Typography>
                        </EndPiece>
                      </MatterCardContentContainer>
                    </MatterCard>
                  );
                },
              )}
            </CardContainer>
            <Typography variant="h5">Statistics</Typography>
            <WinLoss
              title="Win Rate"
              labels={['Won', 'Lost', 'Withdrawn', 'Pending']}
              data={[
                data?.op_firm[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Won',
                ).length,
                data?.op_firm[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Lost',
                ).length,
                data?.op_firm[0]?.matters?.filter(
                  x => x.stage?.stage_name === 'Concluded/Closed-Withdrawn',
                ).length,
                data?.op_firm[0]?.matters
                  ?.filter(
                    x => x.stage?.stage_name !== 'Concluded/Closed-Withdrawn',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Won')
                  .filter(
                    x =>
                      x.stage?.stage_name !== 'Concluded/Closed-Dismissed-WOP',
                  )
                  .filter(x => x.stage?.stage_name !== 'Concluded/Closed-Lost')
                  .length,
              ]}
            />
          </MatterSection>
        </StyledDialogContent>
      </DialogContentText>
    </Dialog>
  );
};
