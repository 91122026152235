import { gql, useMutation } from '@apollo/client';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { sharedStyles } from '../../../components/FormComponents/utils';

export interface UpdateModalProps {
  isMenu?: boolean;
  openEdit: boolean;
  recordType: string;
  matterId: string;
  value: string | boolean;
  setOpenEdit: any;
  attorney: any;
  data: any;
  title: string;
  menuData?: [] | null;
  text?: string;
  tableType?: string;
  filterTableBy?: string | number;
  Id: string | number;
}

const defaultProps = {
  openEdit: false,
  tableType: 'update_matter',
  filterTableBy: 'matter_id',
};

const StyledDateTimePicker = styled(MuiDateTimePicker)`
  ${sharedStyles}
`;

export const UpdateModal: React.FC<UpdateModalProps> = ({
  openEdit,
  Id,
  recordType,
  setOpenEdit,
  title,
  isMenu,
  menuData,
  text,
  tableType,
  filterTableBy,
}) => {
  const [value, setValue] = useState<string | undefined>(text);

  useEffect(() => {
    setValue(text);
  }, [text]);

  const [variableType, setVariableType] = useState<any>();
  const handleBooleanToggle = (e: any): void => {
    setValue(e?.value);
  };

  useMemo(() => {
    // Set the record type of variable for the graphql mutation, i.e. String, timestampz, Boolean
    if (recordType === 'matter_closed') {
      setVariableType('Boolean');
    } else if (recordType === 'matter_opened_date') {
      setVariableType('timestampz');
    } else if (
      recordType === 'matter_type_id' ||
      recordType === 'op_party_id' ||
      recordType === 'matter_court_id' ||
      recordType === 'op_firm_id' ||
      recordType === 'op_counsel_id' ||
      recordType === 'matter_judge_id' ||
      recordType === 'matter_stage_id'
    ) {
      setVariableType('uuid');
    } else {
      setVariableType('String');
    }
  }, [recordType]);

  const CaseUpdate = gql`
    mutation UpdateDocket($${filterTableBy}: uuid, $variable: ${variableType}) {
      ${tableType}(
        where: { ${filterTableBy}: { _eq: $${filterTableBy} } }
        _set: { ${recordType}: $variable }
      ) {
        affected_rows
      }
    }
  `;
  const [generalUpdate] = useMutation(CaseUpdate, {
    variables: {
      [filterTableBy as string]: Id,
      variable: value,
    },
  });
  // const handleClose = (): void => {
  //   setValue(null);
  // };
  const handleChange = (e: any): any => {
    setValue(e.target.value);
  };

  const handleDateChange = (d: any): void => {
    setValue(d);
  };
  const handleUpdate = (): void => {
    generalUpdate().then(() => setOpenEdit(false));
    // .then(() => handleClose());
  };
  return (
    <>
      <Dialog
        open={openEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={!isMenu}
        maxWidth="lg"
        // onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText style={{ minWidth: '35vw' }}>
            {recordType === 'matter_opened_date' && (
              <StyledDateTimePicker
                id={recordType}
                label={title}
                defaultValue={text}
                value={value}
                //   error={error}
                //   helperText={error ? errorText : ''}
                margin="normal"
                variant="inline"
                inputVariant="outlined"
                onChange={handleDateChange}
              />
            )}
            {isMenu || recordType === 'matter_opened_date' ? null : (
              <TextField
                id="outlined-read-only-input22"
                label={title}
                onChange={handleChange as any}
                defaultValue={text}
                value={value}
                margin="normal"
                fullWidth
                variant="outlined"
                color="secondary"
              />
            )}
            {isMenu && (
              <>
                <Autocomplete
                  id="combo-box-demo"
                  autoHighlight
                  autoSelect
                  fullWidth
                  options={menuData as []}
                  getOptionLabel={(option: any): string => option.label}
                  onChange={(e, v): void => handleBooleanToggle(v)}
                  renderInput={(params): ReactElement => (
                    <TextField {...params} label="Select" variant="outlined" />
                  )}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={(): void => setOpenEdit(false)}
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            Cancel
          </Button>
          {(Id && (
            <Button
              onClick={(): void => handleUpdate()}
              color="primary"
              autoFocus
              style={{ fontWeight: 'bold' }}
            >
              Save
            </Button>
          )) || (
            <Button color="primary" autoFocus style={{ fontWeight: 'bold' }}>
              No Client To Update
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

UpdateModal.defaultProps = defaultProps;
