/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useContext } from 'react';
import axios from 'axios';
import {
  TextField,
  Switch,
  Typography,
  Grow,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Alert from '@material-ui/lab/Alert';
import { UserContext } from '../../../../context';
import { StarksLawGrid } from '../../../../utils';
import { PayButton } from './components';

// Custom styling can be passed to options when creating an Element.

export interface CheckoutFormProps {
  chargeAmount: number;
  customerId: string;
  paymentIntentId: string;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  chargeAmount,
  customerId,
  paymentIntentId,
}) => {
  const { state } = useContext(UserContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [checkoutError, setCheckoutError] = useState<any>(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
  const [useProfileBilling, setUseProfileBilling] = useState<boolean>(
    (state.user.mailingZip && true) || false,
  );
  const stripe = useStripe();
  const elements = useElements();
  const [billingDetails, setBillingDetails] = useState<any>({
    Name: `${state.user.firstName} ${state.user.lastName}`,
    Email: state.user.email,
    Phone: '',
    City: state.user.mailingCity,
    Street: state.user.mailingStreet,
    Apt: '',
    State: state.user.mailingState,
    Zip: state.user.mailingZip,
  });

  const handleAddBilling = name => e => {
    setBillingDetails({ ...billingDetails, [name]: e.target.value });
  };
  const handleProfileBillingSwitch = () => {
    setUseProfileBilling(!useProfileBilling);
  };

  // Handle real-time validation errors from the card Element.
  const handleChange = event => {
    if (event.error) {
      setCheckoutError(event.error.message);
    } else {
      setCheckoutError(null);
    }
  };

  const handleSubmit = async ev => {
    const baseServerUrl = process.env.REACT_APP_SERVER_URL;
    ev.preventDefault();

    const billingData = {
      name: billingDetails?.Name,
      email: billingDetails?.Email,
      phone: billingDetails?.Phone || null,
      address: {
        city: billingDetails?.City,
        line1: billingDetails?.Street,
        line2: billingDetails?.Apt,
        state: billingDetails?.State,
        postal_code: billingDetails?.Zip,
      },
    };

    setProcessing(true);

    const cardElement = elements?.getElement('card');

    try {
      const { data: clientSecret } = await axios.post(
        `${baseServerUrl}/client-secret`,
        {
          amount: chargeAmount,
          customer: customerId,
          paymentIntentId,
        },
      );

      const paymentMethodReq = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: billingData,
      })!;

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessing(false);
        return;
      }

      const { error: confirmPaymentError } = await stripe?.confirmCardPayment(
        clientSecret?.client_secret,
        {
          payment_method: paymentMethodReq?.paymentMethod?.id,
        },
      )!;

      if (confirmPaymentError) {
        setCheckoutError(confirmPaymentError.message);
        setProcessing(false);
      } else {
        setPaymentSuccessful(true);
        window.location.reload();
      }
    } catch (err) {
      setCheckoutError(err);
    }
  };

  const cardElementStyles = {
    base: {
      fontSize: '17px',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: cardElementStyles,
  };

  return (
    <>
      <StarksLawGrid
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        containerMargin="5px auto"
        maxWidth="550px"
      >
        <form onSubmit={handleSubmit}>
          <label htmlFor="card-element">
            <Typography variant="h6">Credit Card</Typography>
          </label>
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="5px auto"
            maxWidth="550px"
          >
            <TextField
              variant="outlined"
              color="primary"
              label="Name"
              fullWidth
              value={billingDetails?.Name}
              onChange={handleAddBilling('Name')}
            />
          </StarksLawGrid>
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="5px 1px"
            maxWidth="550px"
          >
            <Typography variant="caption" style={{ textAlign: 'left' }}>
              <Switch
                checked={useProfileBilling}
                onChange={handleProfileBillingSwitch}
              />
              Use Profile Address for Billing
            </Typography>
          </StarksLawGrid>
          {!useProfileBilling ? (
            <>
              <StarksLawGrid
                xs={1}
                sm={1}
                md={2}
                lg={2}
                xl={2}
                containerMargin="5px auto"
                maxWidth="550px"
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Street"
                  value={billingDetails.Street}
                  fullWidth
                  onChange={handleAddBilling('Street')}
                />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Apt/Ste"
                  fullWidth
                  onChange={handleAddBilling('Apt')}
                />
              </StarksLawGrid>
              <StarksLawGrid
                xs={1}
                sm={1}
                md={3}
                lg={3}
                xl={3}
                containerMargin="5px auto"
                maxWidth="550px"
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  label="City"
                  fullWidth
                  value={billingDetails.City}
                  onChange={handleAddBilling('City')}
                />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="State"
                  value={billingDetails.State}
                  fullWidth
                  onChange={handleAddBilling('State')}
                />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Zip"
                  value={billingDetails.Zip}
                  fullWidth
                  onChange={handleAddBilling('Zip')}
                />
              </StarksLawGrid>
              <StarksLawGrid
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                containerMargin="5px auto"
                maxWidth="550px"
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Email"
                  value={billingDetails.Email}
                  fullWidth
                  onChange={handleAddBilling('Email')}
                />
              </StarksLawGrid>
            </>
          ) : null}
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="5px auto"
            maxWidth="550px"
          >
            <CardElement
              id="card-element"
              options={cardElementOpts as any}
              onChange={handleChange}
            />
          </StarksLawGrid>
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="0 auto"
            justifyContent="center"
            justifyItems="center"
            maxWidth="550px"
          >
            <PayButton
              chargeAmount={chargeAmount}
              processing={processing}
              paymentSuccessful={paymentSuccessful}
            />
          </StarksLawGrid>
          {checkoutError ? (
            <StarksLawGrid
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              containerMargin="0 auto"
              justifyContent="center"
              justifyItems="center"
              maxWidth="550px"
            >
              <Grow
                in={checkoutError}
                mountOnEnter
                unmountOnExit
                timeout={3000}
              >
                <Alert
                  severity="error"
                  style={{ width: '100%' }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setCheckoutError(null);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography variant="subtitle1">{checkoutError}</Typography>
                </Alert>
              </Grow>
            </StarksLawGrid>
          ) : null}
        </form>
      </StarksLawGrid>
    </>
  );
};
