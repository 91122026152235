import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { restartWss } from './components/queries/graphqlConfig/apolloClientConfig';
import { UserContext, Types } from './context';
import { getClientInfo } from './components/queries/clients';

import NavBar from './components/menus/navbar';
import {
  Posts,
  Post,
  Case,
  Cases,
  Calendar,
  Clients,
  Dashboard,
  Message,
  Messages,
  TasksView,
  Settings,
  ClientProfile,
} from './views';

import { ClientBilling, ClientDashboard } from './views/customerViews';
import { useMuiTheme, useColors } from './hooks';

interface Color {
  mainColors?: any;
}

const StyledMain = styled.main<{ themeColors: Color }>`
  background-color: ${({ themeColors }): string =>
    themeColors.mainColors.background};
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding-top: 100px;
  overflow-x: hidden;
`;

const StyledContent = styled.div`
  width: 100vw;
  border-radius: 15px;
  margin: 20px;
  text-align: justify;
  color: rgb(45, 45, 45);
  font-size: 1.3em;
`;

const App: React.FC = () => {
  const { state, dispatch } = useContext(UserContext);
  const { getIdTokenClaims, user, isAuthenticated } = useAuth0();
  const { theme, mode, toggleTheme } = useMuiTheme();
  const { themeColors } = useColors();
  const [userRole, setUserRole] = useState('');
  const [email, setEmail] = useState('');

  const [getUserDetails, { data }] = useLazyQuery(getClientInfo, {
    variables: { client_id: state.user.email },
  });

  useEffect(() => {
    getIdTokenClaims()
      .then(token => localStorage.setItem('id_token', token?.__raw || ''))
      .then(() => setUserRole(user?.['https://starks.law/roles']?.[0]))
      // .then(() => setUserId(user['https://hasura.starks.law/db_id']))
      .then(() => setEmail(user?.email))
      .then(() => getUserDetails())
      .then(() => restartWss());
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch({
        type: Types.Update,
        payload: {
          id: data?.client[0]?.client_id,
          userRole,
          email,
          stripeId: data?.client[0]?.client_stripe_id,
          mailingStreet: data?.client[0]?.client_address_street,
          mailingState: data?.client[0]?.client_address_state,
          mailingCity: data?.client[0]?.client_address_city,
          mailingZip: data?.client[0]?.client_address_zip,
          firstName: data?.client[0]?.client_firstName,
          lastName: data?.client[0]?.client_lastName,
          middleName: data?.client[0]?.client_middleName,
        },
      });
    }
  }, [data]);

  if (user.email_verified === false) {
    return (
      <span
        style={{
          display: 'flex',
          width: '100%',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h5"
          style={{ margin: '30px auto', textAlign: 'center' }}
        >
          &nbsp;&nbsp;You must verify your email before using the app
        </Typography>
      </span>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledMain themeColors={themeColors}>
          <NavBar themeChange={toggleTheme} themeType={mode} />
          <>
            <StyledContent>
              {userRole === 'admin' ? (
                <>
                  <Route
                    exact
                    path="/"
                    component={(): React.ReactNode => <Dashboard />}
                  />
                  <Switch>
                    <Route exact path="/cases" component={Cases} />
                    <Route exact path="/cases/:caseID" component={Case} />
                    <Route exact path="/messages" component={Messages} />
                    <Route
                      exact
                      path="/messages/:messageID"
                      component={Message}
                    />
                    <Route exact path="/tasks" component={TasksView} />
                    <Route exact path="/tasks/:taskID" component={TasksView} />
                    <Route exact path="/calendar" component={Calendar} />
                    <Route exact path="/clients" component={Clients} />
                    <Route
                      exact
                      path="/clients/:clientID"
                      component={ClientProfile}
                    />
                    <Route
                      exact
                      path="/clients/:clientID"
                      component={Clients}
                    />
                    <Route exact path="/app-settings" component={Settings} />
                    <Route exact path="/posts" component={Posts} />
                    <Route exact path="/posts/:postID" component={Post} />
                  </Switch>
                </>
              ) : (
                <>
                  {/* Client Routes */}
                  <Route exact path="/" component={ClientDashboard} />
                  <Route
                    exact
                    path="/client-billing"
                    component={ClientBilling}
                  />
                </>
              )}
            </StyledContent>
          </>
        </StyledMain>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default withAuthenticationRequired(App, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => {
    return (
      <span
        style={{
          display: 'flex',
          width: '100%',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h5"
          style={{ margin: '30px auto', textAlign: 'center' }}
        >
          Authenticating&nbsp;&nbsp;
          <img
            src="https://dt84eajp0482g.cloudfront.net/svgimages/postimages/loaderSquares.svg"
            alt="loading"
          />
        </Typography>
      </span>
    );
  },
});
