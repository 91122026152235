import React from 'react';
import { Grid, TextField } from '@material-ui/core';

export const CourtProfile = ({ handleChangeCourtProfile, courtProfile }) => {
  return (
    <>
      <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-name-input"
          label="Court Name/District Number"
          onChange={handleChangeCourtProfile('court_name')}
          fullWidth
          value={courtProfile?.court_name}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-phone-input"
          label="Phone"
          onChange={handleChangeCourtProfile('court_phone')}
          fullWidth
          value={courtProfile?.court_phone}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-fax-input"
          label="Fax"
          onChange={handleChangeCourtProfile('court_fax')}
          fullWidth
          value={courtProfile?.court_fax}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={9} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-address-input"
          label="Address"
          onChange={handleChangeCourtProfile('court_address_street')}
          fullWidth
          value={courtProfile?.court_address_street}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-address2-input"
          label="Ste/Bldg/Fl"
          onChange={handleChangeCourtProfile('court_address_street2')}
          fullWidth
          value={courtProfile?.court_address_street2}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-city-input"
          label="City"
          onChange={handleChangeCourtProfile('court_address_city')}
          fullWidth
          value={courtProfile?.court_address_city}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-state-input"
          label="State"
          onChange={handleChangeCourtProfile('court_address_state')}
          fullWidth
          value={courtProfile?.court_address_state}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip-input"
          label="Zip"
          onChange={handleChangeCourtProfile('court_address_zip')}
          fullWidth
          value={courtProfile?.court_address_zip}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip4-input"
          label="+4"
          onChange={handleChangeCourtProfile('court_address_zip_4')}
          fullWidth
          value={courtProfile?.court_address_zip_4}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>

      <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip4-input"
          label="County"
          onChange={handleChangeCourtProfile('court_county')}
          fullWidth
          value={courtProfile?.court_county}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>

      <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip4-input"
          label="Type"
          onChange={handleChangeCourtProfile('court_type')}
          fullWidth
          value={courtProfile?.court_type}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip4-input"
          label="E-File Link"
          onChange={handleChangeCourtProfile('court_efile_link')}
          fullWidth
          value={courtProfile?.court_efile_link}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
    </>
  );
};
