import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import {
  AccountBalanceTwoTone,
  GavelTwoTone,
  MoodBadTwoTone,
  LocationCityTwoTone,
  StarsTwoTone,
  BlockTwoTone,
  LocalAtmTwoTone,
} from '@material-ui/icons';
import { useColors } from '../hooks';

const StyledIconCC = styled(AccountBalanceTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;

const StyledIconMDJ = styled(GavelTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;
const StyledIconMunicipal = styled(LocationCityTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;
const StyledIconFederal = styled(StarsTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;
const StyledIconBankruptcy = styled(LocalAtmTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;
const StyledIconNone = styled(BlockTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;

const StyledIconMoodBadTwoTone = styled(MoodBadTwoTone)<{ color: any }>`
  color: ${({ color }): string => color};
  margin: 0 10px -5px 0;
`;

interface MatterTypeIconProps {
  type: string;
}

export const MatterTypeIcon: React.FC<MatterTypeIconProps> = ({ type }) => {
  const { themeColors } = useColors();
  switch (type) {
    case 'Common Pleas':
      return (
        <Tooltip title="Common Pleas">
          <StyledIconCC color={themeColors.mainColors.primaryColor} />
        </Tooltip>
      );
    case 'MDJ':
      return (
        <Tooltip title="MDJ">
          <StyledIconMDJ color={themeColors.mainColors.primaryColor} />
        </Tooltip>
      );
    case 'Municipal':
      return (
        <Tooltip title="Philadelphia Municipal Court">
          <StyledIconMunicipal color={themeColors.mainColors.primaryColor} />
        </Tooltip>
      );
    case 'Federal':
      return (
        <Tooltip title="Federal Court">
          <StyledIconFederal color={themeColors.mainColors.primaryColor} />
        </Tooltip>
      );
    case 'Bankruptcy':
      return (
        <Tooltip title="Bankruptcy Court">
          <StyledIconBankruptcy color={themeColors.mainColors.primaryColor} />
        </Tooltip>
      );
    case 'None':
      return (
        <Tooltip title="This type of matter has no court">
          <StyledIconNone color={themeColors.mainColors.primaryColor} />
        </Tooltip>
      );
    case 'Default':
      return (
        <Tooltip title="Default Court Selected and Never Changed">
          <StyledIconMoodBadTwoTone
            color={themeColors.mainColors.primaryColor}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title="Court/Court Type not added">
          <StyledIconMoodBadTwoTone color={themeColors.mainColors.danger} />
        </Tooltip>
      );
  }
};
