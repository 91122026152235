import React from 'react';
import { Grid, TextField } from '@material-ui/core';

export const OpPartyProfile = ({ handleChangeOpProfile, opProfile }) => {
  return (
    <>
      <Grid item xs={12} md={12} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-name-input"
          label="Op Party Name"
          onChange={handleChangeOpProfile('name')}
          fullWidth
          value={opProfile?.name}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-phone-input"
          label="Phone"
          onChange={handleChangeOpProfile('phone')}
          fullWidth
          value={opProfile?.phone}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-fax-input"
          label="Fax"
          onChange={handleChangeOpProfile('fax')}
          fullWidth
          value={opProfile?.fax}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={9} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-address-input"
          label="Address"
          onChange={handleChangeOpProfile('address')}
          fullWidth
          value={opProfile?.address}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-address2-input"
          label="Ste/Bldg/Fl"
          onChange={handleChangeOpProfile('address2')}
          fullWidth
          value={opProfile?.address2}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-city-input"
          label="City"
          onChange={handleChangeOpProfile('city')}
          fullWidth
          value={opProfile?.city}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-state-input"
          label="State"
          onChange={handleChangeOpProfile('state')}
          fullWidth
          value={opProfile?.state}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={3} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip-input"
          label="Zip"
          onChange={handleChangeOpProfile('zip')}
          fullWidth
          value={opProfile?.zip}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
      <Grid item xs={12} md={2} style={{ textAlign: 'left' }}>
        <TextField
          id="profile-zip4-input"
          label="+4"
          onChange={handleChangeOpProfile('zip4')}
          fullWidth
          value={opProfile?.zip4}
          margin="normal"
          variant="outlined"
          color="secondary"
        />
      </Grid>
    </>
  );
};
