import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { BaseTaskModal } from './BaseEditableTaskModal';
import { EDIT_TASK } from '../../mutations';
import { useAddSelectedWorkflow, useCurrentAttorney } from '../../../hooks';
import {
  EditableMatterTask,
  MatterTask,
  Matter,
  MatterEvent,
} from '../../../utils';

type TaskModalProps = {
  open: boolean;
  matter?: Matter;
  event?: MatterEvent;
  onClose: () => void;
  initialState?: MatterTask;
};

const EditTaskModal: React.FC<TaskModalProps> = ({
  open,
  onClose,
  matter,
  event,
  initialState,
}) => {
  const [formError, setFormError] = useState('');
  const [editTask, { data, loading, error }] = useMutation(EDIT_TASK);

  const onSuccess = useCallback(() => {
    onClose();
  }, [onClose]);

  const onError = useCallback(
    (addWorkflowsError: string) => {
      setFormError(addWorkflowsError);
    },
    [setFormError],
  );

  const { onSelection, loading: addingWorkflows } = useAddSelectedWorkflow({
    onSuccess,
    onError,
  });

  const { currentAttorney } = useCurrentAttorney();

  useEffect(() => {
    if (!loading && !addingWorkflows && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = useCallback(
    (taskState: EditableMatterTask): void => {
      if (!initialState?.task_id) {
        setFormError('Task ID not detected');
      } else {
        setFormError('');
        editTask({
          variables: {
            task_id: initialState?.task_id,
            task_matter_id: matter?.matter_id || null,
            task_event_id: event?.event_id || null,
            task_name: taskState.task_name,
            task_description: taskState.task_description,
            task_owner: taskState.task_owner,
            task_due: taskState.task_due,
            task_due_dynamic: taskState.task_due_dynamic,
            task_dynamic_due_from: taskState.task_dynamic_due_from,
            task_completed: taskState.task_completed,
            trigger_workflow_id: taskState.trigger_workflow_id || null,
            task_is_priority: taskState.task_is_priority,
          },
        });

        // initially was not completed, but now it is
        const taskCompleted =
          !initialState.task_completed && taskState.task_completed;

        // task was completed, workflow trigger and other required info exists
        if (
          taskCompleted &&
          matter &&
          taskState.workflowTrigger &&
          taskState.trigger_workflow_id &&
          currentAttorney
        ) {
          onSelection({
            matter,
            event,
            workflow: taskState.workflowTrigger,
            attorney: {
              id: currentAttorney.attorney_id,
              name: currentAttorney.attorney_name,
            },
          });
        }
      }
    },
    [currentAttorney, initialState, setFormError, editTask, onSelection],
  );

  /**
   * We're only allowing the modal to mount when open is true.
   * This ensures that the initial state will not be set before mounting.
   * We do this because initial state can only be set once.
   */
  return (
    <>
      {open && (
        <BaseTaskModal
          open={open}
          title="Edit Task"
          formError={formError}
          eventDate={event?.event_date_time || undefined}
          initialTaskState={initialState}
          onSave={onSave}
          onClose={onClose}
          loading={loading || addingWorkflows}
        />
      )}
    </>
  );
};

export { EditTaskModal };
