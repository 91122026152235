/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Dialog,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import {
  PhoneAndroidTwoTone,
  RemoveRedEyeTwoTone,
  Close as CloseIcon,
} from '@material-ui/icons';
import { boxShadows } from '../../../constants/shadows/shadows';
import { themeBorders } from '../../../constants/borders/borders';
import { useColors } from '../../../hooks';
import { UpdateModal } from '../CaseUpdate';
import { PencilOutline } from '../../../components/stories/SLICONS.stories';
import { ClientProfile } from '../../client';

const StyledIconButton = styled(IconButton)`
  opacity: ${props => (props.m === 'dark' && 0.75) || 0.3};
  transition: all 0.25s ease-in-out;
  padding: 3;
  margin: 0;
  &:hover {
    transform: scale(1.3);
    opacity: 1;
  }
`;

const StyledPhoneAndroidTwoTone = styled(PhoneAndroidTwoTone)`
  color: ${props => props.c};
`;

const InfoSection = {
  borderLeft: themeBorders.doubleLeft,
  minHeight: 150,
  width: '95%',
  margin: 'auto',
  marginTop: 40,
  paddingLeft: 30,
  paddingRight: 30,
  paddingTop: 0,
  boxShadow: boxShadows.three,
};

const PencilButton = color => {
  return <PencilOutline width={18} fill={color} />;
};

const StyledJudgeProfile = styled(RemoveRedEyeTwoTone)`
  color: ${props => props.c};
  cursor: pointer;
`;
const StyledPadding = styled.div`
  margin-bottom: 50px;
`;
export const CLIENT = props => {
  const [openClientProfile, setOpenClientProfile] = useState(false);
  const handleOpenClientProfile = () => {
    setOpenClientProfile(true);
  };
  const handleCloseClientProfile = () => {
    setOpenClientProfile(false);
  };
  const { themeColors } = useColors();
  const mode = localStorage.getItem('theme_mode');
  // new edit logic

  const { cl, readOnly, titleVariant, cardTitleStyle } = props;
  const tableType = 'update_client';
  const filterTableBy = 'client_id';
  const [openEdit, setOpenEdit] = useState(false);
  const [recordType, setRecordType] = useState();
  const [title, setTitle] = useState();
  const [isMenu, setIsMenu] = useState(false);
  const [menuData, setMenuData] = useState();
  const [text, setText] = useState();
  const handleModal = (r, t, m, d, w) => {
    setTitle(t);
    setRecordType(r);
    setIsMenu(m);
    setMenuData(d);
    setOpenEdit(!openEdit);
    setText(w);
  };

  return (
    <>
      <Paper style={InfoSection}>
        <Typography variant={titleVariant} style={cardTitleStyle}>
          Client
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} style={{ textAlign: 'center' }} />
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-client-name"
              label="Client"
              fullWidth
              value={`${(cl?.client_firstName && cl.client_firstName) ||
                ''} ${(cl?.client_lastName && cl.client_lastName) || ''}`}
              margin="normal"
              InputProps={{
                readOnly,
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      onClick={handleOpenClientProfile}
                    >
                      <Tooltip title="Click to View Profile">
                        <StyledJudgeProfile
                          c={themeColors.mainColors.primaryColor}
                        />
                      </Tooltip>
                    </InputAdornment>
                  </>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-client-phone"
              label="Client Phone"
              fullWidth
              value={
                (!cl?.client_phone_cell && `${cl?.client_phone_home || ''}`) ||
                `${cl?.client_phone_cell || ''}`
              }
              margin="normal"
              InputProps={{
                readOnly,
                endAdornment: (
                  <InputAdornment position="end">
                    <StyledIconButton
                      onClick={() =>
                        window.open(
                          `https://starkslaw.3cx.us:5001/webclient/#/call?phone=${cl?.client_phone_cell}`,
                        )
                      }
                    >
                      <StyledPhoneAndroidTwoTone
                        c={themeColors.mainColors.primaryColor}
                      />
                    </StyledIconButton>
                    <StyledIconButton
                      m={mode}
                      aria-label="toggle password visibility"
                      onClick={() =>
                        handleModal(
                          'client_phone_cell',
                          'Update Client Phone',
                          false,
                          [],
                          (cl.client_phone_cell && cl.client_phone_cell) || '',
                        )
                      }
                    >
                      {PencilButton(themeColors.mainColors.primaryColor)}
                    </StyledIconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Client Email"
              fullWidth
              value={cl?.client_email && cl.client_email}
              margin="normal"
              InputProps={{
                readOnly,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ textAlign: 'left' }}>
            <TextField
              id="outlined-read-only-input"
              label="Client Address"
              fullWidth
              value={
                (cl?.client_address_street &&
                  `${cl.client_address_street ||
                    ''}, ${cl.client_address_city ||
                    ''}, ${cl.client_address_state ||
                    ''} ${cl.client_address_zip || ''}`) ||
                ''
              }
              margin="normal"
              InputProps={{
                readOnly,
              }}
              variant="outlined"
              color="secondary"
            />
          </Grid>
        </Grid>
      </Paper>
      <UpdateModal
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        Id={cl?.client_id}
        recordType={recordType}
        data={cl}
        title={title}
        isMenu={isMenu}
        menuData={menuData}
        text={text}
        tableType={tableType}
        filterTableBy={filterTableBy}
      />
      {openClientProfile && (
        <Dialog
          fullScreen
          open={openClientProfile}
          onClose={handleCloseClientProfile}
        >
          <AppBar>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseClientProfile}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">Client Profile</Typography>
            </Toolbar>
          </AppBar>
          <StyledPadding />
          <ClientProfile client_id={props.cl.client_id} />
        </Dialog>
      )}
    </>
  );
};
