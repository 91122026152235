import React from 'react';
import { Calendar as SharedCalendar } from '../../../../components';
import { CalendarProps } from '../../../../components/Calendar/Calendar';

/**
 * This is a light wrapper around the generic shared Calendar component.
 * We're only adding it here for directory structure consistency and
 * for easy future customization of this section.
 */
const Calendar: React.FC<CalendarProps> = ({ events, onViewEvent }) => {
  return <SharedCalendar events={events} onViewEvent={onViewEvent} />;
};

export { Calendar };
