import React, { useEffect } from 'react';
import {
  ErrorNotification,
  FormGrid,
  Input,
  useFormFieldState,
  Modal,
} from '../..';
import { EditableWorkflow } from '../../../utils';

type BaseWorkflowModalProps = {
  open: boolean;
  title: string;
  formError: string;
  initialState?: EditableWorkflow;
  onSave: (workflow: EditableWorkflow) => void;
  onClose: () => void;
  loading: boolean;
};

const BaseWorkflowModal: React.FC<BaseWorkflowModalProps> = ({
  open,
  title,
  formError,
  initialState = {},
  onSave,
  onClose,
  loading,
}) => {
  const {
    state: workflowName,
    setValue: setWorkflowName,
    setError: setWorkflowNameError,
    setErrorText: setWorkflowNameErrorText,
    resetState: resetWorkflowNameState,
  } = useFormFieldState(initialState.workflow_name || undefined);

  const {
    state: workflowDescription,
    setValue: setWorkflowDescription,
    setError: setWorkflowDescriptionError,
    setErrorText: setWorkflowDescriptionErrorText,
    resetState: resetWorkflowDescriptionState,
  } = useFormFieldState(initialState.workflow_description || undefined);

  const isValidWorkflow = (): boolean => {
    return !!workflowName.value.length && !!workflowDescription.value.length;
  };

  const setErrors = (): void => {
    if (!workflowName.value.length) {
      setWorkflowNameError(true);
      setWorkflowNameErrorText('Field is required');
    }
    if (!workflowDescription.value.length) {
      setWorkflowDescriptionError(true);
      setWorkflowDescriptionErrorText('Field is required');
    }
  };

  const onSaveClick = (): void => {
    if (isValidWorkflow()) {
      onSave({
        workflow_name: workflowName.value,
        workflow_description: workflowDescription.value,
      });
    } else {
      setErrors();
    }
  };

  const resetState = (): void => {
    resetWorkflowNameState();
    resetWorkflowDescriptionState();
  };

  useEffect(() => {
    // reset state every time the modal closes
    if (!open) resetState();
  }, [open]);

  return (
    <Modal
      open={open}
      title={title}
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      primaryLoading={loading}
      includeCancelConfirmation
      onPrimaryClick={onSaveClick}
      onSecondaryClick={onClose}
      onClose={onClose}
    >
      <>
        <FormGrid>
          <Input
            label="Workflow name"
            value={workflowName.value}
            onChange={(e): void => {
              const name = e.target.value;
              setWorkflowName(name);
              setWorkflowNameError(false);
            }}
            error={workflowName.error}
            errorText={workflowName.errorText}
          />
          <Input
            label="Workflow description"
            value={workflowDescription.value}
            onChange={(e): void => {
              const type = e.target.value;
              setWorkflowDescription(type);
              setWorkflowDescriptionError(false);
            }}
            error={workflowDescription.error}
            errorText={workflowDescription.errorText}
          />
        </FormGrid>
        <ErrorNotification text={formError} show={!!formError.length} />
      </>
    </Modal>
  );
};

export { BaseWorkflowModal };
