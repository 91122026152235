import { gql } from '@apollo/client';

const DELETE_MESSAGE = gql`
  mutation DeleteMessage($messageID: Int) {
    delete_message(where: { message_id: { _eq: $messageID } }) {
      affected_rows
    }
  }
`;
export default DELETE_MESSAGE;
