import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

/**
 * Do not track this value in state.
 * Synchronous updates are required.
 */
let componentCount = 0;

/**
 * Detect if Jest tests are being run.
 * If in jest environment, set all component indices to 0.
 */
const isJestTestEnv = process.env.REACT_APP_ENV === 'jest';
const jestNext = (): number => 0;
const appNext = (): number => {
  componentCount += 1;
  return componentCount;
};
const nextComponentIndex = isJestTestEnv ? jestNext : appNext;

const defaultState = {
  nextComponentIndex,
};

export const AppContext = React.createContext(defaultState);
export const AppConsumer = AppContext.Consumer;

const AppProvider: React.FC = ({ children }) => (
  <AppContext.Provider
    value={{
      ...defaultState,
    }}
  >
    <StyledThemeProvider theme={{}}>{children}</StyledThemeProvider>
  </AppContext.Provider>
);

export { AppProvider };
