import { gql } from '@apollo/client';

export const GetCreditors = gql`
  subscription GetOpParties {
    op_party {
      op_party_address
      op_party_address2
      op_party_address_city
      op_party_address_state
      op_party_address_zip
      op_party_address_zip_4
      op_party_email
      op_party_fax
      op_party_id
      op_party_name
      op_party_phone
    }
  }
`;
