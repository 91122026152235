import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useMutation } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Chip from '@material-ui/core/Chip';
import { useColors } from '../../hooks';
import { themeBorders } from '../../constants/borders/borders';
import DELETE_MESSAGE from '../../components/mutations/messages/deleteMessage';
import history from '../../history';
import { SendMessage, Delete } from '../../images/icons/SLICONS';

const { themeColors } = useColors();

export const MessageLoader = props => {
  const paper = {
    width: '100%',
    overflowX: 'auto',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    justifyContent: 'space-evenly',
    alignContent: 'space-around',
    minHeight: 200,
  };
  const border = {
    borderLeft: themeBorders.doubleLeft,
  };
  // Delete Mesesage Logic
  const m = props.data.message[0];
  const [deleteMessageOnClick] = useMutation(DELETE_MESSAGE, {
    variables: { messageID: m.message_id },
  });
  // Modal Logic
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseAgree = () => {
    setOpen(false);
    deleteMessageOnClick();
    history.goBack();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <div>
      <Paper style={paper}>
        <div style={border} />
        <div style={{ padding: 40, paddingBottom: 5, width: '100%' }}>
          <div>
            <div
              style={{ width: '100%', textAlign: 'left', paddingBottom: 30 }}
            >
              <div>
                From:{' '}
                {`${m.message_contact_firstName} ${m.message_contact_lastName}`}
              </div>
              <div>Email: {m.message_email}</div>
              <div>Phone: {m.message_phone || 'No Phone Given'}</div>
              <div>Message Sent From: {m.message_sent_from}</div>
            </div>
          </div>
          <div>{m.message_body}</div>
          <br />
          <div
            style={{ float: 'right', display: 'flex', flexDirection: 'row' }}
          >
            <div>
              <Chip
                variant="outlined"
                color="primary"
                label="REPLY"
                icon={
                  <SendMessage fill={themeColors.mainColors.primaryColor} />
                }
                onClick={() =>
                  window.open(
                    `mailto:${m.message_email}?subject=Starks%20Law%20Contact%20Request&body=%20${m.message_contact_firstName}, %20thank%20you%20for%20reaching%20out%20to%20Starks%20Law.`,
                  )
                }
                style={{ paddingLeft: 6.5 }}
              />
            </div>
            <div style={{ paddingLeft: 20, marginRight: -25 }}>
              <Chip
                variant="outlined"
                label="DELETE"
                icon={<Delete fill={themeColors.mainColors.danger} />}
                onClick={() => handleClickOpen()}
                color="primary"
                style={{
                  borderColor: themeColors.mainColors.danger,
                  color: themeColors.mainColors.danger,
                  hover: { backgroundColor: themeColors.mainColors.danger },
                }}
              />
            </div>
          </div>
        </div>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Message?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to delete this message from ${m.message_contact_firstName} $m.message_contact_lastName}? This action is permanent and cannot be undone.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            style={{ fontWeight: 'bold' }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={handleCloseAgree}
            color="primary"
            autoFocus
            style={{ fontWeight: 'bold' }}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
