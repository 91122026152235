import React, { useState } from 'react';
import styled from 'styled-components';
import {
  IconButton,
  Tooltip,
  TextField,
  InputAdornment,
  MobileStepper,
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  LibraryAdd,
  FindInPageTwoTone,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import { WorkflowCard } from './WorkflowCard';
import { useWorkflows } from './useWorkflows';
import { AddWorkflowModal } from '../../../components';
import { useColors } from '../../../hooks';

const StyledAddWorkflowButton = styled(IconButton)<{ $color: string }>`
  position: absolute;
  right: 15px;
  top: 5px;
  color: ${({ $color }): string => $color};
`;
const StyledMobileStepper = styled(MobileStepper)<{ isMobile: boolean }>`
  margin-left: ${({ isMobile }): string => (isMobile && '0px') || '70px'};
`;

const StyledSearchBar = styled.div`
  width: 90%;
  margin: auto;
`;
const Workflows: React.FC = () => {
  const { workflows } = useWorkflows();
  const { themeColors } = useColors();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openAddWorkflowModal, setOpenAddWorkflowModal] = useState(false);
  const [search, setSearch] = useState<string>('');
  const handleSearch = (e): void => {
    setSearch(e.target.value);
  };

  const [activeStep, setActiveStep] = React.useState(0);
  const [numPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('workflowsPerPage') as string, 10) || 4,
  );
  const handleNext = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = (): void => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };
  // console.log('workflows: ', workflows);
  return (
    <>
      <Tooltip title="Add Workflow">
        <StyledAddWorkflowButton
          $color={themeColors.mainColors.primaryColor}
          onClick={(): void => setOpenAddWorkflowModal(true)}
        >
          <LibraryAdd />
        </StyledAddWorkflowButton>
      </Tooltip>
      <StyledSearchBar>
        <TextField
          id="workflow-name-search"
          onChange={handleSearch}
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FindInPageTwoTone color="primary" />
              </InputAdornment>
            ),
          }}
        />
      </StyledSearchBar>
      {workflows
        ?.filter(x =>
          x.workflow_name?.toLowerCase().includes(search?.toLowerCase()),
        )
        ?.slice(
          activeStep * rowsPerPage,
          activeStep * rowsPerPage + rowsPerPage,
        )
        ?.map(workflow => (
          <WorkflowCard key={workflow.workflow_id} workflow={workflow} />
        ))}
      <StyledMobileStepper
        isMobile={isMobile}
        variant="progress"
        steps={numPages}
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === numPages - 1}
          >
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        }
      />
      <AddWorkflowModal
        open={openAddWorkflowModal}
        onClose={(): void => setOpenAddWorkflowModal(false)}
      />
    </>
  );
};

export { Workflows };
