/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useContext, useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import {
  TextField,
  Switch,
  Typography,
  Grow,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { PayButton } from './components';
import { StarksLawGrid } from '../../../../../utils';
import { UserContext } from '../../../../../context';

interface ClientBillingProps {
  client_firstName: string;
  client_lastName: string | null;
  client_email: string;
  client_phone_cell: string | null;
  client_address_city: string | null;
  client_address_street: string | null;
  client_address_state: string | null;
  client_address_zip: number | null;
  client_stripe_id: string | null;
}

interface CheckoutFormProps {
  clientSecret: string;
  chargeAmount: number;
  toggle: (
    amt?: number,
    clientData?: ClientBillingProps,
    id?: number,
    t?: boolean,
  ) => void;
  open: boolean;
  client: ClientBillingProps;
}

export const CheckoutForm: React.FC<CheckoutFormProps> = ({
  clientSecret,
  chargeAmount,
  toggle,
  open,
  client,
}) => {
  const { state } = useContext(UserContext);
  const [processing, setProcessing] = useState<boolean>(false);
  const [checkoutError, setCheckoutError] = useState<any>(null);
  const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(false);
  const [useProfileBilling, setUseProfileBilling] = useState<boolean>(
    (state.user.mailingZip && true) || false,
  );

  const stripe = useStripe();
  const elements = useElements();

  const [billingDetails, setBillingDetails] = useState<any>({
    Name: `${client?.client_firstName} ${client?.client_lastName}`,
    Email: client?.client_email,
    Phone: client?.client_phone_cell,
    City: client?.client_address_city,
    Street: client?.client_address_street,
    Apt: '',
    State: client?.client_address_state,
    Zip: client?.client_address_zip,
  });

  const handleSubmit = async (ev): Promise<void> => {
    ev.preventDefault();

    const billingData = {
      name: billingDetails?.Name,
      email: billingDetails?.Email,
      phone: billingDetails?.Phone || null,
      address: {
        city: billingDetails?.City,
        line1: billingDetails?.Street,
        line2: billingDetails?.Apt,
        state: billingDetails?.State,
        postal_code: billingDetails?.Zip,
      },
    };

    setProcessing(true);

    const cardElement = elements?.getElement('card');

    try {
      const setDelay = ms => new Promise(res => setTimeout(res, ms));
      const paymentMethodReq = await stripe?.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: billingData,
      })!;

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessing(false);
        return;
      }

      const { error: confirmPaymentError } = await stripe?.confirmCardPayment(
        clientSecret,
        {
          payment_method: paymentMethodReq?.paymentMethod?.id,
        },
      )!;

      if (confirmPaymentError) {
        setCheckoutError(confirmPaymentError.message);
        setProcessing(false);
      } else {
        setPaymentSuccessful(true);
        await setDelay(1200);
        toggle();
      }
    } catch (err) {
      setCheckoutError(err);
    }
  };

  const handleAddBilling = name => e => {
    setBillingDetails({ ...billingDetails, [name]: e.target.value });
  };
  const handleProfileBillingSwitch = () => {
    setUseProfileBilling(!useProfileBilling);
  };
  // Handle real-time validation errors from the card Element.
  const handleChange = event => {
    if (event.error) {
      setCheckoutError(event.error.message);
    } else {
      setCheckoutError(null);
    }
  };

  const cardElementStyles = {
    base: {
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: cardElementStyles,
  };
  return (
    <>
      <StarksLawGrid
        xs={1}
        sm={1}
        md={1}
        lg={1}
        xl={1}
        containerMargin="5px auto"
        maxWidth="550px"
      >
        <form onSubmit={handleSubmit}>
          <label htmlFor="card-element">
            <Typography variant="h6">Process Card</Typography>
          </label>
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="5px auto"
            maxWidth="550px"
          >
            <TextField
              variant="outlined"
              color="primary"
              label="Name"
              fullWidth
              value={billingDetails?.Name}
              onChange={handleAddBilling('Name')}
            />
          </StarksLawGrid>
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="5px 1px"
            maxWidth="550px"
          >
            <Typography variant="caption" style={{ textAlign: 'left' }}>
              <Switch
                checked={useProfileBilling}
                onChange={handleProfileBillingSwitch}
              />
              Use Profile Address for Billing
            </Typography>
          </StarksLawGrid>
          {!useProfileBilling ? (
            <>
              <StarksLawGrid
                xs={1}
                sm={1}
                md={2}
                lg={2}
                xl={2}
                containerMargin="5px auto"
                maxWidth="550px"
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Street"
                  value={billingDetails.Street}
                  fullWidth
                  onChange={handleAddBilling('Street')}
                />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Apt/Ste"
                  fullWidth
                  onChange={handleAddBilling('Apt')}
                />
              </StarksLawGrid>
              <StarksLawGrid
                xs={1}
                sm={1}
                md={3}
                lg={3}
                xl={3}
                containerMargin="5px auto"
                maxWidth="550px"
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  label="City"
                  fullWidth
                  value={billingDetails.City}
                  onChange={handleAddBilling('City')}
                />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="State"
                  value={billingDetails.State}
                  fullWidth
                  onChange={handleAddBilling('State')}
                />
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Zip"
                  value={billingDetails.Zip}
                  fullWidth
                  onChange={handleAddBilling('Zip')}
                />
              </StarksLawGrid>
              <StarksLawGrid
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                containerMargin="5px auto"
                maxWidth="550px"
              >
                <TextField
                  variant="outlined"
                  color="primary"
                  label="Email"
                  value={billingDetails.Email}
                  fullWidth
                  onChange={handleAddBilling('Email')}
                />
              </StarksLawGrid>
            </>
          ) : null}
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="15px auto"
            maxWidth="550px"
          >
            <CardElement
              id="card-element"
              options={cardElementOpts as any}
              onChange={handleChange}
            />
          </StarksLawGrid>
          <StarksLawGrid
            xs={1}
            sm={1}
            md={1}
            lg={1}
            xl={1}
            containerMargin="0 auto"
            justifyContent="center"
            justifyItems="center"
            maxWidth="550px"
          >
            <PayButton
              chargeAmount={chargeAmount}
              processing={processing}
              paymentSuccessful={paymentSuccessful}
            />
          </StarksLawGrid>
          {checkoutError ? (
            <StarksLawGrid
              xs={1}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              containerMargin="0 auto"
              justifyContent="center"
              justifyItems="center"
              maxWidth="550px"
            >
              <Grow
                in={checkoutError}
                mountOnEnter
                unmountOnExit
                timeout={3000}
              >
                <Alert
                  severity="error"
                  style={{ width: '100%' }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setCheckoutError(null);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  <Typography variant="subtitle1">{checkoutError}</Typography>
                </Alert>
              </Grow>
            </StarksLawGrid>
          ) : null}
        </form>
      </StarksLawGrid>
    </>
  );
};
