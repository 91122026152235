import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  CssBaseline,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ClickAwayListener,
  useMediaQuery,
  Hidden,
  Tooltip,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Power3,
  DashboardSolid,
  CaseOutline,
  People,
  MessagesOutline,
  CalendarOutline,
  Tasks,
  ChevronLeftCircle,
  SettingsOutline,
  Feather,
  CreditCard,
  DarkMode,
} from '../stories/SLICONS.stories';
import history from '../../history';
import { useColors } from '../../hooks';
import { UserContext } from '../../context';

// const drawerWidth = 240;

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    zIndex: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 'auto',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    display: 'inline',
    width: drawerWidth,
    transition: theme.transitions.create(['width', 'display'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpenFull: {
    display: 'inline',
    width: '100vw',
    transition: theme.transitions.create(['width', 'display'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    height: 35,
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function NavBar({ themeChange, themeType }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { state } = useContext(UserContext);
  const { isAuthenticated, loginWithRedirect, logout, loading } = useAuth0();
  const classes = useStyles();
  const { themeColors } = useColors();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    if (open) {
      setOpen(!open);
    }
  };
  const handleNavigate = route => {
    history.push(route);
    handleDrawerClose();
  };
  const themeSwitcher = {
    float: 'right',
    margin: 0,
    padding: 0,
  };
  const LOGIN = () => {
    loginWithRedirect();
    return loading ? <div>Loading...</div> : null;
  };
  return (
    <>
      {isAuthenticated && state.user.userRole === 'admin' ? (
        <button
          style={{
            position: 'absolute',
            bottom: 15,
            right: 25,
            border: 'none',
            outline: 'none',
            backgroundColor: '#ffffff00',
            padding: 0,
            margin: 0,
            zIndex: 99,
          }}
          onClick={() => history.push('/app-settings')}
        >
          <SettingsOutline
            width={48}
            fill={themeColors.mainColors.secondaryColor}
            style={{ cursor: 'pointer' }}
          />
        </button>
      ) : null}
      <ClickAwayListener onClickAway={handleDrawerClose}>
        <div className={classes.root}>
          <CssBaseline />

          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open,
            })}
            style={{ backgroundColor: themeColors.mainColors.menuColor }}
          >
            <Toolbar className={classes.toolbar}>
              <img
                src="https://dt84eajp0482g.cloudfront.net/svgimages/logo/Starks-Law-Logo-White.svg"
                alt="React Logo"
                style={{ height: '100%', padding: '5px 0px' }}
              />

              <div
                style={{
                  float: 'right',
                  width: '100%',
                  padding: 0,
                  margin: 0,
                }}
              >
                <Hidden smDown>
                  <FormControl component="fieldset" style={themeSwitcher}>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={themeType === 'dark'}
                            onChange={() => themeChange()}
                            value="checkedB"
                            color="secondary"
                          />
                        }
                        label="Night Mode"
                      />
                    </FormGroup>
                  </FormControl>
                </Hidden>
              </div>

              <Hidden mdUp>
                <Tooltip
                  title={
                    themeType === 'dark'
                      ? 'Change to a brighter theme more suitable during daylight hours'
                      : 'Change to a mode easier on the eyes in darker environments'
                  }
                >
                  <IconButton onClick={() => themeChange()}>
                    <DarkMode
                      fill={
                        themeType === 'dark'
                          ? themeColors.mainColors.primaryColor
                          : '#727272'
                      }
                      width={36}
                    />
                  </IconButton>
                </Tooltip>
              </Hidden>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.hide]: isMobile && !open,
              [classes.drawerOpen]: open && !isMobile,
              [classes.drawerOpenFull]: open && isMobile,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.hide]: isMobile && !open,
                [classes.drawerOpen]: open && !isMobile,
                [classes.drawerOpenFull]: open && isMobile,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
          >
            <>
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftCircle
                    fill={themeColors.mainColors.primaryColor}
                    width={36}
                    className={classes.SVG}
                  />
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem button onClick={() => handleNavigate('/')}>
                  <ListItemIcon>
                    <DashboardSolid width={36} />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </List>
              <Divider />
              {isAuthenticated && state.user.userRole === 'admin' ? (
                <>
                  <div style={{ paddingBottom: 10 }} />
                  <List>
                    <ListItem button onClick={() => handleNavigate('/cases')}>
                      <ListItemIcon>
                        <CaseOutline
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Cases" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem button onClick={() => handleNavigate('/clients')}>
                      <ListItemIcon>
                        <People
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Clients" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      button
                      onClick={() => handleNavigate('/messages')}
                    >
                      <ListItemIcon>
                        <MessagesOutline
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Messages" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem
                      button
                      onClick={() => handleNavigate('/calendar')}
                    >
                      <ListItemIcon>
                        <CalendarOutline
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Calendar" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem button onClick={() => handleNavigate('/tasks')}>
                      <ListItemIcon>
                        <Tasks
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Tasks" />
                    </ListItem>
                  </List>
                  <Divider />
                  <List>
                    <ListItem button onClick={() => handleNavigate('/posts')}>
                      <ListItemIcon>
                        <Feather
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Posts" />
                    </ListItem>
                  </List>
                  <Divider />
                </>
              ) : null}
              {state.user.userRole === 'client' ? (
                <>
                  <List>
                    <ListItem
                      button
                      onClick={() => handleNavigate('/client-billing')}
                    >
                      <ListItemIcon>
                        <CreditCard
                          width={36}
                          fill={themeColors.mainColors.primaryColor}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Billing" />
                    </ListItem>
                  </List>
                  <Divider />
                </>
              ) : null}
              <>
                <List>
                  <ListItem button onClick={() => themeChange()}>
                    <ListItemIcon />
                    <ListItemText />
                  </ListItem>
                </List>
                <Divider />
                <List>
                  <ListItem button onClick={() => themeChange()}>
                    <ListItemIcon>
                      <DarkMode
                        width={36}
                        fill={
                          themeType === 'dark'
                            ? themeColors.mainColors.primaryColor
                            : '#727272'
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        themeType === 'dark' ? 'Light Theme' : 'Dark Theme'
                      }
                    />
                  </ListItem>
                </List>
                <Divider />
                {isAuthenticated ? (
                  <>
                    <List>
                      <ListItem
                        button
                        onClick={() =>
                          logout({ returnTo: window.location.origin })
                        }
                      >
                        <ListItemIcon>
                          <Power3
                            width={36}
                            fill="rgba(0,0,0,1)"
                            style={{
                              filter: `drop-shadow(0 0 0.20rem ${themeColors.mainColors.primaryColor}`,
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                      </ListItem>
                    </List>
                    <Divider />
                  </>
                ) : (
                  <List>
                    <Divider />
                    <ListItem button onClick={LOGIN}>
                      <ListItemIcon>
                        <Power3
                          width={36}
                          fill="rgba(0,0,0,1)"
                          style={{
                            filter: `drop-shadow(0 0 0.20rem ${themeColors.mainColors.warning}`,
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Login" />
                    </ListItem>
                    <Divider />
                  </List>
                )}
              </>
            </>
          </Drawer>
        </div>
      </ClickAwayListener>
    </>
  );
}
