import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import {
  Table,
  FormControlLabel,
  Switch,
  TextField,
  InputAdornment,
} from '@material-ui/core';

import { AccountCircleTwoTone } from '@material-ui/icons';
import { DeleteJudge } from '../../../../../components/mutations';
import { useColors } from '../../../../../hooks';
import { DeleteJudgeDialog } from './DeleteJudgeDialog';
import { JudgeTableHead } from './tableHead';
import { JudgeTableBody } from './tableBody';
import { JudgeTablePagination } from './tablePagination';
import { JudgeProfileDialog } from './judgeProfileDialog';

export interface JudgeProps {
  judges?: any;
}

const defaultProps = {
  judges: [],
};

const StyledDiv = styled.div<{ borderColor: string }>`
  overflow: hidden;
  border-top: 8px solid ${({ borderColor }): string => borderColor};
`;
export const Judges: React.FC<JudgeProps> = ({ judges }) => {
  const { themeColors } = useColors();
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(
    parseInt(localStorage.getItem('profileRowsPerPage') as string, 10) || 5,
  );

  // -------------------------------------delete op party -------------------------------------
  const [judgeId, setJudgeId] = useState(null);
  const [deleteTitle, setDeleteTitle] = useState(null);
  const [deleteJudge] = useMutation(DeleteJudge, {
    variables: { judge_id: judgeId },
  });
  // Modal Logic
  const [openJudgeDelete, setOpenJudgeDelete] = React.useState(false);
  const handleClose = (): void => {
    setOpenJudgeDelete(false);
  };
  const handleCloseAgree = (): void => {
    setOpenJudgeDelete(false);
    deleteJudge();
  };
  const handleClickOpenDelete = (ID, TITLE): void => {
    setOpenJudgeDelete(true);
    setJudgeId(ID);
    setDeleteTitle(TITLE);
  };
  const handleSearch = (e): void => {
    setSearch(e.target.value);
  };
  // -------------------------------------end delete op party ----------------------------------
  const [openJudgeProfile, setOpenJudgeProfile] = useState(false);
  const handleOpenJudgeProfile = (ID): void => {
    setOpenJudgeProfile(true);
    setJudgeId(ID);
  };
  const handleCloseJudgeProfile = () => {
    setOpenJudgeProfile(false);
  };
  useEffect(() => {
    if (localStorage.getItem('profileTableDense') === 'dense') {
      setDense(true);
    }
    if (localStorage.getItem('profileTableDense') === 'notDense') {
      setDense(false);
    }
    localStorage.setItem('profileRowsPerPage', rowsPerPage.toString());
  }, []);

  const handleChangeDense = (event: {
    target: { checked: React.SetStateAction<boolean> };
  }): void => {
    setDense(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem('profileTableDense', 'dense');
    }
    if (!event.target.checked) {
      localStorage.setItem('profileTableDense', 'notDense');
    }
  };

  const handleChangePage = (
    _event: unknown,
    newPage: React.SetStateAction<number>,
  ): void => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event: {
    target: { value: string };
  }): void => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem('profileRowsPerPage', event.target.value);
    setPage(0);
  };

  return (
    <>
      <StyledDiv borderColor={themeColors.mainColors.primaryColor}>
        <JudgeTablePagination
          judges={judges}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <div
          style={{
            float: 'left',
            marginBottom: -10,
            marginTop: 7,
            marginLeft: 15,
          }}
        >
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
          <TextField
            id="input-with-icon-search"
            onChange={handleSearch}
            placeholder="Search Last Name"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleTwoTone color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </StyledDiv>
      <div
        style={{
          borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
        }}
      />
      <Table aria-label="simple table" size={dense ? 'small' : 'medium'}>
        <JudgeTableHead />
        <JudgeTableBody
          judges={judges}
          rowsPerPage={rowsPerPage}
          page={page}
          search={search}
          handleClickOpenDelete={handleClickOpenDelete}
          handleOpenJudgeProfile={handleOpenJudgeProfile}
        />
      </Table>

      <>
        <JudgeTablePagination
          judges={judges}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <div
          style={{
            borderTop: `2px solid ${themeColors.mainColors.primaryColor}`,
          }}
        />
      </>
      <DeleteJudgeDialog
        open={openJudgeDelete}
        handleClose={handleClose}
        deleteTitle={deleteTitle}
        handleCloseAgree={handleCloseAgree}
      />
      {openJudgeProfile && (
        <JudgeProfileDialog
          open={openJudgeProfile}
          handleClose={handleCloseJudgeProfile}
          id={judgeId}
        />
      )}
    </>
  );
};

Judges.defaultProps = defaultProps;
