/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import Geocode from 'react-geocode';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  DistanceMatrixService,
  DirectionsService,
  DirectionsRenderer,
} from '@react-google-maps/api';
import { defaultStyle } from './styles';
import { useCurrentAttorney } from '../../hooks';

// geocode setup
Geocode.setApiKey(process.env.REACT_APP_G_MAP_API_KEY);
Geocode.setLanguage('en');

declare global {
  interface Window {
    google: any;
  }
}
interface DefaultMapProps {
  eventLocation: string;
  mapWidth?: string;
  mapHeight?: string;
  mapMaxWidth?: string;
  mapMaxHeight?: string;
  buildDirections?: boolean;
}

const defaultProps = {
  mapWidth: '875px',
  mapHeight: '425px',
  mapMaxWidth: '90vw',
  mapMaxHeight: '90vh',
  buldDirections: false,
};
export const DefaultMap: React.FC<DefaultMapProps> = ({
  eventLocation,
  mapWidth,
  mapHeight,
  mapMaxHeight,
  mapMaxWidth,
  buildDirections,
}) => {
  const { currentAttorney } = useCurrentAttorney();
  const [directionSetup, setDirectionSetup] = useState<any>();
  const [cords, setCords] = useState({ lat: '', lng: '' });
  const [travelDetails, setTravelDetails] = useState<any>();

  useEffect(() => {
    Geocode.fromAddress(eventLocation).then(response => {
      const { lat, lng } = response.results[0].geometry.location;
      setCords({ lat, lng });
    });
    setDirectionSetup({
      response: null,
      travelMode: 'DRIVING',
      origin: `${currentAttorney?.attorney_office_street}, ${currentAttorney?.attorney_office_city}, ${currentAttorney?.attorney_office_state} ${currentAttorney?.attorney_office_zip}`,
      destination: eventLocation,
    });
  }, [eventLocation, currentAttorney]);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_G_MAP_API_KEY as any,
    // libraries: libraries as Array<any>,
  });

  const mapContainerStyle = {
    width: mapWidth,
    height: mapHeight,
    maxWidth: mapMaxWidth,
    maxHeight: mapMaxHeight,
    overflow: 'hidden',
  };
  const options = {
    styles: defaultStyle,
    disableDefaultUI: true,
    zoomControl: true,
    resetBoundsOnResize: true,
  };
  const center = {
    lat: cords?.lat,
    lng: cords?.lng,
  };

  const mapOptions = {
    destination: directionSetup?.destination,
    origin: directionSetup?.origin,
    travelMode: directionSetup?.travelMode,
  };

  const directionsCallback = response => {
    if (response.status === 'OK') {
      setDirectionSetup({ ...directionSetup, response });
    }
  };
  const CallForDirections = () => {
    return (
      <DirectionsService
        // required
        options={mapOptions}
        // required
        callback={directionsCallback}
      />
    );
  };
  const distanceMatrixCallback = response => {
    if (response && !travelDetails) {
      setTravelDetails({
        distanceText: response?.rows[0]?.elements[0]?.distance?.text,
        distanceValue: response?.rows[0]?.elements[0]?.distance?.value,
        durationText: response?.rows[0]?.elements[0]?.duration?.text,
        durationValue: response?.rows[0]?.elements[0]?.duration?.value,
      });
    }
  };
  const GetDistance = () => {
    const distanceMatrixOptions = {
      origins: [directionSetup?.origin],
      destinations: [directionSetup?.destination],
      travelMode: 'DRIVING',
    };
    return (
      <DistanceMatrixService
        options={distanceMatrixOptions}
        callback={distanceMatrixCallback}
      />
    );
  };

  const StyledDiv = styled.div`
    color: black;
    background: white;
  `;
  const infoOptions = {
    disableAutoPan: false,
  };

  const DisplayWindow = () => {
    return (
      <InfoWindow options={infoOptions}>
        <StyledDiv>
          <Typography variant="body2">
            Travel Time: {travelDetails?.durationText}
          </Typography>
        </StyledDiv>
      </InfoWindow>
    );
  };

  if (loadError) return <>Error</>;
  if (!isLoaded) return <>Loading...</>;
  const RenderDirections = () => {
    return (
      <DirectionsRenderer
        // required
        options={{
          directions: directionSetup.response,
        }}
      />
    );
  };
  return (
    <div>
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle as React.CSSProperties}
        zoom={7}
        center={center}
        options={options}
      >
        {directionSetup.response === null &&
          buildDirections &&
          CallForDirections()}
        {directionSetup.response !== null &&
          buildDirections &&
          RenderDirections()}
        {buildDirections && GetDistance()}
        <Marker position={center}>{buildDirections && DisplayWindow()}</Marker>
      </GoogleMap>
    </div>
  );
};
DefaultMap.defaultProps = defaultProps;
