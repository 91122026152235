import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { BaseEventModal } from './BaseEditableEventModal';
import { EDIT_EVENT, EDIT_TASK } from '../../mutations';
import {
  EditableMatterEvent,
  MatterEvent,
  MatterTask,
  generateDynamicDateString,
} from '../../../utils';

type EventModalProps = {
  open: boolean;
  matterId: string;
  onClose: () => void;
  initialState?: MatterEvent;
};

type TaskUpdater = {
  executeTaskUpdate: (eventDate: string, tasks: MatterTask[]) => void;
};

// const taskUpdater = (onUpdateComplete: () => void): TaskUpdater => {
const useTaskUpdater = (): TaskUpdater => {
  // const [mutationsCalled, setMutationsCalled] = useState(0);
  // const [mutationsReturned, setMutationsReturned] = useState(0);
  const [editTask, { error }] = useMutation(EDIT_TASK);

  useEffect(() => {
    if (error) {
      console.error('Task Update Failed: ', error);
    }
  }, [error]);

  const executeTaskUpdate = (eventDate: string, tasks: MatterTask[]): void => {
    tasks.forEach((task): void => {
      if (task.task_due_dynamic) {
        editTask({
          variables: {
            task_id: task.task_id,
            task_event_id: task.task_event_id,
            task_name: task.task_name,
            task_description: task.task_description,
            task_owner: task.task_owner,
            task_due: generateDynamicDateString(
              eventDate,
              task.task_dynamic_due_from || 0,
            ),
            task_due_dynamic: task.task_due_dynamic,
            task_dynamic_due_from: task.task_dynamic_due_from,
            task_completed: task.task_completed,
            // added by travis
            task_matter_id: task.task_matter_id,
          },
        });
      }
    });
    // onUpdateComplete();
  };

  return { executeTaskUpdate };
};

const EditEventModal: React.FC<EventModalProps> = ({
  open,
  onClose,
  matterId,
  initialState,
}) => {
  const [formError, setFormError] = useState('');
  const [editEvent, { data, loading, error }] = useMutation(EDIT_EVENT);
  const { executeTaskUpdate } = useTaskUpdater();

  useEffect(() => {
    if (!loading && !error && !!data) {
      onClose();
    }
  }, [loading]);

  useEffect(() => {
    if (error) setFormError(error.message);
  }, [error]);

  const onSave = (eventState: EditableMatterEvent): void => {
    if (!initialState?.event_id) {
      setFormError('Event ID not detected');
    } else {
      setFormError('');
      editEvent({
        variables: {
          event_id: initialState?.event_id,
          event_matter_id: matterId,
          event_name: eventState.event_name,
          event_location: eventState.event_location,
          event_county: eventState.event_county,
          event_owner: eventState.event_owner,
          event_type: eventState.event_type,
          event_date_time: eventState.event_date_time,
          event_end_time: eventState.event_end_time,
          event_complete: eventState.event_complete,
        },
      });
      executeTaskUpdate(eventState.event_date_time, initialState.tasks || []);
    }
  };

  /**
   * We're only allowing the modal to mount when open is true.
   * This ensures that the initial state will not be set before mounting.
   * We do this because initial state can only be set once.
   */
  return (
    <>
      {open && (
        <BaseEventModal
          open={open}
          title="Edit Event"
          formError={formError}
          initialEventState={initialState}
          onSave={onSave}
          onClose={onClose}
          loading={loading}
        />
      )}
    </>
  );
};

export { EditEventModal };
