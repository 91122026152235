import {
  Chip,
  Collapse,
  Fade,
  LinearProgress,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { InvoicePDF } from '../../../../components';
import {
  Cancel,
  Check,
  Delete,
  Download,
  Link as LinkIcon,
  SendMessage,
} from '../../../../components/stories/SLICONS.stories';
import { boxShadows } from '../../../../constants';
import { useColors, useWidth } from '../../../../hooks';
import { PaymentModal } from './modals/paymentForm';

const InvoiceContainer = styled.div`
  /* width: 90vw; */
  max-width: 1200px;
  margin: 35px 5px;
  display: grid;
  grid-gap: 1rem;
`;
const Invoice = styled(Paper)<{ mode: string; size: number }>`
  display: grid;
  grid-template-columns: 30px auto ${({ size }): string =>
      (size >= 750 && '180px') || (size <= 400 && '60px') || '90px'};
  overflow: hidden;
  box-shadow: ${({ mode }): string =>
    (mode === 'dark' && boxShadows.three) || boxShadows.one};
`;
const INumber = styled.div`
  margin: 10px;
`;
const InvoiceRow = styled.div`
  margin: 5px 15px;
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
  text-align: left;
`;
const StyledChip = styled(Chip)<{ chipColor }>`
  color: ${({ chipColor }): string => chipColor};
  border: 1px solid ${({ chipColor }): string => chipColor};
  margin: 10px 5px 10px auto;
  align-self: 'flex-end';
`;

const EndElement = styled.div<{ screenSize: number }>`
  display: grid;
  grid-template-columns: ${({ screenSize }): string =>
    (screenSize >= 750 && '50% 50%') || '100%'};
  width: 100%;
`;

const EndButtonContainer = styled.div<{ bg: string }>`
  background-color: ${({ bg }): string => bg};
  position: relative;
  cursor: pointer;
  /* :last-child {
    border-radius: 0 4px 4px 0;
  } */
`;
const EndButton = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
`;
const Clickables = styled.div`
  max-width: 300px;
  display: grid;
  grid-gap: 3px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  margin: 0 0 10px 0;
`;
const InvoiceTypeTab = styled.div<{ tabColor: string }>`
  color: #fff;
  background-color: ${({ tabColor }): string => tabColor};
  overflow: hidden;
  position: relative;
  h5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }
`;

const chipColor: React.FC<string> = (
  status?: string | null,
  themeColors?: any,
) => {
  let chipStatusColor;
  if (status?.toLowerCase() === 'paid') {
    chipStatusColor = themeColors.mainColors.success;
  }
  if (status?.toLowerCase() === 'pending') {
    chipStatusColor = themeColors.mainColors.primaryColor;
  }
  if (status?.toLowerCase() === 'due') {
    chipStatusColor = themeColors.mainColors.warning;
  }
  if (status?.toLowerCase() === 'overdue') {
    chipStatusColor = themeColors.mainColors.danger;
  }
  if (status?.toLowerCase() === 'none') {
    chipStatusColor = themeColors.mainColors.primaryColor;
  }
  return chipStatusColor;
};

export const SLCaseInvoiceList = ({
  loading,
  invoices,
  matter,
  filterByCase = false,
  filterVoided = false,
  voided,
  payOrSend,
  deleteOrVoid,
}): any => {
  const { width } = useWidth();
  const { themeColors } = useColors();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);
  const [chargeAmount, setChargeAmount] = useState(0);
  const [invoiceId, setInvoiceId] = useState(null);

  const IconSize = useMemo(() => {
    let size = 'small';
    if (width! > 750) size = 'medium';
    return size;
  }, [width]);

  const handleOpenPaymentToggle = (
    amt,
    clientData,
    id,
    t = !showPaymentForm,
  ): void => {
    setChargeAmount(amt);
    setShowPaymentForm(t);
    setClientInfo(clientData);
    setInvoiceId(id);
  };

  const [pdfInvoiceOpen, setPDFInvoiceOpen] = useState(false);
  const [currentInvoice, setCurrentInvoice] = useState(null);
  const [invoiceNo, setInvoiceNo] = useState(null);

  const handleGeneratePDFInvoice = (a, b): void => {
    setCurrentInvoice(a);
    setInvoiceNo(b);
    setPDFInvoiceOpen(true);
  };

  const handlePDFClose = (): void => {
    setPDFInvoiceOpen(false);
  };

  return (
    <>
      <InvoiceContainer>
        {(!loading &&
          (
            (filterVoided &&
              filterByCase &&
              invoices
                ?.filter(
                  i =>
                    i?.invoice_status_type.invoice_status_type_name !== 'Void',
                )
                .filter(i => i?.matter.matter_id === matter.matter_id)) ||
            (!filterVoided &&
              filterByCase &&
              invoices?.filter(
                i => i?.matter.matter_id === matter.matter_id,
              )) ||
            (filterVoided &&
              !filterByCase &&
              invoices?.filter(
                i => i?.invoice_status_type.invoice_status_type_name !== 'Void',
              )) ||
            invoices
          )?.map((
            // {
            //   invoice_id,
            //   invoice_amount,
            //   invoice_client_id,
            //   invoice_client_notes,
            //   invoice_created_by,
            //   invoice_created_date,
            //   invoice_description,
            //   invoice_discounts,
            //   invoice_due,
            //   invoice_initial_payment,
            //   invoice_internal_notes,
            //   invoice_items,
            //   invoice_matter_id,
            //   invoice_status_type,
            //   payments,
            //   client,
            // },
            curEl,
            index,
            array,
          ) => (
            <>
              <Collapse
                in={!voided[curEl.invoice_id]}
                timeout={1000}
                unmountOnExit
                key={curEl.invoice_id}
              >
                <Fade
                  in={!voided[curEl.invoice_id]}
                  timeout={1000}
                  unmountOnExit
                >
                  <Invoice
                    mode={localStorage.getItem('theme_mode') as any}
                    size={width || 400}
                  >
                    <InvoiceTypeTab
                      tabColor={themeColors.mainColors.primaryColor}
                    >
                      <Typography variant="h5">
                        {(curEl.invoice_initial_payment === true &&
                          'Initial') ||
                          'Monthly'}
                      </Typography>
                    </InvoiceTypeTab>
                    <div>
                      <InvoiceRow>
                        <INumber>
                          <Typography variant="h6" component="div">
                            {curEl.invoice_client_id
                              .split('-')[0]
                              .toUpperCase()}
                            {(index < 10 && '-000') || '-00'}
                            {index + 1}
                          </Typography>
                        </INumber>
                        {curEl.invoice_status_type.invoice_status_type_name !==
                          'Paid' && (
                          <StyledChip
                            size={IconSize as any}
                            label={
                              curEl.invoice_status_type.invoice_status_type_name
                            }
                            variant="outlined"
                            chipColor={chipColor(
                              curEl.invoice_status_type
                                .invoice_status_type_name,
                              themeColors,
                            )}
                          />
                        )}
                      </InvoiceRow>
                      <InvoiceRow>
                        <Typography variant="body2" component="div">
                          Invoice Total: $
                          {(curEl.invoice_amount / 100)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        </Typography>
                        <Typography variant="subtitle2" component="div">
                          Due:{' '}
                          <strong>
                            {moment(curEl.invoice_due, 'YYYY-MM-DD').format(
                              'MMM DD, YYYY',
                            )}
                          </strong>
                        </Typography>
                      </InvoiceRow>
                      <InvoiceRow>
                        <Typography variant="subtitle2" component="div">
                          Remaining Due:
                          <strong>
                            {' '}
                            $
                            {(curEl.invoice_amount / 100)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          </strong>
                        </Typography>
                        <Clickables>
                          <Typography variant="body1" component="div">
                            <Chip
                              onClick={() =>
                                handleGeneratePDFInvoice(
                                  curEl,
                                  `${curEl.invoice_client_id
                                    .split('-')[0]
                                    .toUpperCase()}${(index < 10 && '-000') ||
                                    '-00'}${index + 1}`,
                                )
                              }
                              clickable
                              size={IconSize as any}
                              avatar={<Download fill="black" />}
                              variant="outlined"
                              label="Invoice"
                            />
                          </Typography>
                          <Typography variant="body1" component="div">
                            <Chip
                              clickable
                              avatar={<LinkIcon fill="black" />}
                              size={IconSize as any}
                              variant="outlined"
                              label="Payment"
                              onClick={() =>
                                handleOpenPaymentToggle(
                                  curEl.invoice_amount,
                                  curEl.client,
                                  curEl.invoice_id,
                                )
                              }
                            />
                          </Typography>
                        </Clickables>
                      </InvoiceRow>
                    </div>
                    <EndElement screenSize={width || 300}>
                      {(curEl.invoice_status_type.invoice_status_type_name !==
                        'Paid' && (
                        <EndButtonContainer
                          bg="#FF6961"
                          onClick={(): void =>
                            deleteOrVoid(
                              curEl.invoice_status_type
                                .invoice_status_type_name,
                              curEl.invoice_id,
                            ) as any
                          }
                        >
                          <EndButton>
                            {(curEl.invoice_status_type
                              .invoice_status_type_name === 'Draft' && (
                              <Delete width={24} fill="#fff" />
                            )) || <Cancel width={24} fill="#fff" />}
                            <Typography variant="body2">
                              {(curEl.invoice_status_type
                                .invoice_status_type_name === 'Draft' &&
                                'Delete') ||
                                'Void'}
                            </Typography>
                          </EndButton>
                        </EndButtonContainer>
                      )) || <EndButtonContainer bg="ffffff00" />}
                      <Tooltip
                        title={
                          (curEl.invoice_status_type
                            .invoice_status_type_name === 'Draft' &&
                            'Finalize the invoice and send to the client') ||
                          (curEl.invoice_status_type
                            .invoice_status_type_name !== 'Paid' &&
                            'Mark the invoice as paid. Use this option if you collected payment outside of Stripe') ||
                          'Invoice Paid'
                        }
                      >
                        <EndButtonContainer
                          bg={
                            (curEl.invoice_status_type
                              .invoice_status_type_name !== 'Paid' &&
                              '#071833') ||
                            themeColors.mainColors.success
                          }
                          onClick={(): void =>
                            curEl.invoice_status_type
                              .invoice_status_type_name !== 'Paid' &&
                            (payOrSend(
                              curEl.invoice_status_type
                                .invoice_status_type_name,
                              curEl.invoice_id,
                              6666600,
                            ) as any)
                          }
                        >
                          <EndButton>
                            {(curEl.invoice_status_type
                              .invoice_status_type_name === 'Draft' && (
                              <SendMessage width={24} fill="#fff" />
                            )) ||
                              (curEl.invoice_status_type
                                .invoice_status_type_name !== 'Paid' && (
                                <Check width={24} fill="#fff" />
                              ))}
                            {(curEl.invoice_status_type
                              .invoice_status_type_name === 'Paid' &&
                              'Paid') || (
                              <Typography variant="body2">
                                {(curEl.invoice_status_type
                                  .invoice_status_type_name === 'Draft' &&
                                  'Send Invoice') ||
                                  'Mark Paid'}
                              </Typography>
                            )}
                          </EndButton>
                        </EndButtonContainer>
                      </Tooltip>
                    </EndElement>
                  </Invoice>
                </Fade>
              </Collapse>
            </>
          ))) || <LinearProgress />}
      </InvoiceContainer>
      {showPaymentForm && (
        <PaymentModal
          open={showPaymentForm}
          toggle={handleOpenPaymentToggle}
          chargeAmount={chargeAmount}
          invoiceId={invoiceId || ''}
          client={clientInfo!}
        />
      )}
      {pdfInvoiceOpen && (
        <InvoicePDF
          open={pdfInvoiceOpen}
          handlePDFClose={handlePDFClose}
          invoice={currentInvoice}
          invoiceNo={invoiceNo}
        />
      )}
    </>
  );
};
