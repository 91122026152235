import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import {
  InputLabel,
  IconButton,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { useIndexer } from '../../context';
import { WORKFLOWS } from '../queries';
import { sharedStyles } from './utils';
import { Workflow } from '../../utils';

const StyledFormControl = styled(FormControl)`
  ${sharedStyles}
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  right: 32px;
  top: 4px;
`;

type WorkflowSelectProps = {
  onWorkflowSelect: (workflow?: Workflow) => void;
  initialValue?: string;
  error?: boolean;
  errorText?: string;
  label?: string;
  enableClear?: boolean;
};

const WorkflowSelect: React.FC<WorkflowSelectProps> = ({
  onWorkflowSelect,
  initialValue,
  error,
  errorText = 'Field is required',
  label = 'Workflow',
}) => {
  const { data } = useQuery<{ workflow: Workflow[] }>(WORKFLOWS, {});

  const componentIndex = useIndexer();
  const [workflowName, setWorkflowName] = useState(initialValue || '');

  const labelId = useMemo(() => `workflow-select-label-${componentIndex}`, [
    componentIndex,
  ]);

  const selectId = useMemo(() => `workflow-select-${componentIndex}`, [
    componentIndex,
  ]);

  const findWorkflow = (name: string): Workflow | undefined =>
    data?.workflow.find(workflow => workflow.workflow_name === name);

  useEffect(() => {
    if (data) {
      onWorkflowSelect(findWorkflow(workflowName));
    }
  }, [data, workflowName]);

  const sortedWorkflows =
    (data &&
      data.workflow
        .slice()
        .sort((a, b): any =>
          a.workflow_name?.localeCompare(b.workflow_name as any),
        )) ||
    [];

  return (
    <>
      {data ? (
        <StyledFormControl variant="outlined" error={error}>
          <InputLabel id={labelId} htmlFor={selectId}>
            {label}
          </InputLabel>
          <Select
            labelId={labelId}
            id={selectId}
            value={workflowName}
            onChange={(e): void => {
              const name = e.target.value as string;
              setWorkflowName(name);
            }}
            labelWidth={70}
          >
            {sortedWorkflows.map(({ workflow_id, workflow_name }) => (
              <MenuItem
                key={workflow_id}
                value={workflow_name || 'Error loading workflow'}
              >
                {workflow_name}
              </MenuItem>
            ))}
          </Select>
          {error && <FormHelperText>{errorText}</FormHelperText>}
          {!!workflowName && (
            <StyledIconButton
              onClick={(): void => {
                setWorkflowName('');
              }}
            >
              <Close width={24} />
            </StyledIconButton>
          )}
        </StyledFormControl>
      ) : null}
    </>
  );
};

export { WorkflowSelect };
