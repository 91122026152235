/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Dialog, Button, Grid } from '@material-ui/core';
import { OpParty, Judge, Court, OpposingCounsel } from '../../../../utils';
import {
  OpPartyProfile,
  JudgeProfile,
  OpFirmProfile,
  CourtProfile,
  OpCounselProfile,
} from './profileTypes';
import {
  AddJudge,
  CreditorMutation,
  OP_FIRM,
  ADD_COURT,
  AddOpAttorney,
} from '../../../../components/mutations';

const StyledGrid = styled(Grid)`
  width: 95%;
  max-width: 700px;
  padding: 15px;
  margin: 14px auto;
`;
export interface AddProfileDialogProps {
  open: boolean;
  setOpen: any;
  tab: number;
}
// const Transition = React.forwardRef(function Transition(props: any, ref: any) {
//   return (
//     <>
//       <Slide direction="up" ref={ref} {...props} />
//     </>
//   );
// });

export const AddProfileDialog: React.FC<AddProfileDialogProps> = ({
  open,
  setOpen,
  tab,
}) => {
  const [opProfile, setOpProfile] = useState<OpParty>({});
  const [opFirmProfile, setOpFirmProfile] = useState<OpParty>({});
  const [judgeProfile, setJudgeProfile] = useState<Judge>({});
  const [courtProfile, setCourtProfile] = useState<Court>({});
  const [attyProfile, setAttyProfile] = useState<OpposingCounsel>({});
  const [SendOpProfile] = useMutation(CreditorMutation, {
    variables: {
      address: opProfile.address,
      address2: opProfile.address2,
      city: opProfile.city,
      state: opProfile.state,
      zip: opProfile.zip,
      zip4: opProfile.zip4,
      email: opProfile.email,
      fax: opProfile.fax,
      name: opProfile.name,
      phone: opProfile.phone,
    },
    onCompleted: () => {
      setOpen(false);
    },
  });
  const [SendJudgeProfile] = useMutation(AddJudge, {
    variables: {
      judgeFirstName: judgeProfile.judgeFirstName,
      judgeMiddleName: judgeProfile.judgeMiddleName,
      judgeLastName: judgeProfile.judgeLastName,
      judgeUndesired: judgeProfile.judgeUndesired || false,
    },
    onCompleted: () => {
      setOpen(false);
    },
  });
  const [SendOpAttyProfile] = useMutation(AddOpAttorney, {
    variables: {
      name: attyProfile.op_counsel_name,
      firmId: attyProfile.op_counsel_firm_id,
    },
    onCompleted: () => {
      setOpen(false);
    },
  });
  const [SendOpFirm] = useMutation(OP_FIRM, {
    variables: {
      op_firm_address: opFirmProfile.address,
      op_firm_address2: opFirmProfile.address2,
      op_firm_address_city: opFirmProfile.city,
      op_firm_address_state: opFirmProfile.state,
      op_firm_address_zip: opFirmProfile.zip,
      op_firm_address_zip_4: opFirmProfile.zip4,
      op_firm_email: opFirmProfile.email,
      op_firm_fax: opFirmProfile.fax,
      op_firm_name: opFirmProfile.name,
      op_firm_phone: opFirmProfile.phone,
    },
    onCompleted: () => {
      setOpen(false);
    },
  });
  const [SendCourtProfile] = useMutation(ADD_COURT, {
    variables: {
      court_address_city: courtProfile.court_address_city, // done
      court_address_state: courtProfile.court_address_state, // done
      court_address_street: courtProfile.court_address_street, // done
      court_address_street2: courtProfile.court_address_street2, // done
      court_address_zip: courtProfile.court_address_zip, // done
      court_address_zip_4: courtProfile.court_address_zip_4, // done
      court_county: courtProfile.court_county, // done
      court_efile_link: courtProfile.court_efile_link, // done
      court_fax: courtProfile.court_fax, // done
      court_mdj_number: courtProfile.court_mdj_number,
      court_name: courtProfile.court_name, // done
      court_phone: courtProfile.court_phone, // done
      court_state: courtProfile.court_address_state, // done
      court_type: courtProfile.court_type, // done
    },
    onCompleted: () => {
      setOpen(false);
    },
  });
  const handleChangeOpProfile = k => e => {
    setOpProfile({ ...opProfile, [k]: e.target.value });
  };
  const handleOpAttyProfile = k => (e, v) => {
    setAttyProfile({ ...attyProfile, [k]: e.target.value || v.op_firm_id });
  };
  const handleChangeOpFirmProfile = k => e => {
    setOpFirmProfile({ ...opFirmProfile, [k]: e.target.value });
  };
  const handleChangeCourtProfile = k => e => {
    setCourtProfile({ ...courtProfile, [k]: e.target.value });
  };
  const handleChangeJudgeProfile = k => e => {
    if (k === 'judgeUndesired') {
      setJudgeProfile({ ...judgeProfile, [k]: e.target.checked });
    }
    if (k !== 'judgeUndesired') {
      setJudgeProfile({ ...judgeProfile, [k]: e.target.value });
    }
  };
  const handleSubmit = (): void => {
    switch (tab) {
      case 0:
        SendOpProfile();
        break;
      case 1:
        SendOpFirm();
        break;
      case 2:
        SendOpAttyProfile();
        break;
      case 3:
        SendJudgeProfile();
        break;
      case 4:
        SendCourtProfile();
        break;
      default:
        break;
    }
  };

  const ProfileType = (): unknown => {
    switch (tab) {
      case 0:
        return (
          <>
            <OpPartyProfile
              handleChangeOpProfile={handleChangeOpProfile}
              opProfile={opProfile}
            />
          </>
        );
      case 1:
        return (
          <>
            <OpFirmProfile
              handleChangeOpFirmProfile={handleChangeOpFirmProfile}
              opFirmProfile={opFirmProfile}
            />
          </>
        );
      case 2:
        return (
          <>
            <OpCounselProfile
              handleOpAttyProfile={handleOpAttyProfile}
              attyProfile={attyProfile}
            />
          </>
        );

      case 3:
        return (
          <>
            <JudgeProfile
              handleChangeJudgeProfile={handleChangeJudgeProfile}
              judgeProfile={judgeProfile}
            />
          </>
        );
      case 4:
        return (
          <>
            <CourtProfile
              handleChangeCourtProfile={handleChangeCourtProfile}
              courtProfile={courtProfile}
            />
          </>
        );
      default:
        break;
    }
  };
  return (
    <Dialog
      //   fullScreen
      open={open}
      onClose={setOpen}
      //   TransitionComponent={Transition as any}
    >
      {/* <StyledAppBar background={themeColors.mainColors.menuColor}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={setOpen}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Sound</Typography>
          <Button autoFocus color="inherit">
            save
          </Button>
        </Toolbar>
      </StyledAppBar>{ */}

      <StyledGrid container spacing={2}>
        {ProfileType()}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            fullWidth
            color="secondary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </StyledGrid>
    </Dialog>
  );
};
