import { gql } from '@apollo/client';

export const OpposingAttorneysSub = gql`
  subscription OpposingAttorneys {
    op_counsel {
      op_counsel_firm_id
      op_counsel_id
      op_counsel_name
      matters {
        matter_id
      }
      op_firm {
        op_firm_name
      }
    }
  }
`;
