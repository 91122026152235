import React, { useState, useCallback } from 'react';
import { BaseTaskModal } from './BaseEditableTaskModal';
import { useAddTask } from '../../../hooks';
import { EditableMatterTask, Matter, MatterEvent } from '../../../utils';

type TaskModalProps = {
  open: boolean;
  matter?: Matter;
  event?: MatterEvent;
  onClose: () => void;
};

const AddTaskModal: React.FC<TaskModalProps> = ({
  open,
  matter,
  event,
  onClose,
}) => {
  const [formError, setFormError] = useState('');

  const onSuccess = useCallback((): void => {
    onClose();
  }, [onClose]);

  const onError = useCallback(
    (errorMessage: string): void => {
      setFormError(errorMessage);
    },
    [setFormError],
  );

  const { onAddTask, loading } = useAddTask({ onSuccess, onError });

  const onSave = (taskState: EditableMatterTask): void => {
    setFormError('');
    onAddTask(taskState, matter, event);
  };

  return (
    <BaseTaskModal
      open={open}
      title="Create Task"
      formError={formError}
      eventDate={event?.event_date_time || undefined}
      onSave={onSave}
      onClose={onClose}
      loading={loading}
    />
  );
};

export { AddTaskModal };
