import React from 'react';
import styled from 'styled-components';
import { Typography, Paper } from '@material-ui/core';
import { PriorityHighTwoTone } from '@material-ui/icons';
import { boxShadows } from '../../constants';
import { useColors } from '../../hooks';

const Card = styled(Paper)`
  cursor: pointer;
  width: 95%;
  max-width: 500px;
  margin: 10px 0;
  min-width: 310px;
  transition: all 0.2s ease-in;
  :hover {
    box-shadow: ${boxShadows.two};
    transform: translate(0px, -2px);
  }
`;
const CardContentContainer = styled.div`
  display: grid;
  grid-template-columns: 5px 1fr 70px;
  justify-content: center;
  align-items: center;
`;
const EndPiece = styled.div`
  text-align: center;
  border-left: 1px solid lightgray;
  padding: 25px 0;
`;

interface InfoCardProps {
  title: string;
  subtitle1: string;
  subtitle2: string;
  endText: string;
  onClick: any;
  isPriority?: boolean;
}

const StyledWarn = styled(PriorityHighTwoTone)<{ IconColor: string }>`
  color: ${({ IconColor }): string => IconColor};
  animation: pulse-red 3s infinite;
  border-radius: 50%;
  @keyframes pulse-red {
    0% {
      box-shadow: 0 0 0 0 ${({ IconColor }): string => IconColor};
    }

    50% {
      transform: scale(1.2);
      box-shadow: 0 0 0 6px ${({ IconColor }): string => IconColor}00;
    }

    100% {
      box-shadow: 0 0 0 0 ${({ IconColor }): string => IconColor}00;
    }
  }
`;

export const InfoCard: React.FC<InfoCardProps> = ({
  title,
  subtitle1,
  subtitle2,
  endText,
  onClick,
  isPriority,
}): any => {
  const { themeColors } = useColors();
  return (
    <Card onClick={onClick}>
      <CardContentContainer>
        {(isPriority && (
          <StyledWarn IconColor={themeColors.mainColors.danger} />
        )) || <div />}
        <div>
          <Typography
            variant="subtitle2"
            style={{ fontSize: 12, padding: '0 25px' }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 10, padding: '0 25px' }}
          >
            {subtitle1}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: 10, padding: '0 25px' }}
          >
            {subtitle2}
          </Typography>
        </div>
        <EndPiece>
          <Typography variant="subtitle2" style={{ fontWeight: 'Bold' as any }}>
            {endText}
          </Typography>
        </EndPiece>
      </CardContentContainer>
    </Card>
  );
};
