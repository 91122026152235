import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';
import { PulseLoader as Loader } from 'react-spinners';
import { useColors } from '../../hooks';

const StyledWrapper = styled.span`
  position: relative;
`;

const StyledLoaderWrapper = styled.span`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
  loaderSize?: number;
  loaderColor?: string;
}

const { themeColors } = useColors();
const defaultProps = {
  loaderSize: 6,
  loaderColor: themeColors.mainColors.secondaryColor,
};

const LoadingButton: React.FC<LoadingButtonProps> = ({
  loading,
  loaderSize,
  loaderColor,
  disabled,
  ...props
}) => (
  <StyledWrapper>
    <Button {...props} disabled={loading || disabled} />
    {loading && (
      <StyledLoaderWrapper>
        <Loader size={loaderSize} color={loaderColor} />
      </StyledLoaderWrapper>
    )}
  </StyledWrapper>
);

LoadingButton.defaultProps = defaultProps;

export { LoadingButton };
