import React from 'react';
import styled from 'styled-components';
import { TableBody, TableCell, TableRow, Tooltip } from '@material-ui/core';
import { PriorityHighTwoTone } from '@material-ui/icons';
import { WorkflowTaskModifiers } from '../..';
import { WorkflowTask } from '../../../utils';
import { useColors } from '../../../hooks';

type WorkflowTaskTableProps = {
  tasks: WorkflowTask[];
  onEditTask: (taskId: string) => void;
};

const StyledTableCell = styled(TableCell)`
  :hover {
    cursor: pointer;
  }
`;

const StyledWarn = styled(PriorityHighTwoTone)<{ IconColor: string }>`
  color: ${({ IconColor }): string => IconColor};
  animation: pulse-red 3s infinite;
  border-radius: 50%;
  @keyframes pulse-red {
    0% {
      box-shadow: 0 0 0 0 ${({ IconColor }): string => IconColor};
    }

    50% {
      transform: scale(1.2);
      box-shadow: 0 0 0 6px ${({ IconColor }): string => IconColor}00;
    }

    100% {
      box-shadow: 0 0 0 0 ${({ IconColor }): string => IconColor}00;
    }
  }
`;

const WorkflowTaskTableBody: React.FC<WorkflowTaskTableProps> = ({
  tasks,
  onEditTask,
}) => {
  const { themeColors } = useColors();
  return (
    <TableBody>
      {tasks.map(
        ({
          workflow_task_id,
          workflow_task_name,
          workflow_task_description,
          workflow_task_dynamic_due_from,
          workflow_task_dynamic_lock,
          task_is_priority,
        }) => (
          <TableRow
            key={workflow_task_id}
            onClick={(): void => onEditTask(workflow_task_id)}
            hover
          >
            <StyledTableCell align="center">
              {task_is_priority && (
                <StyledWarn IconColor={themeColors.mainColors.danger} />
              )}
            </StyledTableCell>

            <Tooltip
              title={
                (
                  <div
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: workflow_task_description ?? '',
                    }}
                  />
                ) ?? ''
              }
            >
              <StyledTableCell align="left">
                {workflow_task_name}
              </StyledTableCell>
            </Tooltip>
            <StyledTableCell align="right">
              {workflow_task_dynamic_due_from}
            </StyledTableCell>
            <StyledTableCell align="right">
              {workflow_task_dynamic_lock ? 'Yes' : 'No'}
            </StyledTableCell>
            <TableCell align="right">
              <WorkflowTaskModifiers
                taskId={String(workflow_task_id)}
                onEditClick={(): void => onEditTask(workflow_task_id)}
                suppressView
              />
            </TableCell>
          </TableRow>
        ),
      )}
    </TableBody>
  );
};

export { WorkflowTaskTableBody };
