// TODO: The lint errors in this file need to be addressed
/* eslint-disable  */

import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useMutation, useQuery, gql } from '@apollo/client';
import {
  Paper,
  TextField,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  LinearProgress,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import VisibilityTwoToneIcon from '@material-ui/icons/VisibilityTwoTone';
import VisibilityOffTwoToneIcon from '@material-ui/icons/VisibilityOffTwoTone';
import { useColors } from '../../../hooks';
import { boxShadows } from '../../../constants/shadows/shadows';

const { themeColors } = useColors();

const NewPost = ({ data }) => {
  const bodytext = {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    paddingTop: 30,
  };
  const insertArticle = gql`
    mutation insertArticle(
      $article_body: String
      $article_img: String
      $article_summary: String
      $article_title: String
      $article_url_slug: String
      $category_id: Int
      $article_visibility: Boolean
      $author_id: Int
    ) {
      insert_article(
        objects: {
          article_body: $article_body
          article_img: $article_img
          article_summary: $article_summary
          article_title: $article_title
          article_url_slug: $article_url_slug
          category_id: $category_id
          article_visibility: $article_visibility
          author_id: $author_id
        }
      ) {
        returning {
          article_id
        }
      }
    }
  `;

  const insertArticleTags = gql`
    mutation insertArticleTag($article_id: Int, $tag_id: Int) {
      insert_article_tag(
        objects: { article_id: $article_id, tag_id: $tag_id }
      ) {
        returning {
          article_tag_id
        }
      }
    }
  `;
  const getAttorneys = gql`
    query MyQuery {
      attorney {
        attorney_id
        attorney_name
        attorney_role
        attorney_active
      }
    }
  `;
  const { data: attorneys, loading } = useQuery(getAttorneys);
  const [authorID, setAuthorID] = useState([
    { attorney_id: 6, attorney_name: 'Starks Law' },
  ]);
  const handleAuthor = (e, value) => {
    setAuthorID(value);
  };
  const [body, setBody] = useState();
  const handleEditorChange = e => {
    setBody(e.target.getContent());
  };
  const [title, setTitle] = useState();
  const handleTitleChange = e => {
    setTitle(e.target.value);
  };
  const [summary, setSummary] = useState();
  const handleSummaryChange = e => {
    setSummary(e.target.value);
  };
  const [url, setUrl] = useState();
  const handleUrlChange = e => {
    setUrl(
      e.target.value
        .replace(' ', '-')
        .replace('_', '-')
        .replace('!', '')
        .replace('@', '')
        .replace('#', '')
        .replace('$', '')
        .replace('%', '')
        .replace('^', '')
        .replace('&', '')
        .replace('*', '')
        .replace('(', '')
        .replace(')', '')
        .replace('`', '')
        .replace('~', '')
        .replace('=', '')
        .replace('+', '')
        .replace(']', '')
        .replace('[', '')
        .replace('}', '')
        .replace('{', '')
        .replace("'", '')
        .replace('"', '')
        .replace(';', '')
        .replace(':', '')
        .replace('/', '')
        .replace('?', '')
        .replace('.', '')
        .replace(',', '')
        .replace('<', '')
        .replace('>', '')
        .replace('|', '')
        .replace('*', ''),
    );
  };
  const [keyword, setKeyword] = useState([{ tag_id: '', tag_value: '' }]);
  const handleAddKeyword = (_, value) => {
    setKeyword(value);
  };
  const [category, setCategory] = useState([
    { category_id: '', category_name: '' },
  ]);
  const handleChangeCategory = (e, value) => {
    setCategory(value);
  };
  const [pngImage, setPngImage] = useState();
  const [pngImageName, setPngImageName] = useState('');
  const [articleVisibility, setArticleVisibility] = useState(false);
  const handleArticleVisibilityChange = e => {
    setArticleVisibility(e.target.checked);
  };
  const [svgImage, setSvgImage] = useState();
  const [svgImageName, setSvgImageName] = useState('');
  const [pngImgSrc, setPngImgSrc] = useState();
  const [svgImgSrc, setSvgImgSrc] = useState();
  const [articleId, setArticleId] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);

  const handlePNG = e => {
    setPngImage(e.target.files[0]);
    setPngImageName(e.target.files[0].name);
    const reader = new FileReader();
    // const url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setPngImgSrc(reader.result);
    };
  };

  const handleSVG = e => {
    setSvgImage(e.target.files[0]);
    setSvgImageName(e.target.files[0].name);
    const reader = new FileReader();
    const url = reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = e => {
      setSvgImgSrc(reader.result);
    };
  };

  const [addArticleTags] = useMutation(insertArticleTags);

  const handleImageUpload = e => {
    let formDataSVG = new FormData();
    formDataSVG.append('image', svgImage);
    let formDataPNG = new FormData();
    formDataPNG.append('image', pngImage);

    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        (pngImage && setUploadPercentage(percent / 2)) ||
          setUploadPercentage(percent);
      },
    };
    const options2 = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('id_token')}`,
      },
      onUploadProgress: progressEvent => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);
        setUploadPercentage(50 + percent / 2);
      },
    };
    const baseServerUrl = process.env.REACT_APP_SERVER_URL;
    const putURL = `${baseServerUrl}/upload-post-image`;
    axios
      .post(putURL, formDataSVG, options)
      .then((pngImage && axios.post(putURL, formDataPNG, options2)) || null)
      .catch(err => {
        console.log('err', err);
      });
  };

  const [addArticle] = useMutation(insertArticle, {
    variables: {
      article_body: body,
      article_img: svgImageName,
      article_summary: summary,
      article_title: title,
      article_url_slug: url,
      category_id: category.category_id,
      article_visibility: articleVisibility,
      author_id: authorID.attorney_id,
    },
    update: (proxy, mutationResult) => {
      setArticleId(mutationResult.data.insert_article.returning[0].article_id);
    },
    onCompleted: () => {
      keyword.map(({ tag_id }) =>
        addArticleTags({
          variables: { tag_id: tag_id, article_id: articleId },
        }),
      );
      handleImageUpload();
    },
  });

  const startArticleUpload = () => {
    setUploading(true);
    addArticle();
  };

  if (loading) return <p>Loading ...</p>;
  return (
    <>
      <div style={bodytext}>
        <div className="poststyling">
          <div style={{ height: 30 }}></div>
          <div style={{ margin: 'auto' }}>
            <Paper
              style={{
                padding: 30,
                borderLeft:
                  '20px double ' + themeColors.mainColors.primaryColor,
                margin: 'auto',
                marginBottom: 45,
                boxShadow: boxShadows.three,
              }}
            >
              <TextField
                id="outlined-title"
                label="Title"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleTitleChange}
                defaultValue=""
                value={title}
              />
              <TextField
                id="outlined-summary"
                label="Summary"
                margin="normal"
                variant="outlined"
                fullWidth
                multiline
                rows="4"
                onChange={handleSummaryChange}
                defaultValue=""
                value={summary}
              />
              <Autocomplete
                id="category-dropdown"
                margin="normal"
                autoHighlight
                options={data.category}
                getOptionLabel={option => option.category_name}
                fullWidth
                onChange={handleChangeCategory}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Category"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <TextField
                id="outlined-slug"
                label="URL Slug"
                margin="normal"
                variant="outlined"
                fullwidth
                onChange={handleUrlChange}
                defaultValue=""
                value={url}
                style={{ width: '100%' }}
              />
              <Autocomplete
                id="keyword-dropdown"
                multiple
                filterSelectedOptions
                margin="normal"
                autoHighlight
                options={data.tag}
                getOptionLabel={option => option.tag_value}
                onChange={handleAddKeyword}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Keywords"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <TextField
                    fullWidth
                    id="api-key-input"
                    label="SVG Image"
                    variant="outlined"
                    value={svgImageName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <input
                    accept="image/svg+xml"
                    style={{ display: 'none' }}
                    id="svg-image"
                    type="file"
                    onChange={handleSVG}
                  />
                  <label htmlFor="svg-image">
                    <Button
                      variant="outlined"
                      fullWidth
                      component="span"
                      color="primary"
                      style={{ height: '100%', maxWidth: '20vw' }}
                    >
                      Browse
                    </Button>
                  </label>
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: 5 }}>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                  <TextField
                    fullWidth
                    id="png-image-input"
                    label="PNG Image"
                    variant="outlined"
                    value={pngImageName}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <input
                    accept="image/png"
                    style={{ display: 'none' }}
                    id="png-image"
                    type="file"
                    onChange={handlePNG}
                  />
                  <label htmlFor="png-image">
                    <Button
                      variant="outlined"
                      fullWidth
                      component="span"
                      color="primary"
                      style={{ height: '100%', maxWidth: '20vw' }}
                    >
                      Browse
                    </Button>
                  </label>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="combo-box-demo"
                    // options={attorneys.attorney}
                    options={attorneys.attorney
                      .filter(
                        x =>
                          x.attorney_active &&
                          ['Attorney', 'Support Staff', 'System User'].includes(
                            x.attorney_role,
                          ),
                      )
                      .sort((a, b) =>
                        a.attorney_name.localeCompare(b.attorney_name),
                      )}
                    getOptionLabel={option =>
                      option.attorney_role === 'Attorney'
                        ? `${option.attorney_name}, Esq.`
                        : option.attorney_name
                    }
                    onChange={handleAuthor}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Author"
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Paper>
            <div>
              <img src={svgImgSrc} style={{ maxWidth: 60, margin: 10 }} />
            </div>
          </div>

          <Paper
            style={{
              padding: 30,
              borderLeft: '20px double ' + themeColors.mainColors.primaryColor,
              boxShadow: boxShadows.three,
            }}
          >
            <Editor
              initialValue=""
              apiKey="9h9g57gwpv98kda2tlz6pltsy0ae1muqb5dvgez27rvy2gsa"
              init={{
                height: 500,
                menubar: true,
                plugins: [
                  'advlist autolink autoresize lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor forecolor | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | code | help',
              }}
              onChange={handleEditorChange}
            />
          </Paper>
          <Paper
            style={{
              padding: 30,
              borderLeft: '20px double ' + themeColors.mainColors.primaryColor,
              margin: 'auto',
              marginTop: 45,
              boxShadow: boxShadows.three,
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              component="span"
              color="primary"
              style={{ height: '100%', maxWidth: '20vw' }}
              onClick={startArticleUpload}
            >
              Submit
            </Button>
            <FormControlLabel
              style={{ marginLeft: 20 }}
              control={
                <Checkbox
                  icon={<VisibilityOffTwoToneIcon fontSize="large" />}
                  checkedIcon={<VisibilityTwoToneIcon fontSize="large" />}
                  checked={articleVisibility}
                  onChange={handleArticleVisibilityChange}
                />
              }
              label="Publicly Visible"
            />
            <span style={{ fontSize: '.6rem' }}>
              It is recommended to leave this off until you view the draft on
              the actual site
            </span>
          </Paper>

          <div dangerouslySetInnerHTML={{ __html: body }} />
        </div>
      </div>
      <Dialog
        aria-labelledby="simple-dialog-title"
        open={uploading}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="simple-dialog-title" style={{ textAlign: 'center' }}>
          Uploading Post {`'${title}'`}
        </DialogTitle>
        <div
          style={{
            width: '100%',
            margin: '15px auto',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <img src={svgImgSrc} style={{ height: 250, margin: 10 }} />
        </div>
        <div style={{ width: '100%', padding: '15px 0' }}>
          <div style={{ width: '50%', margin: 'auto' }}>{summary}</div>
        </div>
        <div style={{ width: '95%', margin: ' 20px auto' }}>
          <LinearProgress
            variant="determinate"
            value={uploadPercentage}
            style={{ height: 10, borderRadius: 10 }}
          />
        </div>
      </Dialog>
      <style
        dangerouslySetInnerHTML={{
          __html: `
      .poststyling p {
          text-align: justify;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          font-size: 1.15em;
          font-family: 'Quattrocento', serif;
          }
        .poststyling ul {
          text-align: left;
        }
        .poststyling tbody {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          }
        .poststyling h2 {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          font-weight: 400;
          font-size: 1.7em;
          text-align: left;
          font-family: "trajan-pro-3", serif;
          }
        .poststyling h3 {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          text-align: left;
          font-family: "trajan-pro-3", serif;
          font-size: 1.45em;
          }
        .poststyling h1 {
          text-shadow: 1px 1px 2px rgba(0, 0, 0, .2);
          text-align: left;
          font-family: "trajan-pro-3", serif;
          }
        .poststyling img {
          float: right;
          margin-left: 15px;
          margin-bottom: 15px;
          width: 25vw;
          min-width: 225px;
          height: auto;
          border-radius: 10px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }`,
        }}
      />
    </>
  );
};
export default NewPost;
