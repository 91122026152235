import { useReducer } from 'react';

enum ACTION {
  setValue,
  setError,
  setErrorText,
  resetState,
}

type SetValueAction = { type: ACTION.setValue; payload: string };
type SetErrorAction = { type: ACTION.setError; payload: boolean };
type SetErrorTextAction = {
  type: ACTION.setErrorText;
  payload: string;
};
type ResetStateAction = { type: ACTION.resetState };

type FormFieldState = {
  value: string;
  error: boolean;
  errorText: string;
};

const initialInputState: FormFieldState = {
  value: '',
  error: false,
  errorText: '',
};

const reducer = (
  state: FormFieldState,
  action:
    | SetValueAction
    | SetErrorAction
    | SetErrorTextAction
    | ResetStateAction,
): FormFieldState => {
  switch (action.type) {
    case ACTION.setValue:
      return {
        ...state,
        value: action.payload,
      };
    case ACTION.setError:
      return {
        ...state,
        error: action.payload,
      };
    case ACTION.setErrorText:
      return {
        ...state,
        errorText: action.payload,
      };
    default:
      return initialInputState;
  }
};

type FormFieldOptions = {
  state: FormFieldState;
  setValue: (value: string) => void;
  setError: (error: boolean) => void;
  setErrorText: (errorText: string) => void;
  resetState: () => void;
};

const useFormFieldState = (initialValue?: string): FormFieldOptions => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialInputState,
    value: initialValue || '',
  });

  const setValue = (value: string): void => {
    dispatch({ type: ACTION.setValue, payload: value });
  };

  const setError = (error: boolean): void => {
    dispatch({ type: ACTION.setError, payload: error });
  };

  const setErrorText = (errorText: string): void => {
    dispatch({ type: ACTION.setErrorText, payload: errorText });
  };

  const resetState = (): void => {
    dispatch({ type: ACTION.resetState });
  };

  return { state, setValue, setError, setErrorText, resetState };
};

export { useFormFieldState };
