import React from 'react';
import styled from 'styled-components';
import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import { Loader } from '../../../../utils/Loader';
import { useColors } from '../../../../hooks';

const StyledInfoContainer = styled(Paper)<{
  mode: string;
  color: string;
  mobile: boolean | string;
}>`
  /* padding: 15px; */
  margin: 30px auto 45px;
  width: 80%;
  max-width: 750px;
  border-radius: 20px;
  display: grid;
  overflow: hidden;
  grid-template-columns: ${({ mobile }): string =>
    (mobile && '100%') || 'repeat(auto-fit, minmax(150px, 1fr))'};
  align-items: center;
  background-color: ${({ mode, color }): string =>
    (mode !== 'dark' && 'white') || color};
`;

const StyledInfoBox = styled.div<{ mobile: boolean }>`
  /* border: 1px solid blue; */
  ${({ mobile }): string => (mobile && 'border-bottom') || 'border-right'}: 1px
    outset lightgray;
  border-radius: 20px;
  padding: 10px;
  margin-right: -1.5px;
  margin-bottom: -1.5px;
`;

const StyledInfoBoxLabel = styled.div`
  align-items: center;
  text-align: center;
`;

const StyledInfoBoxAmount = styled.div<{
  isPositive?: boolean;
  colorCoded?: boolean;
  mode?: string;
}>`
  color: ${({ isPositive, colorCoded, mode }): string => {
    let colors = 'inherit';
    if (colorCoded) {
      if (mode === 'dark') {
        colors = (isPositive && 'lightgreen') || 'red';
      } else {
        colors = (isPositive && 'green') || 'red';
      }
    }
    return colors;
  }};
  .MuiTypography-h6 {
    font-weight: 700;
  }
`;

export const ExpenseInfo = ({ expenseSum, paidSum, loading }) => {
  const { themeColors } = useColors();
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <StyledInfoContainer
      mode={localStorage.getItem('theme_mode') ?? 'dark'}
      color={themeColors.mainColors.menuColor}
      mobile={isMobile}
    >
      <StyledInfoBox mobile={isMobile}>
        <StyledInfoBoxLabel>
          <Typography variant="caption">Total Expenses</Typography>
          <StyledInfoBoxAmount>
            <Typography variant="h6">
              {(loading && <Loader size={45} type="finger print" />) ||
                `$${expenseSum
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            </Typography>
          </StyledInfoBoxAmount>
        </StyledInfoBoxLabel>
      </StyledInfoBox>
      <StyledInfoBox mobile={isMobile}>
        <StyledInfoBoxLabel>
          <Typography variant="caption">Fees Collected</Typography>
          <StyledInfoBoxAmount>
            <Typography variant="h6">
              {(loading && <Loader size={45} type="finger print" />) ||
                `$${paidSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            </Typography>
          </StyledInfoBoxAmount>
        </StyledInfoBoxLabel>
      </StyledInfoBox>
      <StyledInfoBox mobile={isMobile}>
        <StyledInfoBoxLabel>
          <Typography variant="caption">Net Profit</Typography>
          <StyledInfoBoxAmount
            isPositive={parseFloat(paidSum) - expenseSum > 0}
            colorCoded
            mode={localStorage.getItem('theme_mode') ?? 'dark'}
          >
            <Typography variant="h6">
              {(loading && <Loader size={45} type="finger print" />) ||
                `$${(paidSum - expenseSum.toFixed(2))
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}
            </Typography>
          </StyledInfoBoxAmount>
        </StyledInfoBoxLabel>
      </StyledInfoBox>
    </StyledInfoContainer>
  );
};
